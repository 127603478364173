import { ChangeEvent, Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";
import { convertNumber, validaStringFormatDecimal } from "../Utilities/Utils";
import { ExtraAccessory } from "../model/interfaces";
import { ButtonProps } from "@mui/material";
import { Simulation } from "../model/Simulation";
import { Option } from "../model/interfaces";

import { IVehicle } from "../model/Vehicle";
import { GroupEditModalProps } from "@hi-interactive-admin/simulador-kinto/pages/proposal/modals/GroupEditModal";

import { IPreConfiguredVehicle } from "../model/PreConfiguredVehicle";
import { GroupEditModalProps as PreConfiguredGroupEditModalProps } from "@hi-interactive-admin/simulador-kinto/pages/backoffice-home/preconfigured-vehicles-management/GroupEditModal";
import { PreConfiguredVehicleState } from "../model/Enums";

const useVehicleGroupEditModalHook = (
  selectedRows: number[],
  // setVehicleDataTable: Dispatch<SetStateAction<IVehicle[]>>, 
  setVehicles: Dispatch<SetStateAction<IPreConfiguredVehicle[]>>,
  // setChangedProposal: Dispatch<SetStateAction<boolean>>,
  vendorsMemo: Option[]
) => {

  const stringPreConfiguredVehicleState = (value: string): PreConfiguredVehicleState => {
    if (Object.values(PreConfiguredVehicleState).includes(value as unknown as PreConfiguredVehicleState)) {
        return value as unknown as PreConfiguredVehicleState;
    }
    return PreConfiguredVehicleState.New;
  };
  
  const [editGroupExtraAccessories, setEditGroupExtraAccessories] = useState<ExtraAccessory[]>([]);
  const [vehicleGroupEditModalValues, setVehicleGroupEditModalValues] =
  useState<{
    // step 5
    incomeWithoutTax: number; // renda target
    incomeWithTax: number;
    collateral: number; // caução
    margin: number; // % margem
    increaseVr: number; // % aumento vr
    initialEntry: number; // entrada inicial
    expenses: number; // despesas de contrato
    marginCheck: boolean;
    commercialEffortCheck: boolean; //disabled -  same as margin check
    vrCheck: boolean;

    // step 2
    discountWithTax: number;
    discountWithoutTax: number;
    discountWithTaxPercentage: number;
    campaignWithTax: number;
    campaignWithTaxPercentage: number;
    campaignWithoutTax: number;
    rappelWithTax: number;
    rappelWithTaxPercentage: number;
    rappelWithoutTax: number;
    extraRappel: number;
    lotId: string;
    extraAccessories: {
      extraAcessoryName: string;
      extraAcessoryPrice: number;
      extraAcessoryInstaller: string;
    }[];
    state: PreConfiguredVehicleState | undefined;
  }>({
    incomeWithoutTax: 0,
    incomeWithTax: 0,
    collateral: 0,
    margin: 0,
    increaseVr:0,
    initialEntry: 0,
    expenses: 0,
    marginCheck: false,
    commercialEffortCheck: false,
    vrCheck: false,
    discountWithTax: 0,
    discountWithoutTax: 0,
    discountWithTaxPercentage: 0,
    campaignWithTax: 0,
    campaignWithTaxPercentage: 0,
    campaignWithoutTax: 0,
    rappelWithTax: 0,
    rappelWithTaxPercentage: 0,
    rappelWithoutTax: 0,
    extraRappel: 0,
    lotId: "",
    extraAccessories: [],
    state: undefined
  });

  
  function updateValuesVehicle(vehicle: IVehicle | IPreConfiguredVehicle, fieldName:string): IVehicle | IPreConfiguredVehicle {
    if(fieldName === "consumersPrice"){
       vehicle.priceexclVAT = convertNumber((
        convertNumber(vehicle.consumersPrice) / (1 + vehicle.vat / 100)).toFixed(2)
      );
    }  
    else {
        vehicle.consumersPrice = convertNumber((
          convertNumber(vehicle.priceexclVAT) * (1 + vehicle.vat / 100)
        ).toFixed(2)
      );
    }
    vehicle.discountWithTax = convertNumber((
        convertNumber(vehicle.discountWithoutTax) * (1 + vehicle.vat / 100)
      ).toFixed(2)
    );
    vehicle.discountWithTaxTotal = 
      // regra para envio de valor
      convertNumber((
        convertNumber(vehicle.discountWithoutTax) +
        ((convertNumber(vehicle.priceexclVAT) + convertNumber(vehicle.totalExtraWithoutTax)) * (Number(vehicle.discountWithTaxPercentage) / 100))
      ).toFixed(2)
      );

      // regra para envio de percentagem
      // convertNumber(
      //   ((convertNumber(vehicle.discountWithoutTax) / ( Number(vehicle.priceexclVAT) + vehicle.totalExtraWithoutTax ) )*100
      // ).toFixed(2));

    vehicle.campaignWithTax = convertNumber((
        Number(vehicle.campaignWithoutTax) * (1 + vehicle.vat / 100)
      ).toFixed(2)
    );
    vehicle.campaignWithTaxTotal =convertNumber((
        convertNumber(vehicle.campaignWithoutTax) +
        convertNumber(vehicle.priceexclVAT) * (Number(vehicle.campaignWithTaxPercentage) / 100)
      ).toFixed(2)
    );
    vehicle.rappelWithTax =convertNumber((
        Number(vehicle.rappelWithoutTax) * (1 + vehicle.vat / 100)
      ).toFixed(2)
    );
    let rappelTotal =convertNumber((
        convertNumber(vehicle.rappelWithoutTax) +
        convertNumber(vehicle.priceexclVAT) * (Number(vehicle.rappelWithTaxPercentage) / 100)
      ).toFixed(2)
    );
    //atualiza rappel
    vehicle.rappelWithTaxTotal = rappelTotal;

    vehicle.totalExtraWithoutTax =convertNumber((
        Number(vehicle.totalExtraWithTax) / (1 + vehicle.vat / 100)
      ).toFixed(2)
    );
    vehicle.totalAccessoriesWithout =convertNumber((
        Number(vehicle.totalAccessoriesWith) / (1 + vehicle.vat / 100)
      ).toFixed(2)
    );
   
    vehicle.proformaInvoicePriceWithoutTax = convertNumber((
        // convertNumber(vehicle.pvp) +
        // convertNumber(vehicle.totalAccessoriesWithout) +
        // convertNumber(vehicle.totalExtraWithoutTax) -
        // convertNumber(vehicle.discountWithTaxTotal) - 
        // convertNumber(vehicle.campaignWithTaxTotal)

        convertNumber(vehicle.priceexclVAT)
        + convertNumber(vehicle.luxury_tax)
        + convertNumber(vehicle.transport_x0026_immatriculation_cost)
        + convertNumber(vehicle.recycling_charge)

        + convertNumber(vehicle.totalAccessoriesWithout)
        + convertNumber(vehicle.totalExtraWithoutTax)

        - convertNumber(vehicle.discountWithTaxTotal) //envio valor
        // - convertNumber(vehicle.discountWithoutTax) //envio percentagem
        - convertNumber(vehicle.campaignWithTaxTotal)
      ).toFixed(2)
    );
    vehicle.proformaInvoicePriceWithTax =convertNumber((
        (
          convertNumber(vehicle.priceexclVAT)
          + convertNumber(vehicle.luxury_tax)
          + convertNumber(vehicle.transport_x0026_immatriculation_cost)
          + convertNumber(vehicle.recycling_charge)

          + convertNumber(vehicle.totalAccessoriesWithout)
          + convertNumber(vehicle.totalExtraWithoutTax)
          
          - convertNumber(vehicle.discountWithTaxTotal) //envio valor
          // - convertNumber(vehicle.discountWithoutTax) //envio percentagem
          - convertNumber(vehicle.campaignWithTaxTotal)
        ) * (1 + vehicle.vat / 100)
      ).toFixed(2)
    );
    vehicle.pvp = convertNumber((
        convertNumber(vehicle.priceexclVAT) +
        convertNumber(vehicle.totalAccessoriesWithout) +
        convertNumber(vehicle.totalExtraWithoutTax) +
        convertNumber(vehicle.luxury_tax) +
        convertNumber(vehicle.transport_x0026_immatriculation_cost) +
        convertNumber(vehicle.recycling_charge)
      ).toFixed(2)
    );

    vehicle.businessValueWithoutTax =convertNumber((
        convertNumber(vehicle.priceexclVAT) +

        convertNumber(vehicle.totalAccessoriesWithout) +
        convertNumber(vehicle.totalExtraWithoutTax) +

        convertNumber(vehicle.luxury_tax) +
        convertNumber(vehicle.transport_x0026_immatriculation_cost) +
        convertNumber(vehicle.recycling_charge) -

        convertNumber(vehicle.rappelWithTaxTotal) -
        convertNumber(vehicle.extraRappel) -

        convertNumber(vehicle.discountWithTaxTotal) -  //envio valor
        // convertNumber(vehicle.discountWithoutTax) -  //envio percentagem
        convertNumber(vehicle.campaignWithTaxTotal)
      ).toFixed(2)
    );
    vehicle.businessValueWithTax =convertNumber((
        (
          convertNumber(vehicle.priceexclVAT) +

          convertNumber(vehicle.totalAccessoriesWithout) +
          convertNumber(vehicle.totalExtraWithoutTax) +

          convertNumber(vehicle.luxury_tax) +
          convertNumber(vehicle.transport_x0026_immatriculation_cost) +
          convertNumber(vehicle.recycling_charge) -

          convertNumber(vehicle.rappelWithTaxTotal) -
          convertNumber(vehicle.extraRappel) -

          convertNumber(vehicle.discountWithTaxTotal) - //envio valor
          // convertNumber(vehicle.discountWithoutTax) - //envio percentagem
          convertNumber(vehicle.campaignWithTaxTotal)
        ) * (1 + vehicle.vat / 100)
      ).toFixed(2)
    );
    vehicle.totalExtraWithTax=convertNumber(vehicle.totalExtraWithTax.toFixed(2));
    // esta secção só se aplica no caso de a viatura ter simulações
    // no limite poderá ser necessário adicionar uma validação para as simulações
    // mas por defeito deverá retornar 'undefined' nos veículos pré configurados
    vehicle.simulations?.map((simula:Simulation) => {
      simula.discountWithTax = vehicle.discountWithTax;
      simula.discountWithTaxTotal = vehicle.discountWithTaxTotal;
      simula.campaignWithTax = vehicle.campaignWithTax;
      simula.campaignWithTaxTotal = vehicle.campaignWithTaxTotal;
      simula.rappelWithoutTax = rappelTotal;
      simula.extraRappel = vehicle.extraRappel;
      simula.proformaInvoicePriceWithoutTax = vehicle.proformaInvoicePriceWithoutTax;
      simula.proformaInvoicePriceWithTax = vehicle.proformaInvoicePriceWithTax;
      simula.businessValueWithoutTax = vehicle.businessValueWithoutTax;
      simula.businessValueWithTax = vehicle.businessValueWithTax;
      simula.totalAccessoriesWith = vehicle.totalAccessoriesWith;
      simula.totalAccessoriesWithout = vehicle.totalAccessoriesWithout;
      simula.totalExtraWithoutTax = vehicle.totalExtraWithoutTax;
      simula.totalExtraWithTax = vehicle.totalExtraWithTax;

      return simula
    })
   
    return vehicle;
  }

  const handleGroupEditModalApplyButtonClick: ButtonProps["onClick"] = useCallback((e: any) => {
    // setChangedProposal(true);
    // setVehicleDataTable((rows) => {
    setVehicles((rows) => {
      
      rows.map((vehicle, index) => {
        selectedRows.map((selectedRow) => {

          if (index !== selectedRow) {
            return vehicle;
          }

          vehicle.discountWithoutTax = vehicleGroupEditModalValues.discountWithoutTax;
          vehicle.discountWithTaxPercentage = vehicleGroupEditModalValues.discountWithTaxPercentage;
          vehicle.campaignWithoutTax = vehicleGroupEditModalValues.campaignWithoutTax;
          vehicle.campaignWithTaxPercentage = vehicleGroupEditModalValues.campaignWithTaxPercentage;

          vehicle.rappelWithoutTax = vehicleGroupEditModalValues.rappelWithoutTax;
          vehicle.rappelWithTaxPercentage = vehicleGroupEditModalValues.rappelWithTaxPercentage;
          vehicle.extraRappel = vehicleGroupEditModalValues.extraRappel;
          vehicle.lotId = vehicleGroupEditModalValues.lotId;
          vehicleGroupEditModalValues.extraAccessories.map((x) => {
            if (vehicle.licensePlate === "") {
              vehicle.accessories.push({
                id: 0,
                // preconfiguredVehicleId: vehicle.id,
                vehicleId: vehicle.id,
                optionTypeId: "",
                description: x.extraAcessoryName,
                responsable: x.extraAcessoryInstaller,
                price: x.extraAcessoryPrice * ((100 + vehicle.vat)/100),
                price_Excl_VAT:x.extraAcessoryPrice,
              });
              vehicle.totalAccessoriesWithout += x.extraAcessoryPrice;
              vehicle.totalAccessoriesWith = vehicle.totalAccessoriesWithout * ((100 + vehicle.vat)/100)
            }
            return x;
          });
          vehicle.state = vehicleGroupEditModalValues.state ? vehicleGroupEditModalValues.state : vehicle.state;
          updateValuesVehicle(vehicle,"");
          return selectedRow;
        })
        return vehicle;
      });

      return rows
    }); 
  },[
    selectedRows,
    vehicleGroupEditModalValues,
    // setChangedProposal,
    // setVehicleDataTable
    setVehicles
  ]);

  const onEditGroupExtraAccessoryPriceChange = useCallback((itemIndex:number) => (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>  {
    setVehicleGroupEditModalValues(current => {
      let extraAccessories = current.extraAccessories;
      extraAccessories[itemIndex].extraAcessoryPrice =convertNumber(e.target.value);
      return {
        ...current,
        extraAccessories: extraAccessories,
      };
    });
    setEditGroupExtraAccessories((rows) => {
      rows.map((row, index:Number) => {
        if (index === itemIndex) {
          row.ExtraAcessoryPriceTextFieldProps.value =convertNumber(validaStringFormatDecimal(e.target.value,row.ExtraAcessoryPriceTextFieldProps.value));
        }
        return row;
      })
      return rows;
    })
  }, []);

  const onEditGroupExtraAccessoryInstallerChange = useCallback((itemIndex:number) => (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>  {
    setVehicleGroupEditModalValues(current => {
      let extraAccessories = current.extraAccessories;
      extraAccessories[itemIndex].extraAcessoryInstaller = e.target.value;
      return {
        ...current,
        extraAccessories: extraAccessories,
      };
    });
    setEditGroupExtraAccessories((rows) => {
      rows.map((row, index:Number) => {
        if (index === itemIndex) {
          row.ExtraAcessoryInstallerDropDownProps.value = e.target.value;
        }
        return row;
      })
      return rows;
    });
  }, []);

  const onEditGroupExtraAccessoryDeleteClick = useCallback((itemIndex:number) => () => {
    let groupEditModalValues = vehicleGroupEditModalValues;
    groupEditModalValues.extraAccessories.splice(itemIndex, 1);
    setVehicleGroupEditModalValues(groupEditModalValues);

    let groupExtras = editGroupExtraAccessories;
    groupExtras.slice(itemIndex,1);
    setEditGroupExtraAccessories(groupExtras)

  }, [vehicleGroupEditModalValues,editGroupExtraAccessories]);

  const onEditGroupExtraAccessoryNameChange = useCallback((itemIndex:number) => (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>  {
    setVehicleGroupEditModalValues(current => {
      let extraAccessories = current.extraAccessories;
      extraAccessories[itemIndex].extraAcessoryName = e.target.value;
      return {
        ...current,
        extraAccessories: extraAccessories,
      };
    });

    setEditGroupExtraAccessories((rows) => {
      rows.map((row, index:Number) => {
        if (index === itemIndex) {
          row.ExtraAcessoryNameTextFieldProps.value = e.target.value;
        }
        return row;
      })
      return rows;
    })
  }, []);

  const handleGroupEditModalChange = useCallback((e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    let value: any;
    let discount: Number = 0;
    let campaign: Number = 0;
    let rappel: Number = 0;
    let targetIncomeWithTax: Number = 0;
    let targetIncomeWithoutTax: Number = 0;
    setVehicleGroupEditModalValues((current) => {
      if (
        e.target.name === "marginCheck" ||
        e.target.name === "commercialEffortCheck" ||
        e.target.name === "vrCheck"
      ) {
        value = (e.target as any).checked;
      } else if (e.target.name === "lotId") {
        value = e.target.value;
      } else if(e.target.name === 'state'){
        value = stringPreConfiguredVehicleState(e.target.value);
      } else {
        value = convertNumber(e.target.value);
        if (e.target.name === "discountWithoutTax"){
          discount = convertNumber((value * (1 + 23 / 100)).toFixed(2));
        } else if (e.target.name === "campaignWithoutTax"){
          campaign = convertNumber((value * (1 + 23 / 100)).toFixed(2));
        } else if (e.target.name === "rappelWithoutTax"){
          rappel = convertNumber((value * (1 + 23 / 100)).toFixed(2));
        } else if (e.target.name === "incomeWithoutTax" /*&& step  === 4*/ ) {
          targetIncomeWithoutTax = convertNumber((value).toFixed(2));
          targetIncomeWithTax = convertNumber((value * (1 + 23 / 100)).toFixed(2));
        } else if (e.target.name === "incomeWithTax" /*&& step  === 4*/ ) {
          targetIncomeWithTax = convertNumber((value).toFixed(2));
          targetIncomeWithoutTax = convertNumber((value / (1.23 )).toFixed(2));         
        }
      }
      // let updatedValues = {...current}

      return {
        ...current,
        [e.target.name]: value,
        discountWithTax:convertNumber(discount ? discount : current.discountWithTax),
        campaignWithTax:convertNumber(campaign ? campaign : current.campaignWithTax),
        rappelWithTax:convertNumber(rappel ? rappel : current.rappelWithTax),
        incomeWithTax:convertNumber(targetIncomeWithTax ? targetIncomeWithTax : current.incomeWithTax),
        incomeWithoutTax:convertNumber(targetIncomeWithoutTax ? targetIncomeWithoutTax : current.incomeWithoutTax),
      };
    });
  }, []);

  const onEditGroupAddAccessoryClick = useCallback(() =>  {
    let values = vehicleGroupEditModalValues
    values.extraAccessories.push({
      extraAcessoryName: "",
      extraAcessoryInstaller: "",
      extraAcessoryPrice: 0,
    });
    setVehicleGroupEditModalValues(values);
    const aux: GroupEditModalProps['extraAccessories'] | PreConfiguredGroupEditModalProps['extraAccessories'] = [];

    vehicleGroupEditModalValues.extraAccessories.forEach((item,index) => {
      return aux.push({
        ExtraAcessoryNameTextFieldProps : {
          label: "Nome",
          value: item.extraAcessoryName,
          onChange: onEditGroupExtraAccessoryNameChange(index),
        },
        ExtraAcessoryPriceTextFieldProps: {
          label: "Preço sem IVA",
          value: item.extraAcessoryPrice,
          onChange: onEditGroupExtraAccessoryPriceChange(index),
        },
        ExtraAcessoryInstallerDropDownProps: {
          label: "Responsável de instalação",
          placeholder: "Inserir nome do responsável",
          value: vendorsMemo.find((x) => {return x.value === item.extraAcessoryInstaller}) ?? '',
          options: vendorsMemo,
          onChange: onEditGroupExtraAccessoryInstallerChange(index),
        },
        deleteExtraAcessoryIconButtonProps:{
          onClick: onEditGroupExtraAccessoryDeleteClick(index),
        }
      });
    })

    setEditGroupExtraAccessories(aux);
  }, [
    onEditGroupExtraAccessoryDeleteClick, onEditGroupExtraAccessoryInstallerChange,
    onEditGroupExtraAccessoryNameChange, onEditGroupExtraAccessoryPriceChange,
    vehicleGroupEditModalValues, vendorsMemo, 
    // validar dependencias 10-04 editGroupExtraAccessories
  ]
      // ,  vehicleGroupEditModalValues, getEditGroupAccessories]
  );
  
  // const vehicleGroupEditModal: Omit<GroupEditModalProps, 'open'> | Omit<PreConfiguredGroupEditModalProps, 'open'> = useMemo(() => ({
  const vehicleGroupEditModal: Omit<PreConfiguredGroupEditModalProps, 'open'> = useMemo(() => ({
    title: 'Editar em grupo',
    // valores de negócio da viatura
    businessSettingsTitle: 'Configurações de negócio',
    discountWithoutTaxTextFieldProps: {
      name: "discountWithoutTax",
      placeholder: '0',      
      label: "Desconto sem e com IVA",
      value: vehicleGroupEditModalValues?.discountWithoutTax,
      endAdornment: '€',
      onChange: handleGroupEditModalChange,
      // label:  propertyHidden('Desconto s/IVA', 'Detalhes Viatura') ? '' : "Desconto sem e com IVA",
      // disabled: propertyDisabled('Desconto s/IVA', 'Detalhes Viatura'),
      // hidden: propertyHidden('Desconto s/IVA', 'Detalhes Viatura'),
    },
    discountWithTaxTextFieldProps: {
      name:"discountWithTax",
      placeholder: '0',
      value: vehicleGroupEditModalValues?.discountWithTax,
      endAdornment: '€',
      onChange: handleGroupEditModalChange,
      disabled: true,
      // disabled: propertyDisabled('Desconto c/IVA', 'Detalhes Viatura'),
      // hidden: propertyHidden('Desconto c/IVA', 'Detalhes Viatura'),
    },
    discountWithTaxPercentageTextFieldProps: {
      name:"discountWithTaxPercentage",
      placeholder: '0',
      value: vehicleGroupEditModalValues?.discountWithTaxPercentage,
      endAdornment: '%',
      onChange: handleGroupEditModalChange,
      // disabled: propertyDisabled('Desconto em percentagem', 'Detalhes Viatura'),
      // hidden: propertyHidden('Desconto em percentagem', 'Detalhes Viatura'),
    },

    CampaignWithoutTaxTextFieldProps: {
      name:"campaignWithoutTax",
      placeholder: '0',
      label: "Campanha sem e com IVA",
      value: vehicleGroupEditModalValues?.campaignWithoutTax,
      endAdornment: '€',
      onChange: handleGroupEditModalChange,
      // disabled: propertyDisabled('Campanha s/Iva', 'Detalhes Viatura'),
      // hidden: propertyHidden('Campanha s/Iva', 'Detalhes Viatura'),
      // label: propertyHidden('Campanha s/Iva', 'Detalhes Viatura') ? '' : "Campanha sem e com IVA",
    },
    // disabled by default
    CampaignWithTaxTextFieldProps: {
      name:"campaignWithTax",
      placeholder: '0',
      value: vehicleGroupEditModalValues?.campaignWithTax,
      endAdornment: '€',
      onChange: handleGroupEditModalChange,
      disabled: true,
      // disabled: propertyDisabled('Campanha c/Iva', 'Detalhes Viatura'),
      // hidden: propertyHidden('Campanha c/Iva', 'Detalhes Viatura'),
    },
    CampaignWithTaxPercentageTextFieldProps: {
      name:"campaignWithTaxPercentage",
      placeholder: '0',
      value: vehicleGroupEditModalValues?.campaignWithTaxPercentage,
      endAdornment: '%',
      onChange: handleGroupEditModalChange,
      // disabled: propertyDisabled('Campanha em percentagem', 'Detalhes Viatura'),
      // hidden: propertyHidden('Campanha em percentagem', 'Detalhes Viatura'),
    },
    
    rappelWithoutTaxTextFieldProps: {
      name:"rappelWithoutTax",
      placeholder: '0',
      label: "Rappel sem e com IVA",
      value: vehicleGroupEditModalValues?.rappelWithoutTax,
      endAdornment: '€',
      onChange: handleGroupEditModalChange,
      // label: propertyHidden('Rappel s/Iva') ? '' : "Rappel sem e com IVA",
      // disabled: propertyDisabled('Rappel s/Iva', 'Detalhes Viatura'),
      // hidden: propertyHidden('Rappel s/Iva', 'Detalhes Viatura'),
    },
    // disabled by default
    rappelWithTaxTextFieldProps: {
      name:"rappelWithTax",
      placeholder: '0',
      value: vehicleGroupEditModalValues?.rappelWithTax,
      endAdornment: '€',
      onChange: handleGroupEditModalChange,
      disabled: true,
      // disabled: propertyDisabled('Rappel c/Iva', 'Detalhes Viatura'),
      // hidden: propertyHidden('Rappel c/Iva', 'Detalhes Viatura'),
    },
    rappelWithTaxPercentageTextFieldProps: {
      name:"rappelWithTaxPercentage",
      placeholder: '0',
      value: vehicleGroupEditModalValues?.rappelWithTaxPercentage,
      endAdornment: '%',
      onChange: handleGroupEditModalChange,
      // disabled: propertyDisabled('Rappel em percentagem', 'Detalhes Viatura'),
      // hidden: propertyHidden('Rappel em percentagem', 'Detalhes Viatura'),
    },
    extraRappelTextFieldProps: {
      name: "extraRappel",
      label: "Rappel extra",
      placheholder: '0',
      value: vehicleGroupEditModalValues?.extraRappel,
      endAdornment: '€',
      // label: propertyHidden('Rappel extra') ? '' : "Rappel extra",
      // disabled: propertyDisabled('Rappel extra', 'Detalhes Viatura'),
      // hidden: propertyHidden('Rappel extra', 'Detalhes Viatura'),
    },
    // acessórios
    manuallyAddedAccessoriesTitle: 'Acessórios adicionados manualmente',
    addAccessoryButtonText: 'Adicionar Acessório',
    addAccessoryButtonProps: {
      onClick: onEditGroupAddAccessoryClick,
    },
    // extraAccessories: [
    //   {
    //     ExtraAcessoryNameTextFieldProps: {
    //       label: 'Nome',
    //       placeholder: 'Inserir nome',
    //     },
    //     ExtraAcessoryPriceTextFieldProps: {
    //       label: 'Preço',
    //       placeholder: '0',
    //       endAdornment: '€',
    //     },
    //     ExtraAcessoryInstallerDropDownProps: {
    //       label: 'Responsável de instalação',
    //       placeholder: 'Inserir nome',
    //       options: [],
    //     },
    //     deleteExtraAcessoryIconButtonProps: {},
    //   },
    // ],
    extraAccessories: editGroupExtraAccessories,
    // lote
    associateLotTitle: 'Associar lote',
    lotIDTextFieldProps: {
      label: "Nome Identificativo do lote",
      value: vehicleGroupEditModalValues?.lotId,
      name:"lotId",
      onChange: handleGroupEditModalChange,
      // label: propertyHidden('Nome Identificativo do Lote', 'Detalhes Viatura') ? '' :"Nome Identificativo do lote",
      // disabled: propertyDisabled('Nome Identificativo do Lote', 'Detalhes Viatura'),
      // hidden: propertyHidden('Nome Identificativo do Lote', 'Detalhes Viatura'),
    },
    // tipo de viatura
    otherFieldsTitle: 'Outros Campos',
    pvpDropdown: {
      name: 'state',
      label: 'Tipo de Utilização',
      placeholder: 'Escolher opção',
      options: [
        {
          label: 'Novo',
          value: 'New',
        },
        {
          label: 'Usado',
          value: 'Used',
        }
      ],
      value: vehicleGroupEditModalValues?.state,
      onChange: handleGroupEditModalChange,
    },
    cancelButtonText: 'Cancelar',
    handleClose: () => {
      setVehicleGroupEditModalValues({
        incomeWithoutTax: 0,
        incomeWithTax: 0,
        collateral:0,
        margin: 0,
        increaseVr:0,
        initialEntry: 0,
        expenses: 0,
        marginCheck: false,
        commercialEffortCheck: false,
        vrCheck: false,
        discountWithoutTax: 0,
        discountWithTax: 0,
        discountWithTaxPercentage: 0,
        campaignWithoutTax: 0,
        campaignWithTax: 0,
        campaignWithTaxPercentage: 0,
        rappelWithoutTax: 0,
        rappelWithTax: 0,
        rappelWithTaxPercentage: 0,
        extraRappel: 0,
        lotId: "",
        extraAccessories: [],
        state: undefined
      });
      setEditGroupExtraAccessories([]);
    },
    confirmButtonText: 'Aplicar',
    confirmButtonProps: {
      onClick: handleGroupEditModalApplyButtonClick,
    }

  }), [
    editGroupExtraAccessories, 
    handleGroupEditModalApplyButtonClick, 
    handleGroupEditModalChange, 
    onEditGroupAddAccessoryClick, 
    vehicleGroupEditModalValues
  ]);

  return { vehicleGroupEditModal };
};
export default useVehicleGroupEditModalHook;