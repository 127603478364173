import { Alert, AlertProps, RecoverPasswordPage, TextFieldProps } from '@hi-interactive-admin/simulador-kinto';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { UserServices } from '../../services/UserServices';
import { UserModel } from '../../model/User';
// import ForgotPassword from '../forgot-password/ForgotPassword';

type RecoverPasswordRouteParams = {
    token: string;
  };

interface IRecoveryEmailForm {
  email: string;
}

const userService = new UserServices();

const RecoverPassword = () => {
  const params = useParams<RecoverPasswordRouteParams>();
  // constantes de mensagems de aviso 
  //inicio
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [alertProps, setAlertProps] = useState<
    Omit<AlertProps, "open" | "onClose">
  >({
    variant: "info",
    text: "",
    title: "",
  });

  const openAlert = (alertProps: Omit<AlertProps, "open" | "onClose">) => {
    setAlertProps(alertProps);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  //fim

  const [dataUserCard, setDataUserCard] = useState<UserModel>({
    userId: 0,
    username: "",
    friendlyName:"",
    avatarSrc:"",
    refreshToken: "",
    typeofIncome:"",
    typeofRental:"",
    commercialConditions:"",
    webPortalContactId:"",
    email:"",
    isAdministrator: false,
    profileId: undefined,
    profile: undefined,
    concessions:[],
    created: new Date(Date.now()),
    createdByUserId:0,
    active:false,
  });

  const [recoveryEmailForm, setForgotPasswordForm] =
    useState<IRecoveryEmailForm>({
      email: '',
    });

  const handleChange: TextFieldProps['onChange'] = (e) => {
    setForgotPasswordForm({
      ...recoveryEmailForm,
      [e.target.name]: e.target.value,
    });
  };

  const handleForgotPasswordClick = useCallback(() => {
    if (recoveryEmailForm.email === "") {
      openAlert({
        variant: "error",
        text: "Definir email para recuperar a password no nome do utilizador!",
        title: "Erro",
      });
    } else {
      userService
        .sendEmailForgotPassword(recoveryEmailForm.email)
        .then((data) => {
          if( data.succeeded){
              openAlert({
              variant: "success",
              text: "Email enviado com sucesso",
              title: "Atenção",
              autoHideDuration: 3000,
            });
          } else {          
            openAlert({
              variant: "error",
              text: data.message,
              title: "Erro",
            });
          }
        });
    }

  },[recoveryEmailForm.email]);

  return (
    <>
      <RecoverPasswordPage
        containerProps={{
          leftSectionContent: 'Todos os caminhos vão dar à mobilidade.',
        }}
        formProps={{
          title: 'Recuperar palavra passe',
          subTitle: 'Insira o e-mail para recuperar a sua password.',
          emailTextFieldProps: {
            label: 'E-mail',
            value: recoveryEmailForm.email,
            name: 'email',
            onChange: handleChange,
          },
          sendEmailButtonText: 'Enviar e-mail',
          sendEmailButtonProps: {
            onClick: handleForgotPasswordClick,
          },
        }}
      />
      <Alert open={open} onClose={handleClose} {...alertProps} />
    </>
  );
};

export default RecoverPassword;
