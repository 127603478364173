import React, { createContext, useState, useContext } from 'react';

const AsyncRequestContext = createContext();

export const AsyncRequestProvider = ({ children }) => {
  const [asyncResponse, setAsyncResponse] = useState(null);

  return (
    <AsyncRequestContext.Provider value={{ asyncResponse, setAsyncResponse }}>
      {children}
    </AsyncRequestContext.Provider>
  );
}

export const useAsyncRequest = () => useContext(AsyncRequestContext);
