import { Client, theme } from '@hi-interactive-admin/simulador-kinto';

export const client1: Client = {
  name: 'EGEO Pressão, S.A.',
  clientId: 'client2',
  ceo: { label: 'Gestor de conta', value: 'Luis Brazão' },
  commercialConditions: {
    label: 'Condições comerciais',
    value: 'Grandes Empresas (Frota > 30 Veículos)',
    //  hidden: true, Esconder propriedade
  },
  commercialId: { label: 'Registo comercial', value: 'IRN146100NN' },
  commercialGroup: { label: 'Grupo empresarial', value: 'EGEO Pressão, S.A.' },
  email: { label: 'Email', value: 'info@egeo.com' },
  identification: {
    label: 'Identificação',
    value: 'Empresarial  |  NIF 514986298',
  },
  mainActivity: {
    label: 'Atividade principal',
    value: 'Limpeza e Manutenção Industrial',
    //hidden: true,
  },
  nif: { label: 'Número de contribuinte', value: '514986298' },

  plafond: { label: 'Plafond', value: '0,00€' },
  sedAddress: {
    label: 'Morada Sede',
    value:
      'Rua 25 de Abril, Nº 1, Quinta da Francelha de Baixo 2685-368 Prior Velho',
  },
  socialCapital: { label: 'Capital social', value: '250.000,00 €' },
  socialName: {
    label: 'Denominação social',
    value: 'EGEO Circular, S.A. e EGEO Pressão, S.A.',
  },
  telf: { label: 'Telf.', value: '213342027', hidden: true },
  telm: { label: 'Telm.', value: '913326541' },
  type: { label: '', value: '' },
  allVehicles: {
    label: 'Total de viaturas na frota do Cliente',
    value: '40',
    //hidden: true,
  },
  vehiclesPercentage: {
    label: 'Viaturas contratadas vs total da frota (%) ',
    value: '35%',
    //hidden: true,
  },
  contractToFinish: '2 contratos a terminar',
  haveContractToFinish: false,
  performedProposals: { label: 'Nº de propostas realizadas', value: 34 },
  statiticsTotal: { label: 'Propostas', value: '45.045,76€' },
  statisticsData: [
    { name: 'Ganhas', value: 35 },
    { name: 'Pendentes', value: 30 },
    { name: 'Perdidas', value: 35 },
  ],
  statisticsColors: [
    theme.colors.primary.teal,
    theme.colors.secondary.teal300,
    theme.colors.secondary.aqua200,
    theme.colors.neutrals.grey200,
  ],
  shareholders: {
    label: 'Sócial ou Acionistas principais',
    value: [
      { value: 'Gonçalo Marques' },
      { value: 'Andreia Gonçalves' },
      { value: 'Andreia Gonçalves' },
    ],
  },
  shareholdersParticipation: {
    label: 'Participação no capital',
    value: '837,40€',
  },
  balance: {
    label: 'Saldo atual',
    value: '250.436,87 €',
  },
  contractedVehicles: {
    label: 'Nº de viaturas',
    value: '5',
  },
  contractedVehiclesValue: {
    label: 'Valor médio das viaturas',
    value: '33.436,87 €',
  },
  debt: {
    label: 'Valor em dívida',
    value: '11.436,87 €',
  },
  proposalVehicles: {
    label: 'Nº de viaturas',
    value: '5',
  },
  proposalVehiclesValue: {
    label: 'Valor médio das viaturas',
    value: '33.436,87 €',
  },
  profitabilityData: [
    {
      name: 'Jan',
      value: 4000,
      mid: 1000,
    },
    {
      name: '',
      value: 2400,
      mid: 1000,
    },
  ],
  documents: [
    { name: 'Contrato de quadro', type: 'Assinado', id: '1', valid: true },
  ],
};

export const client2: Client = {
  name: 'EGEO Pressão, S.A.',
  clientId: 'client2',
  ceo: { label: 'Gestor de conta', value: 'Luis Brazão' },
  commercialConditions: {
    label: 'Condições comerciais',
    value: 'Grandes Empresas (Frota > 30 Veículos)',
  },
  commercialId: { label: 'Registo comercial', value: 'IRN146100NN' },
  commercialGroup: { label: 'Grupo empresarial', value: 'EGEO Pressão, S.A.' },
  email: { label: 'Email', value: 'info@egeo.com' },
  identification: {
    label: 'Identificação',
    value: 'Empresarial  |  NIF 514986298',
  },
  mainActivity: {
    label: 'Atividade principal',
    value: 'Limpeza e Manutenção Industrial',
  },
  nif: { label: 'Número de contribuinte', value: '514986298' },

  plafond: { label: 'Plafond', value: '0,00€' },
  sedAddress: {
    label: 'Morada Sede',
    value:
      'Rua 25 de Abril, Nº 1, Quinta da Francelha de Baixo 2685-368 Prior Velho',
  },
  socialCapital: { label: 'Capital social', value: '250.000,00 €' },
  socialName: {
    label: 'Denominação social',
    value: 'EGEO Circular, S.A. e EGEO Pressão, S.A.',
  },
  telf: { label: 'Telf.', value: '213342027' },
  telm: { label: 'Telm.', value: '913326541' },
  type: { label: '', value: '' },
  allVehicles: {
    label: 'Total de viaturas na frota do Cliente',
    value: '40',
  },
  vehiclesPercentage: {
    label: 'Viaturas contratadas vs total da frota (%) ',
    value: '35%',
  },
  contractToFinish: '2 contratos a terminar',
  haveContractToFinish: false,
  performedProposals: { label: 'Nº de propostas realizadas', value: 34 },
  statiticsTotal: { label: 'Propostas', value: '45.045,76€' },
  statisticsData: [
    { name: 'Ganhas', value: 35 },
    { name: 'Pendentes', value: 30 },
    { name: 'Perdidas', value: 35 },
  ],
  statisticsColors: [
    theme.colors.primary.teal,
    theme.colors.secondary.teal300,
    theme.colors.secondary.aqua200,
    theme.colors.neutrals.grey200,
  ],
  shareholders: {
    label: 'Sócial ou Acionistas principais',
    value: [
      { value: 'Gonçalo Marques' },
      { value: 'Andreia Gonçalves' },
      { value: 'Andreia Gonçalves' },
    ],
  },
  shareholdersParticipation: {
    label: 'Participação no capital',
    value: '837,40€',
  },
  balance: {
    label: 'Saldo atual',
    value: '250.436,87 €',
  },
  contractedVehicles: {
    label: 'Nº de viaturas',
    value: '5',
  },
  contractedVehiclesValue: {
    label: 'Valor médio das viaturas',
    value: '33.436,87 €',
  },
  debt: {
    label: 'Valor em dívida',
    value: '11.436,87 €',
  },
  proposalVehicles: {
    label: 'Nº de viaturas',
    value: '5',
  },
  proposalVehiclesValue: {
    label: 'Valor médio das viaturas',
    value: '33.436,87 €',
  },
  profitabilityData: [
    {
      name: 'Jan',
      value: 100000,
      mid: 90000,
    },
    {
      name: 'Fev',
      value: 250000,
      mid: 220000,
    },
    {
      name: 'Mar',
      value: 275000,
      mid: 245000,
    },
    {
      name: 'Abr',
      value: 80000,
      mid: 50000,
    },
    {
      name: 'Mai',
      value: 90000,
      mid: 70000,
    },
    {
      name: 'Jun',
      value: 95000,
      mid: 85000,
    },
  ],
  documents: [
    { name: 'Contrato de quadro', type: 'Assinado', id: '1', valid: true },
    { name: 'Contrato 2', type: 'Assinado', id: '2', valid: true },
    { name: 'Contrato 4', type: 'Assinado', id: '3', valid: true },
    { name: 'Contrato 5', type: 'Assinado', id: '4', valid: true },
  ],
};
