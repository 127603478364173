import { GroupProposal, Proposal } from "@hi-interactive-admin/simulador-kinto";

export enum ColorEnum {
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
  SUCCESS = "success",
}

export const proposals: Proposal[] = [
  {
    id: "proposal1",
    client: { name: "EGEO Pressão, S.A.", type: "Particular" },
    concession: "Caetano Retail Norte",
    number: { value: "C010089803", label: "Nº da proposta" },
    state: {
      label: "Estado",
      tagLabel: "Pendente",
      color: "info",
      description: "descrição",
    },
    created: { value: "17 Jun. 2020", label: "Criada" },
    channel: { value: "Direto", label: "Canal" },
    key: "José Bernardo",
    negociation: {
      label: "Em negociação",
      vehicles: "1 veículo",
      warning: true,
      warningDescription: "Mudança de preço",
    },
    driver: [{ name: "João Silva", date: "00.00.00" }],
    rentability: {
      label: "Rentabilidade",
      value: "30%",
    },
  },
  {
    id: "proposal2",
    client: { name: "Roas santo", type: "Particular" },
    concession: "Caetano Retail Norte",
    number: { value: "C010089803", label: "Nº da proposta" },
    state: {
      label: "Estado",
      tagLabel: "Ganha",
      color: "success",
      description: "Aguarda validação",
    },
    created: { value: "17 Jun. 2020", label: "Criada" },
    channel: { value: "Direto", label: "Canal" },
    key: "José Bernardo",
    negociation: {
      label: "Em negociação",
      vehicles: "2 veículos",
      warning: false,
      warningDescription: "",
    },
    driver: [
      { name: "João Silva", date: "00.00.00" },
      { name: "Carlos Almeida", date: "00.00.00" },
    ],
    rentability: {
      label: "Rentabilidade",
      value: "30%",
    },
  },
  {
    id: "proposal3",
    client: { name: "Albino Aires", type: "Empresarial" },
    concession: "Caetano Retail Norte",
    number: { value: "C010089803", label: "Nº da proposta" },
    state: {
      label: "Estado",
      tagLabel: "Perdida",
      color: "warning",
      description: "Enviada ao cliente",
    },
    created: { value: "17 Jun. 2020", label: "Criada" },
    channel: { value: "Direto", label: "Canal" },
    key: "José Bernardo",
    negociation: {
      label: "Em negociação",
      vehicles: "3 veículos",
      warning: false,
      warningDescription: "",
    },
    driver: [],
    rentability: {
      label: "Rentabilidade",
      value: "30%",
    },
  },
];

export const groupProposals: GroupProposal[] = [
  {
    onHeadClick: () => {
      return;
    },
    clientName: "EGEO Pressão, S.A.",
    clientId: "12",
    clientType: "Empresarial",
    nif: "NIF 123456789",
    allProposals: 34,
    pendentProposals: 3,
    proposals: [
      {
        id: "proposal1",
        client: { name: "EGEO Pressão, S.A.", type: "Particular" },
        concession: "Caetano Retail Norte",
        number: { value: "C010089803", label: "Nº da proposta" },
        state: {
          label: "Estado",
          tagLabel: "Pendente",
          color: ColorEnum.INFO,
          description: "descrição",
        },
        created: { value: "17 Jun. 2020", label: "Criada" },
        channel: { value: "Direto", label: "Canal" },
        key: "José Bernardo",
        negociation: {
          label: "Em negociação",
          vehicles: "1 veículo",
          warning: true,
          warningDescription: "Mudança de preço",
        },
        driver: [{ name: "João Silva", date: "00.00.00" }],
        rentability: {
          label: "Rentabilidade",
          value: "30%",
        },
      },
      {
        id: "proposal2",
        client: { name: "Roas santo", type: "Particular" },
        concession: "Caetano Retail Norte",
        number: { value: "C010089803", label: "Nº da proposta" },
        state: {
          label: "Estado",
          tagLabel: "Ganha",
          color: ColorEnum.SUCCESS,

          description: "Aguarda validação",
        },
        created: { value: "17 Jun. 2020", label: "Criada" },
        channel: { value: "Direto", label: "Canal" },
        key: "José Bernardo",
        negociation: {
          label: "Em negociação",
          vehicles: "2 veículos",
          warning: false,
          warningDescription: "",
        },
        driver: [{ name: "João Silva", date: "00.00.00" }],
        rentability: {
          label: "Rentabilidade",
          value: "30%",
        },
      },
      {
        id: "proposal3",
        client: { name: "Albino Aires", type: "Empresarial" },
        concession: "Caetano Retail Norte",
        number: { value: "C010089803", label: "Nº da proposta" },
        state: {
          label: "Estado",
          tagLabel: "Perdida",
          color: ColorEnum.WARNING,

          description: "Enviada ao cliente",
        },
        created: { value: "17 Jun. 2020", label: "Criada" },
        channel: { value: "Direto", label: "Canal" },
        key: "José Bernardo",
        negociation: {
          label: "Em negociação",
          vehicles: "3 veículos",
          warning: false,
          warningDescription: "",
        },
        driver: [{ name: "João Silva", date: "00.00.00" }],
        rentability: {
          label: "Rentabilidade",
          value: "30%",
        },
      },
    ],
    //onHeadClick: () => alert('do something'),
  },
  {
    onHeadClick: () => {
      return;
    },
    clientId: "14",
    clientName: "Galp, S.A.",
    clientType: "Empresarial",
    nif: "NIF 123456789",
    allProposals: 34,
    pendentProposals: 3,
    proposals: [
      {
        id: "proposal1",
        client: { name: "EGEO Pressão, S.A.", type: "Particular" },
        concession: "Caetano Retail Norte",
        number: { value: "C010089803", label: "Nº da proposta" },
        state: {
          tagLabel: "Pendente",
          label: "Estado",
          color: ColorEnum.INFO,

          description: "descrição",
        },
        created: { value: "17 Jun. 2020", label: "Criada" },
        channel: { value: "Direto", label: "Canal" },
        key: "José Bernardo",
        negociation: {
          label: "Em negociação",
          vehicles: "1 veículo",
          warning: true,
          warningDescription: "Mudança de preço",
        },
        driver: [{ name: "João Silva", date: "00.00.00" }],
        rentability: {
          label: "Rentabilidade",
          value: "30%",
        },
      },
      {
        id: "proposal2",
        client: { name: "Roas santo", type: "Particular" },
        concession: "Caetano Retail Norte",
        number: { value: "C010089803", label: "Nº da proposta" },
        state: {
          label: "Estado",
          tagLabel: "Ganha",
          color: ColorEnum.SUCCESS,

          description: "Aguarda validação",
        },

        created: { value: "17 Jun. 2020", label: "Criada" },
        channel: { value: "Direto", label: "Canal" },
        key: "José Bernardo",
        negociation: {
          vehicles: "2 veículos",
          label: "Em negociação",
          warning: false,
          warningDescription: "",
        },
        driver: [{ name: "João Silva", date: "00.00.00" }],
        rentability: {
          label: "Rentabilidade",
          value: "30%",
        },
      },
    ],
    //onHeadClick: () => alert('do something'),
  },
];
