import { TagProps } from '@hi-interactive-admin/simulador-kinto';
import { useCallback, useMemo } from 'react';
import { ColorEnum } from '../../model/Enums';
import { Option } from "../../model/interfaces";


const useProposalStatesHook = (state:string, description: string) => {

  const handleState = useCallback(( estado:string, descricao:string) =>  {
    let result: {
      label: string;
      tagLabel: string;
      color: TagProps['color'];
      description: string;
     } = {
      label: 'Estado',
      tagLabel: 'Erro',
      color: ColorEnum.ERROR,
      description: descricao
    }

    if (estado === 'Open') {
      result = {
        label: 'Estado',
        tagLabel: 'Na Comercial',
        color: ColorEnum.INFO,
        description: descricao
      }
    }
    else if (estado === 'On Procurement') {
      result = {
        label: 'Estado',
        tagLabel: 'No Procurement',
        color: ColorEnum.WARNING,
        description: descricao
      }
    }
    else if (estado === 'In Comercial Approving') {
      result = {
        label: 'Estado',
        tagLabel: 'Na Aprovação Comercial',
        color: ColorEnum.WARNING,
        description: descricao
      }
    }
    else if (estado === 'On Customer') {
      result = {
        label: 'Estado',
        tagLabel: 'No Cliente',
        color:  ColorEnum.ERROR, //ColorEnum.INFO,
        description: descricao
      }
    }
    else if (estado === 'Cancelled') {
      result = {
        label: 'Estado',
        tagLabel: 'Fechado',
        color: ColorEnum.SUCCESS,
        description: descricao
      }
    }
    else if (estado === 'On Risk Analysis') {
      result = {
        label: 'Estado',
        tagLabel: 'Na Análise de Risco',
        color: ColorEnum.ERROR, //ColorEnum.INFO,
        description: descricao
      }
    }
    return result;
  },[]);

  const proposalStateMemo = useMemo(() => {
    return handleState(state, description);
  }, [handleState, state, description]);

  const proposalStateOptions: {label: string}[] = [
    {label: 'Na Comercial'},
    {label: 'No Procurement'},
    {label: 'Na Aprovação Comercial'},
    {label: 'No Cliente'},
    {label: 'Fechado'},
    {label: 'Na Análise de Risco'}
  ];

  const proposalStateDetailsScreenPermittedOptions: Option[] = [
    // {label: 'No Procurement', value: 'On Procurement'},
    // {label: 'Na Aprovação Comercial', value:'In Comercial Approving' },
    {label: 'No Cliente', value: 'On Customer'},
    {label: 'Fechado', value: 'Cancelled'},
    {label: 'Na Análise de Risco', value: 'On Risk Analysis'}
  ];

  return {proposalStateMemo, proposalStateOptions, proposalStateDetailsScreenPermittedOptions, handleState};
}
export default useProposalStatesHook;