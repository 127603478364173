import { CheckboxProps } from "@hi-interactive-admin/simulador-kinto";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { ContactsService } from "../../../services/ContactsService";

const contactsService = new ContactsService();

const useClientNamesCustomHook  = () => {

  const [clientNames, setClientNames] = useState<CheckboxProps[]>([]);
  const handleToggleSelectClientNameCallback = useCallback((optionLabel:string) => () => {
    setClientNames((rows:any[]) => 
      rows.map(row => {
        if ( row.label === optionLabel)
        {
          row.checked = !row.checked
        }
        return row;
      })
    )
  },[]);

  const handleClientNames = useCallback((clientNamesTmp:CheckboxProps[]) => {
    let clientNamesOptions: any[] = clientNamesTmp;
    if ( clientNamesOptions.length !== 0) {
      setClientNames((rows:any[]) => {
        rows = clientNamesOptions;
        return rows;
      })
      return clientNamesOptions;
    }

    contactsService.getContacts().then((data) => {
      if(data && data.succeeded === true){
        data.value.forEach((element:any) => {
          clientNamesOptions.push({
            label:element.name,
            checked:false,
            onClick: handleToggleSelectClientNameCallback(element.name),
          })          
        });
        setClientNames((rows:any[]) => {
          rows = clientNamesOptions
          return rows;
        })
        // return clientNames;
      }
    })
    return clientNamesOptions;
  },[handleToggleSelectClientNameCallback]);

  const clientNameMemo = useMemo(() => handleClientNames(clientNames),[clientNames, handleClientNames])
  const handleClientNameTextFieldPropsCallback = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let clientNamesOptions: any[any] = [];
    if( e.target.value !== "" && e.target.value.length > 2){
      contactsService.getContactsByName(e.target.value).then(data => {
        if(data.succeeded === true && data.value.length > 0){
          data.value.forEach((element:any) => {
            clientNamesOptions.push({
              label:element.name,
              checked:false,
              onClick: handleToggleSelectClientNameCallback(element.name),
            })
            
          })
          setClientNames((rows:any[]) => {
            return rows = clientNamesOptions;
          })
        }
      })
    }
    else if (e.target.value === "") {
      contactsService.getContacts().then(data => {
        if(data.succeeded === true){
          data.value.forEach((element:any, index:number) => {
            clientNamesOptions.push({
              label:element.name,
              checked:false,
              onClick: handleToggleSelectClientNameCallback(element.name),
            })
          });
          setClientNames((rows:any[]) => {
            rows = clientNamesOptions;
            return rows;
          })
        }
      });
    }
    else {
      return clientNamesOptions;
    }
  },[handleToggleSelectClientNameCallback])
  //FIM Nome do Cliente

  return { clientNames, handleClientNameTextFieldPropsCallback , clientNameMemo };
};

export default useClientNamesCustomHook;