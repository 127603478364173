import {
  LoginPage,
  TextFieldProps,
  Alert,
  AlertProps,
} from "@hi-interactive-admin/simulador-kinto";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserServices } from "../../services/UserServices";
//import { UserModel } from "../../model/User";
import { Auth } from "../../services/Auth";
import { LoginProps } from "../../model/KintoComponentProps";
// import { SettingsService } from "../../services/SettingsService";

interface ILoginForm {
  username: string;
  password: string;
}
const userService = new UserServices();

const Login: React.FC<LoginProps>  = ({ onLogin }) => {
  // const settingsService = SettingsService.getInstance();
  
  const [open, setOpen] = useState<boolean>(false);
  const [alertProps, setAlertProps] = useState<
    Omit<AlertProps, "open" | "onClose">
  >({
    variant: "info",
    text: "",
    title: "",
  });

  // const [dataUserCard, setDataUserCard] = useState<UserModel>({
  //   userId: 0,
  //   username: "",
  //   friendlyName:"",
  //   avatarSrc:"",
  //   token: "",
  //   typeofIncome:"",
  //   typeofRental:"",
  //   commercialConditions:"",
  //   webPortalContactId:"",
  //   email:"",
  //   isAdministrator: false,
  //   profileId: undefined,
  //   profile: undefined,
  //   concessions: [],
  //   created: new Date(Date.now()),
  //   createdByUserId:0,
  //   active:false,
  // });

  const openAlert = (alertProps: Omit<AlertProps, "open" | "onClose">) => {
    setAlertProps(alertProps);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [showingPassword, setShowingPassword] = useState(false);
  const [loginForm, setLoginForm] = useState<ILoginForm>({
    password: "",
    username: "",
  });

  const handleChange: TextFieldProps["onChange"] = useCallback((e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setLoginForm({ ...loginForm, [e.target.name]: e.target.value });
  },[loginForm]);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [invalidPasswordErrorText,setInvalidPasswordErrorText] = useState<string>("")
  const [invalidPasswordError,setInvalidPasswordError] = useState<boolean>(false)

  const [rememberMe, setRememberMe] = useState(Auth.getRememberMe());
  const [isLoading, setIsLoading] = useState(false);
  const handleRememberMe = useCallback((event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setRememberMe(checked);
    Auth.setRememberMe(checked.toString())
    // if (checked){
    //   localStorage.setItem("rememberMe", checked.toString());
    // }
    // else {
    //   localStorage.setItem("rememberMe", checked.toString());
    // }
  },[])

  const handleCardClick = useCallback((path: string) => {
    if (loginForm.username === "" || loginForm.password === "") {
      openAlert({
        variant: "error",
        text: "Nome do utilizador ou palavra-passe mal definida!",
        title: "Erro",
      });
    } else {
      setIsLoading(true);
      userService.logInUser(loginForm.username, loginForm.password)
        .then((data) => {
          if( data.succeeded){
            //setDataUserCard(data.value);
            if (data.value) {
              Auth.setToken(data.value.token);
              Auth.setRefreshToken(data.value.refreshToken);
              localStorage.setItem("userId", JSON.stringify(data.value.userId));//.userId));
              onLogin(true);
              navigate(pathname);
            } 
          }
          else {
            onLogin(false);
            setInvalidPasswordErrorText("Username ou Password incorreto.");
            setInvalidPasswordError(true);
            openAlert({
              variant: "error",
              text: "Erro no Login!",
              title: "Erro",
            });
          }
          setIsLoading(false);
        });
    }
  },[loginForm.password, loginForm.username, navigate, onLogin, pathname]);

  const handleForgotPasswordClick = useCallback(() => {
    navigate('/password-recovery');
  },[navigate]);
  
  useEffect(() => {
    let rememberMeStorage = Auth.getRememberMe();
    setRememberMe(rememberMeStorage);
    let token = Auth.getToken()
    if (token){
      setIsLoading(true);
      //userService.refreshLogin(token).then(data => {
        //if( data.succeeded){
          //if (data.value) {
          //  Auth.setToken(data.value.token);
          //  localStorage.setItem("userId", JSON.stringify(data.value.userId));
            onLogin(true);
            navigate(pathname);
          //} 
        //}
        setIsLoading(false);
      //})
    }
  }, [navigate, onLogin, pathname]);

  const formPropsMemo = useMemo(() => {
    // let rememberMeStorage = Auth.getRememberMe();
    // setRememberMe(rememberMeStorage);
    // let token = Auth.getToken()
    // if (token){
    //   setIsLoading(true);
    //   userService.refreshLogin(token).then(data => {
    //     if( data.succeeded){
    //       setDataUserCard(data.value);
    //       if (data.value) {
    //         Auth.setToken(data.value.token);
    //         localStorage.setItem("userId", JSON.stringify(data.value.userId));
    //         onLogin(true);
    //         navigate(pathname);
    //       } 
    //     }
    //     setIsLoading(false);
    //   })
    // }


    return {
      title: "Acesso à área de trabalho",
      subTitle: "Autenticar-me para continuar.",
      usernameTextFieldProps: {
        placeholder: "Nome de utilizador",
        value: loginForm.username,
        name: "username",
        onChange: handleChange,
      },
      passwordTextFieldProps: {
        placeholder: "Senha",
        value: loginForm.password,
        name: "password",
        helperText: invalidPasswordErrorText,
        error: invalidPasswordError,
        onChange: handleChange,
      },
      rememberMeCheckboxProps: {
        label: "Manter-me autenticado",
        checked: rememberMe,
        onChange: handleRememberMe,
        //SK-589 VALIDAR esta checkbox e colocar o token na localStorage mediante a selecção desta checkbox
      },
      onPasswordShowToggleClick: () => setShowingPassword((prev) => !prev),
      passwordVisible: showingPassword,

      forgotPasswordText: "Esqueci-me da palavra-passe?",
      forgotPasswordProps: {
        onClick: handleForgotPasswordClick,
      },
      loginButtonText: "Entrar",
      loginButtonProps: {
        onClick: () => {
          handleCardClick("home")
        },
      },
      isLoading: isLoading,
    }
  },[
    handleCardClick, handleForgotPasswordClick,handleChange, handleRememberMe, invalidPasswordError, 
    invalidPasswordErrorText, loginForm.password, loginForm.username, 
    rememberMe, showingPassword,

    //navigate,onLogin,pathname,
    isLoading 
  ])

  return (
    <>
      <LoginPage
        containerProps={{
          leftSectionContent: "Todos os caminhos vão dar à mobilidade.",
        }}
        formProps={formPropsMemo}
      />
      <Alert open={open} onClose={handleClose} {...alertProps} />
    </>
  );
};

export default Login;
