import "@hi-interactive-admin/simulador-kinto/index.esm.css";
import { theme } from "@hi-interactive-admin/simulador-kinto";
import { ThemeProvider } from "@material-ui/system";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import BackofficeHome from "./app/backoffice/BackofficeHome";
import BackofficeUsers from "./app/backoffice-users/BackofficeUsers";
import Login from "./app/login/Login";
import EditProposalDemo from "./app/proposal/edit/EditProposal";
import CreateProposalNew from "./app/proposal/CreateProposalNew";
import ProposalList from "./app/proposal/ProposalList";
import BackofficeEditUser from "./app/backoffice-users/BackofficeEditUser";
import { Auth } from "./services/Auth";
import { useCallback, useEffect, useState } from "react";
import BackofficeEditProfile from "./app/backoffice-profiles/BackofficeEditProfile";
import BackofficeProfile from "./app/backoffice-profiles/BackofficeProfiles";
import { AsyncRequestProvider } from './services/AsyncRequestContext';
import ForgotPassword from "./app/forgot-password/ForgotPassword";
import RecoverPassword from "./app/recover-password/recover-password";
import ChangePassword from "./app/forgot-password/ChangePassword";
import DashboardDemo from "./app/dashboard/DashboardDemo";
import ClientDetailsPageDemo from "./app/clients/ClientDetailsPageDemo";
import ProposalApprovationDemo from "./app/proposal-approval/ProposalApproval";
import BackofficePreconfigured from "./app/backoffice-preconfigured/BackofficePreconfigured";
import OrderDetailsPageDemo from "./app/proposal/OrderDetailsDemo";
import { jwtDecode } from "jwt-decode";
import { UserServices } from "./services/UserServices";


// import { UserServices } from "./services/UserServices";

// const userService = new UserServices();
function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  
  const handleLogin = useCallback((result:boolean) => {
    setIsLoggedIn(result);
  },[]);

  const handleLogout = useCallback(() => {
    Auth.clearToken();
    setIsLoggedIn(false);
  },[]);
 
  const loginComponent = <Login onLogin={handleLogin} />;
  const logTokenExpiration = () => {
    try {
      const token = Auth.getToken();
      const decodedToken = jwtDecode(token);

      const expirationTimestamp = (decodedToken.exp as number) * 1000; // Convert expiration time from seconds to milliseconds

      const expirationDate = new Date(expirationTimestamp);

      //Get the current local date and time
      const currentDateTime = new Date();

      // Check if the token has expired
      if (currentDateTime.getTime() > expirationDate.getTime()) {
        console.log('Token has expired');
      } else {
        console.log('Token is still valid');
      }
      console.log("expirationDate: " + expirationDate.getFullYear() +'-'+ expirationDate.getMonth() + 1 +'-'+ expirationDate.getDate() + ' ' + expirationDate.getHours() +':'+ expirationDate.getMinutes());
      console.log("currentDate: " + currentDateTime.getFullYear() +'-'+ currentDateTime.getMonth() + 1 +'-'+ currentDateTime.getDate() + ' ' + currentDateTime.getHours() +':'+ currentDateTime.getMinutes());
    } catch (error) {
     console.error("Error decoding token:", error);      
    }
  };
  const tokenWillExpireSoon = useCallback( (token:string) => {
      try {
        logTokenExpiration();
        const decodedToken = jwtDecode(token);
        const expirationTimestamp = (decodedToken.exp as number) * 1000; // Convert seconds to milliseconds
        const currentTimestamp = Date.now();
        const thirtyMinutesFromNow = currentTimestamp + (30 * 60 * 1000); // 30 minutes in milliseconds
        return expirationTimestamp < thirtyMinutesFromNow;
      } catch (error) {
        console.error('Error decoding token:', error);
        return false;
      }
    }
  ,[]);
  useEffect(() => {
    const usersService = new UserServices();
    var token = Auth.getToken();
    if (!token) {
      return;
    }
    const checkTokenValidity = async () => {
      var token = Auth.getToken();
      if (!token) {
        return;
      }
      if(tokenWillExpireSoon(token)){
        const refreshToken = Auth.getRefreshToken();
        usersService.refreshLogin(refreshToken).then(data => {
          if( data.succeeded){
            console.log('Token Updated');
            if (data.value) {
              Auth.setToken(data.value.token);
              Auth.setRefreshToken(data.value.refreshToken);
            }
          }
          else{
            handleLogout();
          }
        });
      } else {
      }
    };
    checkTokenValidity();
    // Check token validity every 10 minutes
    const intervalId = setInterval(checkTokenValidity, 10 * 60 * 1000);
    // Cleanup the interval on component unmount
    return () => {
      clearInterval(intervalId);
    };
  }, [handleLogout, tokenWillExpireSoon]);
 
  // const [rememberMe, setRememberMe] = useState(false);

  // useEffect(() => {
  //   let rememberMeStorage = Auth.getRememberMe();
  //   setRememberMe(rememberMeStorage);
  //   let token = Auth.getToken()
  //   if (token){
  //     userService.refreshLogin(token).then(data => {
  //       if( data.succeeded){
  //         // setDataUserCard(data.value);
  //         if (data.value) {
  //           Auth.setToken(data.value.token);
  //           localStorage.setItem("userId", JSON.stringify(data.value.userId));
  //           return handleLogin;
  //         } 
  //       }
  //     })
  //   }
  // }, [handleLogin]);



  return (
    <AsyncRequestProvider>
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          {/* Homepage */}
          <Route index path="/home" element={isLoggedIn ? <BackofficeHome onLogout={handleLogout} /> : loginComponent } />
          
          {/* Propostas */}
          <Route path="/proposal" element={isLoggedIn ? <ProposalList onLogout={handleLogout} /> : loginComponent } />
          <Route path="/proposal/new" element={isLoggedIn ? <CreateProposalNew onLogout={handleLogout} /> : loginComponent} />
          <Route path="/proposal/:proposalId" element={isLoggedIn ? <EditProposalDemo onLogout={handleLogout} /> : loginComponent } />
          <Route path="/proposal/edit/:proposalId" element={isLoggedIn ? <CreateProposalNew onLogout={handleLogout} /> : loginComponent } />
          
          <Route path="/proposal/approve" element={isLoggedIn ? <ProposalApprovationDemo /> : loginComponent } />
          <Route path="/proposal/approve/:proposalId" element={isLoggedIn ? <ProposalApprovationDemo /> : loginComponent } />
          <Route path="/proposal/order/:proposalId" element={isLoggedIn ? <OrderDetailsPageDemo /> : loginComponent } />
          {/* Backoffice */}
          <Route path="/backoffice" element={isLoggedIn ? <BackofficeHome onLogout={handleLogout} /> : loginComponent } />
          <Route path="/profiles" element={isLoggedIn ?<BackofficeProfile onLogout={handleLogout} /> : loginComponent } />
          <Route path="/profiles/:profileId" element={isLoggedIn ?<BackofficeEditProfile onLogout={handleLogout} /> : loginComponent } />
          <Route path="/users" element={isLoggedIn ? <BackofficeUsers onLogout={handleLogout} /> : loginComponent } />
          <Route path="/users/:userId" element={isLoggedIn ? <BackofficeEditUser onLogout={handleLogout} /> : loginComponent }/>

          <Route path="/backoffice/vehicles" element={isLoggedIn ? <BackofficePreconfigured onLogout={handleLogout} /> : loginComponent }/>

          {/* Login/Password */}
          <Route path="/login" element={isLoggedIn ? <BackofficeHome onLogout={handleLogout} /> : loginComponent } />
          <Route path="/reset-password/:token" element={<ForgotPassword />} />
          <Route path="/change-password/:token" element={<ChangePassword />} />
          <Route path="/password-recovery" element={<RecoverPassword />} />
          <Route path="/" element={isLoggedIn ? <BackofficeHome onLogout={handleLogout} /> : loginComponent } />

          {/* Dashboard */}
          <Route path="/dashboard/" element={isLoggedIn ? <DashboardDemo onLogout={handleLogout} /> : loginComponent }/>

          {/* Clientes */}
          <Route path="/clients/:clientId" element={isLoggedIn ? <ClientDetailsPageDemo onLogout={handleLogout} /> : loginComponent } />
          <Route path="/clients/:clientId/:startWithEditMode" element={isLoggedIn ? <ClientDetailsPageDemo onLogout={handleLogout} /> : loginComponent } />

          {/* Contratos */}
          {/* Performance */}

        </Routes>
      </Router>
    </ThemeProvider>
    </AsyncRequestProvider>
  );
}

export default App;
