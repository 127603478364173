import { CheckboxProps } from '@hi-interactive-admin/simulador-kinto';
import { useState, useCallback, useMemo } from 'react';
import { VehicleService } from '../../../services/VehicleService';

const vehicleService = new VehicleService();

const useLicensePlateCustomHook = () => {

  const [licensePlates, setLicensePlates] = useState<CheckboxProps[]>([]);
  const handleToggleSelectLicensePlateCallback = useCallback((licensePlateNumbers: string) => () => {
    setLicensePlates(prevState =>
      prevState.map(licensePlate => {
        if (licensePlate.value === licensePlateNumbers) {
          return { ...licensePlate, checked: !licensePlate.checked }
        }
        else { 
          return licensePlate
        }
      })
      // prevState.map(licensePlate =>
      //   licensePlate.label === licensePlateNumbers
      //     ? { ...licensePlate, checked: !licensePlate.checked }
      //     : licensePlate
      // )
    );
  }, []);

  const handleLicensePlates = useCallback((licensePlatesTmp: CheckboxProps[]) => {
    if (licensePlatesTmp.length > 0) {
      setLicensePlates(licensePlatesTmp);
      return licensePlatesTmp;
    }

    vehicleService.getLicensePlates().then(data => {
      if (data && data.succeeded === true) {
        if (data.value.length > 0){
          const licensePlatesMap = new Set();
          data.value.map((element:string) => {
            if (!licensePlatesMap.has(element)) {
              licensePlatesMap.add(element)
            }
            return element;
          })

          licensePlatesTmp = Array.from(licensePlatesMap).map((element: any) => {
            let licensePlateOption = {
              label: element,
              value: element,
              checked: false,
              onClick: handleToggleSelectLicensePlateCallback(element),
            }
            if (element === ''){
              licensePlateOption = {
                label: 'sem matricula',
                value: element,
                checked: false,
                onClick: handleToggleSelectLicensePlateCallback(element),
              }
            }
            return licensePlateOption
          });
          
          setLicensePlates(licensePlatesTmp);
        }
      }
    })
    return licensePlatesTmp;
  }, [handleToggleSelectLicensePlateCallback]);

  const licensePlatesMemo = useMemo(() => handleLicensePlates(licensePlates), [handleLicensePlates, licensePlates]);
  const handleLicensePlatesTextFieldPropsCallback = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let licensePlatesOptions: any[any] = [];
    if (e.target.value !== "" && e.target.value.length > 2){
      vehicleService.getLicensePlates(e.target.value).then(data => {
        if (data.succeeded === true && data.value.length > 0) {
          licensePlatesOptions = data.value.map((element: any) => ({
            label: element,
            checked: false,
            onClick: handleToggleSelectLicensePlateCallback(element),
          }));
          setLicensePlates(licensePlatesOptions);
        }
      })//.catch(() => setLicensePlates([]));
    }

    else if (e.target.value === "") {
      handleLicensePlates([]);
    } 
    else {
      return licensePlatesOptions
    }
  }, [handleLicensePlates, handleToggleSelectLicensePlateCallback]);

  return { licensePlates, handleLicensePlatesTextFieldPropsCallback, licensePlatesMemo };
};

export default useLicensePlateCustomHook;