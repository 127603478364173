import { CheckboxProps } from "@hi-interactive-admin/simulador-kinto";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { VehicleService } from "../../../services/VehicleService";

const vehicleService = new VehicleService();

const useFrameWorkContractNumberCustomHook  = () => {
  //INICIO Nº de Contrato de Quadro
  const [commercialConditions, setCommercialConditions] = useState<CheckboxProps[]>([])

  const handleToggleSelectFrameworkContractNumberCallback = useCallback((optionLabel:string) => () => {
    setCommercialConditions((rows:any[]) => 
      rows.map(row => {
        if ( row.label === optionLabel)
        {
          row.checked = !row.checked
        }
        return row;
      })
    )
  },[]);

  const handleCommercialConditions = useCallback((commercialConditionsTmp:CheckboxProps[]) => {
    let commercialConditionsOptions: CheckboxProps[] = commercialConditionsTmp;
    if ( commercialConditionsOptions.length !== 0)
    {
      setCommercialConditions((rows:any[]) => {
        rows = commercialConditionsOptions;
        return rows;
      })
      return commercialConditionsOptions;
    }

    vehicleService.getCommercialConditions('').then((data) => {
      if(data.succeeded === true) {
        data.value.forEach((element:any) => {
          commercialConditionsOptions.push({
            label:element.value,
            checked:false,
            onClick: handleToggleSelectFrameworkContractNumberCallback(element.value),
          })
        });

        setCommercialConditions((rows: any[]) => {
          if (rows.length === 0 ){
            rows.push(commercialConditionsOptions)
          }
          return rows;
        });
      }
    })
    return commercialConditionsOptions;
  },[handleToggleSelectFrameworkContractNumberCallback]);

  const frameworkContractNumberMemo = useMemo(() => handleCommercialConditions(commercialConditions),[commercialConditions, handleCommercialConditions])

  
  const handleFrameWorkContractNumberTextFieldPropsCallback = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let commercialConditionsOptions: any[any] = [];
    if( e.target.value !== "" && e.target.value.length > 2){
      vehicleService.getCommercialConditionsByNo(e.target.value).then(data => {
        if(data.succeeded === true && data.value.length > 0){
          data.value.forEach((element:any) => {
            commercialConditionsOptions.push({
              label:element.value,
              checked:false,
              onClick: handleToggleSelectFrameworkContractNumberCallback(element.value),
            })
            
          })
          setCommercialConditions((rows:any[]) => {
            return rows = commercialConditionsOptions;
          })
        }
      })
    }
    else if (e.target.value === "") {
      vehicleService.getCommercialConditionsByNo('').then(data => {
        if(data.succeeded === true){
          data.value.forEach((element:any, index:number) => {
            commercialConditionsOptions.push({
              label:element.value,
              checked:false,
              onClick: handleToggleSelectFrameworkContractNumberCallback(element.value),
            })
          });
          setCommercialConditions((rows:any[]) => {
            rows = commercialConditionsOptions;
            return rows;
          });
        }
      });
    }
    else {
      return commercialConditionsOptions;
    }
    // return driverNames;
  },[handleToggleSelectFrameworkContractNumberCallback])
  //FIM Nº de Contrato de Quadro
  return { commercialConditions, handleFrameWorkContractNumberTextFieldPropsCallback , frameworkContractNumberMemo };
};

export default useFrameWorkContractNumberCustomHook;
