import {AppSettings} from '../model/Settings';
  export class SettingsService {
    private static instance: SettingsService;
    public settings!: AppSettings;
  
    private loadSettings(): Promise<AppSettings> {
      //Adicionada data para não obter da cache
      return fetch(`${window.location.origin}/appSettings.json?${Date.now()}`)
        .then(response => response.json());
    }

    public static getInstance(): Promise<SettingsService> {
      const renewalInterval = 4 * 60 * 60 * 1000; // 4 horas em millisegundos

      if (!SettingsService.instance) {
        const settingsService = new SettingsService();
        return settingsService.loadSettings().then(settings => {
          settingsService.settings = settings;
          SettingsService.instance = settingsService;

          // Renovar a instância a cada renewalInterval (ex.: 4 horas - 4 * 60 * 60 * 1000 milisegundos)
          setInterval(() => {            
            const renewedService = new SettingsService();
            renewedService.loadSettings().then(renewedSettings => {
              renewedService.settings = renewedSettings;
              SettingsService.instance = renewedService;
            });
          }, renewalInterval);
          return settingsService;
        });
      }
      return Promise.resolve(SettingsService.instance);
    }
  }