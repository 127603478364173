import { UpsertProposalRequest } from "../model/UpsertProposalRequest";
import /*axios,*/ { AxiosRequestConfig } from "axios";
import axiosInstance from "../Utilities/axiosInstance";
//import settings from "../appSettings.json";
import { APIResponse } from "../model/APIResponse";
import { AdvancedFilterProposalOptions, ExportFormat } from "../model/Enums";
import { Proposal } from "../model/Proposal";
import { SettingsService } from "./SettingsService";
import { AppSettings } from "../model/Settings";

export class ProposalService {
  private settingsService: any;// = SettingsService.getInstance();
  private entityURL: string = "";
  
  ensureSettingsService =  async () => {
    this.settingsService = await SettingsService.getInstance();
    this.entityURL = this.settingsService.settings.APIBaseURL + this.settingsService.settings.Proposals_RelativeURL;
  };

  getAll = async (): Promise<any> => {
    try {
      await this.ensureSettingsService();
      const { data } = await axiosInstance.get(this.entityURL);
      return data; //.value;
    } catch (error) {
      console.log('proposalService.getall' + error);
    }
  };
  
  getByName = async (client?: string, state?:any): Promise<any> => {
    try {
      await this.ensureSettingsService();
      const { data } = await axiosInstance.post(`${this.entityURL}getbyname/${client}/`,state);
      return data; //.value;
    } catch (error) {
      console.log(error);
    }
  };
  
  get = async (proposalId: number): Promise<any> => {
    try {
      await this.ensureSettingsService();
      const { data } = await axiosInstance.get(this.entityURL + proposalId);
      return data; //.value;
    } catch (error) {
      console.log(error);
      return {
        succeeded: false,
        hasErrors:true,
        message: "Não foi possivel obter a proposta",
        value: {},
        errors: [],
      };
    }
  };

  delete = async (proposalId: number): Promise<any> => {
    try {
      await this.ensureSettingsService();
      const { data } = await axiosInstance.delete(this.entityURL + proposalId);
      return data; //.value;
    } catch (error) {
      console.log(error);
    }
  };
  upsert = async (request : UpsertProposalRequest): Promise<APIResponse> => {
    try {
      await this.ensureSettingsService();
      const { data } = await axiosInstance.post(this.entityURL, request);
      return data;
    } catch (error:any) {
      console.log(error);
      return {
        succeeded: false,
        hasErrors:true,
        message: error.response,
        value: {},
        errors: [],
      };
    }
  };

  getPrintTemplates = async (rentalTypeId : string): Promise<APIResponse> => {
    try {
      await this.ensureSettingsService();
      const { data } = await axiosInstance.get(this.entityURL + this.settingsService.settings.PrintTemplates_RelativeURL + "/" + rentalTypeId);
      return data;
    } 
    catch (error:any) {
      console.log(error);
      return {
        succeeded: false,
        hasErrors:true,
        message: error.message,
        value: {},
        errors: [],
      };
    }
  };
  
  sendToCustomer = async (proposal: Proposal): Promise<APIResponse> => {
    try {
      await this.ensureSettingsService();
      //proposal.vehicles[0].simulations[0].no = "CA10770140";
      const url = this.entityURL + this.settingsService.settings.SendProposalToCustomer_RelativeURL;
      const { data } = await axiosInstance.post(url, proposal, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return data;
    } 
    catch (error:any) {
      console.log(error);
      return {
        succeeded: false,
        hasErrors:true,
        message: error.message,
        value: {},
        errors: [],
      };
    }
  };
  
  sendToCustomerPreview = async (proposal: Proposal): Promise<APIResponse> => {
    try {
      await this.ensureSettingsService();
      //proposal.vehicles[0].simulations[0].no = "CA10770140";
      const url = this.entityURL + this.settingsService.settings.SendProposalToCustomerPreview_RelativeURL;
      const { data } = await axiosInstance.post(url, proposal, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return data;
    } 
    catch (error:any) {
      console.log(error);
      return {
        succeeded: false,
        hasErrors:true,
        message: error.message,
        value: {},
        errors: [],
      };
    }
  };

  addAttachment = async (proposal: Proposal, files: any[],): Promise<APIResponse> => {
    try {
      await this.ensureSettingsService();
      const url = this.entityURL + this.settingsService.settings.SendProposalToCustomer_RelativeURL;
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
         formData.append("file", files[i]);
      }
      formData.append("proposal", JSON.stringify(proposal));
      const { data } = await axiosInstance.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return data;
    } 
    catch (error:any) {
      console.log(error);
      return {
        succeeded: false,
        hasErrors:true,
        message: error.message,
        value: {},
        errors: [],
      };
    }
  };

  addAttachments = async (
    proposalId: number,
    files: any[],
    onUploadProgress: AxiosRequestConfig
  ): Promise<any> => {
    try {
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("file", files[i]);
      }

      await this.ensureSettingsService();
      const url = this.entityURL + this.settingsService.settings.ProposalAttachments_RelativeURL + "/" +proposalId;
      const { data } = await axiosInstance.post(url, formData, onUploadProgress);
      return data;
    } catch (error:any) {
      console.log(error);
      return {
        succeeded: false,
        message: error.message,
        value: {},
        errors: [],
      };
    }
  };

  removeAttachments = async (attachmentGuid:any): Promise<any[]> => {
    try {
      await this.ensureSettingsService();
      //falta extrair giud do attachmnet e enviar no url em vez do objecto attachment
      const url = this.entityURL + this.settingsService.settings.ProposalAttachments_RelativeURL +'/'  + attachmentGuid;
      const { data } = await axiosInstance.delete(url);
      const converted = data.value; // as ProposalComment[];
      return converted;

    } catch (error) {
      console.log(error);
    }
    return [];
  };

  getFilteredProposals = async (
    filters: { type: AdvancedFilterProposalOptions; value: any[] }[]
  ): Promise<APIResponse> => {
    try {
      await this.ensureSettingsService();
      const { data } = await axiosInstance.post(`${this.entityURL}filterProposals`, filters, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return data;
    } catch (error: any) {
      console.log(error.response);
      return {
        succeeded: false,
        hasErrors:true,
        message: error.response,
        value: {},
        errors: [],
      };
    }
  };

  exportSimulations = async (leaseQuotesIds: string[], simulationsIds: string[], exportFormat:ExportFormat): Promise<APIResponse> => {
    try {
      await this.ensureSettingsService();
      const url = this.entityURL + this.settingsService.settings.ExportSimulations_RelativeURL;
      const { data } = await axiosInstance.post(url, {quotesIds: leaseQuotesIds, simulationsIds: simulationsIds, exportFormat: exportFormat}, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return data;
    } 
    catch (error:any) {
      console.log(error);
      return {
        succeeded: false,
        hasErrors:true,
        message: error.message,
        value: {},
        errors: [],
      };
    }
  };
}


