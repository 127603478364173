export enum AdvancedFilterVersionsOptions {
  No,
  Model,
  Make,
  Version,
  Segment,
  FuelType,
  Seats,
  Length,
  Width,
  Height,
  GrossWeight,
  MaxSuitcaseVolume,
  DistanceBetweenFrontAxles,
  DistanceBetweenRearAxles,
  Power,
  EngineCapacity,
  MaxTorque,
  CO2Emission,
  VehicleType,
  ProposalId,
}
export enum AdvancedFilterVehiclesOptions {
    LicensePlate,
    Make,
    Version,
    Remarketing_Status,
    Campaign_Type,
    No
}

export enum AdvancedFilterAccessoryOptions {
  HeaderNo,
  Version,
  General,
  RecordType,
  All,
}


export enum FuelType {
  Benzine,
  Diesel,
  LPG,
  Electro,
  Alternatief,
}

export enum AdvancedFilterProposalOptions {
  RentalType,
  ContractNumber,
  LicencePlate,
  VehicleBrand,
  ClientName,
  Date,
  DriverName,
  Duration,
  Distance,
  SellerName,
  FrameWorkContractNumber,
}
export enum VehicleTypeStatus
{
    New,
    Active,
    Inactive
}
export enum PreConfiguredVehicleState
{
    New,
    Used
}
export class VehicleTypeStatusHelper {
  
  static generateAdvancedFilterVersionsOptions(allExceptInactive: boolean) : ({ type: AdvancedFilterVersionsOptions; value: string }[]) {
    let filters : { type: AdvancedFilterVersionsOptions; value: string }[] = [];
    try{
      const enumValues = Object.keys(VehicleTypeStatus).filter((x) => isNaN(Number(x)));
      const inactiveValueEnumPosition = VehicleTypeStatus.Inactive;
      for (const enumValue of enumValues) {
        const enumPos = enumValue.toString();
        const enumValuePosition = enumValues.indexOf(enumValue);

        if(allExceptInactive && enumValuePosition !== inactiveValueEnumPosition) {
          filters.push({
            type: AdvancedFilterVersionsOptions.VehicleType,
            value: enumValuePosition.toString(),
          });  
        }
      }
      return filters;
    }
    catch(ex){
      console.log(ex);
      return [];
    }
  }
}
export enum ExportFormat {
  PDF = 'PDF',
  Excel = 'Excel',
}
export enum ColorEnum {
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
  SUCCESS = "success",
}