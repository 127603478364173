//import axios from "axios";
import axiosInstance from "../Utilities/axiosInstance";
//import settings from "../appSettings.json";
import {
  RequiredDataProps,
} from "../app/proposal/clients";
import { APIResponse } from "../model/APIResponse";
import { SettingsService } from "./SettingsService";

export class ContactsService {
  private settingsService: any;// = SettingsService.getInstance();
  private entityURL: string = "";

  ensureSettingsService =  async () => {
    this.settingsService = await SettingsService.getInstance();
    this.entityURL = this.settingsService.settings.APIBaseURL + this.settingsService.settings.Contact_RelativeURL;
  };

  getContacts = async () => {
    try {
      await this.ensureSettingsService();
      const { data } = await axiosInstance.get(this.entityURL);
      return data;
    } catch (error) {
      console.log(error);
    }
  };
  getContactsByCombo = async (query:string) => {
    try {
      await this.ensureSettingsService();
      const { data } = await axiosInstance.get(this.entityURL + "GetByCombo?q="+query);
      return data;
    } catch (error) {
      console.log(error);
    }
  };
  getContactsByNo = async (no: string) => {
    try {
      await this.ensureSettingsService();
      const { data } = await axiosInstance.get(this.entityURL + no);
      return data.value;
    } catch (error) {
      console.log(error);
    }
  };
  getContactsByName = async (query:string) => {
    try {
      await this.ensureSettingsService();
      const { data } = await axiosInstance.get(this.entityURL + "GetByName/?q="+query);
      return data;
    } catch (error) {
      console.log(error);
    }
  };
  
  getInterlocutor = async (no: string) => {
    try {
      await this.ensureSettingsService();
      const { data } = await axiosInstance.get(this.entityURL+"GetInterlocutor/" + no);
      return data.value;
    } catch (error) {
      console.log(error);
    }
  };

  postContacts = async (contacts: RequiredDataProps[]): Promise<APIResponse> => {
    try {
      await this.ensureSettingsService();
      const { data } = await axiosInstance.post(this.entityURL,contacts);
      return data;
    } catch (error:any) {
      console.log(error);
      return {
        succeeded: false,
        hasErrors:true,
        message: error.response,
        value: {},
        errors: [],
      };
    }
  };

  putContacts = async (contacts: RequiredDataProps[]): Promise<APIResponse> => {
    try {
      await this.ensureSettingsService();
      if (contacts[1].no === ""){
        contacts[1].no="0";
      }
      let url=this.entityURL+contacts[0].no+"/"+contacts[1].no;
      const { data } = await axiosInstance.put(url,contacts);
      return data;
    } catch (error:any) {
      console.log(error);
      return {
        succeeded: false,
        hasErrors:true,
        message: error.response,
        value: {},
        errors: [],
      };
    }
  };

  getApplicants = async (query:string) => {
    try {
      await this.ensureSettingsService();
      const { data } = await axiosInstance.get(this.entityURL + "GetApplicant/?q="+query);
      return data;
    } catch (error) {
      console.log(error);
    }
  };
 
}
