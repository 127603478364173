import {
  CheckboxProps,
  DocumentPDFIcon,
  GroupOrderedProposal,
  GroupProposal,
  ListItemButtonProps,
  OrderedProposal,
  Proposal,
  ProposalListPage,
  ProposalListPageProps,
  RangeDatePickerProps,
  TagProps,
  AlertProps,
  Alert,
} from "@hi-interactive-admin/simulador-kinto";
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { ColorEnum } from "../../model/Enums";
// import { groupProposals, proposals } from "./proposals";
import { ProposalService } from "../../services/ProposalService";
//import settings from "../../appSettings.json";
import { IVehicle } from "../../model/Vehicle";
import { CustomerService } from "../../services/CustomerServices";
import { VehicleService } from "../../services/VehicleService";
import useLicensePlateCustomHook from "./proposalListCallbacks/licensePlateHooks";
import useSalesPersonsCustomHook from "./proposalListCallbacks/salesPersonHooks";
import { Option } from "../../model/interfaces";
import { AdvancedFilterProposalOptions } from "../../model/Enums";
import useDistanceCustomHook from "./proposalListCallbacks/distanceHooks";
import useDurationCustomHook from "./proposalListCallbacks/durationHooks";
import useFrameWorkContractNumberCustomHook from "./proposalListCallbacks/frameWorkContractNumberHooks";
import useDriverNamesCustomHook from "./proposalListCallbacks/driverNamesHooks";
import useClientNamesCustomHook from "./proposalListCallbacks/clientNamesHook";
import useVehicleBrandsCustomHook from "./proposalListCallbacks/vehicleBrandsHooks";
import useRentalTypesCustomHook from "./proposalListCallbacks/rentalTypeHooks";
import { KintoRootProps } from "../../model/KintoComponentProps";
import { formatDateWithTimeZone } from "../../Utilities/Utils";
import { SettingsService } from "../../services/SettingsService";
import { AppSettings, EmptyAppSettings } from "../../model/Settings";
import { AutocompleteChangeDetails, AutocompleteChangeReason, AutocompleteInputChangeReason } from "@mui/material";
import { useAsyncRequest } from './../../services/AsyncRequestContext';
import { format, subMonths, subWeeks } from "date-fns";
import { UserServices } from "../../services/UserServices";
import useNavbarHook from "../../customCallbacks/navbarHook";
import usePermissionsHook from "../../customCallbacks/permissions/permissionsHook";
import useProposalStatesHook from "../../customCallbacks/proposal/proposalStatesHook";

type RangeDate = [Date | null, Date | null];

interface Filter {
  state:string;
  startDate: string;
  endDate:string;
};

interface IBrand {
  label: string;
  hidden:boolean;
  checkBoxProps: CheckboxProps;
  onClick: ListItemButtonProps['onClick'];
  open: boolean;
  models: {
      id?: string;
      label: string;
      checkBoxProps: CheckboxProps;
      onClick?: () => void;
  }[];
};

const userService = new UserServices();
const proposalService = new ProposalService();
const vehicleService = new VehicleService();
const custService = new CustomerService();


const ProposalList: React.FC<KintoRootProps>  = ({ onLogout }) => {

  const { 
    navigate,
    handleCardClick,
    handleLogout,
    topBarPropsMemo,
    dashboardMenuButtonPropsMemo, 
    proposalMenuButtonPropsMemo, 
    clientsMenuButtonPropsMemo, 
    contractsMenuButtonPropsMemo,
    performanceMenuButtonPropsMemo, 
    backofficeMenuButtonPropsMemo
  } = useNavbarHook(onLogout);
  
  const {currentUser, setCurrentUser } = usePermissionsHook();

  const { asyncResponse, setAsyncResponse } = useAsyncRequest();
  const [open, setOpen] = useState<boolean>(false);
  const [alertProps, setAlertProps] = useState<
    Omit<AlertProps, "open" | "onClose">
  >({
    variant: "info",
    text: "",
    title: "",
    autoHideDuration: 3000,
  });
  const openAlert = (alertProps: Omit<AlertProps, "open" | "onClose">) => {
    setAlertProps(alertProps);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [settings, setSettings] = useState<AppSettings>(new EmptyAppSettings());
  useEffect(() => {
    SettingsService.getInstance()
      .then(x => setSettings(x.settings));
  }, []);

  const [rangeDateValue, setRangeDateValue] = useState<RangeDate>([null, null]);
  const [rangeDateRadioOptionSelected, setRangeDateRadioOptionSelected] =
    useState<string>('lastW');

  const handleArrowClick = useCallback((itemId: string) => {
    navigate(`/proposal/${itemId}`);
  },[navigate]);
  const handleNewProposalClick = useCallback(() => {
    navigate(`/proposal/new`);
  },[navigate]);

  const handleClientDetailsClick = useCallback((itemId: string) => {
    navigate(`/clients/${itemId}`);
  },[navigate]);

  //EM PROPOSTAS - INICIO
  const [requestingChannelOptions, setRequestingChannelOptions] = useState<any>([]);

  const [proposals, setProposals] = useState<Proposal[]>([]);
  const [proposalsStatus, setProposalsStatus] = useState<{
    proposalId: string,
    isClientApproved: boolean,
  }[]>([]);
  const [groupProposals, setGroupProposals] = useState<GroupProposal[]>([]);
  const [orderedProposals, setOrderedProposals] = useState<OrderedProposal[]>([]);
  const [groupOrderedProposals, setGroupOrderedProposals] = useState<GroupOrderedProposal[]>([]);

  const [proposalsSearchOptions, setProposalsSearchOptions] = useState<Option[]>([]);
  const [ordersSearchOptions, setOrdersSearchOptions] = useState<Option[]>([]);
  const [searchOrder, setSearchOrder] = useState<Option>();
  const [searchProposal, setSearchProposal] = useState<Option>();
  const [searchFilters, setSearchFilters] = useState<Filter>({
    state:"all",
    startDate: "",
    endDate:"",
  });
  const [orderSearchFilters, setOrderSearchFilters] = useState<Filter>();

  const [proposalDisable, setProposalDisable] = useState<boolean>(true);
  const [orderDisable, setOrderDisable] = useState<boolean>(true);
  
  // const proposalService = useMemo(() => new ProposalService(), []);
  // const vehicleService = useMemo(() =>new VehicleService(),[]);

  
  //opcoes filtros avançados
  const { setRentalTypes, rentalTypesMemo } = useRentalTypesCustomHook();

  //TODO FALTA VALIDAR ESTE FILTRO
  const handleToggleSelectContractNumberCallback = useCallback((index:number) => {

    setContractNumbers(rows =>
      {
        return rows.map((row,rowIndex) => {
          if (rowIndex === index) {
            return { ...row, checked: !row.checked }
          }
          else { 
            return row
          }
        })
      }
      // prevState.map(licensePlate =>
      //   licensePlate.label === licensePlateNumbers
      //     ? { ...licensePlate, checked: !licensePlate.checked }
      //     : licensePlate
      // )
    )
  },[])

  const [contractNumbers, setContractNumbers] = useState<any[]>([
    '203000',
    '203450',
    '203456',
    '203457',
    '203458',
  ].map((label, index) => ({
    label,
    checked: false,
    onClick: () => handleToggleSelectContractNumberCallback(index),
  })));

  const handleContractNumber = useMemo(() => {
    return contractNumbers;
  },[contractNumbers]);

  const { handleLicensePlatesTextFieldPropsCallback, licensePlatesMemo } = useLicensePlateCustomHook();  
  const { convertToExpandableMakesOptions,
    setBrandsSectionProps, 
    handleAllBrandsCheckBoxProps, 
    handleVehicleBrandTextFieldPropsCallback, 
    brandsMemo
  } = useVehicleBrandsCustomHook();
  const { handleClientNameTextFieldPropsCallback, clientNameMemo} = useClientNamesCustomHook();
  const { handleDriverNameTextFieldPropsCallback, driversMemo} = useDriverNamesCustomHook();
 
  //TODO FALTA VALIDAR ESTE FILTRO
  const handleBetweenDates = () => {
    return;
  }
  const { durationMemo, setDuration, handleToggleSelectDurationsCallback, setNewMonthValue, newMonthValue } = useDurationCustomHook();  
  const { mileageMemo, setMileage, handleToggleSelectMileageCallback, setNewMileageValue, newMileageValue  } = useDistanceCustomHook();
  const { salesPersonNames, handleSellerNameTextFieldPropsCallback, sellerNameMemo  } = useSalesPersonsCustomHook();  
  const { handleFrameWorkContractNumberTextFieldPropsCallback , frameworkContractNumberMemo } =useFrameWorkContractNumberCustomHook();

  //fim opcoes filtros avancados

  const handleDateRangeOption = useCallback((e:React.ChangeEvent<HTMLInputElement>, value: string) => {
    setRangeDateRadioOptionSelected(value)
  },[]);
  
  const { handleState, proposalStateDetailsScreenPermittedOptions } = useProposalStatesHook('','');

  const handleGroupProposal = useCallback((data:any) => {
    let groupProposalsTmp: GroupProposal[] = [];
    data.forEach((element:any) =>
    {
      //instanciar propostas por cliente
      if(!element['ordered']){
        if(groupProposalsTmp.find((x) => { return x.clientName === element['clientName']}) === (null||undefined))
          {
            groupProposalsTmp.push({
              //TODO FALTA VALIDAR CALLBACK
              onHeadClick: () => handleClientDetailsClick(element['contactId']),
              clientName: element['clientName'],
              clientId: element['contactId'],
              clientType: settings.Data.Vehicles_ClientTypology[1].result.find((x) => { return x.value === element['clientTypology']})?.label !== (null || undefined)
                ? settings.Data.Vehicles_ClientTypology[1].result.find((x) => { return x.value === element['clientTypology']})!.label
                : ' - ',
              nif: `NIF ${element['nif']}`,
              allProposals: proposals.filter((x) => x.client.name === element['clientName']).length,
              pendentProposals: proposals.filter((x) => x.client.name === element['clientName'] && x.state.tagLabel === 'Pendente').length,
              proposals: proposals.filter((x) => x.client.name === element['clientName']),
            })
          }
          setGroupProposals(groupProposalsTmp);
      }
    });
  },[
    //validar dependencias
    proposals, settings.Data.Vehicles_ClientTypology,
    handleClientDetailsClick
  ])

  const handleGroupOrderedProposal = useCallback((data:any) => {
    let groupOrderedProposalsTmp: GroupOrderedProposal[] = [];
    data.forEach((element:any) =>       
    {
      //instanciar encomendas por cliente
      if(element['ordered']){
        if(groupOrderedProposalsTmp.find((x) => { return x.clientName === element['clientName']}) === (null||undefined)) {
          //instanciar encomendas por cliente
            groupOrderedProposalsTmp.push({
              
              clientName: element['clientName'],
              clientId: element['contactId'],
              clientType:  settings.Data.Vehicles_ClientTypology[1].result.find((x) => { return x.value === element['clientTypology']})?.label !== (null || undefined)
                ? settings.Data.Vehicles_ClientTypology[1].result.find((x) => { return x.value === element['clientTypology']})!.label
                : ' - ',
              nif: `NIF ${element['nif']}`,
              allProposals: orderedProposals.filter((x) => x.client.name === element['clientName']).length,
              pendentProposals: orderedProposals.filter((x) => x.client.name === element['clientName'] && x.state.tagLabel === 'Pendente').length,
              orderedProposals: orderedProposals.filter((x) => x.client.name === element['clientName']),
            });
          }
          setGroupOrderedProposals(groupOrderedProposalsTmp);
      }
    });

  },[
    //vlaidar dependencias
    orderedProposals, settings.Data.Vehicles_ClientTypology
  ])

  const handleProposal = useCallback((data:any) => {
    let proposalsTmp: Proposal[] = [];
    let approvedProposalsTmp: {
      proposalId: string,
      isClientApproved: boolean,
    }[] = [];
    data.forEach((element:any) => {
      const timeZone:string = "pt-PT";
      const formattedDate = formatDateWithTimeZone(element['createdOn'] as string, timeZone);

      //instanciar proposta
      if(!element['ordered']){
        let proposal:Proposal = {
          id: element['id'],
          client: { 
            name: element['clientName'], 
            type: settings.Data.Vehicles_ClientTypology[1].result.find((x) => { return x.value === element['clientTypology']})?.label !== (null || undefined)
                ? settings.Data.Vehicles_ClientTypology[1].result.find((x) => { return x.value === element['clientTypology']})!.label
                : ' - ',
          }, 
          
          concession: requestingChannelOptions.find((x:any) => { return x.value === element['requestingChannel'] })?.label !== (null || undefined)
            ? requestingChannelOptions.find((x:any) => { return x.value === element['requestingChannel'] })!.label
            : ' - ',

          number: { value: element['id'], label: "Nº da proposta" },
          state: handleState( element['state'], element['description'] ),
          created: { value: formattedDate, label: "Criada" },
          channel: {
            label: "Canal",
            // se garantirmos que o inputChannel da proposta tem sempre valor,
            // podemos substituir o value em baixo pela primeira condição do ternario
            // para garantir o resultado
            value: settings.Data.Vehicles_InputChannel.find((x) => { return x.value === element['inputChannel']})?.label !== (null || undefined)
              ? settings.Data.Vehicles_InputChannel.find((x) => { return x.value === element['inputChannel'] })!.label
              : ' - ',
          },

          // Devia ter estrutura de opção ???
          // key: {
          //   label: element['salesPersonName'],
          //   value: element['salesPersonId'],
          // },
          key: settings.Data.Proposal_SalesPersons.find((x) => { return x.value === element['salesPersonId']})?.label !== (null || undefined)
            ? settings.Data.Proposal_SalesPersons.find((x) => { return x.value === element['salesPersonId'] })!.label
            : element['salesPersonName'],
          negociation: {
            label: "Em negociação",
            vehicles: element['vehicles'].forEach((x:IVehicle) => { return x.quantity }) , //element['totalFleet'], 
            warning: true,
            warningDescription: "por mapear - Mudança de preço",
          },
          //FIXME Alexandre: é preciso corrigir a duplicação de veículos para ter visibilidade sobre o nome 
          driver: [{
            name: element['vehicles'].length > 0 
              ? `${element['vehicles'][0]['driverName']} + ${element['vehicles'].length - 1}` 
              : ' - ',
            date: "00.00.00" 
          }],
          //Este campo aguarda validações por parte da kinto
          rentability: {
            label: "Rentabilidade",
            value: "por mapear - 30%",
          },
        };

        let approvedProposal:{
          proposalId: string,
          isClientApproved: boolean,
        } = {
          proposalId: proposal.id,
          isClientApproved: element.isClientApproved,
        };
        proposalsTmp.push(proposal);
        approvedProposalsTmp.push(approvedProposal);
      }
    });
    setProposals(proposalsTmp);
    setProposalsStatus(approvedProposalsTmp)
  },[
    handleState, requestingChannelOptions, 
    settings.Data.Proposal_SalesPersons, 
    settings.Data.Vehicles_ClientTypology, settings.Data.Vehicles_InputChannel
  ]);

  const handleOrder = useCallback((data:any) => {
    let orderedProposalsTmp: OrderedProposal[] = [];
    data.forEach((element:any) => {
      //instanciar proposta
      if(element['ordered']){
        //instanciar encomenda
        let orderedProposal:OrderedProposal = {
          id: element['id'],
          client: { 
            name: element['clientName'], 
            type:  settings.Data.Vehicles_ClientTypology[1].result.find((x) => { return x.value === element['clientTypology']})?.label !== (null || undefined)
                ? settings.Data.Vehicles_ClientTypology[1].result.find((x) => { return x.value === element['clientTypology']})!.label
                : ' - ',
          }, 
          concession: requestingChannelOptions.find((x:any) => { return x.value === element['requestingChannel'] })?.label !== (null || undefined)
            ? requestingChannelOptions.find((x:any) => { return x.value === element['requestingChannel'] })!.label
            : ' - ',
          number: { value: element['id'], label: "Nº da proposta" },
          state: handleState( element['state'], element['description'] ),
          channel: {
            label: "Canal",
            // se garantirmos que o inputChannel da proposta tem sempre valor,
            // podemos substituir o value em baixo pela primeira condição do ternario
            // para garantir o resultado
            value: settings.Data.Vehicles_InputChannel.find((x) => { return x.value === element['inputChannel']})?.label !== (null || undefined)
              ? settings.Data.Vehicles_InputChannel.find((x) => { return x.value === element['inputChannel'] })!.label
              : ' - ',
          },
          negociation: {
            label: "Em negociação",
            vehicles: element['vehicles'].forEach((x:IVehicle) => { return x.quantity }) , //element['totalFleet'], 
            warning: true,
            warningDescription: "por mapear - Mudança de preço",
          },
          //por mapear
          deliveryDate: [{ date: "17 Jun. 2022", hours: "00.00.00" }],
          conditioning: "-",
          frameworkContract: {
            done: true,
            label: "Assinado",
            description: "Contrato enviado 12.03.2022",
          },
          rentability: {
            label: "Rentabilidade",
            value: "30%",
          },
        }
        orderedProposalsTmp.push(orderedProposal);
      }
    });
    setOrderedProposals(orderedProposalsTmp)
  },[
    handleState, requestingChannelOptions, 
    settings.Data.Vehicles_ClientTypology, 
    settings.Data.Vehicles_InputChannel
  ]);

  const handleClearFilters: ProposalListPageProps['proposalSearchAreaProps']['filterDrawerProps']['applyButtonProps']['onClick'] = 
    useCallback(() => {


      // Poderá ser necessário fazer este ajuste em todos os filtros
      // adicionar o setState do filtro ao hook e 
      // utilizar o state para resetar as checkboxes em vez do memo

      // rentalTypesMemo?.map(x => x.checked = false );
      setRentalTypes((rows) => 
        rows.map(x => {
          x.checked = false;
          return x
        })
      )
      contractNumbers?.map(x => x.checked = false);
      licensePlatesMemo?.map(x => x.checked = false );
      brandsMemo?.map(x => {
        if (x.checkBoxProps.checked === true ) {
          x.checkBoxProps.checked = false;
          x.models.map(model => {
            if (model.checkBoxProps.checked === true) {
              model.checkBoxProps.checked = false;
            }
            return model;
          })
        }
        return x;
      });
      clientNameMemo?.map(x => x.checked = false );
      driversMemo?.map((x:any) => x.checked = false );
      sellerNameMemo?.map(x => x.checked = false);
      frameworkContractNumberMemo?.map(x => x.checked = false );
      durationMemo?.map((x:any) => x.checked = false );
      mileageMemo?.map((x:any) => x.checked = false );

      setRangeDateValue([null,null])
      setRangeDateRadioOptionSelected('lastW')
  

      // Código para resetar as propostas. Removido para limitar chamadas à API e subsquentes renderizações
      // Ativar se necessário
      
      // let dataFilters: { type: AdvancedFilterProposalOptions; value: any[] }[] = [];
      // dataFilters.push({
      //   type: AdvancedFilterProposalOptions.RentalType,
      //   value: rentalTypesMemo?.filter(x => x.checked === true) ?? [],
      // });
      // dataFilters.push({
      //   type: AdvancedFilterProposalOptions.ContractNumber,
      //   value: [],
      // });
      // dataFilters.push({
      //   type: AdvancedFilterProposalOptions.LicencePlate,
      //   value: licensePlatesMemo?.filter(x => x.checked === true) ?? [],
      // });
      // dataFilters.push({
      //   type: AdvancedFilterProposalOptions.VehicleBrand,
      //   value: brandsMemo?.filter(x => x.checkBoxProps.checked === true && x.models?.filter(model => model.checkBoxProps.checked === true)) ?? [],
      // });
      // dataFilters.push({
      //   type: AdvancedFilterProposalOptions.ClientName,
      //   value: clientNameMemo?.filter(x => x.checked === true) ?? [],
      // });
      // dataFilters.push({
      //   type: AdvancedFilterProposalOptions.Date,
      //   value: [],
      // });
      // dataFilters.push({
      //   type: AdvancedFilterProposalOptions.DriverName,
      //   value: driversMemo.filter((x :any)=> x.checked === true) ?? [],
      // });
      // dataFilters.push({
      //   type: AdvancedFilterProposalOptions.Duration,
      //   value: durationMemo?.filter((x: any) => x.checked) ?? [],
      // });
      // dataFilters.push({
      //   type: AdvancedFilterProposalOptions.Distance,
      //   value: mileageMemo?.filter((x: any) => x.checked) ?? [],
      // });
      // dataFilters.push({
      //   type: AdvancedFilterProposalOptions.SellerName,
      //   value: sellerNameMemo?.filter(x => x.checked === true) ?? [],
      // });
      // dataFilters.push({
      //   type: AdvancedFilterProposalOptions.FrameWorkContractNumber,
      //   value: frameworkContractNumberMemo.filter(x => x.checked === true) ?? [],
      // });
      
      // proposalService.getFilteredProposals(dataFilters).then(data => {
      //   if(data && data.succeeded)
      //   {
      //     handleProposal(data.value)
      //     return data.value;
      //   }
      // })
  },[
    brandsMemo, clientNameMemo, 
    driversMemo, durationMemo, 
    frameworkContractNumberMemo, 
    licensePlatesMemo, mileageMemo, 
    // rentalTypesMemo, 
    sellerNameMemo,contractNumbers,
    setRentalTypes
  ]);

  const handleDisableProposal = useCallback( () => {
    const option: Option = {
      value: "",
      label: "",
    };
    const filter:Filter = {
      state: "all",
      startDate: "",
      endDate: "",
    }

    //limpeza dos filtros
    rentalTypesMemo?.map(x => x.checked = false );
    contractNumbers?.map(x => x.checked = false);
    licensePlatesMemo?.map(x => x.checked = false );
    brandsMemo?.map(x => {
      if (x.checkBoxProps.checked === true ) {
        x.checkBoxProps.checked = false;
        x.models.map(model => {
          if (model.checkBoxProps.checked === true) {
            model.checkBoxProps.checked = false;
          }
          return model;
        })
      }
      return x;
    });
    clientNameMemo?.map(x => x.checked = false );
    driversMemo?.map((x:any) => x.checked = false );
    sellerNameMemo?.map(x => x.checked = false);
    frameworkContractNumberMemo?.map(x => x.checked = false );
    durationMemo?.map((x:any) => x.checked = false );
    mileageMemo?.map((x:any) => x.checked = false );
    setRangeDateValue([null,null]);
    setRangeDateRadioOptionSelected('lastW');
    setDateValue('');


    proposalService.getAll().then((data) => {
      handleProposal(data.value);
      handleGroupProposal(data.value);
    });
    setProposalDisable(true);
    //limpar estados
    setSearchProposal(option);
    setSearchFilters(filter);      
  },[ 
    rentalTypesMemo, licensePlatesMemo, brandsMemo, clientNameMemo, driversMemo, sellerNameMemo, frameworkContractNumberMemo, durationMemo, mileageMemo, contractNumbers,
     handleProposal, handleGroupProposal
  ])

  const handleStateChange = useCallback((e:ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    let filter:Filter = {
      state: e.target.value,
      startDate: "",
      endDate: "",
    }
    setSearchFilters(filter);
  },[])

  const handleProposalSearchInputChange = useCallback((event: React.SyntheticEvent<Element, Event>, value: string, reason: AutocompleteInputChangeReason) => {
    let options:Option[] = [];
    if(value.length > 3 ){
      proposalService.getByName(value,{state:null,startDate:"",endDate:""}).then((data) => {
        data.forEach((proposal:any) => {
          let option:Option = {
              label:proposal['nif']+" - "+proposal['clientName'],
              value:proposal['nif'] 
              // value:proposal['customerId'] 
          };
          //filtro duplicados
          if(!options.some( (opt) => { return (opt.value === option.value) }) )
          {
            options.push(option);
          }
        });
        // solução alternativa para o metodo em baixo
        // setProposalsSearchOptions(rows => {
        //   options.map(opt => {
        //     if(!rows.some(row => row.value === opt.value)) {
        //       rows.push(opt)
        //     }
        //     return opt
        //   })
        //   return rows
        // });
        setProposalsSearchOptions(rows =>{
          const updatedRows = [...rows]; 
          // cria novo array em memoria(posição diferente). alterar este array não tem impacto nas rows do proposalsSearchOptions
          // const updatedRows = rows copia o array em memoria(na mesma posição). alterar o array neste caso implica alterações nas rows do proposalsSearchOptions
          options.forEach(opt => {
            if (!updatedRows.some(row => row.value === opt.value)) {
              updatedRows.push(opt);             }
          });

          return updatedRows;
        })
      });
    }
  },[ ]);

  const handleProposalSearchChange = useCallback((event: React.SyntheticEvent<Element, Event>, value: any, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<any> | undefined) => {
    setProposalsSearchOptions([]);
    setSearchProposal(value);
  },[])

  const handleApplyFilters: ProposalListPageProps['proposalSearchAreaProps']['filterDrawerProps']['applyButtonProps']['onClick'] =
   useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      let dataFilters: { type: AdvancedFilterProposalOptions; value: any[] }[] = [];

      dataFilters.push({
        type: AdvancedFilterProposalOptions.RentalType,
        value: rentalTypesMemo?.filter(x => x.checked === true) ?? [],
      });
      dataFilters.push({
        type: AdvancedFilterProposalOptions.ContractNumber,
        value: [],
      });
      dataFilters.push({
        type: AdvancedFilterProposalOptions.LicencePlate,
        value: licensePlatesMemo?.filter(x => x.checked === true) ?? [],
      });
      dataFilters.push({
        type: AdvancedFilterProposalOptions.VehicleBrand,
        value: brandsMemo?.filter(x => x.checkBoxProps.checked === true && x.models?.filter(model => model.checkBoxProps.checked === true)) ?? [],
      });
      dataFilters.push({
        type: AdvancedFilterProposalOptions.ClientName,
        value: clientNameMemo?.filter(x => x.checked === true) ?? [],
      });
      // rangeDateRadioOptionSelected
      let selectedDateOption = rangeDateRadioOptionSelected
      const currentDateTime = new Date();
      // NOTA
      // let today = format(currentDateTime, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
      // poderá ser necessário colocar esta data como data final dos termos de pesquisa das datas
      // caso haja alguma falha na pesquisa.
      // Note-se que esta pesquisa é validada ao milesimo de segundo, pelo que poderá haver algumas discrepâncias se o utilizador 
      // fizer alguma pesquisa identica no espaço de alguns segundos e perder algumas propostas
      if(selectedDateOption === 'lastW') {
        let lastWeek = format(subWeeks(currentDateTime, 1), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
        dataFilters.push({
          type: AdvancedFilterProposalOptions.Date,
          value: [{"startDate":lastWeek,"endDate":null}],
        });
      }
      
      if(selectedDateOption === 'lastM') {
        let lastMonth = format(subMonths(currentDateTime, 1), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
        dataFilters.push({
          type: AdvancedFilterProposalOptions.Date,
          value: [{"startDate":lastMonth,"endDate":null}],
        });
      }

      if(selectedDateOption === 'last3M') {
        let last3months = format(subMonths(currentDateTime, 3), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
        dataFilters.push({
          type: AdvancedFilterProposalOptions.Date,
          value: [{"startDate":last3months,"endDate":null}],
        });
      }

      if(selectedDateOption === 'custom') {
        dataFilters.push({
          type: AdvancedFilterProposalOptions.Date,
          value: rangeDateValue[0] || rangeDateValue[1] ? [{"startDate":rangeDateValue[0],"endDate":rangeDateValue[1]}] : [],
        });
      }

      if(!selectedDateOption) {
        dataFilters.push({
          type: AdvancedFilterProposalOptions.Date,
          value: [],
        });
      }
      
      dataFilters.push({
        type: AdvancedFilterProposalOptions.DriverName,
        value: driversMemo.filter((x :any)=> x.checked === true) ?? [],
      });
      dataFilters.push({
        type: AdvancedFilterProposalOptions.Duration,
        value: durationMemo?.filter((x: any) => x.checked) ?? [],
      });
      dataFilters.push({
        type: AdvancedFilterProposalOptions.Distance,
        value: mileageMemo?.filter((x: any) => x.checked) ?? [],
      });
      dataFilters.push({
        type: AdvancedFilterProposalOptions.SellerName,
        value: sellerNameMemo?.filter(x => x.checked === true) ?? [],
      });
      dataFilters.push({
        type: AdvancedFilterProposalOptions.FrameWorkContractNumber,
        value: frameworkContractNumberMemo.filter(x => x.checked === true) ?? [],
      });
      

      proposalService.getFilteredProposals(dataFilters).then(data => {
        if(data && data.succeeded)
        {
          setDuration(rows => {
            rows.forEach(row => {
              row.checked = false
            })
            return rows
          })
          setMileage(rows => {
            rows.forEach(row => {
              row.checked = false
            })
            return rows
          })

          handleProposal(data.value)
          return data.value;
        }
      })
      setProposalDisable(false);

     

    },[ brandsMemo, clientNameMemo, driversMemo, 
      frameworkContractNumberMemo, handleProposal, 
      licensePlatesMemo, 
      rentalTypesMemo, sellerNameMemo, durationMemo,setDuration,
      mileageMemo, setMileage, rangeDateValue,
      rangeDateRadioOptionSelected
    ]);

  
  
  // const [appliedFilters, setAppliedFilters] = useState<string[]>([]);

  // const handleRemoveAppliedFilter = (index: number) => {
  //   setAppliedFilters((appliedFilters) =>
  //     appliedFilters.filter((_, i) => i !== index)
  //   );
  // };

  
  //USE EFFECTS
  useEffect(() => {
    custService.getCenterDealer().then((data) => {
      if (data) {
        let updatedRequestingChannelOptions:Option[] = [];
        data.map((item: { id: string;description:string }) => {
          return updatedRequestingChannelOptions.push({
            label: item.description,
            value: item.id,
          });
        })
        setRequestingChannelOptions(updatedRequestingChannelOptions)
      }
    });
  },[])

  useEffect(() => {
    if (asyncResponse) {
      let id = asyncResponse.result.toString().split('/').pop();
      // let id:string = result[result.length-1]; 
      // Display the openAlert message with the response data.
      if(asyncResponse.errors !== "") {
        openAlert({
          variant: "error",
          text: asyncResponse.errors,
          title: "Atenção",
          // autoHideDuration: 3000,
          button:{
            props:{
              onClick: handleCardClick(asyncResponse.result),
            },
            text: `Voltar à proposta ${id}.`
          }
        })
      } else {

        openAlert({
          variant: "success",
          text: "Calculo efetuado com sucesso",
          title: "Atenção",
          autoHideDuration: 3000,
          button:{
            props:{
              onClick: handleCardClick(asyncResponse.result),
            },
            text: `Voltar à proposta ${id}.`
          }
        })
      }
      // Reset the asyncResponse in the context to avoid repeated alerts.
      setAsyncResponse(null);
    }

    proposalService.getAll().then(data => {
      if(data && data.succeeded) {
        handleProposal(data.value);
        handleOrder(data.value);
      }

    });

    //INICIO SERVIÇOS FILTROS
    vehicleService.getBrands().then((data) => {
      const items = convertToExpandableMakesOptions(data);
      setBrandsSectionProps(items);
    });

    //FIM

  }, [
    setBrandsSectionProps, 
    handleOrder, handleProposal, 
    asyncResponse, handleCardClick, setAsyncResponse,
    convertToExpandableMakesOptions,
  ]);

  useEffect(() => {

    proposalService.getAll().then((data) => { 
      if(data && data.succeeded)
      {
        if (proposals.length > 0) {
          handleGroupProposal(data.value);
        }
        if (orderedProposals.length > 0)
        {
          handleGroupOrderedProposal(data.value);
        }
      }

    });
  }, [
    proposals,orderedProposals,
    //validar estas dependencias
    handleGroupOrderedProposal, handleGroupProposal, 
  
  ])

  //EM PROPOSTAS - FIM
  
  const [intervalDateValue, setIntervalDateValue] = useState<RangeDate>([
    null,
    null,
  ]);

  const handleChangeStart: RangeDatePickerProps['onChangeEndValue'] = useCallback((
    newValue
  ) => {
    intervalDateValue[0] = newValue;
    setIntervalDateValue([...intervalDateValue]);
    setDateValue(`${newValue?.toLocaleDateString()}`.replace(new RegExp('/', 'g'),'-'))
    setSearchFilters(filter => {
      filter.startDate = `${newValue?.toLocaleDateString()}`.replace(new RegExp('/', 'g'),'-')
      return filter;
    })
  },[intervalDateValue]);

  const [dateValue, setDateValue] = useState<string>('');

  const handleChangeEnd: RangeDatePickerProps['onChangeEndValue'] = useCallback((
    newValue
  ) => {
    intervalDateValue[1] = newValue;
    setIntervalDateValue([...intervalDateValue]);
    if (intervalDateValue[0] && newValue) {
      setDateValue(
        `${intervalDateValue[0].toLocaleDateString()} até ${newValue.toLocaleDateString()}`.replace(new RegExp('/', 'g'),'-')
      );
    }
    setSearchFilters(filter => {
      filter.startDate = `${intervalDateValue[0]?.toLocaleDateString()}`.replace(new RegExp('/', 'g'),'-')
      filter.endDate = `${newValue?.toLocaleDateString()}`.replace(new RegExp('/', 'g'),'-')
      return filter;
    })
  },[intervalDateValue]);

  const handleProposalSearch = useCallback(() => {
    // if (searchProposal) {
    //   // proposalService.getByName(searchProposal.value,searchFilters?.state).then((data) => {
    //   proposalService.getByName(searchProposal.label,searchFilters).then((data) => {
    //     handleProposal(data);
    //   });
    //   setProposalDisable(false);
    // }
    // if (searchFilters) {
    //   proposalService.getByName(undefined,searchFilters).then((data) => {
    //     handleProposal(data);
    //   });
    //   setProposalDisable(false);
    // }
    proposalService.getByName(searchProposal?.value,searchFilters).then((data) => {
      handleProposal(data);
    });
    setProposalDisable(false);
  },[handleProposal, searchFilters, searchProposal])


  return (
    <>
      <ProposalListPage
        topBarProps={topBarPropsMemo}
        backofficeMenuButtonProps={backofficeMenuButtonPropsMemo}
        clientsMenuButtonProps={clientsMenuButtonPropsMemo}
        contractsMenuButtonProps={contractsMenuButtonPropsMemo}
        dashboardMenuButtonProps={dashboardMenuButtonPropsMemo}
        performanceMenuButtonProps={performanceMenuButtonPropsMemo}
        proposalMenuButtonProps={proposalMenuButtonPropsMemo}
        
        tabsText={["Em propostas", "Em encomenda"]}
        pageTitle="Pesquisar propostas"
        addNewProposalButtonText="Nova Proposta"
        addNewProposalButtonProps={{ onClick: handleNewProposalClick }}
        proposalSearchAreaProps={{
          //TODO VALIDAR ISTO
          filterDrawerProps: {
            headerTitle: 'Filtros Avançados',
            rentTypeSectionProps: {
              title: 'Tipo de Aluguer',
              rentTypeCheckboxPropsArray: rentalTypesMemo, 
              // rentTypeCheckboxPropsArray: handleRentType(), 
            },
            contractNumberSectionProps: {
              title: 'Nº de Contrato',
              contractNumberTextFieldProps: {
                onChange: () => alert('Só está prevista a implementação deste filtro na segunda fase do projeto')
              },
              resultsCheckboxPropsArray: handleContractNumber,
            },
            registerPlateSectionProps: {
              title: 'Matrícula',
              registerPlateTextFieldProps: {
                onChange: handleLicensePlatesTextFieldPropsCallback
              },
              resultsCheckboxPropsArray: licensePlatesMemo,
              //  ? licensePlatesMemo : licensePlates ,
            },
            vehicleBrandSectionProps: {
              title: 'Marca da viatura',
              vehicleBrandTextFieldProps: {
                onChange: handleVehicleBrandTextFieldPropsCallback
              },
              // onAllBrandsClick: () => alert('todas as marcas'),
              allBrandsCheckBoxProps: {
                onClick: handleAllBrandsCheckBoxProps
              },
              allBrandsText: 'Todas',
              brands: brandsMemo, 
              // handleVehicleBrand(),
            },
            clientNameSectionProps: {
              title: 'Nome do Cliente',
              clientNameTextFieldProps: {
                onChange: handleClientNameTextFieldPropsCallback
              },
              resultsCheckboxPropsArray: clientNameMemo,
            },
            driverNameSectionProps: {
              title: 'Nome do Condutor',
              driverNameTextFieldProps: {
                onChange: handleDriverNameTextFieldPropsCallback
              },
              resultsCheckboxPropsArray: driversMemo,
              // handleDriverName(),
            },
            
            dateSectionProps: {
              title: 'Data de início e fim',
              radioGroupProps: {
                value: rangeDateRadioOptionSelected,
                onChange: handleDateRangeOption,
                //(e, value) => setRangeDateRadioOptionSelected(value),
              },
              prearrangedDatesRadioPropsArray: [
                {
                  label: 'Última semana',
                  value: 'lastW',
                },
                {
                  label: 'Último mês',
                  value: 'lastM',
                },
                {
                  label: 'Últimos 3 meses',
                  value: 'last3M',
                },
              ],
              customDatesRadioProps: {
                label: 'Data personalizada',
                value: 'custom',
              },
              showDatePicker: rangeDateRadioOptionSelected === 'custom',

              rangeDatePickerProps: {
                startValue: rangeDateValue[0],
                onChangeStartValue: (newValue) => {
                  rangeDateValue[0] = newValue;
                  setRangeDateValue([...rangeDateValue]);
                },
                endValue: rangeDateValue[1],
                onChangeEndValue: (newValue) => {
                  rangeDateValue[1] = newValue;
                  setRangeDateValue([...rangeDateValue]);
                },
                fromText: 'De:',
                toText: 'a:',
                startPlaceholder: 'ex: 27 jan 2023',
                endPlaceholder: 'ex: 25 fev 2023',
              },
            },
            
            durationSectionProps: {
              title: 'Duração em meses',
              durationCheckboxPropsArray: durationMemo,
              addValueButtonProps: {
                onClick:() => {
                  setDuration(rows => [
                    ...rows,
                    {
                      label: `${newMonthValue} meses`,
                      checked:false,
                      value: newMonthValue,
                      onClick: handleToggleSelectDurationsCallback(newMonthValue),
                    }
                  ])
                  setNewMonthValue('');
                },
              },
              addValueButtonText: 'Adicionar',
              otherValueTextFieldProps: { 
                label: 'Adicionar outros valores',
                value: newMonthValue,
                onChange: (e) => setNewMonthValue(e.target.value)
              },
            },
            kmSectionProps: {
              title: 'Km por ano',
              kmCheckboxPropsArray: mileageMemo,
              addValueButtonProps: {
                onClick:() => {
                  setMileage(rows => [
                    ...rows,
                    {
                      label: `${newMileageValue} km/ano`,
                      checked:false,
                      value: newMileageValue,
                      onClick: handleToggleSelectMileageCallback(newMileageValue),
                    }
                  ])
                  setNewMileageValue('');
                },
              },
              addValueButtonText: 'Adicionar',
              otherValueTextFieldProps: { 
                label: 'Adicionar outros valores',
                value: newMileageValue,
                onChange: (e) => setNewMileageValue(e.target.value)
              },
            },
            sellerNameSectionProps: {
              title: 'Nome do Vendedor',
              sellerNameTextFieldProps: {
                onChange: handleSellerNameTextFieldPropsCallback,
              },
              resultsCheckboxPropsArray: sellerNameMemo ? sellerNameMemo : salesPersonNames,
            },
            frameWorkContractNumberSectionProps: {
              title: 'Nº de Contrato de Quadro',
              frameWorkContractNumberTextFieldProps: {
                onChange: handleFrameWorkContractNumberTextFieldPropsCallback
              },
              resultsCheckboxPropsArray: frameworkContractNumberMemo,
            },
            applyButtonText: 'Aplicar',
            applyButtonProps: {
              onClick:handleApplyFilters,
            },
            
            clearAllButtonText: 'Limpar todos os filtros',
            clearAllButtonProps: {
              onClick: handleClearFilters,
            },
            expandAllButtonText: 'Expandir tudo',
            collapseAllButtonText: 'Diminuir tudo',
            // appliedFilters: appliedFilters.map((cur, index) => {
            //   return {
            //     label: cur,
            //     onDelete: () => handleRemoveAppliedFilter(index),
            //   };
            // }),
            // loadingText: 'A procurar resultados para a sua pesquisa',
            // resultsFoundNumber: '02',
            // resultsFoundText: 'Resultados',
            // result: filterResults,
            // selectVehiclesText: 'Selecione as viaturas que pretende adicionar às suas simulações',
          },
          //ENDTODO
          filterIconProps: {},
          eraseLinkText: "LIMPAR",
          eraseLinkProps: {
            disabled: proposalDisable,
            onClick: handleDisableProposal,
          },
          resultNumber: proposals.length.toString(),//"03",//estado.length
          resultText: "resultados encontrados",
          stateDropDownProps: {
            label: "Estado",
            options: [
              { value: "all", label: "Todos" },
              { value: "Ganha", label: "Ganha" },
              { value: "Perdida", label: "Perdida" },
              { value: "Pendente", label: "Pendente" },
            ],
            value: searchFilters.state,
            onChange: handleStateChange, 
            // (e) => {
            //   let filter:Filter = {
            //     state: e.target.value,
            //     startDate: "",
            //     endDate: "",
            //   }
            //   setSearchFilters(filter);
            // },
          },
          rangeDatePickerProps: {
            startValue: intervalDateValue[0],
            onChangeStartValue: handleChangeStart,
            endValue: intervalDateValue[1],
            onChangeEndValue: handleChangeEnd,
            fromText: 'De:',
            toText: 'a:',
            startPlaceholder: 'ex: 27 jan 2023',
            endPlaceholder: 'ex: 25 fev 2023',
            inputFormat: 'dd-MM-yyyy',
          },
          dateTextFieldProps: {
            label: 'Datas de entrega',
            value: dateValue,
          },
          searchProposalButtonText: "Pesquisar",
          searchProposalButtonProps: {
            onClick: handleProposalSearch,
          },
          proposalSearchResultProps: {
            label: "Pesquisar por Nome ou Nif do cliente",
            placeholder: "Pesquisar",
            options: proposalsSearchOptions ? proposalsSearchOptions : [],
            onInputChange: handleProposalSearchInputChange,
            value: searchProposal,
            onChange: handleProposalSearchChange, 

          },
        }}
        orderedProposalSearchAreaProps={{
          //TODO VALIDAR ISTO
          filterDrawerProps: {
            headerTitle: 'Filtros Avançados',
            rentTypeSectionProps: {
              title: 'Tipo de Aluguer',
              rentTypeCheckboxPropsArray: [
                {
                  label: 'AOV',
                },
                {
                  label: 'OPT',
                },
                {
                  label: 'KFL',
                },
                {
                  label: 'TRP',
                },
                {
                  label: 'HR',
                },
              ],
            },
            contractNumberSectionProps: {
              title: 'Nº de Contrato',
              contractNumberTextFieldProps: {},
              resultsCheckboxPropsArray: [
                {
                  label: '203000',
                },
                {
                  label: '203450',
                },
                {
                  label: '203456',
                },
                {
                  label: '203457',
                },
                {
                  label: '203458',
                },
              ],
            },
            registerPlateSectionProps: {
              title: 'Matrícula',
              registerPlateTextFieldProps: {},
              resultsCheckboxPropsArray: [
                {
                  label: 'HJ - 00 - 20',
                },
                {
                  label: 'HJ - 00 - 20',
                },
                {
                  label: 'HJ - 00 - 20',
                },
                {
                  label: 'HJ - 00 - 20',
                },
                {
                  label: 'HJ - 00 - 20',
                },
              ],
            },
            vehicleBrandSectionProps: {
              title: 'Marca da viatura',
              vehicleBrandTextFieldProps: {},
              onAllBrandsClick: () => alert(''),
              allBrandsCheckBoxProps: {},
              allBrandsText: 'Todas',
              brands: [
                {
                  label: 'Audi',
                  onClick: () => alert('expandir audi'),
                  open: true,
                  checkBoxProps: {},
                  models: [
                    { label: 'modelo1', checkBoxProps: {} },
                    {
                      label: 'modelo2',
                      checkBoxProps: {},
                    },
                  ],
                },
                {
                  label: 'BMW',
                  onClick: () => alert('expandir bmw'),
                  open: false,
                  checkBoxProps: {},
                  models: [{ label: 'modelo3', checkBoxProps: {} }],
                },
              ],
            },
            clientNameSectionProps: {
              title: 'Nome do Cliente',
              clientNameTextFieldProps: {},
              resultsCheckboxPropsArray: [
                {
                  label: 'José Silva & Limitada',
                },
                {
                  label: 'José Silva & Limitada',
                },
                {
                  label: 'José Silva & Limitada',
                },
                {
                  label: 'José Silva & Limitada',
                },
                {
                  label: 'José Silva & Limitada',
                },
              ],
            },
            driverNameSectionProps: {
              title: 'Nome do Condutor',
              driverNameTextFieldProps: {},
              resultsCheckboxPropsArray: [
                {
                  label: 'José Silva & Limitada',
                },
                {
                  label: 'José Silva & Limitada',
                },
                {
                  label: 'José Silva & Limitada',
                },
                {
                  label: 'José Silva & Limitada',
                },
                {
                  label: 'José Silva & Limitada',
                },
              ],
            },      
            dateSectionProps: {
              title: 'Data de início e fim',

              radioGroupProps: {
                value: rangeDateRadioOptionSelected,
                onChange: handleDateRangeOption,
                //(e, value) => setRangeDateRadioOptionSelected(value),
              },
              prearrangedDatesRadioPropsArray: [
                {
                  label: 'Última semana',
                  value: 'lastW',
                },
                {
                  label: 'Último mês',
                  value: 'lastM',
                },
                {
                  label: 'Últimos 3 meses',
                  value: 'last3M',
                },
              ],
              customDatesRadioProps: {
                label: 'Data personalizada',
                value: 'custom',
              },
              showDatePicker: rangeDateRadioOptionSelected === 'custom',
              rangeDatePickerProps: {
                startValue: rangeDateValue[0],
                onChangeStartValue: (newValue) => {
                  rangeDateValue[0] = newValue;
                  setRangeDateValue([...rangeDateValue]);
                },
                endValue: rangeDateValue[1],
                onChangeEndValue: (newValue) => {
                  rangeDateValue[1] = newValue;
                  setRangeDateValue([...rangeDateValue]);
                },
                fromText: 'De:',
                toText: 'a:',
              },
            },
            durationSectionProps: {
              title: 'Duração em meses',
              durationCheckboxPropsArray: [
                {
                  label: '12 meses',
                },
                {
                  label: '24 meses',
                },
                {
                  label: '36 meses',
                },
                {
                  label: '48 meses',
                },
                {
                  label: '60 meses',
                },
                {
                  label: '72 meses',
                },
              ],
              addValueButtonProps: {},
              addValueButtonText: 'Adicionar',
              otherValueTextFieldProps: { label: 'Adicionar outros valores' },
            },
            kmSectionProps: {
              title: 'Km por ano',

              kmCheckboxPropsArray: [
                {
                  label: '10000 km',
                },
                {
                  label: '20000 km',
                },
                {
                  label: '30000 km',
                },
                {
                  label: '40000 km',
                },
                {
                  label: '50000 km',
                },
                {
                  label: '60000 km',
                },
                {
                  label: '70000 km',
                },
                {
                  label: '80000 km',
                },
                {
                  label: '90000 km',
                },
                {
                  label: '100000 km',
                },
              ],
              addValueButtonProps: {},
              addValueButtonText: 'Adicionar',
              otherValueTextFieldProps: { label: 'Adicionar outros valores' },
            },
            sellerNameSectionProps: {
              title: 'Nome do Vendedor',
              sellerNameTextFieldProps: {},
              resultsCheckboxPropsArray: [
                {
                  label: 'José Silva',
                },
                {
                  label: 'José Silva',
                },
                {
                  label: 'José Silva',
                },
                {
                  label: 'José Silva',
                },
                {
                  label: 'José Silva',
                },
              ],
            },
            frameWorkContractNumberSectionProps: {
              title: 'Nº de Contrato de Quadro',
              frameWorkContractNumberTextFieldProps: {},
              resultsCheckboxPropsArray: [
                {
                  label: 'CO10089802',
                },
                {
                  label: 'CO10089802',
                },
                {
                  label: 'CO10089802',
                },
                {
                  label: 'CO10089802',
                },
                {
                  label: 'CO10089802',
                },
              ],
            },
            applyButtonText: 'Aplicar',
            applyButtonProps: {
              onClick: () => alert('Adicionar selecionados'),
            },
            clearAllButtonText: 'Limpar todos os filtros',
            clearAllButtonProps: {},
            expandAllButtonText: 'Expandir tudo',
            collapseAllButtonText: 'Diminuir tudo',
            // appliedFilters: appliedFilters.map((cur, index) => {
            //   return {
            //     label: cur,
            //     onDelete: () => handleRemoveAppliedFilter(index),
            //   };
            // }),
            // loadingText: 'A procurar resultados para a sua pesquisa',
            // resultsFoundNumber: '02',
            // resultsFoundText: 'Resultados',
            // result: filterResults,
            // selectVehiclesText: 'Selecione as viaturas que pretende adicionar às suas simulações',
          },
          //ENDTODO
          filterIconProps: {},
          eraseLinkText: "LIMPAR",
          eraseLinkProps: {
            disabled: orderDisable,
            onClick: () => {
              const option: Option = {
                value: "",
                label: "",
              };
              const filter:Filter = {
                state: "all",
                startDate: "",
                endDate: "",
              }

              proposalService.getAll().then((data) => {
                handleOrder(data);
                handleGroupOrderedProposal(data);
              });
              setOrderDisable(true);
              //limpar estados
              setSearchOrder(option);
              setOrderSearchFilters(filter);
            },
          },
          resultNumber: orderedProposals.length.toString(),
          resultText: "resultados encontrados",
          stateDropDownProps: {
            label: "Estado",
            defaultValue: "all",
            options: [
              { value: "all", label: "Todos" },
              { value: "Em encomenda", label: "Em encomenda" },
              { value: "Em análise", label: "Em análise" }, 
              { value: "Em contrato", label: "Em contrato" },
            ],
            value: "",
            onChange: (e) => {
              let filter:Filter = {
                state: e.target.value,
                startDate: "",
                endDate: "",
              }
              setOrderSearchFilters(filter);
            },
          },
          rangeDatePickerProps: {
            startValue: intervalDateValue[0],
            onChangeStartValue: handleChangeStart,
            endValue: intervalDateValue[1],
            onChangeEndValue: handleChangeEnd,
            fromText: 'De:',
            toText: 'a:',
            startPlaceholder: 'ex: 27 jan 2023',
            endPlaceholder: 'ex: 25 fev 2023',
            inputFormat: 'dd-MM-yyyy',
          },
          dateTextFieldProps: {
            label: 'Datas de entrega',
            value: dateValue,
          },
          // dateDropDownProps: {
          //   label: "Datas de entrega",
          //   defaultValue: "all",
          //   options: [
          //     { value: "all", label: "Intervalo de datas" },
          //     { value: "sH", label: "Santogal Honda" },
          //   ],
          // },
          searchProposalButtonText: "Pesquisar",
          searchProposalButtonProps: {
            onClick: 
            () => {
              if (searchOrder)
              {
                proposalService.getByName(searchOrder.value,orderSearchFilters?.state).then((data) => {
                  handleOrder(data);
                });
                setOrderDisable(false);
              }
              else if (orderSearchFilters)
              {
                proposalService.getByName(undefined,orderSearchFilters.state).then((data) => {
                  handleOrder(data);
                });
                setOrderDisable(false);
              }
            },
          },
          proposalSearchResultProps: {
            label: "Pesquisar por Nome ou NIF do cliente",
            placeholder: "Pesquisar",
            options: ordersSearchOptions ? ordersSearchOptions : [], //HYDRA
            onInputChange: (event, value, reason) => {
              let options:Option[] = [];
              if(value.length > 2 ){
                proposalService.getByName(value,{state:null,startDate:"",endDate:""}).then((data) => {
                  data.forEach((proposal:any) => {
                    let option:Option = {
                        label:proposal['nif']+" - "+proposal['clientName'],
                        value:proposal['nif'] 
                    };
                    //filtro duplicados
                    if(!options.some((opt) => {
                      return (opt.value === option.value)
                    }))
                    {
                      options.push(option);
                    }
                  });
                  setOrdersSearchOptions([...ordersSearchOptions,...options]);
                });
              }
            },
            value: searchOrder,
            onChange: (e, option) => {
              setOrdersSearchOptions([]);
              setSearchOrder(option);
            },
          },
        }}
        //listagem de propostas por cliente
        proposalTableGridViewProps={{
          groupProposals: groupProposals, //,
          proposalTableHeadProps: {
            clientDetailsText: "Detalhes do Cliente",
            pendingProposalsText: "Propostas pendentes",
            realizedProposalsText: "Propostas realizadas",
          },
          seeAllProposalText: {
            open: "Ver todas as propostas",
            close: "Ocultar propostas",
          },
          onActionButtonClick: (_, index) => {
            let proposal = proposals.find(x => x.id.toString() === index.toString());
            let proposalStatus = proposalsStatus.find(x => x.proposalId.toString() === index.toString());
            if(proposal && proposalStatus){
              if( proposal.id === proposalStatus.proposalId && proposalStateDetailsScreenPermittedOptions.some(
                searchElement => searchElement.label === proposal?.state.tagLabel
              )){
                handleArrowClick(`${index}`)
              } else {
                handleArrowClick(`edit/${index}`)
              }
            }
          },
        }}
        //listagem de propostas
        proposalTableProps={{
          onActionButtonClick: (_, index) => {
            // if(proposals[index].id === proposalsStatus[index].proposalId && proposalsStatus[index].isClientApproved) {
            if(proposals[index].id === proposalsStatus[index].proposalId && proposalStateDetailsScreenPermittedOptions.some(
              searchElement => searchElement.label === proposals[index].state.tagLabel
            )) {
              navigate(`/proposal/${proposals[index].id}`);
            } else {
              navigate(`/proposal/edit/${proposals[index].id}`);
            }
          },
          rows: proposals.map((cur,index) => {
            return {
              cell: [
                //cliente
                cur.client,
                //concessão
                cur.concession, //+ index.toString(),
                //proposta nº
                cur.number.value,
                //estado
                cur.state,
                // Criada a antes de canal
                cur.created.value,
                //canal
                cur.channel.value,
                //key account depois de canal
                cur.key,
                //em negociação
                cur.negociation,
                //condutor
                cur.driver,
              ],
            };
          }),
          headCells: [
            {
              id: "client",
              label: "Cliente",
            },
            {
              id: "concession",
              label: "Concessão",
            },
            {
              id: "number",
              label: "Proposta Nº ",
            },
            {
              id: "state",
              label: "Estado",
            },
            {
              id: "created",
              label: "Criada a",
            },
            {
              id: "channel",
              label: "Canal",
            },
            {
              id: "key",
              label: "Key Account",
            },
            {
              id: "negociation",
              label: "Em negociação",
            },
            {
              id: "driver",
              label: "Condutor",
            },
          ],
        }}
        //listage de encomendas por cliente
        orderedProposalTableGridViewProps={{
          groupOrderedProposals: groupOrderedProposals.map(item => ({
            ...item,
            onHeadClick: () => handleClientDetailsClick(item.clientId),
          })),
          orderedTableHeadProps: {
            clientDetailsText: "Detalhes do Cliente",
            pendingProposalsText: "Propostas pendentes",
            realizedProposalsText: "Propostas realizadas",
          },
          seeAllProposalText: {
            open: "Ver todas as propostas",
            close: "Ocultar propostas",
          },
        }}
        //listagem de encomendas
        orderedProposalTableProps={{
          onActionButtonClick: (_, index) =>
            handleArrowClick(`order/${orderedProposals[index].id}`),
          rows: orderedProposals.map((cur) => {
            return {
              cell: [
                //cliente
                cur.client,
                //concessão
                cur.concession,
                //Proposta Nº
                cur.number.value,
                //estado
                cur.state,
                //Condicionantes
                cur.conditioning,
                //Contrato de quadro
                cur.frameworkContract,
                //canal
                cur.channel.value,
                //Em negociação
                cur.negociation,
                //Data de entrega
                cur.deliveryDate,
              ],
            };
          }),

          headCells: [
            {
              id: "client",
              label: "Cliente",
            },
            {
              id: "concession",
              label: "Concessão",
            },
            {
              id: "number",
              label: "Proposta Nº ",
            },
            {
              id: "state",
              label: "Estado",
            },
            {
              id: "conditioning",
              label: "Condicionantes",
            },
            {
              id: "frameworkContract",
              label: "Contrato de quadro",
            },
            {
              id: "channel",
              label: "Canal",
            },
            {
              id: "negociation",
              label: "Em negociação",
            },
            {
              id: "date",
              label: "Data de entrega",
            },
          ],
        }}
      />
      <Alert open={open} onClose={handleClose} {...alertProps} />
    </>
  );
};

export default React.memo(ProposalList);
