import {
    ButtonProps,
    ProposalsApprovationPage,
    PumpDieselIcon,
    PumpPetrolIcon,
    SuccessIcon,
    WizardProps,
  } from '@hi-interactive-admin/simulador-kinto';
  import React, { useEffect, useState } from 'react';
  import { useParams } from 'react-router-dom';
  import { step1DetailsExample } from './step1Details';
  
  const handleStepClick: (index: number) => void = (index) => {
    alert(`go to step: ${index + 1}`);
  };
  
  const steps: WizardProps['steps'] = [
    {
      label: 'Seleção de propostas',
      onClick: handleStepClick,
      canClick: true,
    },
    {
      label: 'Ficha de cliente',
      onClick: handleStepClick,
      canClick: true,
    },
    {
      label: 'Documentos',
      onClick: handleStepClick,
      canClick: true,
    },
    {
      label: 'Análise de Risco',
      onClick: handleStepClick,
      canClick: true,
    },
  ];
  
  type ProposalApprovationRouteParams = {
    proposalId: string;
  };
  
  const ProposalApprovationDemo = () => {
    const params = useParams<ProposalApprovationRouteParams>();
    const [step, setStep] = useState<number>(0);
    const [showStep1DetailsChangeImpact, setShowStep1DetailsChangeImpact] =
      useState<boolean>(false);
    const [isStep2AlertOpen, setIsStep2AlertOpen] = useState<boolean>(true);
    const [isStep3AlertOpen, setIsStep3AlertOpen] = useState<boolean>(true);
    const [billingAddressType, setBillingAddressType] = useState<string>('same');
  
    const handleStep1DetailsEditableFieldSave = () => {
      alert('Guardar novo valor');
      setShowStep1DetailsChangeImpact(true);
    };
  
    const handleCloseStep1DetailsChangeImpact = () => {
      setShowStep1DetailsChangeImpact(false);
    };
  
    const handleForward: ButtonProps['onClick'] = () => {
      if (step < 3) setStep(step + 1);
    };
  
    const handleBackward: ButtonProps['onClick'] = () => {
      if (step > 0) setStep(step - 1);
    };
  
    //Para demostrar o ecrã 35
    useEffect(() => {
      //Aguarda análise
      if (params.proposalId === '0') setStep(3);
      //Análise feita e tudo aprovado (concluído)
      if (params.proposalId === '1') setStep(4);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
    const closeStep2Alert = () => {
      setIsStep2AlertOpen(false);
    };
  
    const closeStep3Alert = () => {
      setIsStep3AlertOpen(false);
    };
  
    return (
      <ProposalsApprovationPage
        title="Aprovação de Propostas"
        topBarProps={{
          doubtsLinkProps: {},
          doubtsLinkText: 'Esclarecer dúvidas',
          onLogoClick: () => alert('Kinto logo'),
        }}
        bottomBarProps={{
          cancelButtonProps: {
            onClick: () => alert('cancelar a criação da proposta'),
          },
          extraButtonText: '',
          cancelText: 'Cancelar simulação',
          backwardText: 'Voltar',
          backwardButtonProps: {
            disabled: step === 0,
            onClick: handleBackward,
          },
          forwardText: 'Avançar',
          forwardButtonProps: { disabled: false, onClick: handleForward },
        }}
        proposalNumber="C010089803"
        proposalText="Proposta Nº:"
        requester={{
          label: 'Requerente',
          value: 'Client Nº C102278',
        }}
        contactName={{
          label: 'Nome do contacto',
          value: 'Tiago Matos',
        }}
        validUntil={{
          label: 'Proposta válida até',
          value: '02 -04-2020',
        }}
        proposedIncome={{
          label: 'Renda Proposta',
          value: '381,93€  |  481,93€',
        }}
        steps={steps}
        activeStep={step}
        proposalSelectionProps={{
          vehicleSearchResultProps: {
            options: [{ label: 'opção 1', value: 'op1' }],
            label: 'Pesquise por CA ou veículo',
            placeholder: 'Pesquisar',
          },
          searchButtonText: 'Pesquisar',
          searchButtonProps: {},
          eraseLinkText: 'Limpar',
          orderByDropDownOptions: {
            brandLabel: 'Marca',
            lotLabel: 'Lote',
            modelLabel: 'Modelo',
            MKmLabel: 'Meses/Km',
            segmentLabel: 'Segmento',
            versionLabel: 'Versão',
          },
          orderByDropDownText: 'Ordenar por: ',
          exportButtonText: 'Exportar',
          exportButtonProps: {},
          instructionText: 'Selecione as propostas que pretende aceitar',
          tableData: [
            {
              proposedVehicleText: 'Veículo proposto',
              header: {
                characteristics:
                  '(1461CC | 90CV | 5 lugares | 5 portas | Azul ) ',
                icons: [PumpDieselIcon],
                model: 'Renault IV Sport Tourer Diesel - ST 1.5 Blue DCI Limited',
              },
  
              simulations: [
                {
                  details: {
                    ...step1DetailsExample,
                    servicesArea: {
                      ...step1DetailsExample.servicesArea,
                      accidentDamageEditableFieldProps: {
                        ...step1DetailsExample.servicesArea
                          .accidentDamageEditableFieldProps,
                        onSave: handleStep1DetailsEditableFieldSave,
                        onCancel: () => alert('Recuperar valor antigo'),
                      },
                      tyresEditableFieldProps: {
                        ...step1DetailsExample.servicesArea
                          .tyresEditableFieldProps,
                        onSave: handleStep1DetailsEditableFieldSave,
                        onCancel: () => alert('Recuperar valor antigo'),
                      },
                      accidentVSEditableFieldProps: {
                        ...step1DetailsExample.servicesArea
                          .accidentVSEditableFieldProps,
                        onSave: handleStep1DetailsEditableFieldSave,
                        onCancel: () => alert('Recuperar valor antigo'),
                      },
                      othersEditableFieldProps: {
                        ...step1DetailsExample.servicesArea
                          .othersEditableFieldProps,
                        onSave: handleStep1DetailsEditableFieldSave,
                        onCancel: () => alert('Recuperar valor antigo'),
                      },
                    },
                    characteristicsArea: {
                      ...step1DetailsExample.characteristicsArea,
                      rentDurationEditableFieldProps: {
                        ...step1DetailsExample.characteristicsArea
                          .rentDurationEditableFieldProps,
                        onSave: handleStep1DetailsEditableFieldSave,
                        onCancel: () => alert('Recuperar valor antigo'),
                      },
                      contractedKmsEditableFieldProps: {
                        ...step1DetailsExample.characteristicsArea
                          .contractedKmsEditableFieldProps,
                        onSave: handleStep1DetailsEditableFieldSave,
                        onCancel: () => alert('Recuperar valor antigo'),
                      },
                    },
                    showImpact: showStep1DetailsChangeImpact,
                    impact: {
                      ...step1DetailsExample.impact,
                      onClose: handleCloseStep1DetailsChangeImpact,
                    },
                  },
                  termkmHeaderText: '60/120000',
                  vehicleSummary:
                    'Renault IV Sport Tourer Diesel - ST 1.5 Blue DCI Limited',
                  income: { withoutTax: '454,48 € ', withTax: '553,48 € ' },
                  qtd: '1',
                  id: 'CA10089805',
                  term: '60',
                  km: '120000',
                  rowActions: [
                    {
                      label: 'Ver detalhes',
                    },
                    {
                      label: 'Editar',
                      onClick: () => alert('action click'),
                    },
                  ],
                },
              ],
  
              vehicle: {
                icons: [PumpDieselIcon],
                lot: 'lote: 104930142',
                model:
                  'Renault Mégane IV Sport Tourer Diesel - Mégane ST 1.5 Blue DCI Limited',
                brand: 'Renault',
                segment: 'Diesel',
                version: 'Mégane ST 1.5 Blue DCI Limited',
              },
  
              addSimulationLinkText: 'Nova simulação',
              addSimulationLinkOnClick: () => alert('adicionar simulação'),
            },
          ],
          closeExpandableText: 'Fechar detalhes',
          headCells: [
            { label: 'Simulação' },
            { label: 'Viatura' },
            { label: 'Meses' },
            { label: 'Kilometros' },
            { label: 'Quantidade' },
            { label: 'Renda sem e com IVA' },
          ],
        }}
        clientFileProps={{
          alertProps: {
            open: isStep2AlertOpen,
            onClose: closeStep2Alert,
            text: 'Por favor valide os seus dados ou actualize caso seja necessário.',
            title: 'Já temos a sua ficha de cliente.',
            variant: 'info',
          },
          clientDataArea: {
            title: 'Dados Cliente',
  
            socialDenominationTextFieldProps: {
              label: 'Denominação social',
              placeholder: 'Inserir Denominação social',
  
              defaultValue: 'EGEO Circular, S.A. e EGEO Pressão, S.A.',
            },
            taxNumberTextFieldProps: {
              label: 'NIF',
              placeholder: 'Inserir NIF',
              defaultValue: '514986298',
              externalIcon: SuccessIcon,
            },
            clientTypologyDropDownProps: {
              label: 'Tipologia de Cliente',
              placeholder: 'Inserir Tipologia de Cliente',
              options: [
                {
                  label: 'Grandes Empresas (Frota > 30 veículos)',
                  value: 'ge30f',
                },
              ],
              defaultValue: 'ge30f',
            },
            totalFleetTextFieldProps: {
              label: 'Frota total',
              placeholder: 'Inserir',
  
              defaultValue: '40',
            },
          },
          optionalClientDataArea: {
            title: 'Dados Cliente (Opcional)',
  
            socialDenominationSearchProps: {
              placeholder: 'Pesquisar',
              label: 'Grupo empresarial',
              options: [
                { value: 'EGEO', label: 'EGEO Pressão, S.A.' },
                { value: 'other', label: 'Outro Exemplo' },
              ],
              defaultValue: { value: 'EGEO', label: 'EGEO Pressão, S.A.' },
            },
            bankTextFieldProps: {
              label: 'Banco',
              placeholder: 'Inserir banco',
              defaultValue: 'Novo Banco',
            },
            swiftCodeTextFieldProps: {
              label: 'Swift Code',
              placeholder: 'Inserir swift code',
              defaultValue: '-',
            },
            shareCapitalTextFieldProps: {
              label: 'Capital social',
              placeholder: 'Inserir capital social',
              defaultValue: '100.000,00',
              endAdornment: '€',
            },
            commercialRegistryTextFieldProps: {
              label: 'Registo comercial',
              placeholder: 'Inserir registo comercial',
              defaultValue: 'IRN146100NN',
            },
            IBANTextFieldProps: {
              label: 'IBAN',
              placeholder: 'Inserir IBAN',
              defaultValue: 'PT50',
            },
            addPartnerOrShareholderButtonProps: {},
            addPartnerOrShareholderButtonText: 'Adicionar sócio/acionista',
            partnersOrShareholders: [
              {
                nameTextFieldProps: {
                  label: 'Sócios ou Acionistas principais',
                  defaultValue: 'Andreia Gonçalves',
                },
                equityParticipationTextFieldProps: {
                  label: 'Participação no capital',
                  defaultValue: '50%',
                },
              },
            ],
          },
          interlocutorDataArea: {
            title: 'Dados do interlocutor',
            nameTextFieldProps: {
              label: 'Nome',
              placeholder: 'Inserir nome',
              defaultValue: 'Andreia Gonçalves',
            },
            phoneInputProps: {
              label: 'País',
              defaultValue: 'PT',
            },
            phoneNumberTextFieldProps: {
              label: 'Telefone',
              placeholder: 'Inserir número',
              defaultValue: '213342027',
            },
            emailTextFieldProps: {
              label: 'E-mail',
              placeholder: 'Inserir e-mail',
              defaultValue: 'info@egeo.com',
            },
            mobilePhoneInputProps: {
              label: 'País',
              defaultValue: 'PT',
            },
            mobilePhoneNumberTextFieldProps: {
              label: 'Telemóvel',
              placeholder: 'Inserir número',
              defaultValue: '913326541',
            },
          },
          documentationAddressArea: {
            title: 'Morada de envio documentação',
  
            careOfTextFieldProps: {
              label: 'A/C',
              placeholder: 'Inserir nome',
              defaultValue: 'Andreia Gonçalves',
            },
            headquartersAddressTextFieldProps: {
              label: 'Morada Sede',
              placeholder: 'Inserir morada sede',
              defaultValue: 'Rua 25 de Abril, Nº 1, Quinta da Francelha de Baixo',
            },
            countryInputProps: {
              label: 'País',
              defaultValue: 'PT',
            },
            postalCodeTextFieldProps: {
              label: 'Código Postal',
              defaultValue: '2685',
            },
            postalCodeSecondPartTextFieldProps: {
              defaultValue: '368',
            },
            addressTextFieldProps: { value: 'Prior Velho' },
            addAddressButtonProps: { disabled: true },
            addAddressButtonText: 'Adicionar Segunda morada',
          },
          secondDocumentationAddressArea: {
            careOfTextFieldProps: {
              label: 'A/C',
              placeholder: 'Inserir nome',
              defaultValue: 'Andreia Gonçalves',
            },
            headquartersAddressTextFieldProps: {
              label: 'Morada Sede',
              placeholder: 'Inserir morada sede',
              defaultValue: 'Rua 25 de Abril, Nº 1, Quinta da Francelha de Baixo',
            },
            countryInputProps: {
              label: 'País',
              defaultValue: 'PT',
            },
            postalCodeTextFieldProps: {
              label: 'Código Postal',
              defaultValue: '2685',
            },
            postalCodeSecondPartTextFieldProps: {
              defaultValue: '368',
            },
            addressTextFieldProps: { value: 'Prior Velho' },
            onDelete: () => alert('apagar'),
          },
          billingAddressArea: {
            title: 'Definir morada de envio faturação ',
            billingAddressRadioGroupProps: {
              value: billingAddressType,
              onChange: (e, v) => setBillingAddressType(v),
            },
            sameBillingAddressRadioProps: {
              value: 'same',
              label: 'Mesma morada de envio de documentação',
            },
            addBillingAddressRadioProps: {
              value: 'add',
              label: 'Adicionar outra morada',
            },
            careOfTextFieldProps: {
              label: 'A/C',
              placeholder: 'Inserir nome',
              defaultValue: 'Andreia Gonçalves',
            },
            headquartersAddressTextFieldProps: {
              label: 'Morada Sede',
              placeholder: 'Inserir morada sede',
              defaultValue: 'Rua 25 de Abril, Nº 1, Quinta da Francelha de Baixo',
            },
            countryInputProps: {
              label: 'País',
              defaultValue: 'PT',
            },
            postalCodeTextFieldProps: {
              label: 'Código Postal',
              defaultValue: '2685',
            },
            postalCodeSecondPartTextFieldProps: {
              defaultValue: '368',
            },
            addressTextFieldProps: { value: 'Prior Velho' },
          },
          financialProposalAcceptanceArea: {
            title: 'Aceitação de proposta financeira',
            status: { color: 'success', label: 'Assinado' },
            signDropDownProps: {
              options: [
                {
                  label: 'Digitalmente',
                  value: 'digital',
                },
                {
                  label: 'Download do documento',
                  value: 'download',
                },
              ],
              placeholder: 'Assinar',
              defaultValue: 'download',
            },
            printLinkText: 'Imprimir',
            onPrintLinkClick: () => alert('imprimir'),
            attachFile: true,
            fileAttachArea: {
              attachFileButtonText: 'Anexar ficheiro',
              allowedTypesText:
                'Tipos de ficheiros permitidos: jpg, jpeg, png, doc, pdf, gif, zip, rar, txt, xls, docx, xlsx.',
              allowedTypes: '.pdf,image/*,.doc,.zip,.txt,.rar,.docx,.xlsx',
              fileMaxSize: 'O tamanho máximo dos ficheiros é 2 MB',
              filesProgressBarProps: {
                progress: 50,
                title: 'nomedoficheiro.pdf',
                deleteIcon: true,
                label: '2.3MB/8.4MB',
                deleteTooltipText: 'Apagar',
                onDeleteClick: () => alert('cancelar'),
              },
            },
          },
          draftContractArea: {
            title: 'Minuta contrato de quadro',
            fileDownloadLinkText: 'Download do ficheiro',
            onFileDownloadLinkClick: () => alert('dowload'),
          },
        }}
        documentsProps={{
          alertProps: {
            open: isStep3AlertOpen,
            onClose: closeStep3Alert,
            text: 'Por favor, veja os detalhes abaixo e siga as instruções.',
            title: 'Tem documentos que necessitam de ser actualizados.',
            variant: 'error',
          },
          title: 'Documentação necessária',
          subTitle:
            'Para poder avançar no processo, é necessário submeter os seguintes documentos.',
          enterpriseDocumentsArea: {
            title: 'Documentação empresa',
            documents: [
              {
                title: 'IES - Últimos 2 anos',
                status: {
                  type: 'error',
                  errorText:
                    'Actualizar documento, tem uma data anterior aos últimos dois anos',
                },
                fileAttachArea: [
                  {
                    attachFileButtonText: 'Anexar ficheiro',
                    allowedTypesText:
                      'Tipos de ficheiros permitidos: jpg, jpeg, png, doc, pdf, gif, zip, rar, txt, xls, docx, xlsx.',
                    allowedTypes: '.pdf,image/*,.doc,.zip,.txt,.rar,.docx,.xlsx',
                    fileMaxSize: 'O tamanho máximo dos ficheiros é 2 MB',
                    onAttachFileChange: () => alert('anexar ficheiro'),
                    filesProgressBarProps: [
                      {
                        progress: 50,
                        title: 'nomedoficheiro.pdf',
                        deleteIcon: true,
                        label: '2.3MB/8.4MB',
                        deleteTooltipText: 'Apagar',
                        onDeleteClick: () => alert('cancelar'),
                      },
                    ],
                  },
                ],
                checkboxProps: [
                  {
                    defaultChecked: true,
                    label: 'inferior a um ano',
                  },
                  {
                    defaultChecked: true,
                    label: 'inferior a dois ano',
                  },
                ],
              },
              {
                title: 'Modelo 22 - Últimos 2 anos',
                fileAttachArea: [
                  {
                    attachFileButtonText: 'Anexar ficheiro',
                    allowedTypesText:
                      'Tipos de ficheiros permitidos: jpg, jpeg, png, doc, pdf, gif, zip, rar, txt, xls, docx, xlsx.',
                    allowedTypes: '.pdf,image/*,.doc,.zip,.txt,.rar,.docx,.xlsx',
                    fileMaxSize: 'O tamanho máximo dos ficheiros é 2 MB',
                    onAttachFileChange: () => alert('anexar ficheiro'),
                  },
                ],
              },
              {
                title: 'Balancete Detalhado (dos anos sem IES)',
                fileAttachArea: [
                  {
                    attachFileButtonText: 'Anexar ficheiro',
                    allowedTypesText:
                      'Tipos de ficheiros permitidos: jpg, jpeg, png, doc, pdf, gif, zip, rar, txt, xls, docx, xlsx.',
                    allowedTypes: '.pdf,image/*,.doc,.zip,.txt,.rar,.docx,.xlsx',
                    fileMaxSize: 'O tamanho máximo dos ficheiros é 2 MB',
                    onAttachFileChange: () => alert('anexar ficheiro'),
                  },
                ],
              },
              {
                title: 'Comprovativo de IBAN',
                fileAttachArea: [
                  {
                    attachFileButtonText: 'Anexar ficheiro',
                    allowedTypesText:
                      'Tipos de ficheiros permitidos: jpg, jpeg, png, doc, pdf, gif, zip, rar, txt, xls, docx, xlsx.',
                    allowedTypes: '.pdf,image/*,.doc,.zip,.txt,.rar,.docx,.xlsx',
                    fileMaxSize: 'O tamanho máximo dos ficheiros é 2 MB',
                    onAttachFileChange: () => alert('anexar ficheiro'),
                  },
                ],
              },
              {
                title: 'Mapa de Responsabilidade de Crédito (Banco de Portugal)',
                fileAttachArea: [
                  {
                    attachFileButtonText: 'Anexar ficheiro',
                    allowedTypesText:
                      'Tipos de ficheiros permitidos: jpg, jpeg, png, doc, pdf, gif, zip, rar, txt, xls, docx, xlsx.',
                    allowedTypes: '.pdf,image/*,.doc,.zip,.txt,.rar,.docx,.xlsx',
                    fileMaxSize: 'O tamanho máximo dos ficheiros é 2 MB',
                    onAttachFileChange: () => alert('anexar ficheiro'),
                  },
                ],
              },
            ],
          },
          partnersDocumentsArea: {
            title: 'Documentação sócios',
            documents: [
              {
                title: 'Cartão Cidadão',
                status: {
                  type: 'success',
                },
                fileAttachArea: [
                  {
                    attachFileButtonText: 'Anexar frente do CC',
                    allowedTypesText:
                      'Tipos de ficheiros permitidos: jpg, jpeg, png, doc, pdf, gif, zip, rar, txt, xls, docx, xlsx.',
                    allowedTypes: '.pdf,image/*,.doc,.zip,.txt,.rar,.docx,.xlsx',
                    fileMaxSize: 'O tamanho máximo dos ficheiros é 2 MB',
                    onAttachFileChange: () => alert('anexar ficheiro'),
                    filesProgressBarProps: [
                      {
                        progress: 70,
                        title: 'nomedoficheiro.pdf',
                        deleteIcon: true,
                        label: '2.3MB/8.4MB',
                        deleteTooltipText: 'Apagar',
                        onDeleteClick: () => alert('cancelar'),
                      },
                    ],
                  },
                  {
                    attachFileButtonText: 'Anexar verso do CC',
                    allowedTypesText:
                      'Tipos de ficheiros permitidos: jpg, jpeg, png, doc, pdf, gif, zip, rar, txt, xls, docx, xlsx.',
                    allowedTypes: '.pdf,image/*,.doc,.zip,.txt,.rar,.docx,.xlsx',
                    fileMaxSize: 'O tamanho máximo dos ficheiros é 2 MB',
                    onAttachFileChange: () => alert('anexar ficheiro'),
                    filesProgressBarProps: [
                      {
                        progress: 50,
                        title: 'nomedoficheiro.pdf',
                        deleteIcon: true,
                        label: '2.3MB/8.4MB',
                        deleteTooltipText: 'Apagar',
                        onDeleteClick: () => alert('cancelar'),
                      },
                    ],
                  },
                ],
                fullWidthCheckboxProps: [
                  {
                    label:
                      'Declaro que autorizo a recolha da cópia do meu cartão de cidadão e sua conservação pela KINTO para efeito de contrato com a mesma.',
                    defaultChecked: true,
                  },
                ],
              },
              {
                title: 'Comprovativo de morada',
                fileAttachArea: [
                  {
                    attachFileButtonText: 'Anexar ficheiro',
                    allowedTypesText:
                      'Tipos de ficheiros permitidos: jpg, jpeg, png, doc, pdf, gif, zip, rar, txt, xls, docx, xlsx.',
                    allowedTypes: '.pdf,image/*,.doc,.zip,.txt,.rar,.docx,.xlsx',
                    fileMaxSize: 'O tamanho máximo dos ficheiros é 2 MB',
                    onAttachFileChange: () => alert('anexar ficheiro'),
                  },
                ],
              },
              {
                title: 'Recidos de vencimento (últimos 3 meses)',
                fileAttachArea: [
                  {
                    attachFileButtonText: 'Anexar ficheiro',
                    allowedTypesText:
                      'Tipos de ficheiros permitidos: jpg, jpeg, png, doc, pdf, gif, zip, rar, txt, xls, docx, xlsx.',
                    allowedTypes: '.pdf,image/*,.doc,.zip,.txt,.rar,.docx,.xlsx',
                    fileMaxSize: 'O tamanho máximo dos ficheiros é 2 MB',
                    onAttachFileChange: () => alert('anexar ficheiro'),
                  },
                ],
              },
              {
                title: 'Última Declaração IRS',
                fileAttachArea: [
                  {
                    attachFileButtonText: 'Anexar ficheiro',
                    allowedTypesText:
                      'Tipos de ficheiros permitidos: jpg, jpeg, png, doc, pdf, gif, zip, rar, txt, xls, docx, xlsx.',
                    allowedTypes: '.pdf,image/*,.doc,.zip,.txt,.rar,.docx,.xlsx',
                    fileMaxSize: 'O tamanho máximo dos ficheiros é 2 MB',
                    onAttachFileChange: () => alert('anexar ficheiro'),
                  },
                ],
              },
            ],
          },
        }}
        riskAnalysisProps={{
          vehicles: [
            {
              icons: [PumpPetrolIcon],
              model:
                'Renault Mégane IV Sport Tourer Diesel - Mégane ST 1.5 Blue DCI Limited',
              characteristics: '(1461CC | 90CV | 5 lugares | 5 portas | Azul)',
              simulations: {
                closeExpandableText: 'Fechar Detalhes',
                headCells: [
                  {
                    label: 'Simulação',
                  },
                  {
                    label: 'Quantidade',
                  },
                  {
                    label: 'Prazo',
                  },
                  {
                    label: 'Kilometros',
                  },
                  {
                    label: 'Estado',
                  },
                  {
                    label: 'Renda com e sem IVA',
                  },
                ],
                rows: [
                  {
                    cells: [
                      'CA10089803',
                      '2',
                      '24',
                      '80000',
                      {
                        label:
                          params.proposalId === '1'
                            ? 'Aprovado'
                            : 'Aguarda análise de risco',
                        color: params.proposalId === '1' ? 'success' : 'info',
                      },
                      '481,93€  |  381,93€',
                    ],
                    actions: [
                      {
                        label: 'Ver detalhes',
                      },
                      {
                        label: 'Imprimir',
                      },
                      {
                        label: 'Exportar',
                      },
                    ],
                  },
                  {
                    cells: [
                      'CA10089803',
                      '2',
                      '24',
                      '80000',
                      {
                        label:
                          params.proposalId === '1'
                            ? 'Aprovado'
                            : 'Aguarda análise de risco',
                        color: params.proposalId === '1' ? 'success' : 'info',
                      },
                      '481,93€  |  381,93€',
                    ],
                    actions: [
                      {
                        label: 'Ver detalhes',
                      },
                      {
                        label: 'Imprimir',
                      },
                      {
                        label: 'Exportar',
                      },
                    ],
                  },
                ],
              },
            },
  
            {
              icons: [PumpPetrolIcon],
              model: 'Peugeot 208 Diesel - 208 1.5 Bluehdi Business Active',
              characteristics: '(1499CC | 102CV | 5 lugares | 5 portas)',
              simulations: {
                closeExpandableText: 'Fechar Detalhes',
                headCells: [
                  {
                    label: 'Simulação',
                  },
                  {
                    label: 'Quantidade',
                  },
                  {
                    label: 'Prazo',
                  },
                  {
                    label: 'Kilometros',
                  },
                  {
                    label: 'Estado',
                  },
                  {
                    label: 'Renda com e sem IVA',
                  },
                ],
                rows: [
                  {
                    cells: [
                      'CA10089803',
                      '2',
                      '24',
                      '80000',
                      {
                        label:
                          params.proposalId === '1'
                            ? 'Aprovado'
                            : 'Aguarda análise de risco',
                        color: params.proposalId === '1' ? 'success' : 'info',
                      },
                      '481,93€  |  381,93€',
                    ],
                    actions: [
                      {
                        label: 'Ver detalhes',
                      },
                      {
                        label: 'Imprimir',
                      },
                      {
                        label: 'Exportar',
                      },
                    ],
                  },
                ],
              },
            },
          ],
          frameworkContract:
            params.proposalId === '1'
              ? {
                  title: 'Contrato de quadro',
                  status: {
                    label: 'Assinado',
                    color: 'success',
                  },
                  printButtonText: 'Imprimir',
                  onPrintClick: () => alert('imprimir'),
                  signDropDownProps: {
                    options: [
                      {
                        label: 'Digitalmente',
                        value: 'digital',
                      },
                      {
                        label: 'Download do documento',
                        value: 'download',
                      },
                    ],
                    placeholder: 'Assinar',
                    defaultValue: 'download',
                  },
                  attachFile: true,
                  fileAttachArea: {
                    attachFileButtonText: 'Anexar ficheiro',
                    allowedTypesText:
                      'Tipos de ficheiros permitidos: jpg, jpeg, png, doc, pdf, gif, zip, rar, txt, xls, docx, xlsx.',
                    allowedTypes: '.pdf,image/*,.doc,.zip,.txt,.rar,.docx,.xlsx',
                    fileMaxSize: 'O tamanho máximo dos ficheiros é 2 MB',
                    filesProgressBarProps: {
                      progress: 50,
                      title: 'nomedoficheiro.pdf',
                      deleteIcon: true,
                      label: '2.3MB/8.4MB',
                      deleteTooltipText: 'Apagar',
                      onDeleteClick: () => alert('cancelar'),
                    },
                  },
                }
              : undefined,
          additionalInfo:
            params.proposalId === '1'
              ? {
                  title: 'Informação adicional',
                  description:
                    'Em caso de caução ou entrada inicial, para dar seguimento ao processo é necessário que o pagamento seja efectuado no prazo de 3 dias úteis para o Iban abaixo:',
                  swiftCode: 'BCP',
                  iban: '0033 0000 00237160248 05',
                  fileAttachArea: {
                    attachFileButtonText: 'Anexar comprovativo de pagamento',
                    allowedTypesText:
                      'Tipos de ficheiros permitidos: jpg, jpeg, png, doc, pdf, gif, zip, rar, txt, xls, docx, xlsx.',
                    allowedTypes: '.pdf,image/*,.doc,.zip,.txt,.rar,.docx,.xlsx',
                    fileMaxSize: 'O tamanho máximo dos ficheiros é 2 MB',
                    filesProgressBarProps: {
                      progress: 50,
                      title: 'nomedoficheiro.pdf',
                      deleteIcon: true,
                      label: '2.3MB/8.4MB',
                      deleteTooltipText: 'Apagar',
                      onDeleteClick: () => alert('cancelar'),
                    },
                  },
                }
              : undefined,
        }}
      />
    );
  };
  
  export default React.memo(ProposalApprovationDemo);