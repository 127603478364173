import { useCallback, useMemo, useState } from "react";

//INICIO Duração em Meses
const useDurationCustomHook = () => {

  const [newMonthValue, setNewMonthValue] = useState<string>('')
  const handleToggleSelectDurationsCallback = useCallback((option:string) => () => {
    setDuration((rows:any[]) => 
      rows.map(row => {
        if ( row.value === option) {
          row.checked = !row.checked
        }
        return row;
      })
    )
  },[]);
  
  const [duration, setDuration] = useState<any[]>([
    {
      label: '12 meses',
      checked:false,
      value: '12',
      onClick: handleToggleSelectDurationsCallback("12"),
    },
    {
      label: '24 meses',
      checked:false,
      value: '24',
      onClick: handleToggleSelectDurationsCallback("24"),
    },
    {
      label: '36 meses',
      checked:false,
      value: '36',
      onClick: handleToggleSelectDurationsCallback("36"),
    },
    {
      label: '48 meses',
      checked:false,
      value: '48',
      onClick: handleToggleSelectDurationsCallback("48"),
    },
    {
      label: '60 meses',
      checked:false,
      value: '60',
      onClick: handleToggleSelectDurationsCallback("60"),
    },
    {
      label: '72 meses',
      checked:false,
      value: '72',
      onClick: handleToggleSelectDurationsCallback("72"),
    },
  ]);
 
  const handleMonthsDuration = useCallback((options:any[]) => {
    let durationOptions: any[any] = options;
    if(durationOptions.length !== 0 ) {
      setDuration((rows:any[]) => {
        rows = durationOptions;
        return rows;
      })
      return durationOptions;
    }
    setDuration((rows:any[]) => {
      if(rows.length === 0) {
        rows.push(durationOptions)
      }
      return rows;
    });
    return duration;
  },[duration])

  const durationMemo = useMemo(() => handleMonthsDuration(duration),[
    handleMonthsDuration, duration
  ])

  return { duration, durationMemo, setDuration, handleToggleSelectDurationsCallback, setNewMonthValue, newMonthValue };
};

export default useDurationCustomHook;