import { AccountIcon, BackofficeHomePage } from "@hi-interactive-admin/simulador-kinto";
import { Auth } from "../../services/Auth";
import { useCallback, useEffect, useMemo, useState } from "react";
import { UserServices } from "../../services/UserServices";
import { KintoRootProps } from "../../model/KintoComponentProps";
import { ProfileServices } from "../../services/ProfileServices";
import usePermissionsHook from "../../customCallbacks/permissions/permissionsHook";
import useNavbarHook from "../../customCallbacks/navbarHook";

const userService = new UserServices();
const profileService = new ProfileServices();

const BackofficeHome: React.FC<KintoRootProps>  = ({ onLogout }) => {
  
  const [token, setToken] = useState<string>("");
  useEffect(() => {
    setToken(Auth.getToken);
  }, [token]);
   
  const [totalUsers, setTotalUsers] = useState<string>("");
  const [totalProfiles, setTotalProfiles] = useState<string>("")
   
  const {currentUser, setCurrentUser, propertyDisabled, propertyHidden} = usePermissionsHook();
  const { 
    navigate,
    handleCardClick,
    handleLogout,
    topBarPropsMemo,
    dashboardMenuButtonPropsMemo, 
    proposalMenuButtonPropsMemo, 
    clientsMenuButtonPropsMemo, 
    contractsMenuButtonPropsMemo,
    performanceMenuButtonPropsMemo, 
    backofficeMenuButtonPropsMemo
  } = useNavbarHook(onLogout);

  useEffect(() => {
    userService.get().then(data => {
      if(data){
        setTotalUsers(data.length);
      }
    })
    profileService.get().then(data => {
      if(data && data.succeeded){
        setTotalProfiles(data.value.length);
      }
    })
  }, [])
    
  return (
    <BackofficeHomePage
    
      topBarProps={topBarPropsMemo}
      dashboardMenuButtonProps = {dashboardMenuButtonPropsMemo}
      proposalMenuButtonProps = {proposalMenuButtonPropsMemo}
      clientsMenuButtonProps = {clientsMenuButtonPropsMemo}
      contractsMenuButtonProps = {contractsMenuButtonPropsMemo}
      performanceMenuButtonProps = {performanceMenuButtonPropsMemo}
      backofficeMenuButtonProps = {backofficeMenuButtonPropsMemo}

      profileCardProps={{
        subtitle: "Gestão de Perfis",
        title:totalProfiles,
        onClick: handleCardClick("profiles"),
        // disabled: false,
        hidden: propertyHidden("Perfis", 'Backoffice')
      }}
      userCardProps={{
        subtitle: "Gestão de utilizadores",
        title: totalUsers,
        onClick: handleCardClick("users"),
        // disabled: false,
        hidden: propertyHidden("Utilizadores", 'Backoffice')
      }}
      vehicleCardProps={{
        title: "Viaturas",
        subtitle: "Pré-configuradas",
        onClick: handleCardClick("backoffice/vehicles"),
        // disabled: false,
        hidden: propertyHidden("vehicles", 'Backoffice'),
      }}
    />
  );
};

export default BackofficeHome;
