import { Profile } from "./Profile";

export interface UserModel {
  userId: number;
  friendlyName: string;
  username: string;
  avatarSrc: string;
  refreshToken: string;
  typeofRental:string;
  typeofIncome:string;
  commercialConditions:string;
  webPortalContactId:string;
  //GESTÃO DE UTILIZADORES
  email:string;
  profileId?:number;
  isAdministrator: boolean;
  profile: Profile | undefined;
  concessions:string[];
  created:Date;
  createdByUserId:number;
  active:boolean;
}

export const newUser: UserModel = {
  userId: 0,
  friendlyName: '',
  username: '',
  avatarSrc: '',
  refreshToken: '',
  typeofRental: '',
  typeofIncome: '',
  commercialConditions: '',
  webPortalContactId: '',
  //GESTÃO DE UTILIZADORES
  email: '',
  profileId: undefined,
  isAdministrator: false,
  profile: undefined,
  concessions: [],
  created:  new Date(Date.now()),
  createdByUserId: 0,
  active: false,
}