import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { VehicleService } from "../../services/VehicleService";
import { ComplementaryProductsTableProps } from "@hi-interactive-admin/simulador-kinto/components/complementary-products-table/ComplementaryProductsTable";
// import usePermissionsHook from "../permissions/permissionsHook";
import { AppSettings, EmptyAppSettings } from "../../model/Settings";
import { ContractSettingsDetailsProps } from "@hi-interactive-admin/simulador-kinto/pages/proposal/step-4/ContractSettingsDetails";
import { SettingsService } from "../../services/SettingsService";
import { IVehicle } from "../../model/Vehicle";
import { Option } from "../../model/interfaces";
import { AutocompleteChangeDetails, AutocompleteChangeReason } from "@mui/material";
import { ButtonProps, SearchResultProps } from "@hi-interactive-admin/simulador-kinto";
import { ComplementaryProductsSectionProps } from "@hi-interactive-admin/simulador-kinto/pages/proposal/edit/details/ComplementaryProductsSection";

const vehicleService = new VehicleService();

// tipos de variaveis para tratamento de produtos complementares nas modais
declare interface ComplementaryProductsTable {
  drawerTitle: string;
  title: string;
  productSearchResultProps: SearchResultProps;
  searchButtonText: string;
  searchButtonProps: ButtonProps;
  totalProductsText: string;
  tableProps: ComplementaryProductsTableProps[];  
};
// tipos de variaveis para tratamento de produtos complementares para o ecrã 4 (CreateProposalNew) 
declare interface SimulationComplementaryProductsTableProps {
  simulationIndex: number;
  complementaryProducts: ContractSettingsDetailsProps['complementaryProducts'];
};

declare interface VehicleComplementaryProductsTableProps {
  vehicleIndex: number;
  simulations: SimulationComplementaryProductsTableProps[];
};

declare interface ComplementaryProductsListTableProps {
  vehicleIndex: number,
  simulationIndex: number,
  complementaryProductsList: ComplementaryProductsTableProps['rows'],
};

// tipos de variaveis para tratamento de produtos complementares para o ecrã 21 (EditProposal) 
declare interface EditProposalSimulationComplementaryProductsTableProps {
  simulationIndex: number;
  complementaryProducts: ComplementaryProductsSectionProps['complementaryProducts'];
};

declare interface EditProposalVehicleComplementaryProductsTableProps {
  vehicleIndex: number;
  simulations: EditProposalSimulationComplementaryProductsTableProps[];
};

declare interface EditProposalComplementaryProductsListTableProps {
  vehicleIndex: number,
  simulationIndex: number,
  complementaryProductsList:  ComplementaryProductsSectionProps['complementaryProducts']['product'],
};

const useComplementaryProductsHook = (
  vehicles: IVehicle[], 
  setVehicleDataTable: Dispatch<SetStateAction<IVehicle[]>>, 
  setChangedProposal:Dispatch<SetStateAction<boolean>>,
  modalCommercialCondition: string,
) => {

  const [settings, setSettings] = useState<AppSettings>(new EmptyAppSettings());
  // const { propertyHidden, propertyDisabled } = usePermissionsHook();

  const [updatedVehicleDataTable, setUpdatedVehicleDataTable] = useState<IVehicle[]>([]);
  const [commercialCondition, setCommercialCondition] = useState<string>('');

  // estados dos produtos complementares por simulação
  const [searchOptions, setSearchOptions] = useState<{
    vehicleIndex: number,
    simulationIndex: number,
    searchOptions: Option[]
  }[]>([]);
  const [selectedSearchOptions, setSelectedSearchOptions] = useState<{
    vehicleIndex: number,
    simulationIndex: number,
    value: Option
  }[]>([]);
  // }[]>([{vehicleIndex: -1, simulationIndex: -1, value: {label: '', value: ''}}]);
  const [filteredComplementaryProducts, setFilteredComplementaryProducts] = useState<ComplementaryProductsListTableProps[]>([]);
  const [complementaryProducts, setComplementaryProducts] = useState<VehicleComplementaryProductsTableProps[]>([]);
  const [complementaryProductsList, setComplementaryProductsList] = useState<ComplementaryProductsListTableProps[]>([]);


  // estados dos produtos complementares das modais
  const [ccChanged, setCcChanged] = useState(false);
  const [modalSearchOptions, setModalSearchOptions] = useState<Option[]>([]);
  const [modalSelectedSearchOptions, setModalSelectedSearchOptions] = useState<Option>({label: '', value: ''});
  const [modalFilteredComplementaryProducts, setModalFilteredComplementaryProducts] = useState<ComplementaryProductsTableProps['rows']>([]);
  const [modalComplementaryProducts, setModalComplementaryProducts] = useState<ComplementaryProductsTable>({
    drawerTitle: "Produtos complementares",
    title: "Produtos completares/extras de contrato",
    //PESQUISA
    productSearchResultProps: {
      placeholder:
        "Pesquisar por  descrição ou número do extra",
      options: [
        {
          label: "Opção1",
          value: "op1",
        },
        {
          label: "Opção2",
          value: "op2",
        },
      ],
    },
    searchButtonText: "Pesquisar",
    searchButtonProps: {
      // onClick: () => alert("button click"),
    },
    // LISTA DE PRODUTOS COMPLEMENTARES
    totalProductsText: "0 produtos adicionados",
    tableProps: [{
      displayHead: true,
      headCells: [
        {
          label: "Incluir",
        },
        {
          label: "Nº",
        },
        {
          label: "Descrição",
        },
        {
          label: "Código Registo ",
        },
        {
          label: "Custos adicionais ",
        },
        {
          label: "Seguro",
        },
      ],
      rows: [],
      // onChange: (e, rowIndex) => alert(e.target.name + ' - ' + rowIndex),
    }]
  });
  const [modalComplementaryProductsList, setModalComplementaryProductsList] = useState<ComplementaryProductsTableProps['rows']>([]);
  

   
  //#region CALLBACKS DE RENDERIZAÇÃO DOS PRODUTOS COMPLEMENTARES POR SIMULAÇÃO (CreateProposalNew)
  const handleComplementaryProductPropertyOnChange = useCallback((vehicleIndex: number, simulationIndex: number) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, rowIndex: number) => {
    let checked: boolean = (e.target as any).checked;
    // setComplementaryProductsList((rows) => { 
    //   rows.map((row) => {
    //     let currentComplemetaryProduct = row.complementaryProductsList[rowIndex];
    //     if(row.vehicleIndex === vehicleIndex && row.simulationIndex === simulationIndex) {
    //       // row.complementaryProductsList[rowIndex].cells[0].checked = checked;
    //       currentComplemetaryProduct.cells[0].checked = checked;
          
    //     }

    //     setVehicleDataTable((updatedVehicles) => { 
    //       updatedVehicles.map((vehicle,vIndex) => {
    //         if(vIndex === vehicleIndex) {
    //           vehicle.simulations.map((simulation,sIndex) => {
    //             if(sIndex === simulationIndex) {

    //               let simulationComplementaryProduct = simulation.complementaryProducts.find(x => {
    //                 if(x.no === currentComplemetaryProduct.cells[1] && x.description === currentComplemetaryProduct.cells[2]) {
    //                   return true;
    //                 }
    //                 return false;
    //               })

    //               if (simulationComplementaryProduct && !currentComplemetaryProduct.cells[0].checked) {
    //                 simulation.complementaryProducts = simulation.complementaryProducts.filter(x => {
    //                   if(x.no === currentComplemetaryProduct.cells[1] && x.description === currentComplemetaryProduct.cells[2]) {
    //                     return false;
    //                   }
    //                   return true;
    //                 });
    //               } 
    //               if (!simulationComplementaryProduct && currentComplemetaryProduct.cells[0].checked) {
    //                 simulation.complementaryProducts.push({
    //                   id: 0,
    //                   simulationId: simulation.id,
    //                   no: currentComplemetaryProduct.cells[1],
    //                   description: currentComplemetaryProduct.cells[2],
    //                   registerCode: String(currentComplemetaryProduct.cells[3].value),
    //                   additionalCostValue: String(currentComplemetaryProduct.cells[4][0].value),
    //                   additionalCostTimePeriod: String(currentComplemetaryProduct.cells[4][1].value),
    //                   insurance: String(currentComplemetaryProduct.cells[5].value),
    //                   accessoryType: String(currentComplemetaryProduct.details.accessoryDropDownProps.value),
    //                   basePriceWithoutTax: Number(currentComplemetaryProduct.details.basePrice.withoutTaxTextFieldProps.value),
    //                   basePriceWithTax: Number(currentComplemetaryProduct.details.basePrice.withTaxTextFieldProps.value),
    //                   residualValue: Number(currentComplemetaryProduct.details.residualValueTextFieldProps.value),
    //                   supplier: String(currentComplemetaryProduct.details.supplierDropDownProps.value),
    //                 });
    //               }
    //             }
    //             setChangedProposal(true);
    //             return {...simulation}
    //           });
    //         }
    //         return {...vehicle};
    //       });
    //       return [...updatedVehicles];
    //     });

    //     return {...row};
    //   });
    //   return [...rows];
    // });

    setComplementaryProducts((rows) => {
      rows.map((row) => {
        if(row.vehicleIndex === vehicleIndex) {
          row.simulations.map((simulation) => {
            if(simulation.simulationIndex === simulationIndex) {
              let currentComplemetaryProduct = simulation.complementaryProducts.tableProps.rows[rowIndex];
              currentComplemetaryProduct.cells[0].checked = checked;

              setVehicleDataTable((updatedVehicles) => { 
                updatedVehicles.map((vehicle,vIndex) => {
                  if(vIndex === vehicleIndex) {
                    vehicle.simulations.map((simulation,sIndex) => {
                      if(sIndex === simulationIndex) {
      
                        let simulationComplementaryProduct = simulation.complementaryProducts.find(x => {
                          if(
                            x.no === currentComplemetaryProduct.cells[1]
                            && x.lineNo ===  Number(currentComplemetaryProduct.cells[6])
                            && x.description === currentComplemetaryProduct.cells[2]
                          ) {
                            return true;
                          }
                          return false;
                        })
      
                        if (simulationComplementaryProduct && !currentComplemetaryProduct.cells[0].checked) {
                          simulation.complementaryProducts = simulation.complementaryProducts.filter(x => {
                            if(
                              x.no === currentComplemetaryProduct.cells[1]
                              && x.lineNo ===  Number(currentComplemetaryProduct.cells[6])
                              && x.description === currentComplemetaryProduct.cells[2]
                            ) {
                              return false;
                            }
                            return true;
                          });
                        } 
                        if (!simulationComplementaryProduct && currentComplemetaryProduct.cells[0].checked) {
                          simulation.complementaryProducts.push({
                            id: 0,
                            simulationId: simulation.id,
                            no: currentComplemetaryProduct.cells[1],
                            description: currentComplemetaryProduct.cells[2],
                            registerCode: String(currentComplemetaryProduct.cells[3].value),
                            additionalCostValue: String(currentComplemetaryProduct.cells[4][0].value),
                            additionalCostTimePeriod: String(currentComplemetaryProduct.cells[4][1].value),
                            insurance: String(currentComplemetaryProduct.cells[5].value),
                            accessoryType: String(currentComplemetaryProduct.details.accessoryDropDownProps.value),
                            basePriceWithoutTax: Number(currentComplemetaryProduct.details.basePrice.withoutTaxTextFieldProps.value),
                            basePriceWithTax: Number(currentComplemetaryProduct.details.basePrice.withTaxTextFieldProps.value),
                            residualValue: Number(currentComplemetaryProduct.details.residualValueTextFieldProps.value),
                            supplier: String(currentComplemetaryProduct.details.supplierDropDownProps.value),
                            lineNo: Number(currentComplemetaryProduct.cells[6]),
                            headerNo: currentComplemetaryProduct.cells[7],
                          });
                        }
                      }
                      setChangedProposal(true);
                      return {...simulation}
                    });
                  }
                  return {...vehicle};
                });
                return [...updatedVehicles];
              });
            }


            return {...simulation};
          });
        }
        return {...row};
      });
      return [...rows];
    });

  },[setVehicleDataTable, setChangedProposal])

  const handleSelectedSearchOptionOnInput = useCallback((vehicleIndex: number, simulationIndex: number) => (
    e: React.SyntheticEvent<Element, Event>,
    value: any,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails,
  ) => {

    if(value && value.value !== 'undefined'){
      let result = searchOptions.find(x => x.vehicleIndex === vehicleIndex && x.simulationIndex === simulationIndex)?.searchOptions
        .find(option => option.value.toLocaleLowerCase() === value.value.toLocaleLowerCase() || option.label.toLocaleLowerCase() === value.value.toLocaleLowerCase());

      setSelectedSearchOptions(options => {
        let selectedSearchOption = options.find(x => x.vehicleIndex === vehicleIndex && x.simulationIndex === simulationIndex);
        let selectedSearchOptionIndex = options.findIndex(x => x.vehicleIndex === vehicleIndex && x.simulationIndex === simulationIndex);

        if(selectedSearchOption) {
          selectedSearchOption.value = result ? result : value ;
          options[selectedSearchOptionIndex] = selectedSearchOption;
        } else {
          options.push({
            vehicleIndex: vehicleIndex, 
            simulationIndex: simulationIndex, 
            value: result ? result : value
          });
        }
        return [...options]
      });
    } else {
      setSelectedSearchOptions(options => {
        return options.map(option => {
          if(option.vehicleIndex === vehicleIndex && option.simulationIndex === simulationIndex){
            option.value = {label: '', value: ''};
          }
          return {...option};
        })
      })
      setFilteredComplementaryProducts([]);
    }
  },[searchOptions]);

  const handleFilterComplementaryProductsOnSearch = useCallback((vehicleIndex: number, simulationIndex: number) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if(selectedSearchOptions && selectedSearchOptions.length > 0 && selectedSearchOptions[0].value && selectedSearchOptions[0].value.value !== '') {
      if ( selectedSearchOptions.find(x => x.vehicleIndex === vehicleIndex && x.simulationIndex === simulationIndex)?.value) {

        let filteredValues = complementaryProductsList.find(x => x.vehicleIndex === vehicleIndex && x.simulationIndex === simulationIndex)?.complementaryProductsList
          .filter(x => x.cells[1] === selectedSearchOptions[0].value.value)
       
        if(filteredValues) {
          let result: ComplementaryProductsListTableProps[] = []
          let complemenetaryProductsTable = complementaryProductsList.find(x => x.vehicleIndex === vehicleIndex);
          if(complemenetaryProductsTable){
            complemenetaryProductsTable = {...complemenetaryProductsTable }
            complemenetaryProductsTable.complementaryProductsList = complemenetaryProductsTable.complementaryProductsList.filter(x => x.cells[1] === selectedSearchOptions[0].value.value);
            result = [...result, complemenetaryProductsTable]
          }
          setFilteredComplementaryProducts( result)
        }
      }   
    } else {
      setFilteredComplementaryProducts([]);
    }

  },[complementaryProductsList, selectedSearchOptions]);

  const getDetailsComplementaryProducts = useCallback((vehicles: IVehicle[], vehicleIndex: number, simulationIndex: number, commercialCondition:string, version:string) => {
    let rows: ComplementaryProductsTableProps['rows'] = [];
    let options: Option[] = [{"value":"","label":""}];
    let listPerVehicleSimulation: ComplementaryProductsListTableProps = {
      vehicleIndex: vehicleIndex,
      simulationIndex: simulationIndex,
      complementaryProductsList: rows,
    }
    let searchOptionsPerVehicleSimulation: {
      vehicleIndex: number, 
      simulationIndex: number, 
      searchOptions: Option[]
    } = {
      vehicleIndex: vehicleIndex,
      simulationIndex: simulationIndex,
      searchOptions: options,
    }

    vehicleService.getComplementaryProducts(commercialCondition, version).then(data => {
      if(data && data.succeeded && data.value && data.value.length > 0) {
        data.value.forEach((element: any) => {
          let simulationHasComplementaryProduct = vehicles.find(
            (x,vIndex) => vIndex === vehicleIndex
          )?.simulations?.find(
            (y,sIndex) => sIndex === simulationIndex
          )?.complementaryProducts?.find(
            (z) => z.no === element.no && z.description === element.description 
          );

          rows.push({
            cells: [
              // CHECKBOX
              {
                checked: simulationHasComplementaryProduct ? true : false,
                // este evento não é detetado sequer
                // onChange: (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                //   alert('checked')
                // },
              },
              // NO //"F10017",
              element.no, 
              // DESCRIÇÃO //"Sistema de informação ON TRACK",
              element.description,
              // CÓDIGO REGISTO // MOBI_RAC
              { 
                options: [
                  {
                    label: element.registerCode,
                    value: element.registerCode,
                  },
                ],
                value: element.registerCode,
                disabled: true,
              },
              // CUSTOS ADICIONAIS => VALOR - OPÇÃO
              [
                {
                  value: element.additionalCostValue,
                  disabled: true,
                },
                {
                  options: settings.Data.ComplementaryProductsAdditionalCostsTerm,
                  value: element.additionalCostTimePeriod,
                  disabled: true,
                },
              ],
              // SEGURO
              {
                options: [
                  {
                    label: "Não",
                    value: "No",
                  },
                  {
                    label: "Sim",
                    value: "Yes",
                  }
                ],
                value: element.insurance,
                disabled: true,
              },
              //propriedades adicionais para filtrar produtos complementares de maneira a manter o No somente com o valor do No do produto complementar
              element.lineNo,
              element.headerNo
              
            ],
            details: {
              accessoryDropDownProps: {
                label: 'Tipo de acessório',
                // TODO MAPEAR os estados do tipo de acessorio
                options: [
                  {
                    label: 'Contrato',
                    value: 'Contract',
                  },
                  {
                    label: 'Contrato123',
                    value: 'Factory',
                  },
                  {
                    label: 'Contrato321',
                    value: 'Item',
                  },
                ],
                value: element.accessoryType,
                disabled: true,
              },
              basePrice: {
                label: 'Preço base sem e com IVA',
                withoutTaxTextFieldProps: {
                  value: element.basePriceWithoutTax,
                  endAdornment: '€',
                  disabled: true,
                },
                withTaxTextFieldProps: {
                  value: element.basePriceWithTax,
                  endAdornment: '€',
                  disabled: true,
                },
              },
              residualValueTextFieldProps: {
                label: 'Valor Residual',
                value: element.residualValue,
                endAdornment: '€',
                disabled: true,
              },
              supplierDropDownProps: {
                label: 'Fornecedor',
                // TODO FALAR COM O NUNO POR CAUSA DESTAS OPÇÕES
                options: [
                  {
                    label: element.supplier,
                    value: element.supplier,
                  },

                ],
                value: element.supplier,
                disabled: true,
              },
            }
          });
          //CARREGA OPÇÕES DE PESQUISA
          options.push({
            label: element.no +' - '+ element.description,
            value: element.no
            // value: element.no +' - '+ element.description
          });
        });
      }

      listPerVehicleSimulation.complementaryProductsList = rows.sort((a, b) => {
        if (a.cells[0].checked && !b.cells[0].checked) {
          return -1;
        } else if (!a.cells[0].checked && b.cells[0].checked) {
          return 1;
        } else {
          return 0;
        }
      });
      searchOptionsPerVehicleSimulation.searchOptions = options;

      setComplementaryProductsList(cpRows => {
        if(cpRows.find(x => x.vehicleIndex === vehicleIndex && x.simulationIndex === simulationIndex)) {
          let cpRow = cpRows.find(x => x.vehicleIndex === vehicleIndex && x.simulationIndex === simulationIndex);
          let cpRowIdx = cpRows.findIndex(x => x.vehicleIndex === vehicleIndex && x.simulationIndex === simulationIndex);
          if(cpRow){
            cpRow.complementaryProductsList = listPerVehicleSimulation.complementaryProductsList;
            cpRows[cpRowIdx] = {...cpRow};
            return [...cpRows];
          }
          return cpRows;
        } 
        return [...cpRows, listPerVehicleSimulation];
      });
    
      setSearchOptions(optionsRows => {
        if(optionsRows.find(x => x.vehicleIndex === vehicleIndex && x.simulationIndex === simulationIndex)) {
          return optionsRows;
        } 
        return [...optionsRows, searchOptionsPerVehicleSimulation];
      });

      return { listPerVehicleSimulation, searchOptionsPerVehicleSimulation };
    });
    return { listPerVehicleSimulation, searchOptionsPerVehicleSimulation };
  },[ settings.Data.ComplementaryProductsAdditionalCostsTerm ])

  const getRowComplementaryProducts = useCallback((vehicleIndex: number, simulationIndex: number) => {

    return {
      title: "Produtos completares/extras de contrato",
      //PESQUISA
      productSearchResultProps: {
        placeholder: "Pesquisar por  descrição ou número do extra",
        options: searchOptions.find(x => x.vehicleIndex === vehicleIndex && x.simulationIndex === simulationIndex)?.searchOptions ?? [],
        value: selectedSearchOptions.find(x => x.vehicleIndex === vehicleIndex && x.simulationIndex === simulationIndex)?.value ?? '',
        // onInputChange: handleSelectedSearchOptionOnInput(vehicleIndex, simulationIndex),
        onChange: handleSelectedSearchOptionOnInput(vehicleIndex, simulationIndex),
        // freeSolo: false,
        disableClearable: false,
      },
      searchButtonText: "Pesquisar",
      searchButtonProps: {
        onClick: handleFilterComplementaryProductsOnSearch(vehicleIndex, simulationIndex),
      },
      // LISTA DE PRODUTOS COMPLEMENTARES
      totalProductsText: complementaryProductsList && complementaryProductsList.length > 0 && complementaryProductsList[0].complementaryProductsList.length > 0 
        ? `${complementaryProductsList[0].complementaryProductsList?.filter(x => x.cells[0].checked).length} produtos adicionados`
        : "0 produtos adicionados",
      tableProps: {
        displayHead: true,
        headCells: [
          {
            label: "Incluir",
          },
          {
            label: "Nº",
          },
          {
            label: "Descrição",
          },
          {
            label: "Código Registo ",
          },
          {
            label: "Custos adicionais ",
          },
          {
            label: "Seguro",
          },
        ],
        rows: filteredComplementaryProducts && filteredComplementaryProducts.length > 0 ? filteredComplementaryProducts[0].complementaryProductsList : complementaryProductsList.find(x => x.vehicleIndex === vehicleIndex && x.simulationIndex === simulationIndex)?.complementaryProductsList ?? [],
        onChange: handleComplementaryProductPropertyOnChange(vehicleIndex, simulationIndex),
      },
    }

  },[
    handleComplementaryProductPropertyOnChange,
    handleSelectedSearchOptionOnInput,
    handleFilterComplementaryProductsOnSearch,
    complementaryProductsList,
    searchOptions,
    selectedSearchOptions,
    filteredComplementaryProducts
  ]);
  //#endregion

  //#region CALLBACKS DE RENDERIZAÇÃO DOS PRODUTOS COMPLEMENTARES NAS MODAIS (CreateProposalNew)
  const handleModalSelectedSearchOptionOnInput = useCallback((
    e: React.SyntheticEvent<Element, Event>,
    value: any,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails,
  ) => {

    if(value && value.value !== 'undefined'){
      let result =  modalSearchOptions.find(option => option.value.toLocaleLowerCase() === value.value.toLocaleLowerCase() || option.label.toLocaleLowerCase() === value.value.toLocaleLowerCase());

      if(result){
        setModalSelectedSearchOptions({...result});
      }
    } else {
      setModalSelectedSearchOptions({label: '', value: ''});
      setModalFilteredComplementaryProducts([]);
    }
  },[modalSearchOptions]);
  
  const handleModalFilterComplementaryProductsOnSearch = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if(modalSelectedSearchOptions && modalSelectedSearchOptions.value !== '') {

      let filteredValues = [...modalComplementaryProductsList.filter(x => x.cells[1] === modalSelectedSearchOptions.value)]
        
      if(filteredValues) {
        // cria cópia numa nova posição de memória dos valores filtrados para não afetar o estado
        let result: ComplementaryProductsTableProps['rows'] = []
        result = [...filteredValues]
        setModalFilteredComplementaryProducts(result)
      }
    } else {
      setModalFilteredComplementaryProducts([]);
    }
  },[modalComplementaryProductsList, modalSelectedSearchOptions]);

  const handleModalComplementaryProductPropertyOnChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, rowIndex: number) => {
    let checked: boolean = (e.target as any).checked;
    setModalComplementaryProductsList((rows) => {
      // setCcChanged(true)
      return rows.map((row: any, index: number) => {
        if(index === rowIndex) {
          row.cells[0].checked = checked;
        }
        return {...row};
      }).sort((a, b) => {
        if (a.cells[0].checked && !b.cells[0].checked) {
          return -1;
        } else if (!a.cells[0].checked && b.cells[0].checked) {
          return 1;
        } else {
          return 0;
        }
      });
    })
  },[])

  const handleModalFilteredComplementaryProductPropertyOnChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, rowIndex: number) => {
    let checked: boolean = (e.target as any).checked;
    setModalFilteredComplementaryProducts((rows) => {
      // setCcChanged(true)
      return rows.map((row: any, index: number) => {
        if(index === rowIndex) {
          row.cells[0].checked = checked;
        }
        return {...row};
      }).sort((a, b) => {
        if (a.cells[0].checked && !b.cells[0].checked) {
          return -1;
        } else if (!a.cells[0].checked && b.cells[0].checked) {
          return 1;
        } else {
          return 0;
        }
      });
    })
  },[])

  const getModalDetailsComplementaryProducts = useCallback((commercialConditions: string, version: string) => {
    let rows: ComplementaryProductsTableProps['rows'] = [];
    let options: Option[] = [{"value":"","label":""}];
    
    vehicleService.getComplementaryProducts(commercialConditions, version).then(data => {
      if(data && data.succeeded && data.value && data.value.length > 0) {
        data.value.forEach((element: any) => {

          rows.push({
            cells: [
              // CHECKBOX
              {
                checked: false,
                // este evento não é detetado sequer
                // onChange: (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                //   alert('checked')
                // },
              },
              // NO //"F10017",
              // element.no, 
              element.no,

              // DESCRIÇÃO //"Sistema de informação ON TRACK",
              element.description,
              // CÓDIGO REGISTO // MOBI_RAC
              { 
                options: [
                  {
                    label: element.registerCode,
                    value: element.registerCode,
                  },
                ],
                value: element.registerCode,
                disabled: true,
              },
              // CUSTOS ADICIONAIS => VALOR - OPÇÃO
              [
                {
                  value: element.additionalCostValue,
                  disabled: true,
                },
                {
                  options: settings.Data.ComplementaryProductsAdditionalCostsTerm,
                  value: element.additionalCostTimePeriod,
                  disabled: true,
                },
              ],
              // SEGURO
              {
                options: [
                  {
                    label: "Não",
                    value: "No",
                  },
                  {
                    label: "Sim",
                    value: "Yes",
                  }
                ],
                value: element.insurance,
                disabled: true,
              },
              //propriedades adicionais para filtrar produtos complementares de maneira a manter o No somente com o valor do No do produto complementar
              element.lineNo,
              element.headerNo
            ],
            details: {
              accessoryDropDownProps: {
                label: 'Tipo de acessório',
                // TODO MAPEAR os estados do tipo de acessorio
                options: [
                  {
                    label: 'Contrato',
                    value: 'Contract',
                  },
                  {
                    label: 'Contrato123',
                    value: 'Factory',
                  },
                  {
                    label: 'Contrato321',
                    value: 'Item',
                  },
                ],
                value: element.accessoryType,
                disabled: true,
              },
              basePrice: {
                label: 'Preço base sem e com IVA',
                withoutTaxTextFieldProps: {
                  value: element.basePriceWithoutTax,
                  endAdornment: '€',
                  disabled: true,
                },
                withTaxTextFieldProps: {
                  value: element.basePriceWithTax,
                  endAdornment: '€',
                  disabled: true,
                },
              },
              residualValueTextFieldProps: {
                label: 'Valor Residual',
                value: element.residualValue,
                endAdornment: '€',
                disabled: true,
              },
              supplierDropDownProps: {
                label: 'Fornecedor',
                // TODO FALAR COM O NUNO POR CAUSA DESTAS OPÇÕES
                options: [
                  {
                    label: element.supplier,
                    value: element.supplier,
                  },

                ],
                value: element.supplier,
                disabled: true,
              },
            }
          });
          //CARREGA OPÇÕES DE PESQUISA
          options.push({
            label: element.no +' - '+ element.description,
            value: element.no
            // value: element.no +' - '+ element.description
          });
        });

      }

      // setModalComplementaryProductsList(elements => {return rows});
      setModalComplementaryProductsList(rows);
      setModalSearchOptions(options);
    });
  },[
    settings.Data.ComplementaryProductsAdditionalCostsTerm,
  ]);

  const getModalComplementaryProducts: () => ContractSettingsDetailsProps['complementaryProducts'] = useCallback(() => {
    setCcChanged(false);

    // falta aplicar a quantidade de linhas seleccionadas aqui
    return {
      title: "Produtos completares/extras de contrato",
      //PESQUISA
      productSearchResultProps: {
        placeholder: "Pesquisar por  descrição ou número do extra",
        options: modalSearchOptions.length > 0 ? modalSearchOptions : [],
        value: modalSelectedSearchOptions ? modalSelectedSearchOptions : '',
        onChange: handleModalSelectedSearchOptionOnInput,
        // onChange: handleSelectedSearchOptionOnInput(vehicleIndex, simulationIndex),
        disableClearable: false,
        // freeSolo: false,
      },


      searchButtonText: "Pesquisar",
      searchButtonProps: {
        onClick: handleModalFilterComplementaryProductsOnSearch,
      },
      // LISTA DE PRODUTOS COMPLEMENTARES
      totalProductsText: "0 produtos adicionados",
      tableProps: {
        displayHead: true,
        headCells: [
          {
            label: "Incluir",
          },
          {
            label: "Nº",
          },
          {
            label: "Descrição",
          },
          {
            label: "Código Registo ",
          },
          {
            label: "Custos adicionais ",
          },
          {
            label: "Seguro",
          },
        ],
        rows: modalFilteredComplementaryProducts && modalFilteredComplementaryProducts.length > 0 
          ? modalFilteredComplementaryProducts 
          : modalComplementaryProductsList.length > 0 
            ? modalComplementaryProductsList 
            : [],
        onChange: modalFilteredComplementaryProducts && modalFilteredComplementaryProducts.length > 0 
                  ? handleModalFilteredComplementaryProductPropertyOnChange
                  : handleModalComplementaryProductPropertyOnChange,
      },
    }

  },[
    handleModalFilteredComplementaryProductPropertyOnChange,
    handleModalComplementaryProductPropertyOnChange, 
    handleModalFilterComplementaryProductsOnSearch, 
    handleModalSelectedSearchOptionOnInput, 
    modalComplementaryProductsList, 
    modalSearchOptions, 
    modalSelectedSearchOptions,
    modalFilteredComplementaryProducts,
  ]);
  //#endregion

  // PRODUTOS COMPLEMENTARES POR VIATURA (CreateProposalNew)
  const complementaryProductsByVehicleMemo: VehicleComplementaryProductsTableProps[] = useMemo(() => {
    return [...complementaryProducts];
  }, [complementaryProducts]);

  // PRODUTOS COMPLEMENTARES DAS MODAIS (CreateProposalNew)
  const modalComplementaryProductsMemo: ComplementaryProductsTable = useMemo(() => {
    return {...modalComplementaryProducts};
  },[modalComplementaryProducts])
    
  // USEEFFECTS DE ATUALIZAÇÃO DE ESTADOS NECESSÁRIOS PARA RENDERIZAR OS PRODUTOS COMPLEMENTARES EM AMBOS OS CASOS 
  useEffect(() => {
    if (vehicles && vehicles.length > 0){
      setUpdatedVehicleDataTable(rows =>  {
        return vehicles.map((vehicle) => {
          vehicle.simulations.map((simulation) => {
            if(!simulation.complementaryProducts){
              simulation.complementaryProducts = [];
            }
            return {...simulation};
          });
          return {...vehicle};
        }); 
      })
    };
  },[vehicles]);

  useEffect(() => { 
    setCommercialCondition(modalCommercialCondition);
    setCcChanged(true);
  },[modalCommercialCondition]);

  // USEEFFECTS DE RENDERIZAÇÃO DE PRODUTOS COMPLEMENTARES POR VIATURA (CreateProposalNew)
  useEffect(() => {
    if(updatedVehicleDataTable && updatedVehicleDataTable.length > 0) {   
      updatedVehicleDataTable.forEach((vehicleRow, vehicleIndex) => {
        //procura qual a viatura a adicionar produtos complementares
        vehicleRow.simulations.forEach((simulationRow, simulationIndex) => {
          getDetailsComplementaryProducts(updatedVehicleDataTable, vehicleIndex, simulationIndex, vehicleRow.commercialConditions, vehicleRow.versionId);
        });
      });
    }
  },[updatedVehicleDataTable, getDetailsComplementaryProducts]);

  useEffect(() => {
    let complementaryProductsPerVehicle: VehicleComplementaryProductsTableProps[] = [];
    if(updatedVehicleDataTable && updatedVehicleDataTable.length > 0) {   
      updatedVehicleDataTable.forEach((vehicleRow, vehicleIndex) => {
        //procura qual a viatura a adicionar produtos complementares

        vehicleRow.simulations.forEach((simulationRow, simulationIndex) => {      
          // obtem os produtos complementares por viatura e associa os produtos complementares a cada simulação
          let simulation: SimulationComplementaryProductsTableProps = {
            simulationIndex: simulationIndex,
            complementaryProducts: getRowComplementaryProducts(vehicleIndex, simulationIndex),
          };

          // carrega os produtos complementares por simulação
          let vehicle = complementaryProductsPerVehicle.find(x => x.vehicleIndex === vehicleIndex);
          if (vehicle) {
            vehicle.simulations.push(simulation);
          } else {
            complementaryProductsPerVehicle.push({
              vehicleIndex: vehicleIndex,
              simulations: [simulation],
            });
          }
        });
            
      });
    }
    setComplementaryProducts(complementaryProductsPerVehicle);
  },[
    getRowComplementaryProducts, 
    updatedVehicleDataTable
  ]);

  // USEEFFECTS DE RENDERIZAÇÃO DE PRODUTOS COMPLEMENTARES DAS MODAIS ( CreateProposalNew)
  useEffect(() => {
    let commonComplementaryProducts: ComplementaryProductsTable = {   
      drawerTitle: "Produtos complementares",
      title: "Produtos completares/extras de contrato",
      //PESQUISA
      productSearchResultProps: {
        placeholder:
          "Pesquisar por  descrição ou número do extra",
        options: [
          {
            label: "Opção1",
            value: "op1",
          },
          {
            label: "Opção2",
            value: "op2",
          },
        ],
        value: '',
        onChange: handleModalSelectedSearchOptionOnInput,
        disableClearable: false,
      },
      searchButtonText: "Pesquisar",
      searchButtonProps: {
        // onClick: () => alert("button click"),
      },
      // LISTA DE PRODUTOS COMPLEMENTARES
      totalProductsText: "0 produtos adicionados",
      tableProps: [{
        displayHead: true,
        headCells: [
          {
            label: "Incluir",
          },
          {
            label: "Nº",
          },
          {
            label: "Descrição",
          },
          {
            label: "Código Registo ",
          },
          {
            label: "Custos adicionais ",
          },
          {
            label: "Seguro",
          },
        ],
        rows: [],
        // onChange: (e, rowIndex) => alert(e.target.name + ' - ' + rowIndex),
      }]
      // hidden: propertyHidden('Produtos complementares'),
      // disabled: propertyDisabled('Produtos complementares')
    };

    if (ccChanged) {
      getModalDetailsComplementaryProducts(commercialCondition, "");
    }
    // setModalComplementaryProductsList(elements => {return rows});
    // setModalSearchOptions(options);
    let cp = getModalComplementaryProducts();  
    commonComplementaryProducts = {...commonComplementaryProducts, title: cp.title,
      productSearchResultProps: cp.productSearchResultProps,
      searchButtonText: cp.searchButtonText,
      searchButtonProps: cp.searchButtonProps,
      totalProductsText: cp.totalProductsText,
      tableProps: [cp.tableProps]
    }
    setModalComplementaryProducts(commonComplementaryProducts);

    

  },[
    ccChanged,
    commercialCondition,
    getModalDetailsComplementaryProducts,
    getModalComplementaryProducts,
    handleModalSelectedSearchOptionOnInput
  ]);

  useEffect(() => {
    SettingsService.getInstance()
      .then(x => setSettings(x.settings));
  }, []);



  // estados dos produtos complementares por simulação
  const [editProposalSearchOptions, setEditProposalSearchOptions] = useState<{
    vehicleIndex: number,
    simulationIndex: number,
    searchOptions: Option[]
  }[]>([]);
  const [editProposalSelectedSearchOptions, setEditProposalSelectedSearchOptions] = useState<{
    vehicleIndex: number,
    simulationIndex: number,
    value: Option
  }[]>([]);
  const [editProposalFilteredComplementaryProducts, setEditProposalFilteredComplementaryProducts] = useState<EditProposalComplementaryProductsListTableProps[]>([]);
  const [editProposalComplementaryProducts, setEditProposalComplementaryProducts] = useState<EditProposalVehicleComplementaryProductsTableProps[]>([]);
  const [editProposalComplementaryProductsList, setEditProposalComplementaryProductsList] = useState<EditProposalComplementaryProductsListTableProps[]>([]);

  //#region CALLBACKS DE RENDERIZAÇÃO DOS PRODUTOS COMPLEMENTARES POR SIMULAÇÃO (EditProposal)
  const handleEditProposalComplementaryProductPropertyOnChange = useCallback(
    (vehicleIndex: number, simulationIndex: number) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    // (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {

    // (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, rowIndex: number) => {
    let checked: boolean = (e.target as any).checked;
    let values = (e.target as any).value.split('-');

    setEditProposalComplementaryProducts((rows) => {
      rows.map((row) => {
        if(row.vehicleIndex === vehicleIndex) {
          row.simulations.map((simulation) => {
            if(simulation.simulationIndex === simulationIndex && simulation.complementaryProducts.product && simulation.complementaryProducts.product.length > 0) {
              let rowIndex = simulation.complementaryProducts.product.findIndex(x => x.number.value === values[0] && Number(x.additionalInfo1) === Number(values[1]) && x.additionalInfo2 === values[2]); 
              let currentComplemetaryProduct = simulation.complementaryProducts.product[rowIndex];
              currentComplemetaryProduct.includeCheckboxProps.checked = checked;

              setVehicleDataTable((updatedVehicles) => { 
                updatedVehicles.map((vehicle,vIndex) => {
                  if(vIndex === vehicleIndex) {
                    vehicle.simulations.map((simulation,sIndex) => {
                      if(sIndex === simulationIndex) {
      
                        let simulationComplementaryProduct = simulation.complementaryProducts.find(x => {
                          if(
                            x.no === currentComplemetaryProduct?.number.value
                            && x.lineNo ===  Number(currentComplemetaryProduct.additionalInfo1)
                            && x.description === currentComplemetaryProduct.description.value
                          ) {
                            return true;
                          }
                          return false;
                        })
      
                        if (simulationComplementaryProduct && !currentComplemetaryProduct?.includeCheckboxProps.checked) {
                          simulation.complementaryProducts = simulation.complementaryProducts.filter(x => {
                            if(
                              x.no === currentComplemetaryProduct?.number.value
                              && x.lineNo ===  Number(currentComplemetaryProduct.additionalInfo1)
                              && x.description === currentComplemetaryProduct.description.value
                            ) {
                              return false;
                            }
                            return true;
                          });
                        } 
                        if (!simulationComplementaryProduct && currentComplemetaryProduct?.includeCheckboxProps.checked) {
                          simulation.complementaryProducts.push({
                            id: 0,
                            simulationId: simulation.id,
                            no: currentComplemetaryProduct.number.value,
                            description: currentComplemetaryProduct.description.value,
                            registerCode: String(currentComplemetaryProduct.regCodeDropDownProps.value),
                            additionalCostValue: String(currentComplemetaryProduct.additionalCosts.costTextFieldProps.value),
                            additionalCostTimePeriod: String(currentComplemetaryProduct.additionalCosts.periodicityDropDownProps.value),
                            insurance: String(currentComplemetaryProduct.inseranceDropDownProps.value),
                            accessoryType: String(currentComplemetaryProduct.typeDropDownProps.value),
                            basePriceWithoutTax: Number(currentComplemetaryProduct.basePrice.withoutTaxTextFieldProps.value),
                            basePriceWithTax: Number(currentComplemetaryProduct.basePrice.withTaxTextFieldProps.value),
                            residualValue: Number(currentComplemetaryProduct.residualValueTextFieldProps.value),
                            supplier: String(currentComplemetaryProduct.supplierDropDownProps.value),
                            lineNo: Number(currentComplemetaryProduct.additionalInfo1),
                            headerNo: currentComplemetaryProduct.additionalInfo2,
                          });
                        }
                      }
                      setChangedProposal(true);
                      return {...simulation}
                    });
                  }
                  return {...vehicle};
                });
                return [...updatedVehicles];
              });
            }

            simulation.complementaryProducts.product = simulation.complementaryProducts.product?.sort((a, b) => {
              if (a.includeCheckboxProps.checked && !b.includeCheckboxProps.checked) {
                return -1;
              } else if (!a.includeCheckboxProps.checked && b.includeCheckboxProps.checked) {
                return 1;
              } else {
                return 0;
              }
            });
            return {...simulation};
          });
        }

        return {...row};
      });
      return [...rows];
    });

  },[setVehicleDataTable, setChangedProposal])

  const handleEditProposalSelectedSearchOptionOnInput = useCallback((vehicleIndex: number, simulationIndex: number) => (
    e: React.SyntheticEvent<Element, Event>,
    value: any,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails,
  ) => {

    if(value && value.value !== 'undefined'){
      let result = editProposalSearchOptions.find(x => x.vehicleIndex === vehicleIndex && x.simulationIndex === simulationIndex)?.searchOptions
        .find(option => option.value.toLocaleLowerCase() === value.value.toLocaleLowerCase() || option.label.toLocaleLowerCase() === value.value.toLocaleLowerCase());

      setEditProposalSelectedSearchOptions(options => {
        let selectedSearchOption = options.find(x => x.vehicleIndex === vehicleIndex && x.simulationIndex === simulationIndex);
        let selectedSearchOptionIndex = options.findIndex(x => x.vehicleIndex === vehicleIndex && x.simulationIndex === simulationIndex);

        if(selectedSearchOption) {
          selectedSearchOption.value = result ? result : value ;
          options[selectedSearchOptionIndex] = selectedSearchOption;
        } else {
          options.push({
            vehicleIndex: vehicleIndex, 
            simulationIndex: simulationIndex, 
            value: result ? result : value
          });
        }
        return [...options]
      });
    } else {
      setEditProposalSelectedSearchOptions(options => {
        return options.map(option => {
          if(option.vehicleIndex === vehicleIndex && option.simulationIndex === simulationIndex){
            option.value = {label: '', value: ''};
          }
          return {...option};
        })
      })
      setEditProposalFilteredComplementaryProducts([]);
    }
  },[editProposalSearchOptions]);

  const handleEditProposalFilteredComplementaryProductsOnSearch = useCallback((vehicleIndex: number, simulationIndex: number) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if(editProposalSelectedSearchOptions && editProposalSelectedSearchOptions.length > 0 && editProposalSelectedSearchOptions[0].value && editProposalSelectedSearchOptions[0].value.value !== '') {
      if ( editProposalSelectedSearchOptions.find(x => x.vehicleIndex === vehicleIndex && x.simulationIndex === simulationIndex)?.value && editProposalComplementaryProductsList && editProposalComplementaryProductsList.length > 0) {

        let filteredSimulationWithComplementaryProducts = editProposalComplementaryProductsList.find(x => x.vehicleIndex === vehicleIndex && x.simulationIndex === simulationIndex);
        if( filteredSimulationWithComplementaryProducts && filteredSimulationWithComplementaryProducts.complementaryProductsList && filteredSimulationWithComplementaryProducts.complementaryProductsList.length > 0){
          let filteredValues = filteredSimulationWithComplementaryProducts.complementaryProductsList.filter(x => 
            x.number.value === editProposalSelectedSearchOptions[0].value.value
          )
        
          if(filteredValues) {
            let result: EditProposalComplementaryProductsListTableProps [] = [];
            let complemenetaryProductsTable = editProposalComplementaryProductsList.find(x => x.vehicleIndex === vehicleIndex);
            if(complemenetaryProductsTable){
              complemenetaryProductsTable = {...complemenetaryProductsTable }
              complemenetaryProductsTable.complementaryProductsList = complemenetaryProductsTable.complementaryProductsList?.filter(x => 
                x.number.value === editProposalSelectedSearchOptions[0].value.value
              );
              result = [...result, complemenetaryProductsTable]
            }
            setEditProposalFilteredComplementaryProducts( result)
          }
        }
      }   
    } else {
      setEditProposalFilteredComplementaryProducts([]);
    }

  },[editProposalComplementaryProductsList, editProposalSelectedSearchOptions]);

  const getEditProposalDetailsComplementaryProducts = useCallback((vehicles: IVehicle[], vehicleIndex: number, simulationIndex: number, commercialCondition:string, version:string) => {
    let rows: ComplementaryProductsSectionProps['complementaryProducts']['product'] = [];
    let options: Option[] = [{"value":"","label":""}];

    let listPerVehicleSimulation: EditProposalComplementaryProductsListTableProps = {
      vehicleIndex: vehicleIndex,
      simulationIndex: simulationIndex,
      complementaryProductsList: rows,
    }

    let searchOptionsPerVehicleSimulation: {
      vehicleIndex: number, 
      simulationIndex: number, 
      searchOptions: Option[]
    } = {
      vehicleIndex: vehicleIndex,
      simulationIndex: simulationIndex,
      searchOptions: options,
    }

    vehicleService.getComplementaryProducts(commercialCondition, version).then(data => {
      if(data && data.succeeded && data.value && data.value.length > 0) {
        data.value.forEach((element: any) => {
          let simulationHasComplementaryProduct = vehicles.find(
            (x,vIndex) => vIndex === vehicleIndex
          )?.simulations?.find(
            (y,sIndex) => sIndex === simulationIndex
          )?.complementaryProducts?.find(
            (z) => z.no === element.no && z.description === element.description 
          );

          rows?.push({
            number: {
              value: element.no,
              label: element.no
            },
            additionalInfo1: element.lineNo,
            additionalInfo2: element.headerNo,
            description: {
              value: element.description,
              label: element.description
            },
            regCodeDropDownProps: {
              label: 'Código Registo ',
              options: [
                {
                  label: element.registerCode,
                  value: element.registerCode
                },
              ],
              value: element.registerCode,
              disabled: true,
            },
            additionalCosts: {
              costTextFieldProps: {
                value: element.additionalCostValue,
                endAdornment: '€',
                disabled: true,
              },
              periodicityDropDownProps: {
                label: 'Periodicidade',
                options: settings.Data.ComplementaryProductsAdditionalCostsTerm,
                value: element.additionalCostTimePeriod,
                disabled: true,
              },
            },
            inseranceDropDownProps: {
              label: 'Seguro',
              options: [
                {
                  label: 'Não',
                  value: 'No',
                },
                {
                  label: 'Sim',
                  value: 'Yes',
                },
              ],
              value: element.insurance,
              disabled: true,
            },
            typeDropDownProps: {
              label: 'Tipo de acessório',
              options: [
                {
                  label: 'Contrato',
                  value: 'Contract',
                },
                {
                  label: 'Fábrica',
                  value: 'Factory',
                },
                {
                  label: 'Item',
                  value: 'Item',
                },
              ],
              value: element.accessoryType,
              disabled: true,
            },
            basePrice: {
              withTaxTextFieldProps: {
                value: element.basePriceWithTax,
                endAdornment: '€',
                disabled: true,
              },
              withoutTaxTextFieldProps: {
                value: element.basePriceWithoutTax,
                endAdornment: '€',
                disabled: true,
              }
            },
            residualValueTextFieldProps: {
              label: 'Valor Residual',
              value: element.residualValue,
              endAdornment: '€',
              disabled: true,
            },
            supplierDropDownProps: {
              label: 'Fornecedor',
              options: [
                {
                  label: element.supplier,
                  value: element.supplier,
                },
              ],
              value: element.supplier,
              disabled: true,
            },
            includeCheckboxProps: {
              value: element.no + '-' + element.lineNo + '-' + element.headerNo,
              checked: simulationHasComplementaryProduct ? true : false,
              defaultChecked: simulationHasComplementaryProduct ? true : false,
              onClick: handleEditProposalComplementaryProductPropertyOnChange(vehicleIndex, simulationIndex)
            },
            includeText: 'Incluir'
          });
          //CARREGA OPÇÕES DE PESQUISA
          options.push({
            label: element.no +' - '+ element.description,
            value: element.no
            // value: element.no +' - '+ element.description
          });
        });
      }

      listPerVehicleSimulation.complementaryProductsList = rows?.sort((a,b) => {
        if (a.includeCheckboxProps.checked && !b.includeCheckboxProps.checked) {
          return -1;
        } else if (!a.includeCheckboxProps.checked && b.includeCheckboxProps.checked) {
          return 1;
        } else {
          return 0;
        }
      });
      
      searchOptionsPerVehicleSimulation.searchOptions = options;

      setEditProposalComplementaryProductsList(cpRows => {
        if(cpRows.find(x => x.vehicleIndex === vehicleIndex && x.simulationIndex === simulationIndex)) {
          let cpRow = cpRows.find(x => x.vehicleIndex === vehicleIndex && x.simulationIndex === simulationIndex);
          let cpRowIdx = cpRows.findIndex(x => x.vehicleIndex === vehicleIndex && x.simulationIndex === simulationIndex);
          if(cpRow){
            cpRow.complementaryProductsList = listPerVehicleSimulation.complementaryProductsList;
            cpRows[cpRowIdx] = {...cpRow};
            return [...cpRows];
          }
          return cpRows;
        } 
        return [...cpRows, listPerVehicleSimulation];
      });
    
      setEditProposalSearchOptions(optionsRows => {
        if(optionsRows.find(x => x.vehicleIndex === vehicleIndex && x.simulationIndex === simulationIndex)) {
          return optionsRows;
        } 
        return [...optionsRows, searchOptionsPerVehicleSimulation];
      });

      return { listPerVehicleSimulation, searchOptionsPerVehicleSimulation };
    });
    return { listPerVehicleSimulation, searchOptionsPerVehicleSimulation };
  },[handleEditProposalComplementaryProductPropertyOnChange, settings.Data.ComplementaryProductsAdditionalCostsTerm])

  const getEditProposalRowComplementaryProducts = useCallback((vehicleIndex: number, simulationIndex: number) => {

    return {
      title: "Produtos completares/extras de contrato",
      //PESQUISA
      complementaryProductSearchResultProps: {
        placeholder: "Pesquisar por  descrição ou número do extra",
        options: editProposalSearchOptions.find(x => x.vehicleIndex === vehicleIndex && x.simulationIndex === simulationIndex)?.searchOptions ?? [],
        value: editProposalSelectedSearchOptions.find(x => x.vehicleIndex === vehicleIndex && x.simulationIndex === simulationIndex)?.value ?? '',
        // onInputChange: handleSelectedSearchOptionOnInput(vehicleIndex, simulationIndex),
        onChange: handleEditProposalSelectedSearchOptionOnInput(vehicleIndex, simulationIndex),
        // freeSolo: false,
        disableClearable: false,
      },
      searchButtonText: "Pesquisar",
      searchButtonProps: {
        onClick: handleEditProposalFilteredComplementaryProductsOnSearch(vehicleIndex, simulationIndex),
      },
      // LISTA DE PRODUTOS COMPLEMENTARES
      addedProductsNumberText: editProposalComplementaryProductsList && editProposalComplementaryProductsList.length > 0 && editProposalComplementaryProductsList[0].complementaryProductsList && editProposalComplementaryProductsList[0].complementaryProductsList.length > 0 
        ? `${editProposalComplementaryProductsList[0].complementaryProductsList.filter(x => x.includeCheckboxProps.checked).length} produtos adicionados`
        : "0 produtos adicionados",
      editTableHeadCells: [
        {
          label: "Incluir",
        },
        {
          label: "Nº",
        },
        {
          label: "Descrição",
        },
        {
          label: "Código Registo ",
        },
        {
          label: "Custos adicionais ",
        },
        {
          label: "Seguro",
        },
      ],
      product: editProposalFilteredComplementaryProducts && editProposalFilteredComplementaryProducts.length > 0 ? editProposalFilteredComplementaryProducts[0].complementaryProductsList : editProposalComplementaryProductsList.find(x => x.vehicleIndex === vehicleIndex && x.simulationIndex === simulationIndex)?.complementaryProductsList ?? [],
      onChange: handleEditProposalComplementaryProductPropertyOnChange(vehicleIndex, simulationIndex),


    }
  },[
    handleEditProposalComplementaryProductPropertyOnChange,
    handleEditProposalSelectedSearchOptionOnInput,
    handleEditProposalFilteredComplementaryProductsOnSearch,
    editProposalComplementaryProductsList,
    editProposalSearchOptions,
    editProposalSelectedSearchOptions,
    editProposalFilteredComplementaryProducts
  ]);
  //#endregion

  // USEEFFECTS DE RENDERIZAÇÃO DE PRODUTOS COMPLEMENTARES POR VIATURA (CreateProposalNew)
  useEffect(() => {
    if(updatedVehicleDataTable && updatedVehicleDataTable.length > 0) {   
      updatedVehicleDataTable.forEach((vehicleRow, vehicleIndex) => {
        //procura qual a viatura a adicionar produtos complementares
        vehicleRow.simulations.forEach((simulationRow, simulationIndex) => {
          getEditProposalDetailsComplementaryProducts(updatedVehicleDataTable, vehicleIndex, simulationIndex, vehicleRow.commercialConditions, vehicleRow.versionId);
        });
      });
    }
  },[updatedVehicleDataTable, getEditProposalDetailsComplementaryProducts]);

  useEffect(() => {
    let complementaryProductsPerVehicle: EditProposalVehicleComplementaryProductsTableProps[] = [];
    if(updatedVehicleDataTable && updatedVehicleDataTable.length > 0) {   
      updatedVehicleDataTable.forEach((vehicleRow, vehicleIndex) => {
        //procura qual a viatura a adicionar produtos complementares

        vehicleRow.simulations.forEach((simulationRow, simulationIndex) => {      
          // obtem os produtos complementares por viatura e associa os produtos complementares a cada simulação
          let simulation: EditProposalSimulationComplementaryProductsTableProps = {
            simulationIndex: simulationIndex,
            complementaryProducts: getEditProposalRowComplementaryProducts(vehicleIndex, simulationIndex),
          };

          // carrega os produtos complementares por simulação
          let vehicle = complementaryProductsPerVehicle.find(x => x.vehicleIndex === vehicleIndex);
          if (vehicle) {
            vehicle.simulations.push(simulation);
          } else {
            complementaryProductsPerVehicle.push({
              vehicleIndex: vehicleIndex,
              simulations: [simulation],
            });
          }
        });
            
      });
    }
    setEditProposalComplementaryProducts(complementaryProductsPerVehicle);
  },[
    getEditProposalRowComplementaryProducts, 
    updatedVehicleDataTable
  ]);

  // PRODUTOS COMPLEMENTARES POR VIATURA (CreateProposalNew)
  const editProposalComplementaryProductsByVehicleMemo: EditProposalVehicleComplementaryProductsTableProps[] = useMemo(() => {
    return [...editProposalComplementaryProducts];
  }, [editProposalComplementaryProducts]);


  return { 
    complementaryProductsByVehicleMemo, 
    modalComplementaryProductsMemo, 
    editProposalComplementaryProductsByVehicleMemo, 
    handleEditProposalComplementaryProductPropertyOnChange,
    setModalComplementaryProductsList,
    setModalFilteredComplementaryProducts,
    setModalSelectedSearchOptions,
    modalFilteredComplementaryProducts,
    modalComplementaryProductsList
  }
}

export default useComplementaryProductsHook;