import { CheckboxProps } from "@hi-interactive-admin/simulador-kinto"
import { Option } from "./interfaces"

export interface AppSettings {
    "APIBaseURL": string,
    "Customer_RelativeURL": string,
    "Contact_RelativeURL": string,
    "ProposalComments_RelativeURL": string,
    "ProposalCommentsAttachments_RelativeURL": string,
    "ProposalAttachments_RelativeURL": string,
    "Proposals_RelativeURL": string,
    "Vehicle_RelativeURL": string,
    "Makes_RelativeURL": string,
    "Models_RelativeURL": string,
    "Users_RelativeURL": string,
    "Segments_RelativeURL": string,
    "FuelTypes_RelativeURL": string,
    "TypeStatusOptions_RelativeURL": string,
    "OptionsType_RelativeURL": string,
    "ExtraStandard_RelativeURL": string,
    "Accessories_RelativeURL": string,
    "ModelVersions_RelativeURL": string,
    "ModelVersionsCombo_RelativeURL": string,
    "Simulations_RelativeURL": string,
    "CenterDealer_RelativeURL": string,
    "InsurancePackage_RelativeURL": string,
    "InsurancePackageLine_RelativeURL": string,
    "VehiclePriceGroup_RelativeURL": string,
    "ReplacTransType_RelativeURL": string,
    "ListPrice_RelativeURL": string,
    "PrintTemplates_RelativeURL": string,
    "SendProposalToCustomer_RelativeURL": string,
    "SendProposalToCustomerPreview_RelativeURL": string,
    "ExportSimulations_RelativeURL": string,
    "Profiles_RelativeURL": string,
    "PreConfiguredVehicles_RelativeURL": string,

    "Data": {
        "Vehicle_NumberOfPlacesOptions": string[],
        "Vehicle_TyresValueCalc": number,
        "Vehicle_MinLength": number,
        "Vehicles_MaxLength": number,
        "Vehicles_MinWidth": number,
        "Vehicles_MaxWidth": number,
        "Vehicles_MinGrossWeight": number,
        "Vehicles_MaxGrossWeight": number,
        "Vehicles_MinMaxSuitcaseVolume": number,
        "Vehicles_MaxMaxSuitcaseVolume": number,
        "Vehicles_MinHeight": number,
        "Vehicles_MaxHeight": number,
        "Vehicles_MinDistanceBetweenFrontAxles": number,
        "Vehicles_MaxDistanceBetweenFrontAxles": number,
        "Vehicles_MinDistanceBetweenRearAxles": number,
        "Vehicles_MaxDistanceBetweenRearAxles": number,
        "Vehicles_MinPower": number,
        "Vehicles_MaxPower": number,
        "Vehicles_MinEngineCapacity": number,
        "Vehicles_MaxEngineCapacity": number,
        "Vehicles_MinMaxTorque": number,
        "Vehicles_MaxMaxTorque": number,
        "Vehicles_MinCO2Emission": number,
        "Vehicles_MaxCO2Emission": number,
        "Vehicles_TyresNumber": {
            "label": string,
            "value": string,
        }[],
        Vehicles_TyresNumber_MultiScenario: {
            label: string,
            value: number,
            onChange:any,
            checked: boolean
        }[],
        "Vehicles_Insurance":{
            "label": string,
            "value": string
        }[],
        "Vehicles_YesNo": {
            "label": string,
            "value": string
        }[],
        "Vehicles_InputChannel": {
            "label": string,
            "value": string
        }[],
        "Vehicles_TypeOfRental": {
            "label": string,
            "value": string,
            "result": {
                "label": string,
                "value": string
             }[]
        }[],
        
        "ReplacementVehicleAssignment_Type": {
            "label": string,
            "value": string,
            "result": {
                "label": string,
                "value": string
             }[]
        }[],
        "VehicleSegment": {
            "label": string,
            "value": string,
            "result": {
                "label": string,
                "value": string
             }[]
        }[],
        "Profile_Web_Pages": {
            "id": number,
            "name": string,
            "switchToggle": boolean,
            "seeCheckbox": boolean,
            "editCheckbox": boolean,
            "validateCheckbox": boolean
            "required": boolean
            "editRequired": boolean            
        }[],
        "Profile_WebSub_Pages": {
            "id": number,
            "profileWebPageId":number,
            "name": string,
            "switchToggle": boolean,
            "seeCheckbox": boolean,
            "editCheckbox": boolean,
            "validateCheckbox": boolean,
            "required": boolean
            "editRequired": boolean
        }[],
        "Profile_Web_Fields": {
            "id": number,
            "profileWebSubPageId":number,
            "name": string,
            "switchToggle": boolean,
            "seeCheckbox": boolean,
            "editCheckbox": boolean,
            "validateCheckbox": boolean,
            "required": boolean,
            "editRequired": boolean
        }[],
        "Vehicles_UseProps": {
            "label": string,
            "value": string
        }[],
        "Vehicles_ClientTypology": {
            "label":string,
            "value":string,
            "result": {
                "label": string,
                "value": string
            }[]
        }[],
        "Vehicles_ClientServicesTypology": {
            "label":string,
            "value":string,
            "results": {
                "label": string,
                "value": string
            }[]
        }[],
        "Proposal_SalesPersons": {
            "label": string,
            "value": string
        }[],
        "DeadlineValues": CheckboxProps[],
        "AnnualKmValues": CheckboxProps[], 
        "ComplementaryProductsAdditionalCostsTerm": Option[],
    }
}

export class EmptyAppSettings implements AppSettings {
    APIBaseURL: string = "";
    Customer_RelativeURL: string = "";
    Contact_RelativeURL: string = "";
    ProposalComments_RelativeURL: string = "";
    ProposalCommentsAttachments_RelativeURL: string = "";
    ProposalAttachments_RelativeURL: string = "";
    Proposals_RelativeURL: string = "";
    Vehicle_RelativeURL: string = "";
    Makes_RelativeURL: string = "";
    Models_RelativeURL: string = "";
    Users_RelativeURL: string = "";
    Segments_RelativeURL: string = "";
    FuelTypes_RelativeURL: string = "";
    TypeStatusOptions_RelativeURL: string = "";
    OptionsType_RelativeURL: string = "";
    ExtraStandard_RelativeURL: string = "";
    Accessories_RelativeURL: string = "";
    ModelVersions_RelativeURL: string = "";
    ModelVersionsCombo_RelativeURL: string = "";
    Simulations_RelativeURL: string = "";
    CenterDealer_RelativeURL: string = "";
    InsurancePackage_RelativeURL: string = "";
    InsurancePackageLine_RelativeURL: string = "";
    VehiclePriceGroup_RelativeURL: string = "";
    ReplacTransType_RelativeURL: string = "";
    ListPrice_RelativeURL: string = "";
    PrintTemplates_RelativeURL: string = "";
    SendProposalToCustomer_RelativeURL: string = "";
    SendProposalToCustomerPreview_RelativeURL: string = "";
    ExportSimulations_RelativeURL: string = "";
    Profiles_RelativeURL: string="";
    PreConfiguredVehicles_RelativeURL: string="";
    
    Data: {
        Vehicle_NumberOfPlacesOptions: string[];
        Vehicle_TyresValueCalc: number;
        Vehicle_MinLength: number;
        Vehicles_MaxLength: number;
        Vehicles_MinWidth: number;
        Vehicles_MaxWidth: number;
        Vehicles_MinGrossWeight: number;
        Vehicles_MaxGrossWeight: number;
        Vehicles_MinMaxSuitcaseVolume: number;
        Vehicles_MaxMaxSuitcaseVolume: number;
        Vehicles_MinHeight: number;
        Vehicles_MaxHeight: number;
        Vehicles_MinDistanceBetweenFrontAxles: number;
        Vehicles_MaxDistanceBetweenFrontAxles: number;
        Vehicles_MinDistanceBetweenRearAxles: number;
        Vehicles_MaxDistanceBetweenRearAxles: number;
        Vehicles_MinPower: number;
        Vehicles_MaxPower: number;
        Vehicles_MinEngineCapacity: number;
        Vehicles_MaxEngineCapacity: number;
        Vehicles_MinMaxTorque: number;
        Vehicles_MaxMaxTorque: number;
        Vehicles_MinCO2Emission: number;
        Vehicles_MaxCO2Emission: number;
        Vehicles_TyresNumber: {
            "label": string,
            "value": string,
        }[];
        Vehicles_TyresNumber_MultiScenario: {
            label: string,
            value: number,
            onChange:any,
            checked: boolean
        }[];
        "Vehicles_Insurance":{
            "label": string,
            "value": string
        }[];
        "Vehicles_YesNo": {
            "label": string,
            "value": string
        }[];
        "Vehicles_InputChannel": {
            "label": string,
            "value": string
        }[];
        "Vehicles_TypeOfRental": {
            "label": string,
            "value": string,
            "result": {
                "label": string,
                "value": string
             }[]
        }[],
        "ReplacementVehicleAssignment_Type": {
            "label": string,
            "value": string,
            "result": {
                "label": string,
                "value": string
             }[]
        }[],
        "VehicleSegment": {
            "label": string,
            "value": string,
            "result": {
                "label": string,
                "value": string
             }[]
        }[],
        "Profile_Web_Pages": {
            "id": number,
            "name": string,
            "switchToggle": boolean,
            "seeCheckbox": boolean,
            "editCheckbox": boolean,
            "validateCheckbox": boolean,
            "required": boolean,
            "editRequired": boolean
        }[],
        "Profile_WebSub_Pages": {
            "id": number,
            "profileWebPageId":number,
            "name": string,
            "switchToggle": boolean,
            "seeCheckbox": boolean,
            "editCheckbox": boolean,
            "validateCheckbox": boolean,
            "required": boolean,
            "editRequired": boolean
        }[],
        "Profile_Web_Fields": {
            "id": number,
            "profileWebSubPageId":number,
            "name": string,
            "switchToggle": boolean,
            "seeCheckbox": boolean,
            "editCheckbox": boolean,
            "validateCheckbox": boolean,
            "required": boolean,
            "editRequired": boolean
        }[],
        "Vehicles_UseProps": {
            "label": string,
            "value": string
        }[],
        "Vehicles_ClientTypology": {
            "label":string,
            "value":string,
            "result": {
                "label": string,
                "value": string
            }[]
        }[],
        "Vehicles_ClientServicesTypology": {
            "label":string,
            "value":string,
            "results": {
                "label": string,
                "value": string
            }[]
        }[],
        "Proposal_SalesPersons": {
            "label": string,
            "value": string
        }[],
        "DeadlineValues": CheckboxProps[],
        "AnnualKmValues": CheckboxProps[],
        "ComplementaryProductsAdditionalCostsTerm": Option[]
     }
     constructor(){
        this.Data = {
            Vehicle_NumberOfPlacesOptions : ["2","3","4", "5", "7"],
            Vehicle_TyresValueCalc : 0,
            Vehicle_MinLength : 0,
            Vehicles_MaxLength: 0,
            Vehicles_MinWidth: 0,
            Vehicles_MaxWidth: 0,
            Vehicles_MinGrossWeight: 0,
            Vehicles_MaxGrossWeight: 0,
            Vehicles_MinMaxSuitcaseVolume: 0,
            Vehicles_MaxMaxSuitcaseVolume: 0,
            Vehicles_MinHeight: 0,
            Vehicles_MaxHeight: 0,
            Vehicles_MinDistanceBetweenFrontAxles: 0,
            Vehicles_MaxDistanceBetweenFrontAxles: 0,
            Vehicles_MinDistanceBetweenRearAxles: 0,
            Vehicles_MaxDistanceBetweenRearAxles: 0,
            Vehicles_MinPower: 0,
            Vehicles_MaxPower: 0,
            Vehicles_MinEngineCapacity: 0,
            Vehicles_MaxEngineCapacity: 0,
            Vehicles_MinMaxTorque: 0,
            Vehicles_MaxMaxTorque: 0,
            Vehicles_MinCO2Emission: 0,
            Vehicles_MaxCO2Emission: 0,
            Vehicles_TyresNumber: [
                {
                    "label": "Ilimitados",
                    "value": "0"
                },
                {
                    "label": "2",
                    "value": "2",
                },
                {
                    "label": "4",
                    "value": "4",
                },
                {
                    "label": "6",
                    "value": "6",
                },
                {
                    "label": "8",
                    "value": "8",
                },
                {
                    "label": "10",
                    "value": "10",
                }
                ,
                {
                    "label": "12",
                    "value": "12",
                }
            ],
            Vehicles_TyresNumber_MultiScenario: [
                {
                    label: "2",
                    value: 2,
                    checked: false,
                    onChange: () => {}
                },
                {
                    label: "4",
                    value: 4,
                    checked: false,
                    onChange: () => {}
                },
                {
                    label: "6",
                    value: 6,
                    checked: false,
                    onChange: () => {}
                },
                {
                    label: "8",
                    value: 8,
                    checked: false,
                    onChange: () => {}
                },
                {
                    label: "10",
                    value: 10,
                    checked: false,
                    onChange: () => {}
                }
                ,
                {
                    label: "12",
                    value: 12,
                    checked: false,
                    onChange: () => {}
                }
            ],
            Vehicles_Insurance: [
                {
                    "label": "Não",
                    "value": "false"
                },
                {
                    "label": "Sim",
                    "value": "true"
                },
                {
                    "label": "RC",
                    "value": "rc"
                }
            ],
            Vehicles_YesNo: [
                {
                    "label": "Não",
                    "value": "false"
                },
                {
                    "label": "Sim",
                    "value": "true"
                }
            ],
            Vehicles_InputChannel: [
                { "value": "0", "label": "Direto" },
                { "value": "1", "label": "Indireto" },
                { "value": "2", "label": "Análise Interna" },
                { "value": "3", "label": "Digital" },
                { "value": "4", "label": "Web" }
            ],
            Vehicles_TypeOfRental: [
                {  
                    "value":"ENU",
                    "label":"Inglês", 
                    "result": [
                        { "value": "0", "label": "Antec." },
                        { "value": "1", "label": "Post." },
                        { "value": "2", "label": "Post. 30 Days" },
                        { "value": "3", "label": "Post. 60 Days" },
                        { "value": "4", "label": "Post. 90 Days" }
                    ]
                },
                {  
                    "value":"PTG",
                    "label":"Português", 
                    "result": [
                        { "value": "0", "label": "Antecipado" },
                        { "value": "1", "label": "Postecipado" },
                        { "value": "2", "label": "Post. 30 Dias" },
                        { "value": "3", "label": "Post. 60 Dias" },
                        { "value": "4", "label": "Post. 90 Dias" }
                    ]
                }
           ],
            ReplacementVehicleAssignment_Type: [
                {  
                    "value":"ENU",
                    "label":"Inglês", 
                    "result": [
                        { "value": "0", "label": "RV Maintenance." },
                        { "value": "1", "label": "RV Breakdown" },
                        { "value": "2", "label": "RV Accident" },
                        { "value": "3", "label": "RV Theft" },
                    ]
                },
                {  
                    "value":"PTG",
                    "label":"Português", 
                    "result": [
                        { "value": "0", "label": "VS Revisão" },
                        { "value": "1", "label": "VS Avaria" },
                        { "value": "2", "label": "VS Sinistro" },
                        { "value": "3", "label": "VS Roubo" },
                    ]
                }
            ], 
            VehicleSegment: [
                {  
                    "value":"ENU",
                    "label":"Inglês", 
                    "result": [
                        { "value": "0", "label": "Superior Segment" },
                        { "value": "1", "label": "Same Segment" },
                        { "value": "2", "label": "Lower Segment" }
                    ]
                },
                {  
                    "value":"PTG",
                    "label":"Português", 
                    "result": [
                        { "value": "0", "label": "Segmento Superior" },
                        { "value": "1", "label": "Mesmo Segmento" },
                        { "value": "2", "label": "Segmento Inferior" }
                    ]
                }
            ],  
            Profile_Web_Pages: 
            [
                { "id": 1, "name": "DashBoard",
                    "switchToggle": false,
                    "seeCheckbox": false,
                    "editCheckbox": false,
                    "validateCheckbox": false,
                    "required": false,
                    "editRequired": false
                },
                { "id": 2, "name": "Propostas",
                    "switchToggle": false,
                    "seeCheckbox": false,
                    "editCheckbox": false,
                    "validateCheckbox": false,
                    "required": false,
                    "editRequired": false
                },
                { "id": 3, "name": "Perfis",
                    "switchToggle": false,
                    "seeCheckbox": false,
                    "editCheckbox": false,
                    "validateCheckbox": false,
                    "required": false,
                    "editRequired": false
                },
                { "id": 4, "name": "Utilizadores",
                    "switchToggle": false,
                    "seeCheckbox": false,
                    "editCheckbox": false,
                    "validateCheckbox": false,
                    "required": false,
                    "editRequired": false
                },
                { "id": 5, "name": "Clientes",
                    "switchToggle": false,
                    "seeCheckbox": false,
                    "editCheckbox": false,
                    "validateCheckbox": false,
                    "required": false,
                    "editRequired": false
                },
                { "id": 6, "name": "Contratos",
                    "switchToggle": false,
                    "seeCheckbox": false,
                    "editCheckbox": false,
                    "validateCheckbox": false,
                    "required": false,
                    "editRequired": false
                },
                { "id": 7, "name": "Performance",
                    "switchToggle": false,
                    "seeCheckbox": false,
                    "editCheckbox": false,
                    "validateCheckbox": false,
                    "required": false,
                    "editRequired": false
                }   
            ],
            Profile_WebSub_Pages: 
            [
                { "id": 1,"profileWebPageId":2, "name": "Detalhes Viatura",
                    "switchToggle": false,
                    "seeCheckbox": false,
                    "editCheckbox": false,
                    "validateCheckbox": false,
                    "required": false,
                    "editRequired": false
                },
                { "id": 2,"profileWebPageId":2, "name": "Tipo de Contrato",
                    "switchToggle": false,
                    "seeCheckbox": false,
                    "editCheckbox": false,
                    "validateCheckbox": false,
                    "required": false,
                    "editRequired": false
                },
                { "id": 3,"profileWebPageId":2, "name": "Definições de Contrato",
                    "switchToggle": false,
                    "seeCheckbox": false,
                    "editCheckbox": false,
                    "validateCheckbox": false,
                    "required": false,
                    "editRequired": false
                },
                { "id": 4,"profileWebPageId":2, "name": "Cotação",
                    "switchToggle": false,
                    "seeCheckbox": false,
                    "editCheckbox": false,
                    "validateCheckbox": false,
                    "required": false,
                    "editRequired": false
                }
            ],
            Profile_Web_Fields: 
            [
                { "id": 1,"profileWebSubPageId":1, "name": "Campanha s/Iva",
                    "switchToggle": false,
                    "seeCheckbox": false,
                    "editCheckbox": false,
                    "validateCheckbox": false,
                    "required": false,
                    "editRequired": false
                },
                { "id": 2,"profileWebSubPageId":1, "name": "Campanha c/Iva",
                    "switchToggle": false,
                    "seeCheckbox": false,
                    "editCheckbox": false,
                    "validateCheckbox": false,
                    "required": false,
                    "editRequired": true
                },
                { "id": 3,"profileWebSubPageId":1, "name": "Campanha em percentagem",
                    "switchToggle": false,
                    "seeCheckbox": false,
                    "editCheckbox": false,
                    "validateCheckbox": false,
                    "required": false,
                    "editRequired": false
                },
                { "id": 4,"profileWebSubPageId":1, "name": "Total campanha",
                    "switchToggle": false,
                    "seeCheckbox": false,
                    "editCheckbox": false,
                    "validateCheckbox": false,
                    "required": false,
                    "editRequired": true
                },
                { "id": 5,"profileWebSubPageId":1, "name": "Rappel s/Iva",
                    "switchToggle": false,
                    "seeCheckbox": false,
                    "editCheckbox": false,
                    "validateCheckbox": false,
                    "required": false,
                    "editRequired": false
                },
                { "id": 6,"profileWebSubPageId":1, "name": "Rappel c/Iva",
                    "switchToggle": false,
                    "seeCheckbox": false,
                    "editCheckbox": false,
                    "validateCheckbox": false,
                    "required": false,
                    "editRequired": true
                },
                { "id": 7,"profileWebSubPageId":1, "name": "Rappel em percentagem",
                    "switchToggle": false,
                    "seeCheckbox": false,
                    "editCheckbox": false,
                    "validateCheckbox": false,
                    "required": false,
                    "editRequired": false
                },
                { "id": 8,"profileWebSubPageId":1, "name": "Rappel extra",
                    "switchToggle": false,
                    "seeCheckbox": false,
                    "editCheckbox": false,
                    "validateCheckbox": false,
                    "required": false,
                    "editRequired": false
                },
                { "id": 9,"profileWebSubPageId":1, "name": "Valor de negócio s/Iva",
                    "switchToggle": false,
                    "seeCheckbox": false,
                    "editCheckbox": false,
                    "validateCheckbox": false,
                    "required": false,
                    "editRequired": false
                },
                { "id": 10,"profileWebSubPageId":1, "name": "Caracteristicas técnicas",
                    "switchToggle": false,
                    "seeCheckbox": false,
                    "editCheckbox": false,
                    "validateCheckbox": false,
                    "required": false,
                    "editRequired": false
                },
                { "id": 11,"profileWebSubPageId":1, "name": "BPM",
                    "switchToggle": false,
                    "seeCheckbox": false,
                    "editCheckbox": false,
                    "validateCheckbox": false,
                    "required": false,
                    "editRequired": false
                },
                { "id": 12,"profileWebSubPageId":1, "name": "Mudança de óleo",
                    "switchToggle": false,
                    "seeCheckbox": false,
                    "editCheckbox": false,
                    "validateCheckbox": false,
                    "required": false,
                    "editRequired": false
                },
                { "id": 13,"profileWebSubPageId":1, "name": "Serviço completo",
                    "switchToggle": false,
                    "seeCheckbox": false,
                    "editCheckbox": false,
                    "validateCheckbox": false,
                    "required": false,
                    "editRequired": false
                },
                { "id": 14,"profileWebSubPageId":1, "name": "Uso(Categoria da viatura)",
                    "switchToggle": false,
                    "seeCheckbox": false,
                    "editCheckbox": false,
                    "validateCheckbox": false,
                    "required": false,
                    "editRequired": false
                }
            ],
            Vehicles_UseProps: [
                {
                "label": "Ligeiros de Passageiros",
                "value": "Cars"
                },
                {
                "label": "Ligeiros de Mercadorias",
                "value": "Commercial Vehicles"
                },
                {
                "label": "Ligeiros Transp. Púb. Passag. e Ambulâncias",
                "value": "Public Pass. Transp. Vehicle & Ambulances"
                },
                {
                "label": "Pesados de Passageiros",
                "value": "Bus"
                },
                {
                "label": "Pesados e Reboques (PB>35ton)",
                "value": "Trailers (GW>35ton)"
                },
                {
                "label": "Transp. Escolar e Automóv. Ligeiros para Instrução",
                "value": "School Transp. & Instruction Automob."
                },
                {
                "label": "Pesados de Mercadorias",
                "value": "Merchandise Trucks"
                },
                {
                "label": "Pesados de Mercadorias,Reboques e Semi-reboques (PB>35ton) Excep. Agríc",
                "value": "Other Trailers Except Agric. (GW>35ton)"
                },
                {
                "label": "Restantes Automóveis Ligeiros,",
                "value": "Other Cars"
                },
                {
                "label": "Tractor Agrícula",
                "value": "Agricultural Tractor"
                },
                {
                "label": "Motorizada",
                "value": "Motor"
                },
                {
                "label": "Empilhador",
                "value": "Truck"
                },
                {
                "label": "Todos",
                "value": "All"
                }
            ],
            Vehicles_ClientTypology:
            [
                {
                   "value": "ENU",
                   "label":"Inglês",
                   "result":[
                      {
                         "label": "Private",
                         "value": "Private"
                      },
                      {
                         "label": "Small Enterprise/ENI's (Fleet between 1 and 5 Vehicles)",
                         "value": "Small Enterprise"
                      },
                      {
                         "label": "Medium Enterprise (Fleet between 6 and 30 Vehicles)",
                         "value": "Medium Enterprise"
                      },
                      {
                         "label": "Large Enterprise (Fleet > 30 Vehicles)",
                         "value": "Large Enterprise"
                      },
                      {
                         "label": "Corporate",
                         "value": "Corporate"
                      },
                      {
                         "label": "State",
                         "value": "State"
                      },
                      {
                         "label": "Municipalities",
                         "value": "Municipalities"
                      }
                   ]
                },
                {
                   "value": "PTG",
                   "label":"Português",
                   "result":[
                      {
                         "label": "Particular",
                         "value": "Private"
                      },
                      {
                         "label": "Pequena Empresa/ENI's (Frota entre 1 e 5 Veículos)",
                         "value": "Small Enterprise"
                      },
                      {
                         "label": "Média Empresa (Frota entre 6 e 30 Veículos)",
                         "value": "Medium Enterprise"
                      },
                      {
                         "label": "Grande Empresa (Frota > 30 Veículos)",
                         "value": "Large Enterprise"
                      },
                      {
                         "label": "Corporate",
                         "value": "Corporate"
                      },
                      {
                         "label": "Estado",
                         "value": "State"
                      },
                      {
                         "label": "Municípios",
                         "value": "Municipalities"
                      }
                   ]
                }
            ],
            Vehicles_ClientServicesTypology:
            [
               {
                  "value": "ENU",
                  "label":"Inglês",
                  "results": [
                     {
                        "label": "Company",
                        "value": "company"
                     },
                     {
                        "label": "Private",
                        "value": "Particular"
                     },
                     {
                        "label": "ENI",
                        "value": "ENI"
                     }
                  ]
               },
               {
                  "value": "PTG",
                  "label":"Português",
                  "results": [
                     {
                        "label": "Empresa",
                        "value": "company"
                     },
                     {
                        "label": "Particular",
                        "value": "Particular"
                     },
                     {
                        "label": "ENI",
                        "value": "ENI"
                     }
                  ]
               }
            ],
            Proposal_SalesPersons: [
                { "value": "op1", "label": "Luís Silva" },
                { "value": "op2", "label": "Roberto Rodrigues" },
                { "value": "op3", "label": "Raul Pessoa" },
                { "value": "op4", "label": "Jéssica Ferreira" },
                { "value": "op5", "label": "Mariana Nunes" },
                { "value": "op6", "label": "Inês Aleixo" },
                { "value": "op7", "label": "Jorge Pires" },
                { "value": "op8", "label": "Ricardo Pereira" },
                { "value": "op9", "label": "Daniel Castro" },
                { "value": "op10", "label": "Manuel dos Santos" }
            ],
            DeadlineValues:[
                { value: 24, onChange:() =>{}, checked: false },
                { value: 36, onChange:() =>{}, checked: false },
                { value: 48, onChange:() =>{}, checked: false },
                { value: 60, onChange:() =>{}, checked: false },
                { value: 72, onChange:() =>{}, checked: false }
             ],
            AnnualKmValues: [
                { value: 10000, onChange: () => {}, checked: false },
                { value: 20000, onChange: () => {}, checked: false},
                { value: 30000, onChange: () => {}, checked: false},
                { value: 40000, onChange: () => {}, checked: false},
                { value: 50000, onChange: () => {}, checked: false}
            ],
            ComplementaryProductsAdditionalCostsTerm:
            [
               { value: "Month", label: "Mês" },
               { value: "Year", label: "Ano" },
               { value: "Total Term", label: "Termo Completo" },
               { value: "Thousand Km", label: "1000 KM" },
               { value: "Factor", label: "Factor" }
            ]
        }
     }
}
  
  