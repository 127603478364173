import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import usePermissionsHook from "./permissions/permissionsHook";


import { AccountIcon } from '@hi-interactive-admin/simulador-kinto';

const useNavbarHook = (onLogout:() => void) => {

  const navigate = useNavigate();
  const handleCardClick = useCallback((path: string) => () => {
    navigate(`/${path}`);
  },[navigate]);

  const handleLogout = useCallback(() => {
    //Auth.clearToken();
    onLogout();
    navigate(`/Login`);
  }, [navigate, onLogout]);

  const { currentUser, propertyDisabled, propertyHidden} = usePermissionsHook();

  // rotas definidas
  const dashboardMenuButtonPropsMemo = useMemo(() => ({
    label: "Dashboard",
    hidden: propertyHidden("Dashboard"),
    disabled: propertyDisabled("Dashboard"),
    onClick:  handleCardClick("dashboard"),
  }),[handleCardClick, propertyDisabled, propertyHidden]);

  const proposalMenuButtonPropsMemo = useMemo(() => ({
    label: "Propostas",
    hidden: propertyHidden("Propostas"),
    disabled: propertyDisabled("Propostas"),
    onClick: handleCardClick("proposal"),
  }),[handleCardClick, propertyDisabled, propertyHidden]);

  const backofficeMenuButtonPropsMemo = useMemo(() => ({
    label: "Backoffice",
    hidden: propertyHidden("Backoffice"),
    disabled: propertyDisabled("Backoffice"),
    onClick: handleCardClick("backoffice"),
  }),[handleCardClick, propertyDisabled, propertyHidden]);

  // rotas não definidas
  const clientsMenuButtonPropsMemo = useMemo(() => ({
    label: "Clientes",
    hidden: propertyHidden("Clientes"),
    disabled: propertyDisabled("Clientes"),

    onClick: handleCardClick("clients/1"),

    // onClick: () => alert("menu click"),
  }),[handleCardClick, propertyDisabled, propertyHidden]);

  const contractsMenuButtonPropsMemo = useMemo(() => ({
    label: "Contratos",
    hidden: propertyHidden("Contratos"),
    disabled: propertyDisabled("Contratos"),
    onClick: () => alert("menu click"),
  }),[propertyDisabled, propertyHidden]);

  const performanceMenuButtonPropsMemo = useMemo(() => ({
    label: "Performance",
    hidden: propertyHidden("Performance"),
    disabled: propertyDisabled("Performance"),
    onClick: () => alert("menu click"),
  }),[propertyDisabled, propertyHidden]);

  const topBarPropsMemo = useMemo(() => ({
    actions: [
      {
        label: 'Perfil',
        onClick: handleCardClick(`users/${currentUser.userId}`),
      },

      {
        label: 'Definições',
        onClick: handleCardClick("backoffice"),
      },
      {
        label: 'Idioma (PT)',
        onClick: () => alert(''),
      },

      {
        label: 'Logout',
        onClick: handleLogout,
      },
    ],
    onLogoClick: handleCardClick('home'),
    onUserClick: () => alert("Hello " + currentUser.friendlyName + "!"),
    username: currentUser.friendlyName,
    userImageSrc: currentUser.avatarSrc ?? AccountIcon,

    notificationDrawerProps: {
      tagLabel: "Nova",
      notificationText: "Notificações",
      notificationsNumber: "2 novas",
      notifications: [
        {
          isNew: true,
          avatarSrc: AccountIcon,
          name: "Luís Brazão",
          text: `Boa tarde Pedro, praesent faucibus odio vitae ornare ullamcorper.
                Praesent ultrices risus sit amet enim ultrices bibendum. Faucibus
                odio vitae ornare ullamcorper. Praesent ultrices risus sit amet
                enim ultrices bibendum.`,
          date: `Ontem às 17:32`,
        },
      ],
    },
  }),[currentUser.friendlyName, currentUser.avatarSrc, currentUser.userId, handleCardClick, handleLogout])


  return { 
    navigate, 
    handleCardClick, 
    handleLogout,
    topBarPropsMemo,
    dashboardMenuButtonPropsMemo, 
    proposalMenuButtonPropsMemo, 
    clientsMenuButtonPropsMemo, 
    contractsMenuButtonPropsMemo, 
    performanceMenuButtonPropsMemo, 
    backofficeMenuButtonPropsMemo
  };
}
export default useNavbarHook;
