import { CheckboxProps } from '@hi-interactive-admin/simulador-kinto';
import { useState, useCallback, useMemo } from 'react';
import { CustomerService } from '../../../services/CustomerServices';
import { SettingsService } from '../../../services/SettingsService';

const customerService = new CustomerService();

const useSalesPersonsCustomHook = () => {
  // const settingsService = SettingsService.getInstance();
  const [salesPersonNames, setSalesPersonNames] = useState<CheckboxProps[]>([]);

  const handleToggleSelectSalesPersonNameCallback = useCallback((name: string) => () => {
    setSalesPersonNames(prevState =>
      prevState.map(salesPersonName =>
        salesPersonName.label === name
          ? { ...salesPersonName, checked: !salesPersonName.checked }
          : salesPersonName
      )
    );
  }, []);

  const handleSalesPersonName = useCallback((salesPersonNamesTmp: CheckboxProps[]) => {
    if (salesPersonNamesTmp.length > 0) {
      setSalesPersonNames(salesPersonNamesTmp);
      return salesPersonNamesTmp;
    }
    customerService
      .getSalesPersons()
      .then(data => {
        if (data.succeeded === true) {
          const salesPersonNamesOptions = data.value.map((element: any) => ({
            label: element.name,
            checked: false,
            onClick: handleToggleSelectSalesPersonNameCallback(element.name),
          }));
          setSalesPersonNames(salesPersonNamesOptions);
          return salesPersonNamesOptions;
        }
        return salesPersonNames;
      })
      .catch(() => setSalesPersonNames([]));
  },[handleToggleSelectSalesPersonNameCallback, salesPersonNames]);

  const sellerNameMemo = useMemo(() => handleSalesPersonName(salesPersonNames), [
    salesPersonNames, handleSalesPersonName
  ]);

  const handleSellerNameTextFieldPropsCallback = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (e.target.value === "") {
        handleSalesPersonName([]);
      }
      if (e.target.value.length < 3) {
        return;
      }
      let salesPersonNamesOptions: any[any] = [];
      customerService
        .getSalesPersonsByName(e.target.value)
        .then(data => {
          if (data.succeeded === true && data.value.length > 0) {
            salesPersonNamesOptions = data.value.map((element: any) => ({
              label: element.name,
              checked: false,
              onClick: handleToggleSelectSalesPersonNameCallback(element.name),
            }));
            setSalesPersonNames(salesPersonNamesOptions);
          }
        })
        .catch(() => setSalesPersonNames([]));
    },
    [handleSalesPersonName, handleToggleSelectSalesPersonNameCallback]
  );

  return { salesPersonNames, handleSellerNameTextFieldPropsCallback, sellerNameMemo };
};

export default useSalesPersonsCustomHook;

