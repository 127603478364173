import { ListItemButtonProps } from "@hi-interactive-admin/simulador-kinto";
import React, { ChangeEvent, useCallback, useMemo, useState } from "react";

const useVehicleBrandsCustomHook  = () => {
//INICIO Marca da Viatura
  const [brandsSectionProps, setBrandsSectionProps] = useState<any>([]);
  const convertToExpandableMakesOptions = useCallback((items: any) => {
    let convertedItems = items.map((brand: any) => {
      let newItem = {
        label: brand.id,
        hidden: false,
        open: false,
        checkBoxProps: {
          checked: false,
        },
        models: brand.models.map(
          (model: { id: string; description: string }) => {
            return {
              id: model.id,
              label: model.description,
              checkBoxProps: {
                checked: false,
              },
            };
          }
        ),
      };
      return newItem;
    });
    return convertedItems;
  },[]);

  const handleToggleDisplayModelsCallback = useCallback((item:any) => () => {
    setBrandsSectionProps((rows: any[]) => 
      rows.map((row)=> {
        if(row.label === item.label){
          row.open = !row.open;
        }
        return row;
      })
    );
  },[]);

  const handleToggleSelectModelCallback = useCallback((item:any) => () => {
    setBrandsSectionProps((rows:any[]) => 
      rows.map((row) => {
        if(row.label === item.label)
        {
          row.checkBoxProps.checked = !row.checkBoxProps.checked;
          row.models.map((model:any) => model.checkBoxProps.checked = !model.checkBoxProps.checked);
        }
        return row;
      })
    )
  },[])
  const handleVehicleBrandTextFieldPropsCallback = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setBrandsSectionProps( (rows:any[]) => 
      rows.map((row:any) => 
      {
        row.hidden = true;
        if(row.label.toLowerCase().includes(e.target.value.toLowerCase()))
        {
          row.hidden = false
        }
        return row;
      })
    )
  },[])
  //SERA QUE AQUI TEMOS DE USAR O useMemo?
  //se sim usar estes dois metodos
  const handleVehicleBrand = useCallback((brandsSectionProps:any) => {
    let brandsTmp: [{
      label: string,
      hidden: boolean,
      onClick: ListItemButtonProps['onClick'],
      open: boolean,
      checkBoxProps: {
        checked: boolean,
      },
      models: [{
        id: string,
        label: string,
        checkBoxProps: {
          checked: boolean,
        },
      }];
    }] = [{
      label: "",
      hidden: true,
      onClick: () => {},
      open: false,
      checkBoxProps: {
        checked: false,
      },
      models: [{
        id: "",
        label: "string",
        checkBoxProps: {
          checked: false,
        },
      }]
    }];
    
    if (brandsSectionProps !== (null || undefined) && brandsSectionProps.length > 0) {
      // brandsTmp = brandsSectionProps.filter((x: { hidden: boolean }) => x.hidden === false).map((item: {
      brandsTmp = brandsSectionProps.filter((x: { hidden: boolean }) => x.hidden === false).map((item: {
        [x: string]: any;
        label: any;
        open:boolean;
        hidden:boolean;
        models: {
          [x: string]: any;
          id: string;
          label: string; 
        }[]; }, index:number) => {
        return {
          label: item.label,
          hidden: false,
          onClick: handleToggleDisplayModelsCallback(item),
          open: item.open,
          checkBoxProps: {
            checked: item.checkBoxProps.checked,
            onClick: handleToggleSelectModelCallback(item),
          },
          models: item.models.map(
            (model: { id: string; label: string },modelIndex:number) => {
              return {
                id: model.id,
                label: model.label,
                checkBoxProps: {
                  checked: item.models[modelIndex].checkBoxProps.checked,
                  onClick: () => {
                    item.models[modelIndex].checkBoxProps.checked = !item.models[modelIndex].checkBoxProps.checked;
                    setBrandsSectionProps([...brandsSectionProps]);
                  },
                },
              };
            }
          ),                
        }
      });
    }
    return brandsTmp
  },[handleToggleDisplayModelsCallback, handleToggleSelectModelCallback])
  
  const brandsMemo = useMemo(() => handleVehicleBrand(brandsSectionProps),[brandsSectionProps,handleVehicleBrand])

  const handleAllBrandsCheckBoxProps = useCallback((e:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const element = e.target as HTMLInputElement;
    setBrandsSectionProps((rows: any[]) => 
      rows.map((row)=> {
        row.checkBoxProps.checked = element.checked;
        row.models.map((model: any) => {
          model.checkBoxProps.checked = element.checked;
          return model;
        })
        return row;
      })
    )
  },[]);
  //FIM Marca da Viatura
  return { brandsSectionProps, convertToExpandableMakesOptions,setBrandsSectionProps, handleVehicleBrandTextFieldPropsCallback, handleAllBrandsCheckBoxProps , brandsMemo };
};

export default useVehicleBrandsCustomHook;
