import { CheckboxProps, DropDownProps, IconButtonProps, TextFieldProps, VehicleDetailsProps } from "@hi-interactive-admin/simulador-kinto";

export interface Option {
  value: string;
  label: string;
}

export interface CampaignVehicleDetails {
  id:string;
  icons: string[]; 
  checkboxProps: CheckboxProps;
  name: string;
  characteristics: string;
  businessPrice: {
    label: string;
    value: string;
  };
  extras: { label: string; value: string };
  proformaPrice: { label: string; value: string };
  details: Omit<VehicleDetailsProps, 'tableIndex'>;
}

export interface UsedVehicleDetails {
  icons: string[];
  checkboxProps: CheckboxProps;
  name: string;
  characteristics: string;
  licensePlate: {
    label: string;
    value: string;
  };
}

export interface IPreConfiguredVehicleDetails {
  hidden: boolean;
  id: string;
  versionId: string;
  icons: string[];
  checkboxProps: CheckboxProps;
  name: string;
  characteristics: string;
  licensePlate: {
      label: string;
      value: string;
  };
  vehicleState: {
      label: string;
      value: string;
  };
}

export interface SalesPerson {
  code:string,
  name:string,
}

export interface ILabelValue {
  label: string;
  value: string;
}

export interface ExtraAccessory {
  ExtraAcessoryNameTextFieldProps: TextFieldProps;
  ExtraAcessoryPriceTextFieldProps: TextFieldProps;
  ExtraAcessoryInstallerDropDownProps: DropDownProps;
  deleteExtraAcessoryIconButtonProps: IconButtonProps;
}


export interface InsuranceOptions {
  //insurance package description
  insurancePackageOption: Option,
  
  // vehicle replacement options
  vsOverhaulVehicleOptions: Option[],
  // Theft
  vsTheftVehicleOptions: Option[],
  // Accident
  vsAccidentVehicleOptions: Option[],
  // Assistance
  vsAssistanceVehicleOptions: Option[],
  // Default
  vehicleTypeOptions: Option[],

  vsOverhaulVehicleTypeOptions: Option[],
  vsTheftVehicleTypeOptions: Option[],
  vsAccidentVehicleTypeOptions: Option[],
  vsAssistanceVehicleTypeOptions: Option[],

  fuelOptions: Option[],
  viaVerdeOptions: Option[],

  //insurance package options
  insurance: string,
  vehicleDamageOptions: Option[],
  roadAssistanceOptions: Option[],
  occupiersOptions: Option[],
  liabilityOptions: Option[],
  isolatedGlassBreakOptions: Option[],
  //insurance package default options by insurance and vehice usage(vehicle.use)
  responsibility: string,
  ownDamage: string,
  occupants: string,
  travelAssistance: string,
  glassBreakage: string,
  secureOptions: boolean,

}

export interface InsuranceFrameworkContract {
  typeofRental: string,
  commercialConditions: string,
  insurancePackageOptions: InsuranceOptions[],

  // insurancePackageOption: string,
  
  // vsOverhaulVehicleOptions: Option[],
  // // Theft
  // vsTheftVehicleOptions: Option[],
  // // Accident
  // vsAccidentVehicleOptions: Option[],
  // // Assistance
  // vsAssistanceVehicleOptions: Option[],
  // // Default
  // vehicleTypeOptions: Option[],

  // vsOverhaulVehicleTypeOptions: Option[],
  // vsTheftVehicleTypeOptions: Option[],
  // vsAccidentVehicleTypeOptions: Option[],
  // vsAssistanceVehicleTypeOptions: Option[],

  // fuelOptions: Option[],
  // viaVerdeOptions: Option[],

  // vehicleDamageOptions: Option[],
  // roadAssistanceOptions: Option[],
  // occupiersOptions: Option[],
  // liabilityOptions: Option[],
  // isolatedGlassBreakOptions: Option[],
}

export const emptyInsuranceOption: InsuranceOptions = {
  insurancePackageOption: {
    value: '',
    label: ''
  },

  vsOverhaulVehicleOptions: [],
  vsTheftVehicleOptions: [],
  vsAccidentVehicleOptions: [],
  vsAssistanceVehicleOptions: [],
  vehicleTypeOptions: [],

  vsOverhaulVehicleTypeOptions: [],
  vsTheftVehicleTypeOptions: [],
  vsAccidentVehicleTypeOptions: [],
  vsAssistanceVehicleTypeOptions: [],

  fuelOptions: [],
  viaVerdeOptions: [],

  insurance: '',
  vehicleDamageOptions: [],
  roadAssistanceOptions: [],
  occupiersOptions: [],
  liabilityOptions: [],
  isolatedGlassBreakOptions: [],

  responsibility: '',
  ownDamage: '',
  occupants: '',
  travelAssistance: '',
  glassBreakage: '',
  secureOptions: false,
}

export const emptyInsuranceFramworkContract: InsuranceFrameworkContract = {
  typeofRental: '',
  commercialConditions: '',
  insurancePackageOptions: []
  // insurancePackageOption: '',

  // vsOverhaulVehicleOptions: [],
  // vsTheftVehicleOptions: [],
  // vsAccidentVehicleOptions: [],
  // vsAssistanceVehicleOptions: [],
  // vehicleTypeOptions: [],

  // vsOverhaulVehicleTypeOptions: [],
  // vsTheftVehicleTypeOptions: [],
  // vsAccidentVehicleTypeOptions: [],
  // vsAssistanceVehicleTypeOptions: [],

  // fuelOptions: [],
  // viaVerdeOptions: [],

  // vehicleDamageOptions: [],
  // roadAssistanceOptions: [],
  // occupiersOptions: [],
  // liabilityOptions: [],
  // isolatedGlassBreakOptions: [],
}