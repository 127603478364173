// TypeScript
import { useState, useCallback, useMemo, ChangeEvent, useEffect } from 'react';
import { AlertProps, BackofficePreconfiguredVehiclesPageProps, CheckboxProps, CreateProposalPageProps, TextFieldProps, ValueLabelProps } from '@hi-interactive-admin/simulador-kinto';
import { AppSettings } from '../model/Settings';
import { VehicleService } from '../services/VehicleService';
import { convertNumber, validateStringFormatNumber } from '../Utilities/Utils';
import { AdvancedFilterVersionsOptions} from '../model/Enums';
import axios from 'axios';
import VehicleUtilities from '../Utilities/VehicleUtilities';

export interface IAdvVehicleFilterHandler {
  icons: string[];
  checkboxProps: CheckboxProps;
  name: string;
  characteristics: string;
  businessPrice: ValueLabelProps;
  associateLotTextFieldProps: TextFieldProps;
  versionDetails: TextFieldProps; 
  makeId: ValueLabelProps; 
  modelId: string; 
  versionId: CheckboxProps;
};

const useAdvVehicleFilterHook = (
    settings: AppSettings, 
    vehicleService: VehicleService, 
    handleAddAdvSearchSelectedVehicles_Click: (itemsToAdd: any) => Promise<void>,
    openAlert: (alertProps: Omit<AlertProps, "open" | "onClose">) => void
  ) => {
  const [filterLoading, setFilterLoading] = useState<boolean>(false);
  const [actionFilter, setActionFilter] = useState<boolean>(false);
  const [advFilterTypeStatusOptions, setAdvFilterTypeStatusOptions] = useState<CheckboxProps[]>([]);
  const [filterResults, setFilterResults] = 
    useState<CreateProposalPageProps["vehicleDataProps"]["filterDrawerProps"]["result"] | 
        BackofficePreconfiguredVehiclesPageProps["filterDrawerProps"]["result"]
    >([]);
  const [brandsSectionProps, setBrandsSectionProps] = useState<any>([]);
  const [advFilterFuelTypeOptions, setAdvFilterFuelTypeOptions] = useState<CheckboxProps[]>([]);
  const [advFilterResultsNumber, setAdvFilterResultsNumber] = useState<number>(0);
  const [advFilterSegmentsSearchOptions, setAdvFilterSegmentsSearchOptions] = useState<any>([]);
  // FILTROS - CHECKBOX SEGMENTOS
  const [advFilterSegmentsOptions, setAdvFilterSegmentsOptions] = useState<any>([]);
  // FILTROS - CHECKBOX LOTAÇÃO DA VIATURA
  const [advFilterSeats, setAdvFilterSeats] = useState<CheckboxProps[]>([])
  const [appliedFilters, setAppliedFilters] = useState<{
    label: string;
    id: string;
  }[]>([]);
  //ESPECIFICAÇÕES DA VIATURA
  const [vehiclesMinGrossWeightFilter, setVehiclesMinGrossWeightFilter] = useState(settings.Data.Vehicles_MinGrossWeight);
  const [vehiclesMaxGrossWeightFilter, setVehiclesMaxGrossWeightFilter] = useState(settings.Data.Vehicles_MaxGrossWeight);
  const [vehiclesMinMaxSuitcaseVolumeFilter,setVehiclesMinMaxSuitcaseVolumeFilter] = useState(settings.Data.Vehicles_MinMaxSuitcaseVolume);
  const [vehiclesMaxMaxSuitcaseVolumeFilter,setVehiclesMaxMaxSuitcaseVolumeFilter] = useState(settings.Data.Vehicles_MaxMaxSuitcaseVolume);
  //ESPAÇO E DIMENSÕES
  const [vehiclesMinLengthFilter, setVehiclesMinLengthFilter] = useState(settings.Data.Vehicle_MinLength);
  const [vehiclesMaxLengthFilter, setVehiclesMaxLengthFilter] = useState(settings.Data.Vehicles_MaxLength);

  const [vehiclesMinWidthFilter, setVehiclesMinWidthFilter] = useState(settings.Data.Vehicles_MinWidth);
  const [vehiclesMaxWidthFilter, setVehiclesMaxWidthFilter] = useState(settings.Data.Vehicles_MaxWidth);

  const [vehiclesMinHeightFilter, setVehiclesMinHeightFilter] = useState(settings.Data.Vehicles_MinHeight);
  const [vehiclesMaxHeightFilter, setVehiclesMaxHeightFilter] = useState(settings.Data.Vehicles_MaxHeight);
  
  const [vehiclesMinDistanceBetweenFrontAxlesFilter, setVehiclesMinDistanceBetweenFrontAxlesFilter] = useState(settings.Data.Vehicles_MinDistanceBetweenFrontAxles);
  const [ vehiclesMaxDistanceBetweenFrontAxlesFilter, setVehiclesMaxDistanceBetweenFrontAxlesFilter] = useState(settings.Data.Vehicles_MaxDistanceBetweenFrontAxles);
  
  const [vehiclesMinDistanceBetweenRearAxlesFilter,setVehiclesMinDistanceBetweenRearAxlesFilter] = useState(settings.Data.Vehicles_MinDistanceBetweenRearAxles);
  const [vehiclesMaxDistanceBetweenRearAxlesFilter,setVehiclesMaxDistanceBetweenRearAxlesFilter] = useState(settings.Data.Vehicles_MaxDistanceBetweenRearAxles);

  //ESPECIFICAÇÕES DO MOTOR
  const [vehiclesMinPowerFilter, setVehiclesMinPowerFilter] = useState(settings.Data.Vehicles_MinPower);
  const [vehiclesMaxPowerFilter, setVehiclesMaxPowerFilter] = useState(settings.Data.Vehicles_MaxPower);
  
  const [vehiclesMinEngineCapacityFilter, setVehiclesMinEngineCapacityFilter] = useState(settings.Data.Vehicles_MinEngineCapacity);
  const [vehiclesMaxEngineCapacityFilter, setVehiclesMaxEngineCapacityFilter] = useState(settings.Data.Vehicles_MaxEngineCapacity);
  
  const [vehiclesMinMaxTorqueFilter, setVehiclesMinMaxTorqueFilter] = useState(settings.Data.Vehicles_MinMaxTorque);
  const [vehiclesMaxMaxTorqueFilter, setVehiclesMaxMaxTorqueFilter] = useState(settings.Data.Vehicles_MaxMaxTorque);
  
    const [vehiclesMinCO2EmissionFilter, setVehiclesMinCO2EmissionFilter] = useState(settings.Data.Vehicles_MinCO2Emission);
  const [vehiclesMaxCO2EmissionFilter, setVehiclesMaxCO2EmissionFilter] = useState(settings.Data.Vehicles_MaxCO2Emission);

  //ESPECIFICAÇÕES DA VIATURA
  let vehicleMinGrossWeightDefaultValue = settings.Data.Vehicles_MinGrossWeight;
  let vehicleMaxGrossWeightDefaultValue =settings.Data.Vehicles_MaxGrossWeight;

  let vehicleMinMaxSuitcaseVolumeDefaultValue = settings.Data.Vehicles_MinMaxSuitcaseVolume;
  let vehicleMaxMaxSuitcaseVolumeDefaultValue = settings.Data.Vehicles_MaxMaxSuitcaseVolume;

  //ESPAÇO E DIMENSÕES
  let vehicleMinLengthDefaultValue = settings.Data.Vehicle_MinLength;
  let vehicleMaxLengthDefaultValue = settings.Data.Vehicles_MaxLength;
  
  let vehicleMinWidthDefaultValue = settings.Data.Vehicles_MinWidth;
  let vehicleMaxWidthDefaultValue = settings.Data.Vehicles_MaxWidth;
  
  let vehicleMinHeightDefaultValue = settings.Data.Vehicles_MinHeight;
  let vehicleMaxHeightDefaultValue = settings.Data.Vehicles_MaxHeight;

  let vehicleMinDistanceBetweenFrontAxlesDefaultValue = settings.Data.Vehicles_MinDistanceBetweenFrontAxles;
  let vehicleMaxDistanceBetweenFrontAxlesDefaultValue =settings.Data.Vehicles_MaxDistanceBetweenFrontAxles;
  
  let vehicleMinDistanceBetweenRearAxlesDefaultValue = settings.Data.Vehicles_MinDistanceBetweenRearAxles;
  let vehicleMaxDistanceBetweenRearAxlesDefaultValue = settings.Data.Vehicles_MaxDistanceBetweenRearAxles;

  //ESPECIFICAÇÕES DO MOTOR
  let vehicleMinPowerDefaultValue = settings.Data.Vehicles_MinPower;
  let vehicleMaxPowerDefaultValue = settings.Data.Vehicles_MaxPower;

  let vehicleMinEngineCapacityDefaultValue = settings.Data.Vehicles_MinEngineCapacity;
  let vehicleMaxEngineCapacityDefaultValue = settings.Data.Vehicles_MaxEngineCapacity;

  let vehicleMinMaxTorqueDefaultValue = settings.Data.Vehicles_MinMaxTorque;
  let vehicleMaxMaxTorqueDefaultValue = settings.Data.Vehicles_MaxMaxTorque;

  let vehicleMinCO2EmissionDefaultValue = settings.Data.Vehicles_MinCO2Emission;
  let vehicleMaxCO2EmissionDefaultValue = settings.Data.Vehicles_MaxCO2Emission;

  const handleBrandsSearchChange = useCallback((e: any, option: any) => {
    for (let i = 0; i < brandsSectionProps.length; i++) {
      if (option === null) {
        brandsSectionProps[i].hidden = false;
      } else if (brandsSectionProps[i].label !== option.label) {
        brandsSectionProps[i].hidden = true;
      }
    }
    setBrandsSectionProps([...brandsSectionProps]);
  }, [brandsSectionProps]);

  const handleAllBrandsCheckboxProps = useCallback((e: any) => {
    const element = e.target as HTMLInputElement; //onSelectAllBrandsAndModelsClick(e.target as HTMLInputElement),
    for (var i = 0; i < brandsSectionProps.length; i++) {
      brandsSectionProps[i].checkBoxProps.checked = element.checked;
      for (var j = 0; j < brandsSectionProps[i].models.length; j++) {
        brandsSectionProps[i].models[j].checkBoxProps.checked =
          element.checked;
      }
    }
    setBrandsSectionProps([...brandsSectionProps]);
  }, [brandsSectionProps]);

  const handleVehiclesMinGrossWeightFilter = useCallback((e: any) => {
    let value: number = vehiclesMinGrossWeightFilter;
    try {
      value =convertNumber(validateStringFormatNumber(e.target.value,value.toString()));
    } catch (e) {
      console.log(e);
    }
    setVehiclesMinGrossWeightFilter(value);
  }, [vehiclesMinGrossWeightFilter]);

  const handleBrandsClick = useCallback((item: {
    [x: string]: any;
    label: any;
    open: boolean;
    models: {
      [x: string]: any;
      id: string;
      label: string;
    }[];
  }) => () => {
    for (let i = 0; i < brandsSectionProps.length; i++) {
      if (brandsSectionProps[i].label === item.label) {
        brandsSectionProps[i].open = !brandsSectionProps[i].open;
        break;
      }
    }
    setBrandsSectionProps([...brandsSectionProps]);
  }, [brandsSectionProps]);

  const handleBrandsCheckboxPropsClick = useCallback((item: {
    [x: string]: any;
    label: any;
    open: boolean;
    models: {
      [x: string]: any;
      id: string;
      label: string;
    }[];
  }) => () => {
    for (let i = 0; i < brandsSectionProps.length; i++) {
      if (brandsSectionProps[i].label === item.label) {
        brandsSectionProps[i].checkBoxProps.checked =
          !brandsSectionProps[i].checkBoxProps.checked;
        brandsSectionProps[i].models.forEach(
          (m: { checkBoxProps: { checked: boolean } }) =>
            (m.checkBoxProps.checked = !m.checkBoxProps.checked)
        );
        break;
      }
    }
    setBrandsSectionProps([...brandsSectionProps]);
  }, [brandsSectionProps]);

  const handleBrandsModelCheckboxPropsClick = useCallback((item: {
    [x: string]: any;
    label: any;
    open: boolean;
    models: {
      [x: string]: any;
      id: string;
      label: string;
    }[];
  }, 
  modelIndex: number 
) => () => {
  item.models[modelIndex].checkBoxProps.checked =
    !item.models[modelIndex].checkBoxProps.checked;
  setBrandsSectionProps([...brandsSectionProps]);
}, [brandsSectionProps]);

  const handleVehiclesMaxGrossWeightFilter = useCallback((e: any) => {
    let value: number = vehiclesMaxGrossWeightFilter;
    try {
      value =convertNumber(validateStringFormatNumber(e.target.value,value.toString()));
    } catch (e) {
      console.log(e);
    }
    setVehiclesMaxGrossWeightFilter(value);
  }, [vehiclesMaxGrossWeightFilter]);

  const filterDrawerPropsBrands = useMemo(() => {
    let brands = brandsSectionProps.filter((x: { hidden: boolean }) => x.hidden === false).map((item: {
      [x: string]: any; label: any; open:boolean; models: {
        [x: string]: any; id: string; label: string;
      }[];
    }, index:number) => {
      return {
        label: item.label,
        hidden: false,
        onClick: handleBrandsClick(item),
        open: item.open,
        checkBoxProps: {
          checked: item.checkBoxProps.checked,
          onClick: handleBrandsCheckboxPropsClick(item),
        },
        models: item.models.map((model: { id: string; label: string },modelIndex:number) => {
          return {
            id: model.id,
            label: model.label,
            checkBoxProps: {
              checked: item.models[modelIndex].checkBoxProps.checked,
              onClick: handleBrandsModelCheckboxPropsClick(item,modelIndex),
            },
            onClick: handleBrandsModelCheckboxPropsClick(item,modelIndex)
          };
        }),
      }
    });
    return brands;
  }, [
    brandsSectionProps,
    handleBrandsClick,
    handleBrandsCheckboxPropsClick,
    handleBrandsModelCheckboxPropsClick,
  ]);

  const handleVehiclesMinMaxSuitcaseVolumeFilter = useCallback((e: any) => {
    let value: number = vehiclesMinMaxSuitcaseVolumeFilter;
    try {
      value =convertNumber(validateStringFormatNumber(e.target.value,value.toString()));
    } catch (e) {
      console.log(e);
    }
    setVehiclesMinMaxSuitcaseVolumeFilter(value);
  }, [vehiclesMinMaxSuitcaseVolumeFilter]);

  const handleVehiclesMaxMaxSuitcaseVolumeFilter = useCallback((e: any) => {
    let value: number = vehiclesMaxMaxSuitcaseVolumeFilter;
    try {
      value = convertNumber(validateStringFormatNumber(e.target.value,value.toString()));
    } catch (e) {
      console.log(e);
    }
    setVehiclesMaxMaxSuitcaseVolumeFilter(value);
  }, [vehiclesMaxMaxSuitcaseVolumeFilter]);

  const handleVehiclesSuitcaseVolumeBarFilterChange = useCallback((_: any, value: any) => {
    if (Array.isArray(value)) {
      setVehiclesMinMaxSuitcaseVolumeFilter(value[0]);
      setVehiclesMaxMaxSuitcaseVolumeFilter(value[1]);
    }
  }, []);

  const handleVehiclesGrossWeightBarFilterChange = useCallback((_: any, value: any) => {
    if (Array.isArray(value)) {
      setVehiclesMinGrossWeightFilter(value[0]);
      setVehiclesMaxGrossWeightFilter(value[1]);
    }
  }, []);

  const handleSegmentsSearch = useCallback(
    (e: any, option: any) => {
      for (let i = 0; i < advFilterSegmentsOptions.length; i++) {
        if (option === null) {
          advFilterSegmentsOptions[i].hidden = false;
        } else if (advFilterSegmentsOptions[i].label !== option.label) {
          advFilterSegmentsOptions[i].hidden = true;
        }
        else if(advFilterSegmentsOptions[i].label === option.label){
          advFilterSegmentsOptions[i].checkBoxProps.checked = true;
        }
      }
      setAdvFilterSegmentsOptions((options: any[]) =>
        options.map((item) => item)
      );
    },
    [advFilterSegmentsOptions]
  );

  const handleAdvFilterSegmentClick = useCallback(
    (segmentId: string) => () => {

      const index = advFilterSegmentsOptions.findIndex((x:{ value: string; }) => x.value === segmentId);
      if(index > -1){
        advFilterSegmentsOptions[index].checkBoxProps.checked =
          !advFilterSegmentsOptions[index].checkBoxProps.checked;
        setAdvFilterSegmentsOptions((items: any[]) => items.map((item) => item));
      }
    },
    [advFilterSegmentsOptions]
  );

  const handleAdvFilterSeatsClick = useCallback(
    (index: number) => () => {
      setAdvFilterSeats((seatsOptions) => {
        return seatsOptions.map((row, seatIndex:number) => {
          if(seatIndex === index){
            row.checked = !row.checked;
          }
          return row;
        });
      });
    },[]
  );

  const advFilterSeatsOptions = useMemo(() =>
    advFilterSeats.map((item, index: number) => {
      return {
        label: item.label,
        checked: item.checked,
        onClick: handleAdvFilterSeatsClick(index),
      };
    }
  ), [advFilterSeats, handleAdvFilterSeatsClick]);
 
  const handleVehiclesMinLengthFilter = useCallback((e: any) => {
    let value: number = vehiclesMinLengthFilter;
    // const minLengthDefaultValue =
    //   vehicleMinLengthDefaultValue;
    try {
      value =convertNumber(validateStringFormatNumber(e.target.value,value.toString()));
    } catch (e) {
      console.log(e);
    }
    //FIXME a alteração da caixa de texto não replica para o slider
    /*
    if(value < minLengthDefaultValue)
      value = minLengthDefaultValue;
    if(value > vehicleMaxLengthDefaultValue)
      value = vehicleMaxLengthDefaultValue;
    */
    //if(value >= minLengthDefaultValue && value <= vehicleMaxLengthDefaultValue)
    setVehiclesMinLengthFilter(value);
  }, [vehiclesMinLengthFilter]);

  const handleVehiclesMaxLengthFilter = useCallback((e: any) => {
    let value: number = vehiclesMaxLengthFilter;
    // const maxLengthDefaultValue =
    //   vehicleMaxLengthDefaultValue;

    try {
      value =convertNumber(validateStringFormatNumber(e.target.value,value.toString()));
    } catch (e) {
      console.log(e);
    }
    //FIXME a alteração da caixa de texto não replica para o slider
    /*
    if(value > maxLengthDefaultValue)
      value = maxLengthDefaultValue;
    if(value < vehicleMinLengthDefaultValue)
      value = vehicleMaxLengthDefaultValue;
    */
    setVehiclesMaxLengthFilter(value);
  }, [vehiclesMaxLengthFilter]);

  const handleVehiclesLengthBarFilterChange = useCallback((_: any, value: any) => {
    if (Array.isArray(value)) {
      setVehiclesMinLengthFilter(value[0]);
      setVehiclesMaxLengthFilter(value[1]);
    }
  }, []);

  const handleVehiclesMinWidthFilter = useCallback((e: any) => {
    let value: number = vehiclesMinWidthFilter;
    const minWidthDefaultValue = vehicleMinWidthDefaultValue;

    try {
      value =convertNumber(validateStringFormatNumber(e.target.value,value.toString()));
    } catch (e) {
      console.log(e);
    }

    if (value < minWidthDefaultValue) value = minWidthDefaultValue;
    if (value > vehicleMaxWidthDefaultValue)
      value = vehicleMaxWidthDefaultValue;

    setVehiclesMinWidthFilter(value);
  }, [
    vehiclesMinWidthFilter,
    vehicleMaxWidthDefaultValue,
    vehicleMinWidthDefaultValue,
  ]);

  const handleVehiclesMaxWidthFilter = useCallback((e: any) => {
    let value: number = vehiclesMaxWidthFilter;
    const maxWidthDefaultValue = vehicleMaxWidthDefaultValue;

    try {
      value =convertNumber(validateStringFormatNumber(e.target.value,value.toString()));
    } catch (e) {
      console.log(e);
    }
    if (value > maxWidthDefaultValue) value = maxWidthDefaultValue;
    if (value < vehicleMinWidthDefaultValue)
      value = vehicleMaxWidthDefaultValue;

    setVehiclesMaxWidthFilter(value);
  }, [
    vehiclesMaxWidthFilter,
    vehicleMaxWidthDefaultValue,
    vehicleMinWidthDefaultValue,
  ]);

  const handleVehiclesWidthBarFilterChange = useCallback((_: any, value: any) => {
    if (Array.isArray(value)) {
      setVehiclesMinWidthFilter(value[0]);
      setVehiclesMaxWidthFilter(value[1]);
    }
  }, []);

  const handleVehiclesMinHeightFilter = useCallback((e: any) => {
    let value: number = vehiclesMinHeightFilter;
    try {
      value =convertNumber(validateStringFormatNumber(e.target.value,value.toString()));
    } catch (e) {
      console.log(e);
    }
    setVehiclesMinHeightFilter(value);
  }, [vehiclesMinHeightFilter]);

  const handleVehiclesMaxHeightFilter = useCallback((e: any) => {
    let value: number = vehiclesMaxHeightFilter;
    try {
      value =convertNumber(validateStringFormatNumber(e.target.value,value.toString()));
    } catch (e) {
      console.log(e);
    }
    setVehiclesMaxHeightFilter(value);
  }, [vehiclesMaxHeightFilter]);

  const handleVehiclesHeightBarFilterChange = useCallback((_: any, value: any) => {
    if (Array.isArray(value)) {
      setVehiclesMinHeightFilter(value[0]);
      setVehiclesMaxHeightFilter(value[1]);
    }
  }, []);

  const handleVehiclesMinDistanceBetweenFrontAxlesFilter = useCallback((e: any) => {
    let value: number = vehiclesMinDistanceBetweenFrontAxlesFilter;
    try {
      value =convertNumber(validateStringFormatNumber(e.target.value,value.toString()));
    } catch (e) {
      console.log(e);
    }
    setVehiclesMinDistanceBetweenFrontAxlesFilter(value);
  }, [vehiclesMinDistanceBetweenFrontAxlesFilter]);

  const handleVehiclesMaxDistanceBetweenFrontAxlesFilter = useCallback((e: any) => {
    let value: number = vehiclesMaxDistanceBetweenFrontAxlesFilter;
    try {
      value =convertNumber(validateStringFormatNumber(e.target.value,value.toString()));
    } catch (e) {
      console.log(e);
    }
    setVehiclesMaxDistanceBetweenFrontAxlesFilter(value);
  }, [vehiclesMaxDistanceBetweenFrontAxlesFilter]);

  const handleVehiclesDistanceBetweenFrontAxlesBarFilterChange = useCallback((_: any, value: any) => {
    if (Array.isArray(value)) {
      setVehiclesMinDistanceBetweenFrontAxlesFilter(value[0]);
      setVehiclesMaxDistanceBetweenFrontAxlesFilter(value[1]);
    }
  }, []);

  const handleVehiclesMinDistanceBetweenRearAxlesFilter = useCallback((e: any) => {
    let value: number = vehiclesMinDistanceBetweenRearAxlesFilter;
    try {
      value =convertNumber(validateStringFormatNumber(e.target.value,value.toString()));
    } catch (e) {
      console.log(e);
    }
    setVehiclesMinDistanceBetweenRearAxlesFilter(value);
  }, [vehiclesMinDistanceBetweenRearAxlesFilter]);

  const handleVehiclesMaxDistanceBetweenRearAxlesFilter = useCallback((e: any) => {
    let value: number = vehiclesMaxDistanceBetweenRearAxlesFilter;
    try {
      value =convertNumber(validateStringFormatNumber(e.target.value,value.toString()));
    } catch (e) {
      console.log(e);
    }
    setVehiclesMaxDistanceBetweenRearAxlesFilter(value);
  }, [vehiclesMaxDistanceBetweenRearAxlesFilter]);

  const handleVehiclesDistanceVetweenAxlesBarFilterChange = useCallback((_: any, value: any) => {
    if (Array.isArray(value)) {
      setVehiclesMinDistanceBetweenRearAxlesFilter(value[0]);
      setVehiclesMaxDistanceBetweenRearAxlesFilter(value[1]);
    }
  }, []);

  const handleVehiclesMinPowerFilter = useCallback((e: any) => {
    let value: number = vehiclesMinPowerFilter;
    try {
      value =convertNumber(validateStringFormatNumber(e.target.value,value.toString()));
    } catch (e) {
      console.log(e);
    }
    setVehiclesMinPowerFilter(value);
  }, [vehiclesMinPowerFilter]);

  const handleVehiclesMaxPowerFilter = useCallback((e: any) => {
    let value: number = vehiclesMaxPowerFilter;
    try {
      value =convertNumber(validateStringFormatNumber(e.target.value,value.toString()));
    } catch (e) {
      console.log(e);
    }
    setVehiclesMaxPowerFilter(value);
  }, [vehiclesMaxPowerFilter]);

  const handleVehiclesPowerBarFilterChange = useCallback((_: any, value: any) => {
    if (Array.isArray(value)) {
      setVehiclesMinPowerFilter(value[0]);
      setVehiclesMaxPowerFilter(value[1]);
    }
  }, []);

  const handleVehiclesMinEngineCapacityFilter = useCallback((e: any) => {
    let value: number = vehiclesMinEngineCapacityFilter;
    try {
      value =convertNumber(validateStringFormatNumber(e.target.value,value.toString()));
    } catch (e) {
      console.log(e);
    }
    setVehiclesMinEngineCapacityFilter(value);
  }, [vehiclesMinEngineCapacityFilter]);

  const handleVehiclesMaxEngineCapacityFilter = useCallback((e: any) => {
    let value: number = vehiclesMaxEngineCapacityFilter;
    try {
      value =convertNumber(validateStringFormatNumber(e.target.value,value.toString()));
    } catch (e) {
      console.log(e);
    }
    setVehiclesMaxEngineCapacityFilter(value);
  }, [vehiclesMaxEngineCapacityFilter]);

  const handleVehiclesEngineCapacityBarFilterChange = useCallback((_: any, value: any) => {
    if (Array.isArray(value)) {
      setVehiclesMinEngineCapacityFilter(value[0]);
      setVehiclesMaxEngineCapacityFilter(value[1]);
    }
  }, []);

  const handleVehiclesMinMaxTorqueFilter = useCallback((e: any) => {
    let value: number = vehiclesMinMaxTorqueFilter;
    try {
      value =convertNumber(validateStringFormatNumber(e.target.value,value.toString()));
    } catch (e) {
      console.log(e);
    }
    setVehiclesMinMaxTorqueFilter(value);
  }, [vehiclesMinMaxTorqueFilter]);

  const handleVehiclesMaxMaxTorqueFilter = useCallback((e: any) => {
    let value: number = vehiclesMaxMaxTorqueFilter;
    try {
      value =convertNumber(validateStringFormatNumber(e.target.value,value.toString()));
    } catch (e) {
      console.log(e);
    }
    setVehiclesMaxMaxTorqueFilter(value);
  }, [vehiclesMaxMaxTorqueFilter]);

  const handleVehiclesTorqueBarFilterChange = useCallback((_: any, value: any) => {
    if (Array.isArray(value)) {
      setVehiclesMinMaxTorqueFilter(value[0]);
      setVehiclesMaxMaxTorqueFilter(value[1]);
    }
  }, []);

  const handleVehiclesMinCO2EmissionFilter = useCallback((e: any) => {
    let value: number = vehiclesMinCO2EmissionFilter;
    try {
      value =convertNumber(validateStringFormatNumber(e.target.value,value.toString()));
    } catch (e) {
      console.log(e);
    }
    setVehiclesMinCO2EmissionFilter(value);
  }, [vehiclesMinCO2EmissionFilter]);

  const handleVehiclesMaxCO2EmissionFilter = useCallback((e: any) => {
    let value: number = vehiclesMaxCO2EmissionFilter;
    try {
      value =convertNumber(validateStringFormatNumber(e.target.value,value.toString()));
    } catch (e) {
      console.log(e);
    }
    setVehiclesMaxCO2EmissionFilter(value);
  }, [vehiclesMaxCO2EmissionFilter]);

  const handleVehiclesCO2EmissionbarFitlerChange = useCallback((_: any, value: any) => {
    if (Array.isArray(value)) {
      setVehiclesMinCO2EmissionFilter(value[0]);
      setVehiclesMaxCO2EmissionFilter(value[1]);
    }
  }, []);

  const handleAdvFilterSetSelectedChange = useCallback(
    (index: number) => () => {
      if (filterResults !== undefined && filterResults.length > 0) {
        filterResults[index].checkboxProps.checked =
          !filterResults[index].checkboxProps.checked;
        setFilterResults((fr) => fr?.map((result) => result));
      }
    },
    [filterResults]
  );

  const handleAdvFilterSetBatchChange = useCallback(
    (index: number) =>
      (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        //const filterResults1 = filterResults;
        if (filterResults !== undefined && filterResults.length > 0) {
          filterResults[index].associateLotTextFieldProps.value =
            e.target.value;
          setFilterResults((fr) => fr?.map((result) => result));
        }
      },
    [filterResults]
  );
  
  const advSearch = useCallback((validateData: boolean) => {
    let displayableFilters: any = [];
    let dataFilters: {
      type: AdvancedFilterVersionsOptions;
      value: string;
    }[] = [];
    
    advFilterTypeStatusOptions.filter((x) => x.checked === true).forEach((x) => {
      displayableFilters.push({
        label: x.label,
        id:
          "type:" + AdvancedFilterVersionsOptions.VehicleType +
          ";" + x.value,
      });
      dataFilters.push({
        type: AdvancedFilterVersionsOptions.VehicleType,
        value: (x.value as number).toString(),
      });
    });

    advFilterSeats.filter((x) => x.checked === true).forEach((x) => {
      const values = x.label ? x.label.split(" "): "";
      displayableFilters.push({
        label: x.label,
        id: "type:" + AdvancedFilterVersionsOptions.Seats + ";" + values[0],
      });
      dataFilters.push({
        type: AdvancedFilterVersionsOptions.Seats,
        value: values[0],
      });
    });

    advFilterFuelTypeOptions.filter((x) => x.checked === true).forEach((x) => {
      displayableFilters.push({
        label: x.label,
        id: "type:" + AdvancedFilterVersionsOptions.FuelType + ";" + x.id,
      });
      dataFilters.push({
        type: AdvancedFilterVersionsOptions.FuelType,
        value: x.id ? x.id : "",
      });
    });

    advFilterSegmentsOptions
      .filter((x: { checkBoxProps:{checked: boolean} }) => x.checkBoxProps.checked === true)
      .forEach((x: { label: string; value: string }) => {
        displayableFilters.push({
          label: x.label,
          id: "type:" + AdvancedFilterVersionsOptions.Segment + ";" + x.value,
        });
        dataFilters.push({
          type: AdvancedFilterVersionsOptions.Segment,
          value: x.value,
        });
      });

    if (
      vehiclesMinLengthFilter >= vehicleMinLengthDefaultValue ||
      vehiclesMaxLengthFilter <= vehicleMaxLengthDefaultValue
    ) {
      displayableFilters.push({
        label: `Comprimento entre ${vehiclesMinLengthFilter} e ${vehiclesMaxLengthFilter} (mm)`,
        id: `type:${AdvancedFilterVersionsOptions.Length}; ${vehiclesMinLengthFilter}-${vehiclesMaxLengthFilter}`,
      });
    }
    dataFilters.push({
      type: AdvancedFilterVersionsOptions.Length,
      value: vehiclesMinLengthFilter + "-" + vehiclesMaxLengthFilter,
    });

    if (
      vehiclesMinWidthFilter >= vehicleMinWidthDefaultValue ||
      vehiclesMaxWidthFilter <= vehicleMaxWidthDefaultValue
    ) {
      displayableFilters.push({
        label: `Largura entre ${vehiclesMinWidthFilter} e ${vehiclesMaxWidthFilter} (mm)`,
        id: `type:${AdvancedFilterVersionsOptions.Width};${vehiclesMinWidthFilter}-${vehiclesMaxWidthFilter}`,
      });
    }
    dataFilters.push({
      type: AdvancedFilterVersionsOptions.Width,
      value: vehiclesMinWidthFilter + "-" + vehiclesMaxWidthFilter,
    });

    if (
      vehiclesMinHeightFilter >= vehicleMinHeightDefaultValue ||
      vehiclesMaxHeightFilter <= vehicleMaxHeightDefaultValue
    ) {
      displayableFilters.push({
        label: `Altura entre ${vehiclesMinHeightFilter} e ${vehiclesMaxHeightFilter} (mm)`,
        id: `type:${AdvancedFilterVersionsOptions.Height};${vehiclesMinHeightFilter}-${vehiclesMaxHeightFilter}`,
      });
    }
    dataFilters.push({
      type: AdvancedFilterVersionsOptions.Height,
      value: vehiclesMinHeightFilter + "-" + vehiclesMaxHeightFilter,
    });

    if (
      vehiclesMinGrossWeightFilter >= vehicleMinGrossWeightDefaultValue ||
      vehiclesMaxGrossWeightFilter <= vehicleMaxGrossWeightDefaultValue
    ) {
      displayableFilters.push({
        label: `Peso bruto entre ${vehiclesMinGrossWeightFilter} e ${vehiclesMaxGrossWeightFilter} (KG)`,
        id: `type: ${AdvancedFilterVersionsOptions.GrossWeight};{$vehiclesMinGrossWeightFilter}-${vehiclesMaxGrossWeightFilter}`,
      });
    }
    dataFilters.push({
      type: AdvancedFilterVersionsOptions.GrossWeight,
      value:
        vehiclesMinGrossWeightFilter + "-" + vehiclesMaxGrossWeightFilter,
    });

    if (
      vehiclesMinMaxSuitcaseVolumeFilter >= vehicleMinMaxSuitcaseVolumeDefaultValue ||
      vehiclesMaxMaxSuitcaseVolumeFilter <= vehicleMaxMaxSuitcaseVolumeDefaultValue
    ) {
      displayableFilters.push({
        label:
          "Volume máx. mala entre " +
          vehiclesMinMaxSuitcaseVolumeFilter +
          " e " +
          vehiclesMaxMaxSuitcaseVolumeFilter +
          " (L)",
        id:
          "type:" +
          AdvancedFilterVersionsOptions.MaxSuitcaseVolume +
          ";" +
          vehiclesMinMaxSuitcaseVolumeFilter +
          "-" +
          vehiclesMaxMaxSuitcaseVolumeFilter,
      });
    }
    dataFilters.push({
      type: AdvancedFilterVersionsOptions.MaxSuitcaseVolume,
      value:
        vehiclesMinMaxSuitcaseVolumeFilter +
        "-" +
        vehiclesMaxMaxSuitcaseVolumeFilter,
    });

    if (
      vehiclesMinDistanceBetweenFrontAxlesFilter >= vehicleMinDistanceBetweenFrontAxlesDefaultValue ||
      vehiclesMaxDistanceBetweenFrontAxlesFilter <= vehicleMaxDistanceBetweenFrontAxlesDefaultValue
    ) {
      displayableFilters.push({
        label:
          "Distancia eixos dianteiros entre " +
          vehiclesMinDistanceBetweenFrontAxlesFilter +
          " e " +
          vehiclesMaxDistanceBetweenFrontAxlesFilter +
          " (mm)",
        id:
          "type:" +
          AdvancedFilterVersionsOptions.DistanceBetweenFrontAxles +
          ";" +
          vehiclesMinDistanceBetweenFrontAxlesFilter +
          "-" +
          vehiclesMaxDistanceBetweenFrontAxlesFilter,
      });
    }
    dataFilters.push({
      type: AdvancedFilterVersionsOptions.DistanceBetweenFrontAxles,
      value:
        vehiclesMinDistanceBetweenFrontAxlesFilter +
        "-" +
        vehiclesMaxDistanceBetweenFrontAxlesFilter,
    });

    if (
      vehiclesMinDistanceBetweenRearAxlesFilter >= vehicleMinDistanceBetweenRearAxlesDefaultValue ||
      vehiclesMaxDistanceBetweenRearAxlesFilter <= vehicleMaxDistanceBetweenRearAxlesDefaultValue
    ) {
      displayableFilters.push({
        label:
          "Distancia eixos traseiros entre " +
          vehiclesMinDistanceBetweenRearAxlesFilter +
          " e " +
          vehiclesMaxDistanceBetweenRearAxlesFilter +
          " (mm)",
        id:
          "type:" +
          AdvancedFilterVersionsOptions.DistanceBetweenRearAxles +
          ";" +
          vehiclesMinDistanceBetweenRearAxlesFilter +
          "-" +
          vehiclesMaxDistanceBetweenRearAxlesFilter,
      });
    }
    dataFilters.push({
      type: AdvancedFilterVersionsOptions.DistanceBetweenRearAxles,
      value: `${vehiclesMinDistanceBetweenRearAxlesFilter}-${vehiclesMaxDistanceBetweenRearAxlesFilter}`,
    });

    if (
      vehiclesMinPowerFilter >= vehicleMinPowerDefaultValue || 
      vehiclesMaxPowerFilter <= vehicleMaxPowerDefaultValue 
    ) {
      displayableFilters.push({
        label:
          "Potência do motor entre " +
          vehiclesMinPowerFilter +
          " e " +
          vehiclesMaxPowerFilter +
          " (CV)",
        id:
          "type:" +
          AdvancedFilterVersionsOptions.Power +
          ";" +
          vehiclesMinPowerFilter +
          "-" +
          vehiclesMaxPowerFilter,
      });
    }
    dataFilters.push({
      type: AdvancedFilterVersionsOptions.Power,
      value: vehiclesMinPowerFilter + "-" + vehiclesMaxPowerFilter,
    });
    
    if (
      vehiclesMinEngineCapacityFilter >= vehicleMinEngineCapacityDefaultValue ||
      vehiclesMaxEngineCapacityFilter <= vehicleMaxEngineCapacityDefaultValue
    ) {
      displayableFilters.push({
        label:
          "Cilindrada motor entre " +
          vehiclesMinEngineCapacityFilter +
          " e " +
          vehiclesMaxEngineCapacityFilter +
          " (CC)",
        id:
          "type:" +
          AdvancedFilterVersionsOptions.EngineCapacity +
          ";" +
          vehiclesMinEngineCapacityFilter +
          "-" +
          vehiclesMaxEngineCapacityFilter,
      });
    }
    dataFilters.push({
      type: AdvancedFilterVersionsOptions.EngineCapacity,
      value: vehiclesMinEngineCapacityFilter + "-" + vehiclesMaxEngineCapacityFilter,
    });

    if (
      vehiclesMinMaxTorqueFilter >= vehicleMinMaxTorqueDefaultValue ||
      vehiclesMaxMaxTorqueFilter <= vehicleMaxMaxTorqueDefaultValue
    ) {
      displayableFilters.push({
        label:
          "Binário máximo entre " +
          vehiclesMinMaxTorqueFilter +
          " e " +
          vehiclesMaxMaxTorqueFilter,
        id:
          "type:" +
          AdvancedFilterVersionsOptions.MaxTorque +
          ";" +
          vehiclesMinMaxTorqueFilter +
          "-" +
          vehiclesMaxMaxTorqueFilter,
      });
    }
    dataFilters.push({
      type: AdvancedFilterVersionsOptions.MaxTorque,
      value: vehiclesMinMaxTorqueFilter + "-" + vehiclesMaxMaxTorqueFilter,
    });

    if (
      vehiclesMinCO2EmissionFilter >= vehicleMinCO2EmissionDefaultValue ||
      vehiclesMaxCO2EmissionFilter <= vehicleMaxCO2EmissionDefaultValue
    ) {
      displayableFilters.push({
        label:
          "CO2 Emissão entre " +
          vehiclesMinCO2EmissionFilter +
          " e " +
          vehiclesMaxCO2EmissionFilter +
          " Grama/Kg",
        id:
          "type:" +
          AdvancedFilterVersionsOptions.CO2Emission +
          ";" +
          vehiclesMinCO2EmissionFilter +
          "-" +
          vehiclesMaxCO2EmissionFilter,
      });
    }
    dataFilters.push({
      type: AdvancedFilterVersionsOptions.CO2Emission,
      value:
        vehiclesMinCO2EmissionFilter + "-" + vehiclesMaxCO2EmissionFilter,
    });

    brandsSectionProps.forEach(
      (brand: {
        models: {
          checkBoxProps: { checked: boolean };
          label: string;
          id: string;
        }[];
        label: string;
      }) => {
        brand.models.forEach(
          (model: {
            id: string;
            label: string;
            checkBoxProps: { checked: boolean };
          }) => {
            if (model.checkBoxProps.checked) {
              displayableFilters.push({
                label: brand.label + ": " + model.label,
                id: `type:${AdvancedFilterVersionsOptions.Model};${model.id}`,
              });
              dataFilters.push({
                type: AdvancedFilterVersionsOptions.Model,
                value: model.id,
              });
            }
          }
        );
      }
    );

    if (displayableFilters.length > 0) {
      setAppliedFilters(displayableFilters);
      setFilterLoading(true);
      setActionFilter(true);

      const normalCancelToken = axios.CancelToken.source();
      vehicleService.getVersions(dataFilters, normalCancelToken).then((data) => {
      // vehiclesService.getVersions(dataFilters, cancelToken).then((data) => {
        if (!data.succeeded) {
          openAlert({
            variant: "error",
            text: data.message,
            title: "Atenção",
          });
          setFilterLoading(false);
          setActionFilter(false);
        } else {
          setFilterLoading(false);
          if (data.value.length > 0) {
            var items = data.value.map(
              (
                item: {
                  no: string;
                  make: string;
                  modelNo: string;
                  versionId: string;
                  description: string;
                  caracteristics: any;
                  priceexclVAT: string;
                  fuelType: string;
                  status: boolean;
                },
                index: number
              ) => {
                const fuelIcon = VehicleUtilities.getFuelTypeIconFor(
                  item.fuelType?.toLowerCase()
                );
                return {
                  versionDetails: item,
                  name: item.make + " " + item.description,
                  makeId: item.make,
                  modelId:item.modelNo,
                  versionId: item.no, //item.versionId,
                  characteristics: item.caracteristics, //TODO: avaliar criar do lado do interface devido ao multilingue
                  fuelIcon: fuelIcon,
                  businessPrice: {
                    label: "Preço de negócio",
                    value: (item.priceexclVAT ? item.priceexclVAT : 0) + " €",
                  },
                  checkboxProps: {
                    onChange: handleAdvFilterSetSelectedChange(index),
                  },
                  associateLotTextFieldProps: {
                    label: "Associar lote",
                    onChange: handleAdvFilterSetBatchChange(index),
                  },
                  isInactive: item.status
                };
              }
            );
            setFilterResults((rows: any) => {
              rows = items
              return rows;
            });
            setAdvFilterResultsNumber(items.length || 0);
          }
          else {
            setAdvFilterResultsNumber(0);
          }
        }
      }).finally(() => {
        // Call the cancelTimeout function to cancel the timeout if the request completed before the timeout expired
        vehicleService.cancelRequest("Pedido excedeu o tempo limite.", normalCancelToken);
        // vehiclesService.cancelRequest("Pedido excedeu o tempo limite.", cancelToken); 
        // cancelToken = axios.CancelToken.source()
      });
    } else {
      if (validateData) {
        openAlert({
          variant: "error",
          text: "Por favor selecione um ou mais critérios de pesquisa.",
          title: "Atenção",
        });
      }
    }
  },[advFilterTypeStatusOptions, advFilterSeats, advFilterFuelTypeOptions, advFilterSegmentsOptions, vehiclesMinLengthFilter, vehicleMinLengthDefaultValue, vehiclesMaxLengthFilter, vehicleMaxLengthDefaultValue, vehiclesMinWidthFilter, vehicleMinWidthDefaultValue, vehiclesMaxWidthFilter, vehicleMaxWidthDefaultValue, vehiclesMinHeightFilter, vehicleMinHeightDefaultValue, vehiclesMaxHeightFilter, vehicleMaxHeightDefaultValue, vehiclesMinGrossWeightFilter, vehicleMinGrossWeightDefaultValue, vehiclesMaxGrossWeightFilter, vehicleMaxGrossWeightDefaultValue, vehiclesMinMaxSuitcaseVolumeFilter, vehicleMinMaxSuitcaseVolumeDefaultValue, vehiclesMaxMaxSuitcaseVolumeFilter, vehicleMaxMaxSuitcaseVolumeDefaultValue, vehiclesMinDistanceBetweenFrontAxlesFilter, vehicleMinDistanceBetweenFrontAxlesDefaultValue, vehiclesMaxDistanceBetweenFrontAxlesFilter, vehicleMaxDistanceBetweenFrontAxlesDefaultValue, vehiclesMinDistanceBetweenRearAxlesFilter, vehicleMinDistanceBetweenRearAxlesDefaultValue, vehiclesMaxDistanceBetweenRearAxlesFilter, vehicleMaxDistanceBetweenRearAxlesDefaultValue, vehiclesMinPowerFilter, vehicleMinPowerDefaultValue, vehiclesMaxPowerFilter, vehicleMaxPowerDefaultValue, vehiclesMinEngineCapacityFilter, vehicleMinEngineCapacityDefaultValue, vehiclesMaxEngineCapacityFilter, vehicleMaxEngineCapacityDefaultValue, vehiclesMinMaxTorqueFilter, vehicleMinMaxTorqueDefaultValue, vehiclesMaxMaxTorqueFilter, vehicleMaxMaxTorqueDefaultValue, vehiclesMinCO2EmissionFilter, vehicleMinCO2EmissionDefaultValue, vehiclesMaxCO2EmissionFilter, vehicleMaxCO2EmissionDefaultValue, brandsSectionProps, vehicleService, openAlert, handleAdvFilterSetSelectedChange, handleAdvFilterSetBatchChange]);

  const handleRemoveAppliedFilter = useCallback(
    (index: number, executeSearch: boolean, filters:any[]) => {
      const removedFilter = filters.filter((_, i) => i === index);
      const filterCriteria = removedFilter[0].id.split(";");
      const filterTypeElements = filterCriteria[0].split(":");
      const filterTypeValue = parseInt(filterTypeElements[1]);
      const filterType: AdvancedFilterVersionsOptions = filterTypeValue as AdvancedFilterVersionsOptions;

      switch (filterType) {
        case AdvancedFilterVersionsOptions.CO2Emission:
          setVehiclesMinCO2EmissionFilter(vehicleMinCO2EmissionDefaultValue);
          setVehiclesMaxCO2EmissionFilter(vehicleMaxCO2EmissionDefaultValue);
          break;
        case AdvancedFilterVersionsOptions.EngineCapacity:
          setVehiclesMinEngineCapacityFilter(vehicleMinEngineCapacityDefaultValue);
          setVehiclesMaxEngineCapacityFilter(vehicleMaxEngineCapacityDefaultValue);
          break;
        case AdvancedFilterVersionsOptions.GrossWeight:
          setVehiclesMinGrossWeightFilter(vehicleMinGrossWeightDefaultValue);
          setVehiclesMaxGrossWeightFilter(vehicleMaxGrossWeightDefaultValue);
          break;
        case AdvancedFilterVersionsOptions.Height:
          setVehiclesMinHeightFilter(vehicleMinHeightDefaultValue);
          setVehiclesMaxHeightFilter(vehicleMaxHeightDefaultValue);
          break;
        case AdvancedFilterVersionsOptions.Length:
          setVehiclesMinLengthFilter(vehicleMinLengthDefaultValue);
          setVehiclesMaxLengthFilter(vehicleMaxLengthDefaultValue);
          break;
        case AdvancedFilterVersionsOptions.DistanceBetweenFrontAxles:
          setVehiclesMinDistanceBetweenFrontAxlesFilter(vehicleMinDistanceBetweenFrontAxlesDefaultValue);
          setVehiclesMaxDistanceBetweenFrontAxlesFilter(vehicleMaxDistanceBetweenFrontAxlesDefaultValue);
          break;
        case AdvancedFilterVersionsOptions.DistanceBetweenRearAxles:
          setVehiclesMinDistanceBetweenRearAxlesFilter(vehicleMinDistanceBetweenRearAxlesDefaultValue);
          setVehiclesMaxDistanceBetweenRearAxlesFilter(vehicleMaxDistanceBetweenRearAxlesDefaultValue);
          break;
        case AdvancedFilterVersionsOptions.MaxSuitcaseVolume:
          setVehiclesMinMaxSuitcaseVolumeFilter(vehicleMinMaxSuitcaseVolumeDefaultValue);
          setVehiclesMaxMaxSuitcaseVolumeFilter(vehicleMaxMaxSuitcaseVolumeDefaultValue);
          break;
        case AdvancedFilterVersionsOptions.MaxTorque:
          setVehiclesMinMaxTorqueFilter(vehicleMinMaxTorqueDefaultValue);
          setVehiclesMaxMaxTorqueFilter(vehicleMaxMaxTorqueDefaultValue);
          break;
        case AdvancedFilterVersionsOptions.Power:
          setVehiclesMinPowerFilter(vehicleMinPowerDefaultValue);
          setVehiclesMaxPowerFilter(vehicleMaxPowerDefaultValue);
          break;
        case AdvancedFilterVersionsOptions.Width:
          setVehiclesMinWidthFilter(vehicleMinWidthDefaultValue);
          setVehiclesMaxWidthFilter(vehicleMaxWidthDefaultValue);
          break;

        // TIPO DE COMBUSTÍVEIS
        case AdvancedFilterVersionsOptions.FuelType:
          let fuelTypeValue = filterCriteria[1];
          setAdvFilterFuelTypeOptions((options)=> {
            return options.map((fuelTypeOption) => {
              if(fuelTypeOption.id === fuelTypeValue){
                fuelTypeOption.checked = false;
              }
              return fuelTypeOption;
            })
          });
          break;

        //FIXME Alexandre: VALIDAR se são precisos estes dois cases
        // ESTADO DAS VIATURAS
        case AdvancedFilterVersionsOptions.VehicleType:
          let typeStatusValue = -1;
          try {
            typeStatusValue = parseInt(filterCriteria[1]);
          } catch (ex) {
            console.log("Unable to parse type status value");
          }
          setAdvFilterTypeStatusOptions(options => {
            return options.map((typeStatusOption) => {
              if(typeStatusOption.value === typeStatusValue) {
                typeStatusOption.checked = false;
              }
              return typeStatusOption;
            })
          })
          break;

        // QUANTIDADES DE LUGARES
        case AdvancedFilterVersionsOptions.Seats:
          setAdvFilterSeats(options => {
            if(options && options.length > 0){
              return options.map((seatStatusOption) => {
                if(seatStatusOption.label?.split(" ")[0] === filterCriteria[1]){
                  seatStatusOption.checked = false;
                }
                return seatStatusOption;
              })
            } else {
              return []
            }
          });
          break;

        // TIPO DE VIATURAS 
        case AdvancedFilterVersionsOptions.Segment:
          for (let i = 0; i < advFilterSegmentsOptions.length; i++) {
            if (advFilterSegmentsOptions[i].value === filterCriteria[1]) {
              advFilterSegmentsOptions[i].checkBoxProps.checked = false;
              break;
            }
          }
          setAdvFilterSegmentsOptions(advFilterSegmentsOptions);
          break;

        // MARCAS E MODELOS DE VIATURAS
        case AdvancedFilterVersionsOptions.Model:
          let brands = brandsSectionProps.map(
            (brand: {
              [x: string]: any;
              label: any;
              open: boolean;
              checkBoxProps: CheckboxProps;
              models: {
                [x: string]: any;
                id: string;
                label: string;
                checkBoxProps: CheckboxProps;
              }[];
            }) => {
              brand.models.forEach((model: any) => {
                brand.hidden = false;
                brand.checkBoxProps.checked = false;
                brand.models.map(
                  (model: {
                    id: string;
                    label: string;
                    checkBoxProps: CheckboxProps;
                  }) => {
                    if (model.id === filterCriteria[1]) {
                      model.checkBoxProps.checked = false;
                    }
                    return model;
                  }
                );
              });
              return brand;
            }
          );
          setBrandsSectionProps(brands);
          break;
        default:
          break;
      }
      setAppliedFilters((currentFilters) =>
        currentFilters.filter((_, i) => i !== index)
      );
      if (executeSearch) advSearch(false);
    },
    [ 
      // advFilterFuelTypeOptions,
      // advFilterSeats,
      // advFilterTypeStatusOptions,
      advFilterSegmentsOptions,
      advSearch,
      brandsSectionProps,

      // default values
      vehicleMaxCO2EmissionDefaultValue,
      vehicleMaxDistanceBetweenFrontAxlesDefaultValue,
      vehicleMaxDistanceBetweenRearAxlesDefaultValue,
      vehicleMaxEngineCapacityDefaultValue,
      vehicleMaxGrossWeightDefaultValue,
      vehicleMaxHeightDefaultValue,
      vehicleMaxLengthDefaultValue,
      vehicleMaxMaxSuitcaseVolumeDefaultValue,
      vehicleMaxMaxTorqueDefaultValue,
      vehicleMaxPowerDefaultValue,
      vehicleMaxWidthDefaultValue,
      vehicleMinCO2EmissionDefaultValue,
      vehicleMinDistanceBetweenFrontAxlesDefaultValue,
      vehicleMinDistanceBetweenRearAxlesDefaultValue,
      vehicleMinEngineCapacityDefaultValue,
      vehicleMinGrossWeightDefaultValue,
      vehicleMinHeightDefaultValue,
      vehicleMinLengthDefaultValue,
      vehicleMinMaxSuitcaseVolumeDefaultValue,
      vehicleMinMaxTorqueDefaultValue,
      vehicleMinPowerDefaultValue,
      vehicleMinWidthDefaultValue,
    ]
  );

  const advSearchDefault = useCallback((): any[] => {
    let displayableFilters: any = [];
    let dataFilters: {
      type: AdvancedFilterVersionsOptions;
      value: string;
    }[] = [];

    advFilterTypeStatusOptions.filter((x) => x.checked === true).forEach((x) => {
      displayableFilters.push({
        label: x.label,
        id:
          "type:" + AdvancedFilterVersionsOptions.VehicleType +
          ";" + x.value,
      });
      dataFilters.push({
        type: AdvancedFilterVersionsOptions.VehicleType,
        value: typeof x.value === 'string' ? x.value : "",
      });
    });

    advFilterSeats.filter((x) => x.checked === true).forEach((x) => {
      const values = x.label ? x.label.split(" "): "";
      displayableFilters.push({
        label: x.label,
        id: "type:" + AdvancedFilterVersionsOptions.Seats + ";" + values[0],
      });
      dataFilters.push({
        type: AdvancedFilterVersionsOptions.Seats,
        value: values[0],
      });
    });
    
    advFilterFuelTypeOptions.filter((x) => x.checked === true).forEach((x) => {
      displayableFilters.push({
        label: x.label,
        id: "type:" + AdvancedFilterVersionsOptions.FuelType + ";" + x.id,
      });
      dataFilters.push({
        type: AdvancedFilterVersionsOptions.FuelType,
        value: x.id ? x.id : "",
      });
    });

    advFilterSegmentsOptions
      .filter((x: { checkBoxProps:{checked: boolean} }) => x.checkBoxProps.checked === true)
      .forEach((x: { label: string; value: string }) => {
        displayableFilters.push({
          label: x.label,
          id: "type:" + AdvancedFilterVersionsOptions.Segment + ";" + x.value,
        });
        dataFilters.push({
          type: AdvancedFilterVersionsOptions.Segment,
          value: x.value,
        });
      });

    if (
      vehiclesMinLengthFilter >= vehicleMinLengthDefaultValue ||
      vehiclesMaxLengthFilter <= vehicleMaxLengthDefaultValue
    ) {
      displayableFilters.push({
        label: `Comprimento entre ${vehiclesMinLengthFilter} e ${vehiclesMaxLengthFilter} (mm)`,
        id: `type:${AdvancedFilterVersionsOptions.Length}; ${vehiclesMinLengthFilter}-${vehiclesMaxLengthFilter}`,
      });
    }
    dataFilters.push({
      type: AdvancedFilterVersionsOptions.Length,
      value: vehiclesMinLengthFilter + "-" + vehiclesMaxLengthFilter,
    });

    if (
      vehiclesMinWidthFilter >= vehicleMinWidthDefaultValue ||
      vehiclesMaxWidthFilter <= vehicleMaxWidthDefaultValue
    ) {
      displayableFilters.push({
        label: `Largura entre ${vehiclesMinWidthFilter} e ${vehiclesMaxWidthFilter} (mm)`,
        id: `type:${AdvancedFilterVersionsOptions.Width};${vehiclesMinWidthFilter}-${vehiclesMaxWidthFilter}`,
      });
    }
    dataFilters.push({
      type: AdvancedFilterVersionsOptions.Width,
      value: vehiclesMinWidthFilter + "-" + vehiclesMaxWidthFilter,
    });

    if (
      vehiclesMinHeightFilter >= vehicleMinHeightDefaultValue ||
      vehiclesMaxHeightFilter <= vehicleMaxHeightDefaultValue
    ) {
      displayableFilters.push({
        label: `Altura entre ${vehiclesMinHeightFilter} e ${vehiclesMaxHeightFilter} (mm)`,
        id: `type:${AdvancedFilterVersionsOptions.Height};${vehiclesMinHeightFilter}-${vehiclesMaxHeightFilter}`,
      });
    }
    dataFilters.push({
      type: AdvancedFilterVersionsOptions.Height,
      value: vehiclesMinHeightFilter + "-" + vehiclesMaxHeightFilter,
    });

    if (
      vehiclesMinGrossWeightFilter >= vehicleMinGrossWeightDefaultValue ||
      vehiclesMaxGrossWeightFilter <= vehicleMaxGrossWeightDefaultValue
    ) {
      displayableFilters.push({
        label: `Peso bruto entre ${vehiclesMinGrossWeightFilter} e ${vehiclesMaxGrossWeightFilter} (KG)`,
        id: `type: ${AdvancedFilterVersionsOptions.GrossWeight};{$vehiclesMinGrossWeightFilter}-${vehiclesMaxGrossWeightFilter}`,
      });
    }
    dataFilters.push({
      type: AdvancedFilterVersionsOptions.GrossWeight,
      value:
        vehiclesMinGrossWeightFilter + "-" + vehiclesMaxGrossWeightFilter,
    });

    if (
      vehiclesMinMaxSuitcaseVolumeFilter >= vehicleMinMaxSuitcaseVolumeDefaultValue ||
      vehiclesMaxMaxSuitcaseVolumeFilter <= vehicleMaxMaxSuitcaseVolumeDefaultValue
    ) {
      displayableFilters.push({
        label:
          "Volume máx. mala entre " +
          vehiclesMinMaxSuitcaseVolumeFilter +
          " e " +
          vehiclesMaxMaxSuitcaseVolumeFilter +
          " (L)",
        id:
          "type:" +
          AdvancedFilterVersionsOptions.MaxSuitcaseVolume +
          ";" +
          vehiclesMinMaxSuitcaseVolumeFilter +
          "-" +
          vehiclesMaxMaxSuitcaseVolumeFilter,
      });
    }
    dataFilters.push({
      type: AdvancedFilterVersionsOptions.MaxSuitcaseVolume,
      value:
        vehiclesMinMaxSuitcaseVolumeFilter +
        "-" +
        vehiclesMaxMaxSuitcaseVolumeFilter,
    });

    if (
      vehiclesMinDistanceBetweenFrontAxlesFilter >= vehicleMinDistanceBetweenFrontAxlesDefaultValue ||
      vehiclesMaxDistanceBetweenFrontAxlesFilter <= vehicleMaxDistanceBetweenFrontAxlesDefaultValue
    ) {
      displayableFilters.push({
        label:
          "Distancia eixos dianteiros entre " +
          vehiclesMinDistanceBetweenFrontAxlesFilter +
          " e " +
          vehiclesMaxDistanceBetweenFrontAxlesFilter +
          " (mm)",
        id:
          "type:" +
          AdvancedFilterVersionsOptions.DistanceBetweenFrontAxles +
          ";" +
          vehiclesMinDistanceBetweenFrontAxlesFilter +
          "-" +
          vehiclesMaxDistanceBetweenFrontAxlesFilter,
      });
    }
    dataFilters.push({
      type: AdvancedFilterVersionsOptions.DistanceBetweenFrontAxles,
      value:
        vehiclesMinDistanceBetweenFrontAxlesFilter +
        "-" +
        vehiclesMaxDistanceBetweenFrontAxlesFilter,
    });

    if (
      vehiclesMinDistanceBetweenRearAxlesFilter >= vehicleMinDistanceBetweenRearAxlesDefaultValue ||
      vehiclesMaxDistanceBetweenRearAxlesFilter <= vehicleMaxDistanceBetweenRearAxlesDefaultValue
    ) {
      displayableFilters.push({
        label:
          "Distancia eixos traseiros entre " +
          vehiclesMinDistanceBetweenRearAxlesFilter +
          " e " +
          vehiclesMaxDistanceBetweenRearAxlesFilter +
          " (mm)",
        id:
          "type:" +
          AdvancedFilterVersionsOptions.DistanceBetweenRearAxles +
          ";" +
          vehiclesMinDistanceBetweenRearAxlesFilter +
          "-" +
          vehiclesMaxDistanceBetweenRearAxlesFilter,
      });
    }
    dataFilters.push({
      type: AdvancedFilterVersionsOptions.DistanceBetweenRearAxles,
      value: `${vehiclesMinDistanceBetweenRearAxlesFilter}-${vehiclesMaxDistanceBetweenRearAxlesFilter}`,
    });

    if (
      vehiclesMinPowerFilter >= vehicleMinPowerDefaultValue || 
      vehiclesMaxPowerFilter <= vehicleMaxPowerDefaultValue 
    ) {
      displayableFilters.push({
        label:
          "Potência do motor entre " +
          vehiclesMinPowerFilter +
          " e " +
          vehiclesMaxPowerFilter +
          " (CV)",
        id:
          "type:" +
          AdvancedFilterVersionsOptions.Power +
          ";" +
          vehiclesMinPowerFilter +
          "-" +
          vehiclesMaxPowerFilter,
      });
    }
    dataFilters.push({
      type: AdvancedFilterVersionsOptions.Power,
      value: vehiclesMinPowerFilter + "-" + vehiclesMaxPowerFilter,
    });
    
    if (
      vehiclesMinEngineCapacityFilter >= vehicleMinEngineCapacityDefaultValue ||
      vehiclesMaxEngineCapacityFilter <= vehicleMaxEngineCapacityDefaultValue
    ) {
      displayableFilters.push({
        label:
          "Cilindrada motor entre " +
          vehiclesMinEngineCapacityFilter +
          " e " +
          vehiclesMaxEngineCapacityFilter +
          " (CC)",
        id:
          "type:" +
          AdvancedFilterVersionsOptions.EngineCapacity +
          ";" +
          vehiclesMinEngineCapacityFilter +
          "-" +
          vehiclesMaxEngineCapacityFilter,
      });
    }
    dataFilters.push({
      type: AdvancedFilterVersionsOptions.EngineCapacity,
      value: vehiclesMinEngineCapacityFilter + "-" + vehiclesMaxEngineCapacityFilter,
    });

    if (
      vehiclesMinMaxTorqueFilter >= vehicleMinMaxTorqueDefaultValue ||
      vehiclesMaxMaxTorqueFilter <= vehicleMaxMaxTorqueDefaultValue
    ) {
      displayableFilters.push({
        label:
          "Binário máximo entre " +
          vehiclesMinMaxTorqueFilter +
          " e " +
          vehiclesMaxMaxTorqueFilter,
        id:
          "type:" +
          AdvancedFilterVersionsOptions.MaxTorque +
          ";" +
          vehiclesMinMaxTorqueFilter +
          "-" +
          vehiclesMaxMaxTorqueFilter,
      });
    }
    dataFilters.push({
      type: AdvancedFilterVersionsOptions.MaxTorque,
      value: vehiclesMinMaxTorqueFilter + "-" + vehiclesMaxMaxTorqueFilter,
    });

    if (
      vehiclesMinCO2EmissionFilter >= vehicleMinCO2EmissionDefaultValue ||
      vehiclesMaxCO2EmissionFilter <= vehicleMaxCO2EmissionDefaultValue
    ) {
      displayableFilters.push({
        label:
          "CO2 Emissão entre " +
          vehiclesMinCO2EmissionFilter +
          " e " +
          vehiclesMaxCO2EmissionFilter +
          " Grama/Kg",
        id:
          "type:" +
          AdvancedFilterVersionsOptions.CO2Emission +
          ";" +
          vehiclesMinCO2EmissionFilter +
          "-" +
          vehiclesMaxCO2EmissionFilter,
      });
    }
    dataFilters.push({
      type: AdvancedFilterVersionsOptions.CO2Emission,
      value:
        vehiclesMinCO2EmissionFilter + "-" + vehiclesMaxCO2EmissionFilter,
    });

    brandsSectionProps.forEach(
      (brand: {
        models: {
          checkBoxProps: { checked: boolean };
          label: string;
          id: string;
        }[];
        label: string;
      }) => {
        brand.models.forEach(
          (model: {
            id: string;
            label: string;
            checkBoxProps: { checked: boolean };
          }) => {
            if (model.checkBoxProps.checked) {
              displayableFilters.push({
                label: brand.label + ": " + model.label,
                id: `type:${AdvancedFilterVersionsOptions.Model};${model.id}`,
              });
              dataFilters.push({
                type: AdvancedFilterVersionsOptions.Model,
                value: model.id,
              });
            }
          }
        );
      }
    );

    
   return displayableFilters;
    
     
  },[
  advFilterFuelTypeOptions, advFilterSeats, advFilterSegmentsOptions, advFilterTypeStatusOptions, 
  brandsSectionProps,
  vehicleMaxCO2EmissionDefaultValue, vehicleMaxDistanceBetweenFrontAxlesDefaultValue, 
  vehicleMaxDistanceBetweenRearAxlesDefaultValue, vehicleMaxEngineCapacityDefaultValue, 
  vehicleMaxGrossWeightDefaultValue, vehicleMaxHeightDefaultValue,
  vehicleMaxLengthDefaultValue, vehicleMaxMaxSuitcaseVolumeDefaultValue, 
  vehicleMaxMaxTorqueDefaultValue, vehicleMaxWidthDefaultValue, 
  vehicleMinCO2EmissionDefaultValue, vehicleMaxPowerDefaultValue, 
  vehicleMinPowerDefaultValue, vehicleMinDistanceBetweenFrontAxlesDefaultValue,
  vehicleMinDistanceBetweenRearAxlesDefaultValue, vehicleMinEngineCapacityDefaultValue, 
  vehicleMinGrossWeightDefaultValue, vehicleMinHeightDefaultValue, 
  vehicleMinLengthDefaultValue, vehicleMinMaxSuitcaseVolumeDefaultValue, 
  vehicleMinMaxTorqueDefaultValue, vehicleMinWidthDefaultValue, 

  vehiclesMaxCO2EmissionFilter, vehiclesMaxDistanceBetweenFrontAxlesFilter,
  vehiclesMaxDistanceBetweenRearAxlesFilter, vehiclesMaxEngineCapacityFilter, 
  vehiclesMaxGrossWeightFilter, vehiclesMaxHeightFilter, 
  vehiclesMaxLengthFilter, vehiclesMaxMaxSuitcaseVolumeFilter,
  vehiclesMaxMaxTorqueFilter, vehiclesMaxWidthFilter, 
  vehiclesMinCO2EmissionFilter, vehiclesMinDistanceBetweenFrontAxlesFilter, 
  vehiclesMinDistanceBetweenRearAxlesFilter, vehiclesMinEngineCapacityFilter, 
  vehiclesMinGrossWeightFilter, vehiclesMinHeightFilter,
  vehiclesMinLengthFilter, vehiclesMinMaxSuitcaseVolumeFilter, 
  vehiclesMinMaxTorqueFilter, vehiclesMaxPowerFilter, 
  vehiclesMinPowerFilter, vehiclesMinWidthFilter, 
]);

const resetAdvancedFilters = useCallback(() => {
  setAppliedFilters([]);
  let filters = advSearchDefault();
  for (let i = 0; i < filters.length; i++) {
    handleRemoveAppliedFilter(i, false,filters);
  }

  let brands = brandsSectionProps.map(
    (brand: {
      [x: string]: any;
      label: any;
      open: boolean;
      checkBoxProps: CheckboxProps;
      models: {
        [x: string]: any;
        id: string;
        label: string;
        checkBoxProps: CheckboxProps;
      }[];
    }) => {
      brand.hidden = false;
      brand.checkBoxProps.checked = false;
      brand.models.map(
        (model: {
          id: string;
          label: string;
          checkBoxProps: CheckboxProps;
        }) => {
          model.checkBoxProps.checked = false;
          return model;
        }
      );
      return brand;
    }
  );
  setBrandsSectionProps(brands);
  const manualCancelToken = axios.CancelToken.source();
  // vehiclesService.cancelRequest("Pedido cancelado pelo utilizador", cancelToken)
  // cancelToken = axios.CancelToken.source()
  vehicleService.cancelRequest("Pedido cancelado pelo utilizador", manualCancelToken)
  setFilterLoading(false)
  setActionFilter(false)
  setFilterResults([]);
}, [advSearchDefault, brandsSectionProps, vehicleService, handleRemoveAppliedFilter]);

  const removeFilter = useCallback(
    (id: string) => () => {
      let filterToRemove = appliedFilters.findIndex((x) => {
        return x.id === id;
      });
      if (appliedFilters.length>0) {
        handleRemoveAppliedFilter(filterToRemove, true,appliedFilters);
      }
    },
    [appliedFilters, handleRemoveAppliedFilter]
  );

  const onAdvFiltersAddSelectedVehicles = useCallback(async () => {
    var itemsToAdd = filterResults?.filter(
      (x) => x.checkboxProps.checked === true
    );
    if(handleAddAdvSearchSelectedVehicles_Click && itemsToAdd){
      handleAddAdvSearchSelectedVehicles_Click(itemsToAdd);
    }
    setAdvFilterResultsNumber(0);
    resetAdvancedFilters();
  }, [filterResults, handleAddAdvSearchSelectedVehicles_Click, resetAdvancedFilters]);
  
  const handleApplyFilters: CreateProposalPageProps["vehicleDataProps"]["filterDrawerProps"]["applyButtonProps"]["onClick"] | BackofficePreconfiguredVehiclesPageProps["filterDrawerProps"]["applyButtonProps"]["onClick"] =
    useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      advSearch(true);
  },
  [advSearch]);

  const appliedFiltersProps = useMemo(() => {
    let filters = appliedFilters.map((cur, index) => {
      return {
        label: cur.label,
        id: cur.id,
        onDelete: removeFilter(cur.id),
      };
    });
    return filters;
  }, [removeFilter, appliedFilters]);

  const filterDrawerPropsFilterResults = useMemo(() => {
    let results = filterResults?.map((b, index) => {
      return {
        name: b.name,
        characteristics: b.characteristics, //TODO: avaliar criar do lado do interface devido ao multilingue
        icons: b.icons,
        businessPrice: b.businessPrice,
        checkboxProps: {
          onChange: handleAdvFilterSetSelectedChange(index),
        },
        //associateLotTextFieldProps: b.associateLotTextFieldProps,
        associateLotTextFieldProps: {
          label: b.associateLotTextFieldProps.label,
          value: b.associateLotTextFieldProps.value,
          onChange: handleAdvFilterSetBatchChange(index),
        },
      };
    });
    return results;
  }, [
    filterResults,
    handleAdvFilterSetBatchChange,
    handleAdvFilterSetSelectedChange,
  ]);

  const filterDrawerPropsApplyButtonPropsClick = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!filterLoading && filterResults?.length === 0 && !actionFilter) {
      if (handleApplyFilters) {
        handleApplyFilters(e);
      }
    } else {
      if(filterResults?.length === 0 && actionFilter) {
        resetAdvancedFilters();
      } else {
        onAdvFiltersAddSelectedVehicles();
      }
    }
  }, [
    actionFilter,
    filterLoading,
    filterResults?.length,
    handleApplyFilters,
    onAdvFiltersAddSelectedVehicles,
    resetAdvancedFilters,
  ]);

  const handleAdvFilterFuelTypeClick = useCallback((index: number) => () => {
    setAdvFilterFuelTypeOptions((fuelTypeOptions) => {
      return fuelTypeOptions.map((row:any, rowIndex:number) => {
        if(index === rowIndex){
          row.checked = !row.checked
        }
        return row
      })
    })
  },[]);
  
  const filterDrawerProps = useMemo(() => ({
    loading: filterLoading,
    headerTitle: !filterLoading && filterResults?.length === 0
      ? "Filtros Avançados"
      : "Resultados da pesquisa",
    brandSectionProps: {
      vehicleTypesText: "Tipos de viaturas",
      vehicleTypesCheckboxProps: advFilterTypeStatusOptions,
      brandSearchResultProps: {
        label: "Pesquisar marca",
        options: brandsSectionProps.map(
          (item: { value: string; label: string }) => {
            return { value: item.value, label: item.label };
          }
        ),
        placeholder: "Pesquisar",
        onChange: handleBrandsSearchChange,
      },
      allBrandsCheckBoxProps: {
        onClick: handleAllBrandsCheckboxProps,
      },
      allBrandsText: "Todas",
      brands: filterDrawerPropsBrands,
    },
    vehicleSpecificationsSectionProps: {
      vehicleFuelTypesText: "Tipo de Combustível",
      vehicleFuelTypesCheckboxProps: advFilterFuelTypeOptions,
      sliders: [
        {
          label: "Peso Bruto da viatura (Kg)",
          measurementType: "kg",
          maxValue: vehicleMaxGrossWeightDefaultValue,
          minValue: vehicleMinGrossWeightDefaultValue,
          step: 1,
          values: [
            vehiclesMinGrossWeightFilter,
            vehiclesMaxGrossWeightFilter,
          ],
          valuesTextFieldProps: [
            {
              value: vehiclesMinGrossWeightFilter,
              onChange: handleVehiclesMinGrossWeightFilter,
            },
            {
              value: vehiclesMaxGrossWeightFilter,
              onChange: handleVehiclesMaxGrossWeightFilter,
            },
          ],
          onChange: handleVehiclesGrossWeightBarFilterChange,
        },
        {
          label: "Volume Máximo da mala (L)",
          measurementType: "L",
          maxValue: vehicleMaxMaxSuitcaseVolumeDefaultValue,
          minValue: vehicleMinMaxSuitcaseVolumeDefaultValue,
          step: 1,
          values: [
            vehiclesMinMaxSuitcaseVolumeFilter,
            vehiclesMaxMaxSuitcaseVolumeFilter,
          ],
          valuesTextFieldProps: [
            {
              value: vehiclesMinMaxSuitcaseVolumeFilter,
              onChange: handleVehiclesMinMaxSuitcaseVolumeFilter,
            },
            {
              value: vehiclesMaxMaxSuitcaseVolumeFilter,
              onChange: handleVehiclesMaxMaxSuitcaseVolumeFilter,
            },
          ],
          onChange: handleVehiclesSuitcaseVolumeBarFilterChange,
        },
      ],
      segmentSearchResultProps: {
        label: "Pesquisar segmento",
        options: advFilterSegmentsSearchOptions,
        onChange: handleSegmentsSearch,
      },
      segments: advFilterSegmentsOptions.filter((x: { hidden: boolean }) => x.hidden === false).map((
        x: { label: string, value: string, hidden: boolean, checkBoxProps:{checked:boolean} },
        index: number
      ) => {
        return {
          label: x.label,
          value: x.value,
          hidden: x.hidden,
          checkBoxProps: {
            checked: x.checkBoxProps.checked,
          },
          onClick: handleAdvFilterSegmentClick(x.value),
        };
      }),
    },
    spaceAndDimensionsSectionProps: {
      seatsNumberText: "Número de lugares",
      seatsNumberCheckboxProps: advFilterSeatsOptions,
      sliders: [
        {
          label: "Comprimento (mm)",
          measurementType: "mm",
          maxValue: vehicleMaxLengthDefaultValue,
          minValue: vehicleMinLengthDefaultValue,
          step: 100,
          values: [
            vehiclesMinLengthFilter,
            vehiclesMaxLengthFilter,
          ],
          valuesTextFieldProps: [
            {
              value: vehiclesMinLengthFilter,
              onChange: handleVehiclesMinLengthFilter,
            },
            {
              value: vehiclesMaxLengthFilter,
              onChange: handleVehiclesMaxLengthFilter,
            },
          ],
          onChange: handleVehiclesLengthBarFilterChange,
        },
        {
          label: "Largura (mm)",
          measurementType: "mm",
          maxValue: vehicleMaxWidthDefaultValue,
          minValue: vehicleMinWidthDefaultValue,
          values: [
            vehiclesMinWidthFilter,
            vehiclesMaxWidthFilter,
          ],
          valuesTextFieldProps: [
            {
              value: vehiclesMinWidthFilter,
              onChange: handleVehiclesMinWidthFilter,
            },
            {
              value: vehiclesMaxWidthFilter,
              onChange: handleVehiclesMaxWidthFilter,
            },
          ],
          onChange:handleVehiclesWidthBarFilterChange,
        },
        {
          label: "Altura (mm)",
          measurementType: "mm",
          maxValue: vehicleMaxHeightDefaultValue,
          minValue: vehicleMinHeightDefaultValue,
          values: [
            vehiclesMinHeightFilter,
            vehiclesMaxHeightFilter,
          ],
          valuesTextFieldProps: [
            {
              value: vehiclesMinHeightFilter,
              onChange: handleVehiclesMinHeightFilter,
            },
            {
              value: vehiclesMaxHeightFilter,
              onChange: handleVehiclesMaxHeightFilter,
            },
          ],
          onChange: handleVehiclesHeightBarFilterChange,
        },
        {
          label: "Distancia entre eixos dianteiros (mm)",
          measurementType: "mm",
          maxValue: vehicleMaxDistanceBetweenFrontAxlesDefaultValue,
          minValue: vehicleMinDistanceBetweenFrontAxlesDefaultValue,
          values: [
            vehiclesMinDistanceBetweenFrontAxlesFilter,
            vehiclesMaxDistanceBetweenFrontAxlesFilter,
          ],
          valuesTextFieldProps: [
            {
              value: vehiclesMinDistanceBetweenFrontAxlesFilter,
              onChange: handleVehiclesMinDistanceBetweenFrontAxlesFilter,
            },
            {
              value: vehiclesMaxDistanceBetweenFrontAxlesFilter,
              onChange: handleVehiclesMaxDistanceBetweenFrontAxlesFilter,
            },
          ],
          onChange:  handleVehiclesDistanceBetweenFrontAxlesBarFilterChange,
        },
        {
          label: "Distancia entre eixos traseiros (mm)",
          measurementType: "mm",
          maxValue: vehicleMaxDistanceBetweenRearAxlesDefaultValue,
          minValue: vehicleMinDistanceBetweenRearAxlesDefaultValue,
          values: [
            vehiclesMinDistanceBetweenRearAxlesFilter,
            vehiclesMaxDistanceBetweenRearAxlesFilter,
          ],
          valuesTextFieldProps: [
            {
              value: vehiclesMinDistanceBetweenRearAxlesFilter,
              onChange: handleVehiclesMinDistanceBetweenRearAxlesFilter,
            },
            {
              value: vehiclesMaxDistanceBetweenRearAxlesFilter,
              onChange: handleVehiclesMaxDistanceBetweenRearAxlesFilter,
            },
          ],
          onChange: handleVehiclesDistanceVetweenAxlesBarFilterChange,
        },
      ],
    },
    engineSpecsSectionProps: {
      sliders: [
        {
          label: "Potência (CV)",
          measurementType: "CV",
          maxValue: vehicleMaxPowerDefaultValue,
          minValue: vehicleMinPowerDefaultValue,
          step: 50,
          values: [
            vehiclesMinPowerFilter,
            vehiclesMaxPowerFilter,
          ],
          valuesTextFieldProps: [
            {
              value: vehiclesMinPowerFilter,
              onChange: handleVehiclesMinPowerFilter,
            },
            {
              value: vehiclesMaxPowerFilter,
              onChange: handleVehiclesMaxPowerFilter,
            },
          ],
          onChange: handleVehiclesPowerBarFilterChange,
        },
        {
          label: "Cilindrada Motor",
          measurementType: "CC",
          maxValue: vehicleMaxEngineCapacityDefaultValue,
          minValue: vehicleMinEngineCapacityDefaultValue,
          step: 50,
          values: [
            vehiclesMinEngineCapacityFilter,
            vehiclesMaxEngineCapacityFilter,
          ],
          valuesTextFieldProps: [
            {
              value: vehiclesMinEngineCapacityFilter,
              onChange: handleVehiclesMinEngineCapacityFilter,
            },
            {
              value: vehiclesMaxEngineCapacityFilter,
              onChange: handleVehiclesMaxEngineCapacityFilter,
            },
          ],
          onChange: handleVehiclesEngineCapacityBarFilterChange,
        },
        {
          label: "Binário Máximo",
          measurementType: "",
          maxValue: vehicleMaxMaxTorqueDefaultValue,
          minValue: vehicleMinMaxTorqueDefaultValue,
          step: 5,
          values: [
            vehiclesMinMaxTorqueFilter,
            vehiclesMaxMaxTorqueFilter,
          ],
          valuesTextFieldProps: [
            {
              value: vehiclesMinMaxTorqueFilter,
              onChange: handleVehiclesMinMaxTorqueFilter,
            },
            {
              value: vehiclesMaxMaxTorqueFilter,
              onChange: handleVehiclesMaxMaxTorqueFilter,
            },
          ],
          onChange: handleVehiclesTorqueBarFilterChange,
        },
        {
          label: "CO2 Emissão (Grama/Kg)",
          measurementType: "Grama/Kg",
          maxValue: vehicleMaxCO2EmissionDefaultValue,
          minValue: vehicleMinCO2EmissionDefaultValue,
          step: 1,
          values: [
            vehiclesMinCO2EmissionFilter,
            vehiclesMaxCO2EmissionFilter,
          ],
          valuesTextFieldProps: [
            {
              value: vehiclesMinCO2EmissionFilter,
              onChange: handleVehiclesMinCO2EmissionFilter,
            },
            {
              value: vehiclesMaxCO2EmissionFilter,
              onChange: handleVehiclesMaxCO2EmissionFilter,
            },
          ],
          onChange: handleVehiclesCO2EmissionbarFitlerChange,
        },
      ],
    },
    applyButtonText: !filterLoading && filterResults?.length === 0 && !actionFilter
       ? "Pesquisar"
       : filterResults?.length === 0 && actionFilter ? "Limpar filtros" : "Adicionar seleção",
    applyButtonProps: {
      onClick: filterDrawerPropsApplyButtonPropsClick,
      disabled: (!(!filterLoading && filterResults?.length === 0) && filterResults?.filter((x) => x.checkboxProps.checked === true)?.length===0),
    },
    clearAllButtonText: "Limpar todos os filtros", //TODO: implementar
    clearAllButtonProps: {
      onClick: resetAdvancedFilters,
    },
    expandAllButtonText: "Expandir tudo",
    collapseAllButtonText: "Diminuir tudo",
    appliedFilters: appliedFiltersProps,

    loadingText: "A procurar resultados para a sua pesquisa",
    resultsFoundNumber: advFilterResultsNumber.toString(),
    resultsFoundText: "Resultados",
    result: filterDrawerPropsFilterResults,

    selectVehiclesText:
      advFilterResultsNumber > 0
        ? "Selecione as viaturas que pretende adicionar às suas simulações"
        : "",
  }),
  [
    actionFilter,
    advFilterFuelTypeOptions,
    advFilterResultsNumber,
    advFilterSegmentsOptions,
    advFilterSegmentsSearchOptions,
    advFilterTypeStatusOptions,
    // advFilterSeats,
    brandsSectionProps,
    filterLoading,
    filterResults,
    // handleAdvFilterFuelTypeClick,
    handleAdvFilterSegmentClick,
    // handleAdvFilterTypeStatusClick,
    resetAdvancedFilters,
    vehicleMaxCO2EmissionDefaultValue,
    vehicleMaxDistanceBetweenFrontAxlesDefaultValue,
    vehicleMaxDistanceBetweenRearAxlesDefaultValue,
    vehicleMaxEngineCapacityDefaultValue,
    vehicleMaxGrossWeightDefaultValue,
    vehicleMaxHeightDefaultValue,
    vehicleMaxLengthDefaultValue,
    vehicleMaxMaxSuitcaseVolumeDefaultValue,
    vehicleMaxMaxTorqueDefaultValue,
    vehicleMaxPowerDefaultValue,
    vehicleMaxWidthDefaultValue,
    vehicleMinCO2EmissionDefaultValue,
    vehicleMinDistanceBetweenFrontAxlesDefaultValue,
    vehicleMinDistanceBetweenRearAxlesDefaultValue,
    vehicleMinEngineCapacityDefaultValue,
    vehicleMinGrossWeightDefaultValue,
    vehicleMinHeightDefaultValue,
    vehicleMinLengthDefaultValue,
    vehicleMinMaxSuitcaseVolumeDefaultValue,
    vehicleMinMaxTorqueDefaultValue,
    vehicleMinPowerDefaultValue,
    vehicleMinWidthDefaultValue,
    vehiclesMaxCO2EmissionFilter,
    vehiclesMaxDistanceBetweenFrontAxlesFilter,
    vehiclesMaxDistanceBetweenRearAxlesFilter,
    vehiclesMaxEngineCapacityFilter,
    vehiclesMaxGrossWeightFilter,
    vehiclesMaxHeightFilter,
    vehiclesMaxLengthFilter,
    vehiclesMaxMaxSuitcaseVolumeFilter,
    vehiclesMaxMaxTorqueFilter,
    vehiclesMaxPowerFilter,
    vehiclesMaxWidthFilter,
    vehiclesMinCO2EmissionFilter,
    vehiclesMinDistanceBetweenFrontAxlesFilter,
    vehiclesMinDistanceBetweenRearAxlesFilter,
    vehiclesMinEngineCapacityFilter,
    vehiclesMinGrossWeightFilter,
    vehiclesMinHeightFilter,
    vehiclesMinLengthFilter,
    vehiclesMinMaxSuitcaseVolumeFilter,
    vehiclesMinMaxTorqueFilter,
    vehiclesMinPowerFilter,
    vehiclesMinWidthFilter,

    filterDrawerPropsBrands,
    filterDrawerPropsApplyButtonPropsClick,
    filterDrawerPropsFilterResults,
    // handleRemoveAppliedFilter  appliedFilters, advFilterSeats,
    appliedFiltersProps,
    advFilterSeatsOptions,
    handleAllBrandsCheckboxProps,
    handleBrandsSearchChange,
    handleSegmentsSearch,
    handleVehiclesGrossWeightBarFilterChange,
    handleVehiclesMaxGrossWeightFilter,
    handleVehiclesMinGrossWeightFilter,
    handleVehiclesMinMaxSuitcaseVolumeFilter,
    handleVehiclesMaxMaxSuitcaseVolumeFilter,
    handleVehiclesSuitcaseVolumeBarFilterChange,
    handleVehiclesMinLengthFilter,
    handleVehiclesMaxLengthFilter,
    handleVehiclesLengthBarFilterChange,
    handleVehiclesMinWidthFilter,
    handleVehiclesMaxWidthFilter,
    handleVehiclesWidthBarFilterChange,
    handleVehiclesMinHeightFilter,
    handleVehiclesMaxHeightFilter,
    handleVehiclesHeightBarFilterChange,
    handleVehiclesMinDistanceBetweenFrontAxlesFilter,
    handleVehiclesMaxDistanceBetweenFrontAxlesFilter,
    handleVehiclesDistanceBetweenFrontAxlesBarFilterChange,
    handleVehiclesMinDistanceBetweenRearAxlesFilter,
    handleVehiclesMaxDistanceBetweenRearAxlesFilter,
    handleVehiclesDistanceVetweenAxlesBarFilterChange,

    handleVehiclesMinPowerFilter,
    handleVehiclesMaxPowerFilter,
    handleVehiclesPowerBarFilterChange,
    handleVehiclesMinEngineCapacityFilter,
    handleVehiclesMaxEngineCapacityFilter,
    handleVehiclesEngineCapacityBarFilterChange,
    handleVehiclesMinMaxTorqueFilter,
    handleVehiclesMaxMaxTorqueFilter,
    handleVehiclesTorqueBarFilterChange,
    handleVehiclesMinCO2EmissionFilter,
    handleVehiclesMaxCO2EmissionFilter,
    handleVehiclesCO2EmissionbarFitlerChange,
  ]);

  const handleAdvFilterTypeStatusClick = useCallback((index: number) => () => {
    setAdvFilterTypeStatusOptions((typeStatusOptions) => {
      return typeStatusOptions.map((row:any, rowIndex:number) => {
        if(index === rowIndex){
          row.checked = !row.checked
        }
        return row
      })
    });
  },[]);

  useEffect(()=>{
    vehicleService.getTypeStatusOptions().then((data) => {
      let items: CheckboxProps[] = data.map(
        (item: { key: number; value: string }, index: number) => {
          let typeStatusOption: CheckboxProps = {
            label: item.value,
            value: item.key,
            checked:false,
            onClick: handleAdvFilterTypeStatusClick(index)
          }
          return typeStatusOption;
        }
      );
      setAdvFilterTypeStatusOptions(items);
    });
    vehicleService.getFuelTypes().then((data) => {
      var items: CheckboxProps[] = data.map(
        (item: { description: string; id: string }, index: number) => {
          let fuelStatusOption: CheckboxProps = {
            label: item.description,
            id: item.id,
            checked:false,
            onClick: handleAdvFilterFuelTypeClick(index),
          }
          return fuelStatusOption;
        }
      );
      setAdvFilterFuelTypeOptions(items);
    });
    vehicleService.getSegments().then((data) => {
      let segments: any[] = [];
      let items = data.map(
        (item: { id: string; description: string; hidden: boolean }) => {
          segments.push({
            label: item.description,
            value: item.id,
            hidden: false,
            checkBoxProps:{
              checked:false,
            }
          });

          return { label: item.description, id: item.id };
        }
      );
      setAdvFilterSegmentsSearchOptions(items);
      setAdvFilterSegmentsOptions(segments);
    });
  }, [
    handleAdvFilterFuelTypeClick, 
    handleAdvFilterTypeStatusClick, 
    vehicleService
  ]);

  useEffect(() => {
    setVehiclesMinGrossWeightFilter(settings.Data.Vehicles_MinGrossWeight);
    setVehiclesMaxGrossWeightFilter(settings.Data.Vehicles_MaxGrossWeight);
    setVehiclesMinMaxSuitcaseVolumeFilter(settings.Data.Vehicles_MinMaxSuitcaseVolume);
    setVehiclesMaxMaxSuitcaseVolumeFilter(settings.Data.Vehicles_MaxMaxSuitcaseVolume);
    //ESPAÇO E DIMENSÕES
    setVehiclesMinLengthFilter(settings.Data.Vehicle_MinLength);
    setVehiclesMaxLengthFilter(settings.Data.Vehicles_MaxLength);

    setVehiclesMinWidthFilter(settings.Data.Vehicles_MinWidth);
    setVehiclesMaxWidthFilter(settings.Data.Vehicles_MaxWidth);

    setVehiclesMinHeightFilter(settings.Data.Vehicles_MinHeight);
    setVehiclesMaxHeightFilter(settings.Data.Vehicles_MaxHeight);
  
    setVehiclesMinDistanceBetweenFrontAxlesFilter(settings.Data.Vehicles_MinDistanceBetweenFrontAxles);
    setVehiclesMaxDistanceBetweenFrontAxlesFilter(settings.Data.Vehicles_MaxDistanceBetweenFrontAxles);
  
    setVehiclesMinDistanceBetweenRearAxlesFilter(settings.Data.Vehicles_MinDistanceBetweenRearAxles);
    setVehiclesMaxDistanceBetweenRearAxlesFilter(settings.Data.Vehicles_MaxDistanceBetweenRearAxles);

    //ESPECIFICAÇÕES DO MOTOR
    setVehiclesMinPowerFilter(settings.Data.Vehicles_MinPower);
    setVehiclesMaxPowerFilter(settings.Data.Vehicles_MaxPower);
  
    setVehiclesMinEngineCapacityFilter(settings.Data.Vehicles_MinEngineCapacity);
    setVehiclesMaxEngineCapacityFilter(settings.Data.Vehicles_MaxEngineCapacity);
  
    setVehiclesMinMaxTorqueFilter(settings.Data.Vehicles_MinMaxTorque);
    setVehiclesMaxMaxTorqueFilter(settings.Data.Vehicles_MaxMaxTorque);
  
    setVehiclesMinCO2EmissionFilter(settings.Data.Vehicles_MinCO2Emission);
    setVehiclesMaxCO2EmissionFilter(settings.Data.Vehicles_MaxCO2Emission);

    //ESPECIFICAÇÕES DA VIATURA
    vehicleMinGrossWeightDefaultValue = settings.Data.Vehicles_MinGrossWeight;
    vehicleMaxGrossWeightDefaultValue = settings.Data.Vehicles_MaxGrossWeight;

    vehicleMinMaxSuitcaseVolumeDefaultValue = settings.Data.Vehicles_MinMaxSuitcaseVolume;
    vehicleMaxMaxSuitcaseVolumeDefaultValue = settings.Data.Vehicles_MaxMaxSuitcaseVolume;

    //ESPAÇO E DIMENSÕES
    vehicleMinLengthDefaultValue = settings.Data.Vehicle_MinLength;
    vehicleMaxLengthDefaultValue = settings.Data.Vehicles_MaxLength;
  
    vehicleMinWidthDefaultValue = settings.Data.Vehicles_MinWidth;
    vehicleMaxWidthDefaultValue = settings.Data.Vehicles_MaxWidth;
  
    vehicleMinHeightDefaultValue = settings.Data.Vehicles_MinHeight;
    vehicleMaxHeightDefaultValue = settings.Data.Vehicles_MaxHeight;

    vehicleMinDistanceBetweenFrontAxlesDefaultValue = settings.Data.Vehicles_MinDistanceBetweenFrontAxles;
    vehicleMaxDistanceBetweenFrontAxlesDefaultValue =settings.Data.Vehicles_MaxDistanceBetweenFrontAxles;
  
    vehicleMinDistanceBetweenRearAxlesDefaultValue = settings.Data.Vehicles_MinDistanceBetweenRearAxles;
    vehicleMaxDistanceBetweenRearAxlesDefaultValue = settings.Data.Vehicles_MaxDistanceBetweenRearAxles;

    //ESPECIFICAÇÕES DO MOTOR
    vehicleMinPowerDefaultValue = settings.Data.Vehicles_MinPower;
    vehicleMaxPowerDefaultValue = settings.Data.Vehicles_MaxPower;

    vehicleMinEngineCapacityDefaultValue = settings.Data.Vehicles_MinEngineCapacity;
    vehicleMaxEngineCapacityDefaultValue = settings.Data.Vehicles_MaxEngineCapacity;

    vehicleMinMaxTorqueDefaultValue = settings.Data.Vehicles_MinMaxTorque;
    vehicleMaxMaxTorqueDefaultValue = settings.Data.Vehicles_MaxMaxTorque;

    vehicleMinCO2EmissionDefaultValue = settings.Data.Vehicles_MinCO2Emission;
    vehicleMaxCO2EmissionDefaultValue = settings.Data.Vehicles_MaxCO2Emission;

    const seats = settings.Data.Vehicle_NumberOfPlacesOptions.map((item: any, index: number) => {
      let vehicleSeats: CheckboxProps = {
        label: item + " lugares",
        checked: false,
        onClick: handleAdvFilterSeatsClick(index),
      };
      return vehicleSeats;
    });
    setAdvFilterSeats(seats)
  }, [settings]);

  return { onAdvFiltersAddSelectedVehicles, filterResults, filterDrawerProps, setBrandsSectionProps, brandsSectionProps };
};
export default useAdvVehicleFilterHook;