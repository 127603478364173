import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { VehicleService } from "../../../services/VehicleService";

const useDriverNamesCustomHook  = () => {
  const vehicleService = new VehicleService();
  //INICIO Nome do Condutor
  const [drivers, setDrivers] = useState<any[]>([]);
  const handleToggleSelectClientNameCallback = useCallback((optionLabel:string) => () => {
    setDrivers((rows:any[]) => 
      rows.map(row => {
        if ( row.label === optionLabel)
        {
          row.checked = !row.checked
        }
        return row;
      })
    )
  },[]);

  const handleDrivers =  (driversTmp: any[]) => {
    let driverNames: any[any] = driversTmp;
    if(driverNames.length !== 0 )
    {
      setDrivers((rows:any[]) => {
        rows = driverNames;
        return rows;
      })
      return driverNames;
    }
    vehicleService.getAllDrivers().then(data => {
      if(data.succeeded === true){
        data.value.forEach((element:any, index:number) => {
          driverNames.push({
            label:element.label,
            checked:false,
            onClick: handleToggleSelectClientNameCallback(element.label),
          })
        });
        setDrivers((rows:any[]) => {
          if(rows.length === 0)
          {
            rows.push(driverNames)
          }
          return rows;
        });
      }
    });
    return driverNames;
  };
  const driversMemo = useMemo(() => handleDrivers(drivers),[drivers])
  const handleDriverNameTextFieldPropsCallback = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let driverNames: any[any] = [];
    if( e.target.value !== "" && e.target.value.length > 2){
      vehicleService.getDriversByNameOrNo(e.target.value).then(data => {
        if(data.succeeded === true && data.value.length > 0){
          data.value.forEach((driver:any) => {
            driverNames.push({
              label:driver.label,
              checked:false,
              onClick: handleToggleSelectClientNameCallback(driver.label),
  
            })
          })
          setDrivers((rows:any[]) => {
            return rows = driverNames;
          })
        }
      })
    }
    else if (e.target.value === "") {
      vehicleService.getAllDrivers().then(data => {
        if(data.succeeded === true){
          data.value.forEach((driver:any, index:number) => {
              driverNames.push({
                label:driver.label,
                checked:false,
                onClick: handleToggleSelectClientNameCallback(driver.label),
              })
          });
          setDrivers((rows:any[]) => {
            rows = driverNames;
            return rows;
          });
        }
      });
    }
    else {
      return driverNames;
    }
    // return driverNames;
  },[])
  //FIM Nome do Condutor

  return { drivers, handleDriverNameTextFieldPropsCallback, driversMemo };
};

export default useDriverNamesCustomHook;