import {SimplifiedVehicleDetailsProps, VehicleDetailsProps } from "@hi-interactive-admin/simulador-kinto";

// state declarations
let basePriceWithoutTaxTextField = "0";
let discountWithTaxTextField = "campo base para atualização de dados";

//TODO EDITAR EM GRUPO substituir os default values por values para alimentar os campos



const getAccessories = (n: number) => {
  const aux: VehicleDetailsProps['extraAccessories'] = [];
  for (let i = 1; i < n + 1; i++) {
    aux.push({
      deleteExtraAcessoryIconButtonProps: { disabled: true },
      ExtraAcessoryNameTextFieldProps: {
        label: 'Nome',
        value: `Acessório ${i}`,
      },
      ExtraAcessoryInstallerTextFieldProps: {
        label: 'Responsável de instalação',
        placeholder: `Responsável ${i}`,
      },
      ExtraAcessoryPriceTextFieldProps: {
        label: 'Preço',
        value: (i * 100).toString(),
        endAdornment: '€',
      },
      lazyload: true,
    });
  }
  return aux;
};

const getExtras = (n: number) => {
  const aux: VehicleDetailsProps['extraStandard'] = [];
  for (let i = 1; i < n + 1; i++) {
    aux.push({
      group: i % 2 === 0 ? 'comm' : 'tyres',
      groupLabel: i % 2 === 0 ? 'Comunicações' : 'Pneus',
      code: `7700-${i}`,
      valueTextFieldProps: { value: 200 },
      checkboxProps: {
        checked: false,
      },
      label: `opção ${i}`,
      lazyload: true,
    });
  }
  return aux;
};

export const simplifiedVehicleDetails: SimplifiedVehicleDetailsProps = {
  otherVehicleDataTitle: 'Outros dados da viatura',
  registrationTextFieldProps: {
    label: 'Matrícula',
    placeholder: '00-00-00',
  },
  associateLotText: 'Associar lote',
  lotIDTextFieldProps: {
    label: 'Nome Identificativo do lote',
    placeholder: 'Inserir nome do lote',
  },
};

export const vehicleDetails: Omit<
  VehicleDetailsProps,
  'onExtraCheckboxChange' | 'tableIndex'
> = {
  expandExtraAccessoriesText: 'Mostrar todos os acessórios',
  collapseExtraAccessoriesText: 'Ocultar acessórios',
  expandExtraStandardsText: 'Mostrar todos os extras',
  collapseExtraStandardsText: 'Ocultar extras',
  noSelectedExtraStandardsText: [
    'Não existem extras selecionados',
    'Selecione os extras da lista apresentada em Todos os extras',
  ],
  otherVehicleDataTitle: 'Outros dados da viatura',
  registrationTextFieldProps: {
    label: 'Matrícula',
    placeholder: '00-00-00',
  },
  fuelType: {
    label: 'Combustível',
    value: 'Diesel',
  },
  cylinderDisplacement: {
    label: 'Cilindrada',
    value: '1.5',
  },
  potency: {
    label: 'Potência',
    value: '100CV',
  },
  associateLotText: 'Associar lote',
  lotIDTextFieldProps: {
    label: 'Nome Identificativo do lote',
    placeholder: 'Inserir nome do lote',
  },
  businessSettingsTitle: 'Configurações do negócio',
  vehicleValuesText: 'Valores da viatura',
  valuesWithoutTaxText: 'Valores sem IVA',
  basePriceWithoutTaxTextFieldProps: {
    label: 'Preço base',
    placeholder: '0',
    defaultValue: '13.596',
    endAdornment: '€',
  },
  totalExtraWithoutTaxTextFieldProps: {
    label: 'Total extras',
    placeholder: '0',
    defaultValue: '13.596',
    endAdornment: '€',
  },
  totalAccessoriesWithoutTaxTextFieldProps: {
    label: 'Total acessórios',
    placeholder: '0',
    defaultValue: '13.596',
    endAdornment: '€',
  },
  discountWithoutTaxTextFieldProps: {
    label: 'Desconto',
    placeholder: '0',
    defaultValue: '13.596',
    endAdornment: '€',
  },
  proformaInvoicePriceWithoutTaxTextFieldProps: {
    label: 'Preço fatura proforma',
    placeholder: '0',
    defaultValue: '13.596',
    endAdornment: '€',
  },
  campaignWithoutTaxTextFieldProps: {
    label: 'Campanha',
    placeholder: '0',
    defaultValue: '13.596',
    endAdornment: '€',
  },
  rappelWithoutTaxTextFieldProps: {
    label: 'Rappel',
    placeholder: '0',
    defaultValue: '400',
    endAdornment: '€',
  },
  onDiscountFileClick: () => alert('discount file'),
  onRappelFileClick: () => alert('rappel file'),
  rappelWithoutTaxExtraTextFieldProps: {
    label: 'Rappel extra',
    placeholder: '0',
    defaultValue: '400',
    endAdornment: '€',
    warning: true,
  },
  hasRappelWithoutTaxExtraThreshold: true,
  rappelWithoutTaxExtraThresholdLabel: 'Carece validação',
  valuesWithTaxText: 'Valores com IVA',
  basePriceWithTaxTextFieldProps: { placeholder: '0', endAdornment: '€' },
  totalExtraWithTaxTextFieldProps: { placeholder: '0', endAdornment: '€' },
  totalAccessoriesWithTaxTextFieldProps: {
    placeholder: '0',
    endAdornment: '€',
  },
  discountWithTaxTextFieldProps: { placeholder: '0', endAdornment: '€' },
  discountWithTaxExtraTextFieldProps: { placeholder: '0', endAdornment: '%' },
  discountWithTaxTotalTextFieldProps: { placeholder: '0', endAdornment: '€' },
  proformaInvoicePriceWithTaxTextFieldProps: {
    placeholder: '0',
    endAdornment: '€',
  },
  campaignWithTaxTextFieldProps: { placeholder: '0', endAdornment: '€' },
  campaignWithTaxExtraTextFieldProps: { placeholder: '0', endAdornment: '%' },
  campaignWithTaxTotalTextFieldProps: { placeholder: '0', endAdornment: '€' },
  rappelWithTaxTextFieldProps: { placeholder: '0', endAdornment: '€' },
  rappelWithTaxExtraTextFieldProps: { placeholder: '0', endAdornment: '%' },
  rappelWithTaxTotalTextFieldProps: { placeholder: '0', endAdornment: '€' },
  expensesText: 'Despesas',
  ecoValueTextFieldProps: {
    label: 'Ecovalor',
    placeholder: '0',
    endAdornment: '€',
  },
  ISV_WithoutTaxTextFieldProps: {
    label: 'ISV sem IVA',
    placeholder: '0',
    endAdornment: '€',
  },
  PVP_WithoutTaxTextFieldProps: {
    label: 'PVP sem IVA',
    placeholder: '0',
    endAdornment: '€',
  },
  legalizationAndTransportCostsTextFieldProps: {
    label: 'Despesas de legalização e transporte',
    placeholder: '0',
    endAdornment: '€',
  },
  addAccessoryTitle: 'Selecionar Acessórios/extras',
  addAccessoryText: 'Adicionar acessórios',
  selectExtraStandardTitle: 'Escolher extras standard',

  rentalDataText: ' Dados do aluguer',
  rentalType: { label: 'Tipo de aluguer', value: 'AOV' },
  businessConditions: {
    label: 'Condiçoes comerciais',
    value: 'CP100195',
  },
  amountTextFieldProps: { label: 'Qtd.', placeholder: '0' },
  businessPriceText: 'Preço do negócio',
  businessValueWithoutTaxTextFieldProps: {
    label: 'Valor de negócio sem IVA',
    defaultValue: '13.596 ',
    endAdornment: '€',
  },
  businessValueWithTaxTextFieldProps: {
    label: 'Valor de negócio com IVA',
    endAdornment: '€',
    defaultValue: '13.596 ',
  },

  addAccessoryButtonText: 'Adicionar Acessório',
  addAccessoryButtonProps: {
    onClick: () => alert('adicionar nova linha ao array'),
  },
  searchStandardExtraButtonText: 'Pesquisar',
  searchStandardExtraButtonProps: {},
  allExtrasButtonText: 'Todos os extras',
  allExtrasButtonProps: {},
  selectedExtrasButtonText: 'Extras selecionados',
  selectedExtrasButtonProps: {},
  extraAccessories: getAccessories(10),
  // [
  //   {
  //     deleteExtraAcessoryIconButtonProps: { disabled: true },
  //     ExtraAcessoryNameTextFieldProps: {
  //       label: 'Nome',
  //       placeholder: 'Inserir nome do acessório',
  //     },
  //     ExtraAcessoryInstallerTextFieldProps: {
  //       label: 'Responsável de instalação',
  //       placeholder: 'Inserir nome do responsável',
  //     },
  //     ExtraAcessoryPriceTextFieldProps: {
  //       label: 'Preço',
  //       placeholder: 'Inserir valor ',
  //     },
  //   },
  // ],
  extraIncompatibilityModalProps: {
    title: 'Incompatibilidade de extras',
    cancelButtonText: 'Manter opções',
    confirmButtonText: 'Atualizar',
    incompatabilityText: [
      'Estes dois acessórios que selecionou são incompativeis.',
      'Por favor escolha, qual opção pretende manter?',
    ],
    requiredExtrasText: [
      'A opção selecionada requer que inclua também os extras abaixo:',
    ],
    alreadyIncludedText: [
      'O Pack selecionado inclui os extras abaixo, pretende manter todas as opçoes mesmo assim?',
    ],
    titleRequiredExtras: "",
    titlePacksIncompatibility: ""
  },
  extraStandard: getExtras(10),
  //  [
  //   {
  //     group: 'comm',
  //     groupLabel: 'Comunicações',
  //     code: '770000',
  //     label: 'ESACO (main group)',
  //     valueTextFieldProps: { value: 200 },
  //     checkboxProps: {
  //       checked: false,
  //     },
  //   },
  //   {
  //     group: 'comm',
  //     groupLabel: 'Comunicações',
  //     code: '770010',
  //     label: 'Transmissão/Chassis/Suspensão',
  //     valueTextFieldProps: { value: 200 },
  //     checkboxProps: {
  //       checked: false,
  //     },
  //   },
  //   {
  //     group: 'other',
  //     groupLabel: 'Outros',
  //     code: '770011',
  //     label: 'Audio/Comunicações/Instrumentos',
  //     valueTextFieldProps: { value: 200 },
  //     checkboxProps: {
  //       checked: false,
  //     },
  //   },
  //   {
  //     group: 'wheel',
  //     groupLabel: 'Rodas',
  //     checkboxProps: {
  //       checked: false,
  //     },
  //     label: 'Pack',
  //     code: '754908',
  //     valueTextFieldProps: { value: 200 },
  //     includes: [
  //       {
  //         label: 'Pneus com auto vedante',
  //         code: '754905',
  //       },
  //       {
  //         label: 'Pneus especiais',
  //         code: '754907',
  //       },
  //     ],
  //   },
  //   {
  //     group: 'wheel',
  //     groupLabel: 'Rodas',
  //     checkboxProps: {
  //       checked: false,
  //     },
  //     label: 'Pack2',
  //     code: '754909',
  //     valueTextFieldProps: { value: '200' },
  //     requiredCodes: ['754905', '754907'],
  //     incompatibilityCodes: ['754908'],
  //   },
  //   {
  //     group: 'wheel',
  //     groupLabel: 'Rodas',
  //     checkboxProps: {
  //       checked: false,
  //     },
  //     label: 'Pneus com auto vedante',
  //     code: '754905',
  //     valueTextFieldProps: { value: 200 },
  //   },
  //   {
  //     group: 'wheel',
  //     groupLabel: 'Rodas',
  //     checkboxProps: {
  //       checked: false,
  //     },
  //     label: 'Pneus sem auto vedante',
  //     code: '754906',
  //     valueTextFieldProps: { value: 200 },
  //     incompatibilityCodes: ['754905'],
  //   },
  //   {
  //     group: 'wheel',
  //     groupLabel: 'Rodas',
  //     checkboxProps: {
  //       checked: false,
  //     },
  //     label: 'Pneus especiais',
  //     code: '754907',
  //     valueTextFieldProps: { value: 200 },
  //   },
  // ],
  extraStandardSearchResultProps: {
    label: 'Pesquisar por extra',
    placeholder: 'Pesquisar',

    options: [
      {
        label: 'opção1',
        value: 'op1',
      },
      {
        label: 'opção2',
        value: 'op2',
      },
    ],
  },

  technicalCharacteristics: [
    {
      title: 'Caractersiticas técnicas',
      characteristic: [
        {
          group: 'vehicleSpec',
          groupLabel: 'Especificações da viatura',
          label: 'Ano do modelo',
          value: '2008',
        },
        {
          group: 'vehicleSpec',
          groupLabel: 'Especificações da viatura',
          label: 'Data de registo',
          value: '22-02-2022',
        },
        {
          group: 'vehicleSpec',
          groupLabel: 'Especificações da viatura',
          label: 'Quilometragem',
          value: '2008',
        },
        {
          group: 'vehicleSpec',
          groupLabel: 'Especificações da viatura',
          label: 'Número de portas',
          value: '5',
        },
        {
          group: 'vehicleSpec',
          groupLabel: 'Especificações da viatura',
          label: 'Estado',
          value: 'Novo',
        },
        {
          group: 'vehicleSpec',
          groupLabel: 'Especificações da viatura',
          label: 'Uso',
          value: 'Ligeiro de passageiros',
        },
      ],
    },
    {
      title: 'Espaço e Dimensões',
      characteristic: [
        {
          group: 'iDim',
          groupLabel: 'Dimensões interiores',
          label: 'Número de lugares',
          value: '5',
        },
        {
          group: 'iDim',
          groupLabel: 'Dimensões interiores',
          label: 'Comprimento interior (mm)',
          value: '1795',
        },
        {
          group: 'iDim',
          groupLabel: 'Dimensões interiores',
          label: 'Largura interior (mm)',
          value: '1510',
        },
        {
          group: 'iDim',
          groupLabel: 'Dimensões interiores',
          label: 'Altura interior',
          value: '1155',
        },
        {
          group: 'iDim',
          groupLabel: 'Dimensões interiores',
          label: 'Volume Mala (L)	',
          value: '50',
        },
        {
          group: 'Dim&W',
          groupLabel: 'Dimensões e pesos',
          label: 'Comprimento exterior (mm)',
          value: '4370',
        },
        {
          group: 'Dim&W',
          groupLabel: 'Dimensões e pesos',
          label: 'Largura exterior (mm)',
          value: '1790',
        },
        {
          group: 'Dim&W',
          groupLabel: 'Dimensões e pesos',
          label: 'Distância entre Eixos (mm)',
          value: '2640',
        },
        {
          group: 'Dim&W',
          groupLabel: 'Dimensões e pesos',
          label: 'Distância entre Eixos Dianteiros (mm)',
          value: '1530',
        },
        {
          group: 'Dim&W',
          groupLabel: 'Dimensões e pesos',
          label: 'Distância entre Eixos traseiros (mm)',
          value: '1530',
        },
      ],
    },
    {
      title: 'Especificações do motor',
      characteristic: [
        {
          group: 'motor',
          groupLabel: 'Motor',
          label: 'Taxa de compressão',
          value: '10.0:1',
        },
        {
          group: 'motor',
          groupLabel: 'Motor',
          label: 'Diâmetro',
          value: '71.5',
        },
        {
          group: 'motor',
          groupLabel: 'Motor',
          label: 'Binário Máximo (Nm)',
          value: '185/1500-4000',
        },
        {
          group: 'motor',
          groupLabel: 'Motor',
          label: 'Potência máxima (kw)',
          value: '116',
        },
        {
          group: 'envP',
          groupLabel: 'Performance Ambiental',
          label: 'Capacidade do Tanque de Combustível (L)',
          value: '50',
        },
        {
          group: 'envP',
          groupLabel: 'Performance Ambiental',
          label: 'Tipo de Combustível ',
          value: 'Gasolina',
        },
        {
          group: 'envP',
          groupLabel: 'Performance Ambiental',
          label: 'CO2-Emissão Grama / Kg',
          value: '141',
        },
        {
          group: 'perf',
          groupLabel: 'Performance',
          label: 'Velocidade Máxima (Km/H)',
          value: '200',
        },
        {
          group: 'perf',
          groupLabel: 'Performance',
          label: 'Aceleração 0-100 Km / H',
          value: '9.3',
        },
        {
          group: 'perf',
          groupLabel: 'Performance',
          label: 'Autonomia',
          value: '500 km',
        },
      ],
    },
  ],

  otherCharacteristics: [
    {
      title: 'Outra informaçãos/serviços',
      characteristic: [
        {
          group: 'vehicleSpec',
          groupLabel: 'Subtitulo',
          label: 'BPM',
          value: '',
        },
        {
          group: 'vehicleSpec',
          groupLabel: 'Subtitulo',
          label: 'Mudança de óleo',
          value: '',
        },
      ],
      characteristicsDropdownProps: [
        {
          label: 'Uso',
          options: [
            {
              label: 'Categoria IPO',
              value: 'cIPO',
            },
          ],
          defaultValue: 'cIPO',
        },
      ],
    },
  ],
  // SK-850
  tiresTitle: '',
  tiresMeasurements: {
    options: [],
    label: 'Pesquise por CA ou veículo',
    placeholder: 'Pesquisar',
    value: '',
    // onInputChange: handleOnInputSearchOptions,
    freeSolo: true,
    disableClearable: false
  },
  tiresDivergentMeasurements: {
    options: [],
    label: 'Pesquise por CA ou veículo',
    placeholder: 'Pesquisar',
    value: '',
    // onInputChange: handleOnInputSearchOptions,
    freeSolo: true,
    disableClearable: false
  },
  
};
