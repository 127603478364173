import { CheckboxProps } from "@hi-interactive-admin/simulador-kinto";
import { useCallback, useMemo, useState } from "react";
import { VehicleService } from "../../../services/VehicleService";

const vehicleService = new VehicleService();

const useRentalTypesCustomHook  = () => {
  //INCIO Tipo de Aluger
  const [rentalTypes, setRentalTypes] = useState<CheckboxProps[]>([])

  const handleToggleSelectRentalTypeCallback = useCallback((optionLabel:string) => () => {
    setRentalTypes((rows) => 
      rows.map(row => {
        if ( row.label === optionLabel)
        {
          row.checked = !row.checked;
        }
        return row;
      })
    )
  },[]);

  const handleRentType = useCallback((rentalTypes:CheckboxProps[]) => {
    let rentTypeOptions: CheckboxProps[] = rentalTypes;
    if ( rentTypeOptions.length !== 0) 
    {
      setRentalTypes((rows) => {
        rows = rentTypeOptions 
        return rows;
      });
      return rentTypeOptions;
    }

    vehicleService.getRentalTypes().then((data) => {
      if(data && data.succeeded === true) {
        data.value.forEach((element:any) => {
          rentTypeOptions.push({ 
            label:element.value,
            checked:false,
            onClick: handleToggleSelectRentalTypeCallback(element.value),
          })
        });

        setRentalTypes((rows) => {
          if (rows.length === 0 ){
            rows = rentTypeOptions
          }
          return rows;
        });
      }
    })
    return rentTypeOptions;
  },[handleToggleSelectRentalTypeCallback]);

  const rentalTypesMemo = useMemo(() => handleRentType(rentalTypes),[handleRentType, rentalTypes])
  //FIM Tipo de Aluger
  return { rentalTypes, setRentalTypes, rentalTypesMemo };
};

export default useRentalTypesCustomHook;