//import axios from "axios";
import axiosInstance from "../Utilities/axiosInstance";
import { Profile } from "../model/Profile";
import { SettingsService } from "./SettingsService";

export class ProfileServices {
  //private readonly settingsService = SettingsService.getInstance();
  // constructor(){
  //   //const settingsService = await SettingsService.getInstance();
  //   this.EntityURL = this.settingsService.settings.APIBaseURL + this.settingsService.settings.Users_RelativeURL;
  // }

  private EntityURL: string = '';// = settings.APIBaseURL + settings.Users_RelativeURL;
  
  loadSettings =  async () => {
    if(this.EntityURL.length === 0){
      const settingsService = await SettingsService.getInstance();
      this.EntityURL = settingsService.settings.APIBaseURL + settingsService.settings.Profiles_RelativeURL;
    }
  };

  add = async (user:Profile) => {
    await this.loadSettings();
    try {
      const { data } = await axiosInstance.post(this.EntityURL,user);

      return data.value;
    } catch (error) {
      console.log(error);
    }
  };
  get = async () => {
    await this.loadSettings();
    try {
      const { data } = await axiosInstance.get(this.EntityURL);
      return data;
    } catch (error) {
      console.log(error);
    }
  };


  delete = async (profileId:number) => {
    await this.loadSettings();
    try {
      const { data } = await axiosInstance.delete(this.EntityURL + 'delete/' + profileId);
      return data.value;
    } catch (error) {
      console.log(error);
    }
  }
  getProfileByNameOrNumber = async (searchParam:string) => {
    await this.loadSettings();
    try {
      const { data } = await axiosInstance.get(this.EntityURL + 'SearchProfile/' + searchParam);
      return data.value;
    } catch (error) {
      console.log(error);
    }
  };
  getProfileById = async (id:number, active:boolean | null | undefined) => {
    await this.loadSettings();
    try {
      let url = this.EntityURL + 'profile/' + id;
      if(active !== (null||undefined))
      {
        url += `/${active}`;
      }
      const { data } = await axiosInstance.get(url);
      return data.value;
    } catch (error) {
      console.log(error);
    }
  };
}
