import /*axios,*/ { AxiosRequestConfig } from "axios";
import axiosInstance from "../Utilities/axiosInstance";
import { APIResponse } from "../model/APIResponse";
import { ProposalComment } from "../model/ProposalComment";
import { SettingsService } from "./SettingsService";

export class CommentsService {
  private settingsService: any;
  private entityURL: string = "";
  private entityURLAttachments:string = "";

  ensureSettingsService =  async () => {
    this.settingsService = await SettingsService.getInstance();
    this.entityURL = this.settingsService.settings.APIBaseURL + this.settingsService.settings.ProposalComments_RelativeURL;
    this.entityURLAttachments = this.settingsService.settings.APIBaseURL + this.settingsService.settings.ProposalCommentsAttachments_RelativeURL;
  };

  getComments = async (proposalId: number): Promise<ProposalComment[]> => {
    try {
      await this.ensureSettingsService();
      const url = this.entityURL + '/'  + proposalId;
      const { data } = await axiosInstance.get(url);
      const converted = data.value as ProposalComment[];
      return converted;
    } catch (error) {
      console.log(error);
    }
    return [];
  };

  clearUnread = async (proposalId: number): Promise<APIResponse> => {
    try {
      await this.ensureSettingsService();
      const url = this.entityURL + '/' + proposalId + "/clearunread";
      const { data } = await axiosInstance.post(url);
      return data;
    } catch (error:any) {
      console.log(error);
      return {
        succeeded: false,
        hasErrors:true,
        message: error.message,
        value: {},
        errors: [],
      };
    }
  };

  addComment = async (
    proposalId: number,
    message: string,
    attachments: any
  ): Promise<ProposalComment | null> => {
    try {
      await this.ensureSettingsService();
      const url = this.entityURL + '/'  + proposalId;
      if(message === null || message === undefined)
        	message = '';
      const { data } = await axiosInstance.post(url,  {message, attachments} , {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const converted = data.value as ProposalComment;
      return converted;
    } catch (error) {
      console.log(error);
    }
    return null;
  };
  updateComment = async (
    proposalId: number,
    commentId: number,
    message: string,
    attachments: any
  ): Promise<ProposalComment | null> => {
    try {
      await this.ensureSettingsService();
      const url = this.entityURL + '/'  + proposalId + '/' + commentId;
      if(message === null || message === undefined)
        	message = '';
      const { data } = await axiosInstance.patch(url,  {commentId, message, attachments} , {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const converted = data.value as ProposalComment;
      return converted;
    } catch (error) {
      console.log(error);
    }
    return null;
  };
  
  addAttachments = async (
    proposalId: number,
    commentId: string,
    files: any[],
    onUploadProgress: AxiosRequestConfig
  ): Promise<any> => {
    try {
      await this.ensureSettingsService();
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("file", files[i]);
      }

      const url = this.entityURLAttachments;
      const { data } = await axiosInstance.post(url, formData, onUploadProgress);
      const converted = data.value;

      return converted;
    } catch (error) {
      console.log(error);
    }
    return null;
  };

  getAttachments = async (proposalId:string, commentId:string): Promise<ProposalComment[]> => {
    try {
      await this.ensureSettingsService();
      const url = this.entityURL + proposalId + "/" + commentId;
      const { data } = await axiosInstance.get(url);
      const converted = data.value; // as ProposalComment[];
      return converted;

    } catch (error) {
      console.log(error);
    }
    return [];
  };

  getAttachment = async (guid:string): Promise<any> => {
    try {
      await this.ensureSettingsService();
      const url = this.entityURLAttachments + "/"  + guid;
      const { data } = await axiosInstance.get(url, 
        { 
          // headers: {
          //   'Content-Type': 'application/pdf',
          // },
          // responseType: 'blob',
        });
      const converted = data.value; 
      return converted;

    } catch (error) {
      console.log(error);
    }
    return [];
  };

  removeAttachments = async (attachmentGuid:any): Promise<ProposalComment[]> => {
    try {
      await this.ensureSettingsService();
      //falta extrair giud do attachmnet e enviar no url em vez do objecto attachment
      const url = this.entityURL + '/'  + attachmentGuid;
      const { data } = await axiosInstance.delete(url);
      const converted = data.value; // as ProposalComment[];
      return converted;

    } catch (error) {
      console.log(error);
    }
    return [];
  };
  
  
}
