// import { Alert, AlertProps,ForgotPasswordPage, TextFieldProps } from "@enso-origins/hi-kinto";
import { Alert, AlertProps,ForgotPasswordPage, TextFieldProps } from "@hi-interactive-admin/simulador-kinto";
import { useCallback, useEffect, useState } from 'react';
import { useParams,useNavigate } from "react-router-dom";
import { UserServices } from "../../services/UserServices";
import { UserModel } from "../../model/User";
import { Auth } from "../../services/Auth";
import { passwordFormat } from "../../Utilities/Utils";
import { JwtPayload, jwtDecode } from "jwt-decode";
type ForgotPasswordRouteParams = {
  token: string;
};

interface IForgotPasswordForm {
  newPassword: string;
  confirmPassword: string;
}

const userService = new UserServices();

const ChangePassword = () => {
  const params = useParams<ForgotPasswordRouteParams>();
  const [showingNewPassword, setShowingNewPassword] = useState(false);
  const [showingConfirmPassword, setShowingConfirmPassword] = useState(false);
  
  // constantes de mensagems de aviso 
  //inicio
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [alertProps, setAlertProps] = useState<
    Omit<AlertProps, "open" | "onClose">
  >({
    variant: "info",
    text: "",
    title: "",
  });

  const openAlert = (alertProps: Omit<AlertProps, "open" | "onClose">) => {
    setAlertProps(alertProps);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //fim
  const [dataUserCard, setDataUserCard] = useState<UserModel>({
    userId: 0,
    username: "",
    friendlyName:"",
    avatarSrc:"",
    refreshToken: "",
    typeofIncome:"",
    typeofRental:"",
    commercialConditions:"",
    webPortalContactId:"",
    email:"",
    isAdministrator: false,
    profileId: undefined,
    profile: undefined,
    concessions:[],
    created: new Date(Date.now()),
    createdByUserId:0,
    active:false,
  });
  
  const [forgotPasswordForm, setForgotPasswordForm] =
    useState<IForgotPasswordForm>({
      newPassword: '',
      confirmPassword: '',
    });

  const handleChange: TextFieldProps['onChange'] = (e) => {
    setForgotPasswordForm({
      ...forgotPasswordForm,
      [e.target.name]: e.target.value,
    });
  };
  
   const handleResetPasswordClick = useCallback(() => {
    if(forgotPasswordForm.newPassword === "" || forgotPasswordForm.confirmPassword === "" || forgotPasswordForm.confirmPassword !== forgotPasswordForm.newPassword) {
      openAlert({
        variant: "error",
        text: "Password mal definida!",
        title: "Erro",
      });
    }
    else if(!passwordFormat(forgotPasswordForm.newPassword).hasErrors || !passwordFormat(forgotPasswordForm.confirmPassword).hasErrors) {
      openAlert({
        variant: "error",
        text: "Deve ter pelo menos 8 carateres e conter pelo menos uma letra maiuscula e um número",
        title: "Erro",
      });
    }
    else {

      const token = Auth.getToken();
      const decodedToken:JwtPayload = jwtDecode(token);
 
      // Example: Accessing the NameIdentifier claim
      const userID: number = (decodedToken as any).nameid;
      userService.getUserById(userID,null).then((data) => {
        if(data && data.succeeded){
          setDataUserCard(data.value);
          
          userService.changePwd((data.value as UserModel), forgotPasswordForm.newPassword)
          .then((data) => {
            if(data.succeeded){
                Auth.clearToken();
                navigate("/login");
            }
            else {
              openAlert({
                variant: "error",
                text: data.message,//"Erro ao definir a nova password",
                title: "Erro",
              });
            }
          });
  
        }
      });

    }
  },[forgotPasswordForm, navigate]);

  useEffect(() => {
     if (params.token){
      Auth.setToken(params.token);
    }
  }, [navigate, params.token]);

  return (
    <>
      <ForgotPasswordPage
        containerProps={{
          leftSectionContent: 'Todos os caminhos vão dar à mobilidade.',
        }}
        formProps={{
          title: 'Definir palavra passe',
          subTitle: 'Definir nova palavra passe para continuar.',
          newPasswordTextFieldProps: {
            label: 'Nova palavra passe',
            helperText:
              'Deve ter pelo menos 8 carateres e conter pelo menos uma letra maiuscula e um número',

            value: forgotPasswordForm.newPassword,
            name: 'newPassword',
            onChange: handleChange,
          },
          confirmPasswordTextFieldProps: {
            label: 'Confirmar palavra passe',
            helperText: 'As duas palavras passe devem ser iguais',
            error:(forgotPasswordForm.confirmPassword !== forgotPasswordForm.newPassword) ?true:false,

            value: forgotPasswordForm.confirmPassword,
            name: 'confirmPassword',
            onChange: handleChange,
          },
          onNewPasswordShowToggleClick: () =>
            setShowingNewPassword((prev) => !prev),
          newPasswordVisible: showingNewPassword,

          confirmPasswordVisible: showingConfirmPassword,
          onConfirmPasswordShowToggleClick: () =>
            setShowingConfirmPassword((prev) => !prev),

          resetPasswordButtonText: 'Definir palavra passe',

          resetPasswordButtonProps: {
            onClick: handleResetPasswordClick,
          },
        }}
      />
      <Alert open={open} onClose={handleClose} {...alertProps} />
    </>
  );
};

export default ChangePassword;
