import { CreateProposalPageProps } from "@hi-interactive-admin/simulador-kinto";
import { Contact } from "../../model/Contact";
import CustomerBankAccount from "../../model/CustomerBankAccount";


export interface RequiredDataProps {
  no: string;
  odataEtag: string;
  name: string;
  name2: string;
  salespersonCode: string;
  salesPersonName: string;
  vaT_Registration_No: string;
  type:string;
  customerType: string;
  customerTypePortal:string;
  totalFleet: number;
  interlocutorName: string;

  phoneNo: string;
  mobile_Phone_No: string;
  e_Mail: string;
}

export const emptyRequiredData: RequiredDataProps = {
  no: "",
  odataEtag: "",
  name: "",
  name2: "",
  salespersonCode: "",
  salesPersonName: "",
  vaT_Registration_No: "",
  type:"",
  customerType: "",
  customerTypePortal: "",
  totalFleet: 0,

  phoneNo: "",
  mobile_Phone_No: "",
  e_Mail: "",
  interlocutorName:"",
 
};

