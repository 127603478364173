// import CryptoJS from 'crypto-js';
// import { UserServices } from '../services/UserServices';

import { ExportFormat } from "../model/Enums";

export function validateEmail(value: any): boolean {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
    return true;
  }
  return false;
}

export function validatePhoneNumber(value: any): boolean {
  if (value.length !== 9) 
  return false;  
  if (/^[0-9]+$/.test(value)) 
    return true;
  return false;
}

export function validateStringFormatNumber(str:string,oldValue:any): string {
  if (/^[0-9]+$/.test(str)) 
    return str;
  return oldValue;
}

export function validaStringFormatDecimal(str:string,oldValue:any) : string{
    const regex = /^-?\d*\.?\d*$/;
 
  if(str === ""){
    return "0";

  }else if(str === "0-" || str === "-0"){
      return "-";
  }
  else if (!regex.test(str)) {
    return oldValue; 
  }
  
  const parts = str.split('.');
  if (parts[1] && parts[1].length > 2) {
    return oldValue;
  }
  
  parts[0] = parts[0].replace(/^0+/, '');
  if (parts[0] === '') {
    parts[0] = '0'; 
  }

  str = parts.join('.'); 
  return parts.join('.');


}

export function validaFormatoDecimal(str:string): boolean {
  // Use uma expressão regular para verificar se a string é um número decimal válido
  const regex = /^-?\d*\.?\d*$/;
  return regex.test(str);
}

export function convertNumber(str:any) : number{
   
  if(!str){
    return 0;
  }
  if (str.toString().includes('.')) {
    const parts = str.toString().split('.');
    if (parts[0] === '') {
      parts[0] = '0'; 
    }
    if (parts[1] === '') {
      parts[1] = '0'; 
    }
    str = parts.join('.'); 
  }

  var num = parseFloat(str.toString());
  if (isNaN(num)) {
    return 0;
  } else {
    return num;
  } 
}

export function validateNIF(value: any): boolean {
  const nif = typeof value === "string" ? value : value.toString();
  const validationSets = {
    one: ["1", "2", "3", "5", "6", "8"],
    two: [
      "45",
      "70",
      "71",
      "72",
      "74",
      "75",
      "77",
      "79",
      "90",
      "91",
      "98",
      "99",
    ],
  };
  if (nif.length !== 9) return false;
  if (
    !validationSets.one.includes(nif.substr(0, 1)) &&
    !validationSets.two.includes(nif.substr(0, 2))
  )
    return false;

  const total =
    nif[0] * 9 +
    nif[1] * 8 +
    nif[2] * 7 +
    nif[3] * 6 +
    nif[4] * 5 +
    nif[5] * 4 +
    nif[6] * 3 +
    nif[7] * 2;
  const modulo11 = Number(total) % 11;
  const checkDigit = modulo11 < 2 ? 0 : 11 - modulo11;

  return checkDigit === Number(nif[8]);
}

// export function encryptMD5(toEncrypt:string, useHashing:boolean) {
//   let secret:string = '';
//   const userService = new UserServices();
//   userService.getAuth().then(data => {
//     if(data.succeeded)
//       secret = data.value;
//   });
//   // const secret = "!KintoSimulador123";
//   let key = CryptoJS.enc.Utf8.parse(secret);
//   let encrypted = '';
//   if (useHashing) {
//     key = CryptoJS.MD5(secret);
//   }
//   encrypted = CryptoJS.TripleDES.encrypt(toEncrypt, key, {
//     mode: CryptoJS.mode.ECB,
//     padding: CryptoJS.pad.Pkcs7
//   }).toString();
//   return encrypted;
// }

export function formatDateWithTimeZone<T extends string>(dateString: T, timeZone: string, timeUnitToExtend?: string, valueToExtend?: number): string {
  try {
    const date = new Date(dateString);
    // se for preciso adicionar tempo
    if(valueToExtend && timeUnitToExtend){
      switch(timeUnitToExtend){
        case "days":
          date.setDate(date.getDate() + valueToExtend);
          break;
        case "months":
          date.setMonth(date.getMonth() + valueToExtend);
          break;
        case "years":
          date.setFullYear(date.getFullYear() + valueToExtend);
          break;
        default:
            break;
      }
    
    }
    let options:Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    // new Intl.DateTimeFormat(timeZone,options).format(date);
    return new Intl.DateTimeFormat(timeZone,options).format(date);
  }
  catch(ex){
    return dateString;
  }
}

export function passwordFormat(string:string) {
  // Verifica se a string tem pelo menos 8 caracteres
  if (string.length < 8) {
    return {hasErrors: false, errorType: 'size'};
  }

  // Verifica se a string contém pelo menos uma letra maiúscula e um número
  var possuiMaiuscula = false;
  var possuiNumero = false;

  for (var i = 0; i < string.length; i++) {
    var caractere = string.charAt(i);

    if (caractere >= 'A' && caractere <= 'Z') {
      possuiMaiuscula = true;
    } else if (!isNaN(Number(caractere))) {
      possuiNumero = true;
    }

    // Se já encontramos uma letra maiúscula e um número, podemos sair do loop
    if (possuiMaiuscula && possuiNumero) {
      break;
    }
  }

  // Retorna true se a string atender a todos os critérios
  if(!possuiMaiuscula) {
    return {hasErrors: (possuiMaiuscula && possuiNumero), errorType: 'uppercase'};
  } else if(!possuiNumero){
    return {hasErrors: (possuiMaiuscula && possuiNumero), errorType: 'number'};
  } else if(!possuiMaiuscula && !possuiNumero){
    return {hasErrors: (possuiMaiuscula && possuiNumero), errorType: 'both'};
  } else{
    return {hasErrors: (possuiMaiuscula && possuiNumero), errorType: ''};
  }
}

export function downloadFile(base64String:string, filename:string, exportFormat: ExportFormat) {
  // Convert the Base64 string back to binary data
  const binaryString = atob(base64String);

  // Create an array of 8-bit unsigned integers
  const bytes = new Uint8Array(binaryString.length);

  // Assign the binary data to the bytes
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  let blob;
  // Create a new Blob object from the bytes
  switch(exportFormat){
    case ExportFormat.PDF:
      blob =  new Blob([bytes], { type: 'application/pdf' });;
      break;
    case ExportFormat.Excel:
      blob = new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      break;
      
    default:
      break;
  }
  if(blob){
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};
export function convertStringToEnum(value: string): ExportFormat | undefined {
  return ExportFormat[value as keyof typeof ExportFormat];
};

