//import axios from "axios";
import axiosInstance from "../Utilities/axiosInstance";
//import settings from "../appSettings.json";
import { Customer } from "../model/Customer";
import { SettingsService } from "./SettingsService";

export class CustomerService {
  private settingsService: any;
  private entityURL: string = "";// =    settings.APIBaseURL + settings.Customer_RelativeURL;

  // constructor(){
  //   //const settingsService = SettingsService.getInstance();
  //   this.entityURL = "";//
  // }
  ensureSettingsService =  async () => {
    this.settingsService = await SettingsService.getInstance();
    this.entityURL = this.settingsService.settings.APIBaseURL + this.settingsService.settings.Customer_RelativeURL;
  };
  getCustomers = async () => {
    try {
      await this.ensureSettingsService();
      const { data } = await axiosInstance.get(this.entityURL);
      return data.value;
    } catch (error) {
      console.log(error);
    }
  };
  getCustomersByCombo = async (query:string) => {
    try {
      await this.ensureSettingsService();
      const { data } = await axiosInstance.get(this.entityURL + "GetByCombo?q="+query);
      return data.value;
    } catch (error) {
      console.log(error);
    }
  };
  getCustomersByNo = async (no: string) => {
    try {
      await this.ensureSettingsService();
      const { data } = await axiosInstance.get(this.entityURL + no);
      return data.value;
    } catch (error) {
      console.log(error);
    }
  };
  getCenterDealer = async () => {
    try {
      await this.ensureSettingsService();
      const url =this.entityURL + this.settingsService.settings.CenterDealer_RelativeURL;
      const { data } = await axiosInstance.get(url);
      
      return data.value;
    } catch (error) {
      console.log(error);
    }
  };
  postCustomers = async (customer: Customer) => {
    try {
      await this.ensureSettingsService();
      const { data } = await axiosInstance.post(this.entityURL, customer);
      return data.value;
    } catch (error) {
      console.log(error);
    }
  };
  putCustomers = async (customer: Customer, no: string) => {
    try {
      await this.ensureSettingsService();
      const { data } = await axiosInstance.put(this.entityURL + no, customer);
      return data.value;
    } catch (error) {
      console.log(error);
    }
  };
  ///TODO: se 401 entra em loop infinito
  getSalesPersons = async () => {
    try {
      await this.ensureSettingsService();
      const url = `${this.entityURL}SalesPerson`;
      const { data } = await axiosInstance.get(url);
      
      return data;
    } catch (error) {
      console.log(error);
    }
  }
  getSalesPersonsByName = async (name:string) => {
    try {
      await this.ensureSettingsService();
      const url = `${this.entityURL}SalesPersonByName/`;
      const { data } = await axiosInstance.get(url + name);
      
      return data;
    } catch (error) {
      console.log(error);
    }
  }
}
