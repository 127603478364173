import {
    AccountIcon,
    ButtonProps,
    OrderDetailsPage,
    OrderDetailsPageProps,
    ProgressBarProps,
    RadioGroupProps,
    SuccessIcon,
    WizardProps,
  } from '@hi-interactive-admin/simulador-kinto';
  import React, { useState } from 'react';
  import { useParams } from 'react-router-dom';
  
  type OrderDetailsRouteParams = {
    proposalId: string;
  };
  
  const handleStepClick: (index: number) => void = (index) => {
    alert(`go to step: ${index + 1}`);
  };
  const steps: WizardProps['steps'] = [
    {
      label: 'Análise de Risco',
      onClick: handleStepClick,
      canClick: true,
    },
    {
      label: 'Nota de encomenda',
      onClick: handleStepClick,
      canClick: true,
    },
    {
      label: 'Entrega',
      onClick: handleStepClick,
      canClick: true,
    },
  ];
  
  type Partners =
    OrderDetailsPageProps['riskAnalysisProps']['riskAnalysisArea']['clientFile']['optionalClientDataArea']['partners'];
  
  const OrderDetailsPageDemo = () => {
    const params = useParams<OrderDetailsRouteParams>();
  
    const variant: OrderDetailsPageProps['variant'] =
      params.proposalId === 'proposal2' ? 'kinto' : 'concessionaire';
  
    const [step, setStep] = useState<number>(0);
    const [partners, setPartners] = useState<Partners>([
      {
        partnerOrMajorShareholdersTextFieldProps: {
          label: 'Sócios ou Acionistas principais',
          placeholder: 'Inserir nome',
        },
        equityParticipationTextFieldProps: {
          label: 'Participação no capital',
          placeholder: 'Inserir percentagem',
        },
      },
    ]);
    const [secondAddress, setSecondAddress] = useState<boolean>(false);
    const [billingAddress, setBillingAddress] = useState<string>('sameAddress');
    const [signDemo, setSignDemo] = useState<string>('');
    const [signEmailConfirmationModalOpen, setSignEmailConfirmationModalOpen] =
      useState<boolean>(false);
    const [firstRowGeneratedStatus, setFirstRowGeneratedStatus] =
      useState<
        OrderDetailsPageProps['orderDetailProps']['vehicles'][0]['generatedStatus']
      >();
    const [firstRowConfirmationModalFile, setFirstRowConfirmationModalFile] =
      useState<ProgressBarProps[]>();
  
    const handleOpenEmailConfirmationModal = () => {
      setSignEmailConfirmationModalOpen(true);
    };
  
    const handleCloseEmailConfirmationModal = () => {
      setSignEmailConfirmationModalOpen(false);
    };
  
    const handleValidateOrder = () => {
      setFirstRowGeneratedStatus({
        tagLabel: 'Nota de encomenda validada',
        tagColor: 'success',
      });
    };
  
    const handleConfirmationModalFileChange = () => {
      setFirstRowConfirmationModalFile([
        {
          title: 'nomeDoFicheiro.pdf',
          progress: 33,
          deleteIcon: true,
          label: '2.3MB/8.4MB',
        },
      ]);
    };
  
    const handleAddDocumentationAddress: ButtonProps['onClick'] = () => {
      setSecondAddress(true);
    };
  
    const handleForward: ButtonProps['onClick'] = () => {
      if (step < 2) setStep(step + 1);
    };
  
    const handleBackward: ButtonProps['onClick'] = () => {
      if (step > 0) setStep(step - 1);
    };
  
    const handleAddPartner: ButtonProps['onClick'] = () => {
      partners.push({
        partnerOrMajorShareholdersTextFieldProps: {
          label: 'Sócios ou Acionistas principais',
          placeholder: 'Inserir nome',
        },
        equityParticipationTextFieldProps: {
          label: 'Participação no capital',
          placeholder: 'Inserir percentagem',
        },
      });
      setPartners([...partners]);
    };
  
    const handleBillingAddressChange: RadioGroupProps['onChange'] = (e) => {
      setBillingAddress(e.target.value);
    };
  
    return (
      <OrderDetailsPage
        variant={variant}
        topBarProps={{
          actions: [
            {
              label: 'Perfil',
              onClick: () => alert(''),
            },
  
            {
              label: 'Definições',
              onClick: () => alert(''),
            },
            {
              label: 'Idioma (PT)',
              onClick: () => alert(''),
            },
  
            {
              label: 'Logout',
              onClick: () => alert(''),
            },
          ],
          notificationButtonProps: {
            onClick: () => alert('alterar o numero de notificações por ler'),
          },
          username: 'John Doe',
          userImageSrc: 'assets/UserImage.png',
          onLogoClick: () => alert('Kinto logo'),
          onUserClick: () => alert('Hello John Doe!'),
          notificationDrawerProps: {
            tagLabel: 'Nova',
            notificationText: 'Notificações',
            notificationsNumber: '2 novas',
            notifications: [
              {
                isNew: true,
                avatarSrc: AccountIcon,
                name: 'Luís Brazão',
                text: `Boa tarde Pedro, praesent faucibus odio vitae ornare ullamcorper.
        Praesent ultrices risus sit amet enim ultrices bibendum. Faucibus
        odio vitae ornare ullamcorper. Praesent ultrices risus sit amet
        enim ultrices bibendum.`,
                date: `Ontem às 17:32`,
              },
            ],
          },
        }}
        bottomBarProps={{
          cancelButtonProps: {
            onClick: () => alert('cancelar a criação da proposta'),
          },
          cancelText: 'Cancelar',
          extraButtonText: '',
          backwardText: 'Voltar',
          backwardButtonProps: {
            disabled: step === 0,
            onClick: handleBackward,
          },
          forwardText: step < 2 ? 'Avançar' : 'Concluir processo',
          forwardButtonProps: { disabled: false, onClick: handleForward },
        }}
        dashboardMenuButtonProps={{
          label: 'Dashboard',
          onClick: () => alert('menu click'),
        }}
        proposalMenuButtonProps={{
          label: 'Propostas',
          onClick: () => alert('menu click'),
        }}
        clientsMenuButtonProps={{
          label: 'Clientes',
          onClick: () => alert('menu click'),
        }}
        contractsMenuButtonProps={{
          label: 'Contratos',
          onClick: () => alert('menu click'),
        }}
        performanceMenuButtonProps={{
          label: 'Performance',
          onClick: () => alert('menu click'),
        }}
        backofficeMenuButtonProps={{
          label: 'Backoffice',
          onClick: () => alert('menu click'),
        }}
        steps={steps}
        activeStep={step}
        breadCrumbsLinks={[
          {
            label: 'Propostas',
          },
          {
            label: 'Proposta Nº:C010089803',
          },
        ]}
        orderInfo={{
          client: {
            value: 'EGEO Pressão, S.A.',
            id: 'Client Nº C102278',
          },
          rentalType: { label: 'Tipo de aluguer', value: 'AOV' },
          commercialConditions: {
            label: 'Condições comerciais',
            value: ['CPAOV004', 'CPAOV004'].join(' e '),
          },
          clientType: 'Grandes empresas - Frota > 30 veículos',
          inputChannel: { label: 'Canal de entrada', value: 'Direto' },
          underNegotiation: { label: 'Em negociação', value: '25 veículos' },
          expiryDate: {
            label: 'Validade',
            from: 'CRIADA a 17 jun. 2020',
            to: 'VÁLIDA até 17 jul 2020',
          },
          Responsible: { label: 'Responsável', value: 'Luís Brazão' },
  
          editText: 'editar',
          onEditClick: () => {
            alert('editar');
          },
        }}
        proposalNumber="C010089803"
        proposalText="Proposta Nº:"
        historyButtonText="Ver histórico de ações"
        notesButtonText="Notas"
        unreadNotesNumber={2}
        riskAnalysisProps={{
          riskAnalysisArea: {
            title: 'Análise de Risco ',
            expandText: 'Abrir detalhes',
            collapseText: 'Fechar detalhes',
            type: 'Com letra',
            status: { label: 'Aprovado', color: 'success' },
            clientFile: {
              note:
                variant === 'kinto'
                  ? {
                      imageSrc: 'assets/UserImage.png',
                      title: 'Nota da Análise de risco',
                      text: 'Boa tarde Luís, praesent faucibus odio vitae ornare ullamcorper. Praesent ultrices risus sit amet enim ultrices bibendum.',
                      date: 'hoje às 10:15',
                    }
                  : undefined,
              title: 'Ficha cliente',
              socialDenominationTextFieldProps: {
                label: 'Denominação social',
                defaultValue: 'EGEO Circular, S.A. e EGEO Pressão, S.A.',
              },
              taxNumberTextFieldProps: {
                label: 'NIF',
                defaultValue: '514986298',
              },
              clientTypologyDropDownProps: {
                options: [
                  {
                    label: 'Grandes Empresas (Frota > 30 veículos)',
                    value: 'ge_f30',
                  },
                ],
                label: 'Tipologia de Cliente',
                defaultValue: 'ge_f30',
                placeholder: 'Inserir Tipologia de Cliente',
              },
              totalFleetTextFieldProps: {
                label: 'Frota total',
                defaultValue: 40,
              },
              expandDetailsText: 'Mostrar mais detalhes da ficha de cliente',
              collapseDetailsText: 'Fechar detalhes da ficha de cliente',
              optionalClientDataArea: {
                title: 'Dados Cliente (Opcional)',
                enterpriseGroupSearchResultProps: {
                  placeholder: 'Pesquisar',
                  options: [
                    {
                      label: 'EGEO Pressão, S.A.',
                      value: 'egeoPSA',
                    },
                  ],
                  label: 'Grupo empresarial',
                },
                bankTextFieldProps: {
                  label: 'Banco',
                  placeholder: 'Inserir banco',
                },
                swiftCodeTextFieldProps: {
                  label: 'Swift Code',
                  placeholder: 'Inserir swift code',
                },
                shareCapitalTextFieldProps: {
                  label: 'Capital social',
                  placeholder: 'Inserir capital social',
                },
                commercialRegistryTextFieldProps: {
                  label: 'Registo comercial',
                  placeholder: 'Inserir registo comercial',
                },
                IBANTextFieldProps: {
                  label: 'IBAN',
                  placeholder: 'Inserir IBAN',
                },
                onDeletePartnerRow: (index) => alert(`remover linha ${index}`),
                partners: partners,
                addPartnerOrShareholderButtonText: 'Adicionar sócio/acionista',
                addPartnerOrShareholderButtonProps: {
                  onClick: handleAddPartner,
                },
              },
              interlocutorDataArea: {
                title: 'Dados do interlocutor',
                nameTextFieldProps: {
                  label: 'Nome',
                  placeholder: 'Inserir nome',
                },
                phoneInputProps: {
                  label: 'País',
                  defaultValue: 'PT',
                },
                phoneNumberTextFieldProps: {
                  label: 'Telefone',
                  placeholder: 'Inserir número',
                },
                emailTextFieldProps: {
                  label: 'E-mail',
                  placeholder: 'Inserir e-mail',
                },
                mobilePhoneInputProps: {
                  label: 'País',
                  defaultValue: 'PT',
                },
                mobilePhoneNumberTextFieldProps: {
                  label: 'Telemóvel',
                  placeholder: 'Inserir número',
                },
              },
              documentationAddressArea: {
                title: 'Morada de envio documentação',
                careOfTextFieldProps: {
                  label: 'A/C',
                  placeholder: 'Inserir nome',
                },
                countryInputProps: {
                  label: 'País',
                  defaultValue: 'PT',
                },
                postalCodeSecondPartTextFieldProps: {},
                postalCodeTextFieldProps: {
                  label: 'Código Postal',
                },
                locationTextFieldProps: { value: 'Prior Velho' },
                headquartersAddressTextFieldProps: {
                  label: 'Morada Sede',
                  placeholder: 'Inserir morada sede',
                },
  
                addAddressButtonText: 'Adicionar Segunda morada',
                addAddressButtonProps: {
                  onClick: handleAddDocumentationAddress,
                  disabled: secondAddress,
                },
              },
              secondDocumentationAddressArea: {
                careOfTextFieldProps: {},
                countryInputProps: {},
                postalCodeSecondPartTextFieldProps: {},
                postalCodeTextFieldProps: {},
                locationTextFieldProps: {},
                headquartersAddressTextFieldProps: {},
              },
              billingAddressArea: {
                title: 'Definir morada de envio faturação ',
  
                sameAddressRadioProps: {
                  label: 'Mesma morada de envio de documentação',
                  value: 'sameAddress',
                },
                addAddressRadioProps: {
                  label: 'Adicionar outra morada',
                  value: 'newAddress',
                },
                billingRadioGroupProps: {
                  value: billingAddress,
                  onChange: handleBillingAddressChange,
                },
                address: {
                  careOfTextFieldProps: {},
                  countryInputProps: {},
                  postalCodeSecondPartTextFieldProps: {},
                  postalCodeTextFieldProps: {},
                  locationTextFieldProps: {},
                  headquartersAddressTextFieldProps: {},
                },
              },
              enterpriseDocumentsArea: {
                title: 'Documentação empresa',
                documents: [
                  {
                    date: variant === 'kinto' ? '12.Mar.2022' : undefined,
                    actions:
                      variant === 'kinto'
                        ? [
                            {
                              label: 'Solicitar novo',
                              onClick: () => alert('action click'),
                            },
  
                            {
                              label: 'Partilhar',
                              onClick: () => alert('action click'),
                            },
  
                            {
                              label: 'Visualizar',
                              onClick: () => alert('action click'),
                            },
  
                            {
                              label: 'Imprimir',
                              onClick: () => alert('action click'),
                            },
  
                            {
                              label: 'Eliminar',
                              onClick: () => alert('action click'),
                            },
                          ]
                        : undefined,
                    title: 'IES - últimos 2 anos',
                    status: {
                      type: 'error',
                      errorText:
                        'Actualizar documento, tem uma data anterior aos últimos dois anos',
                    },
                    fileAttachArea:
                      variant === 'kinto'
                        ? undefined
                        : [
                            {
                              attachFileButtonText: 'Anexar ficheiro',
                              allowedTypesText:
                                'Tipos de ficheiros permitidos: jpg, jpeg, png, doc, pdf, gif, zip, rar, txt, xls, docx, xlsx.',
                              allowedTypes:
                                '.pdf,image/*,.doc,.zip,.txt,.rar,.docx,.xlsx',
                              fileMaxSize:
                                'O tamanho máximo dos ficheiros é 2 MB',
                              filesProgressBarProps: [
                                {
                                  progress: 50,
                                  title: 'nomedoficheiro.pdf',
                                  deleteIcon: true,
                                  label: '2.3MB/8.4MB',
                                  deleteTooltipText: 'Apagar',
                                  onDeleteClick: () => alert('cancelar'),
                                },
                              ],
                            },
                          ],
  
                    textfieldProps:
                      variant === 'kinto'
                        ? undefined
                        : [{ defaultValue: '23-11-2019' }],
                    checkboxProps:
                      variant === 'kinto'
                        ? undefined
                        : [
                            {
                              label: 'Só possuo IES do último ano',
                              defaultChecked: true,
                            },
                          ],
                  },
                  {
                    date: variant === 'kinto' ? '12.Mar.2022' : undefined,
                    actions:
                      variant === 'kinto'
                        ? [
                            {
                              label: 'Solicitar novo',
                              onClick: () => alert('action click'),
                            },
  
                            {
                              label: 'Partilhar',
                              onClick: () => alert('action click'),
                            },
  
                            {
                              label: 'Visualizar',
                              onClick: () => alert('action click'),
                            },
  
                            {
                              label: 'Imprimir',
                              onClick: () => alert('action click'),
                            },
  
                            {
                              label: 'Eliminar',
                              onClick: () => alert('action click'),
                            },
                          ]
                        : undefined,
                    title: 'Modelo 22 - últimos 2 anos',
                    status: {
                      type: 'success',
                    },
                    fileAttachArea:
                      variant === 'kinto'
                        ? undefined
                        : [
                            {
                              attachFileButtonText: 'Anexar ficheiro',
                              allowedTypesText:
                                'Tipos de ficheiros permitidos: jpg, jpeg, png, doc, pdf, gif, zip, rar, txt, xls, docx, xlsx.',
                              allowedTypes:
                                '.pdf,image/*,.doc,.zip,.txt,.rar,.docx,.xlsx',
                              fileMaxSize:
                                'O tamanho máximo dos ficheiros é 2 MB',
                              filesProgressBarProps: [
                                {
                                  progress: 50,
                                  title: 'nomedoficheiro.pdf',
                                  deleteIcon: true,
                                  label: '2.3MB/8.4MB',
                                  deleteTooltipText: 'Apagar',
                                  onDeleteClick: () => alert('cancelar'),
                                },
                              ],
                            },
                          ],
                  },
                  {
                    date: variant === 'kinto' ? '12.Mar.2022' : undefined,
                    actions:
                      variant === 'kinto'
                        ? [
                            {
                              label: 'Solicitar novo',
                              onClick: () => alert('action click'),
                            },
  
                            {
                              label: 'Partilhar',
                              onClick: () => alert('action click'),
                            },
  
                            {
                              label: 'Visualizar',
                              onClick: () => alert('action click'),
                            },
  
                            {
                              label: 'Imprimir',
                              onClick: () => alert('action click'),
                            },
  
                            {
                              label: 'Eliminar',
                              onClick: () => alert('action click'),
                            },
                          ]
                        : undefined,
                    title: 'Balancete Detalhado (dos anos sem IES)',
                    status: {
                      type: 'success',
                    },
                    fileAttachArea:
                      variant === 'kinto'
                        ? undefined
                        : [
                            {
                              attachFileButtonText: 'Anexar ficheiro',
                              allowedTypesText:
                                'Tipos de ficheiros permitidos: jpg, jpeg, png, doc, pdf, gif, zip, rar, txt, xls, docx, xlsx.',
                              allowedTypes:
                                '.pdf,image/*,.doc,.zip,.txt,.rar,.docx,.xlsx',
                              fileMaxSize:
                                'O tamanho máximo dos ficheiros é 2 MB',
                              filesProgressBarProps: [
                                {
                                  progress: 50,
                                  title: 'nomedoficheiro.pdf',
                                  deleteIcon: true,
                                  label: '2.3MB/8.4MB',
                                  deleteTooltipText: 'Apagar',
                                  onDeleteClick: () => alert('cancelar'),
                                },
                              ],
                            },
                          ],
                  },
  
                  {
                    date: variant === 'kinto' ? '12.Mar.2022' : undefined,
                    actions:
                      variant === 'kinto'
                        ? [
                            {
                              label: 'Solicitar novo',
                              onClick: () => alert('action click'),
                            },
  
                            {
                              label: 'Partilhar',
                              onClick: () => alert('action click'),
                            },
  
                            {
                              label: 'Visualizar',
                              onClick: () => alert('action click'),
                            },
  
                            {
                              label: 'Imprimir',
                              onClick: () => alert('action click'),
                            },
  
                            {
                              label: 'Eliminar',
                              onClick: () => alert('action click'),
                            },
                          ]
                        : undefined,
                    title: 'Comprovativo de IBAN',
                    status: {
                      type: 'success',
                    },
                    fileAttachArea:
                      variant === 'kinto'
                        ? undefined
                        : [
                            {
                              attachFileButtonText: 'Anexar ficheiro',
                              allowedTypesText:
                                'Tipos de ficheiros permitidos: jpg, jpeg, png, doc, pdf, gif, zip, rar, txt, xls, docx, xlsx.',
                              allowedTypes:
                                '.pdf,image/*,.doc,.zip,.txt,.rar,.docx,.xlsx',
                              fileMaxSize:
                                'O tamanho máximo dos ficheiros é 2 MB',
                              filesProgressBarProps: [
                                {
                                  progress: 50,
                                  title: 'nomedoficheiro.pdf',
                                  deleteIcon: true,
                                  label: '2.3MB/8.4MB',
                                  deleteTooltipText: 'Apagar',
                                  onDeleteClick: () => alert('cancelar'),
                                },
                              ],
                            },
                          ],
                  },
  
                  {
                    date: variant === 'kinto' ? '12.Mar.2022' : undefined,
                    actions:
                      variant === 'kinto'
                        ? [
                            {
                              label: 'Solicitar novo',
                              onClick: () => alert('action click'),
                            },
  
                            {
                              label: 'Partilhar',
                              onClick: () => alert('action click'),
                            },
  
                            {
                              label: 'Visualizar',
                              onClick: () => alert('action click'),
                            },
  
                            {
                              label: 'Imprimir',
                              onClick: () => alert('action click'),
                            },
  
                            {
                              label: 'Eliminar',
                              onClick: () => alert('action click'),
                            },
                          ]
                        : undefined,
                    title:
                      'Mapa de Responsabilidade de Crédito (Banco de Portugal)',
                    status: {
                      type: 'success',
                    },
                    fileAttachArea:
                      variant === 'kinto'
                        ? undefined
                        : [
                            {
                              attachFileButtonText: 'Anexar ficheiro',
                              allowedTypesText:
                                'Tipos de ficheiros permitidos: jpg, jpeg, png, doc, pdf, gif, zip, rar, txt, xls, docx, xlsx.',
                              allowedTypes:
                                '.pdf,image/*,.doc,.zip,.txt,.rar,.docx,.xlsx',
                              fileMaxSize:
                                'O tamanho máximo dos ficheiros é 2 MB',
                              filesProgressBarProps: [
                                {
                                  progress: 50,
                                  title: 'nomedoficheiro.pdf',
                                  deleteIcon: true,
                                  label: '2.3MB/8.4MB',
                                  deleteTooltipText: 'Apagar',
                                  onDeleteClick: () => alert('cancelar'),
                                },
                              ],
                            },
                          ],
                  },
                ],
              },
              registrationGroupArea: {
                businessDropDownProps: {
                  label: 'Grupo Registo Negócio',
                  placeholder: 'Selecionar',
                },
                clientDropDownProps: {
                  label: 'Grupo Registo Cliente',
                  placeholder: 'Selecionar',
                },
              },
            },
          },
          frameworkContractArea: {
            seeLinkText: variant === 'kinto' ? 'visualizar' : undefined,
            onSeeLinkClick: variant === 'kinto' ? () => alert('view') : undefined,
            date: variant === 'kinto' ? '12.Mar.2022' : undefined,
            actions:
              variant === 'kinto'
                ? [
                    {
                      label: 'Solicitar novo',
                      onClick: () => alert('action click'),
                    },
  
                    {
                      label: 'Partilhar',
                      onClick: () => alert('action click'),
                    },
  
                    {
                      label: 'Visualizar',
                      onClick: () => alert('action click'),
                    },
  
                    {
                      label: 'Imprimir',
                      onClick: () => alert('action click'),
                    },
  
                    {
                      label: 'Eliminar',
                      onClick: () => alert('action click'),
                    },
                  ]
                : undefined,
            title: 'Contrato de quadro',
            sentDate: 'Enviado a 12.03.2022',
            status: { color: 'warning', label: 'Aguarda assinatura' },
            signDropDownProps:
              variant === 'kinto'
                ? undefined
                : {
                    options: [
                      {
                        label: 'Digitalmente',
                        value: 'digital',
                      },
                      {
                        label: 'Download do documento',
                        value: 'download',
                      },
                    ],
                    placeholder: 'Assinar',
                    value: signDemo,
                    onChange: (e) => {
                      setSignDemo(e.target.value);
                      if (e.target.value === 'download')
                        alert('Download do documento');
                      else if (e.target.value === 'digital')
                        handleOpenEmailConfirmationModal();
                    },
                  },
            emailConfirmationModalProps:
              variant === 'kinto'
                ? undefined
                : {
                    cancelText: 'Cancelar',
                    confirmText: 'Validar',
                    title: 'Confirmar email de envio do Contrato',
                    text: 'Por favor valide o email de envio do contrato',
                    emailTextFieldProps: {
                      defaultValue: 'ri.miranda@egeo.pt',
                      label: 'Email para envio',
                    },
                    open: signEmailConfirmationModalOpen,
                    handleClose: handleCloseEmailConfirmationModal,
                    onCancel: handleCloseEmailConfirmationModal,
                    onConfirm: () => {
                      handleCloseEmailConfirmationModal();
                      alert('validar');
                    },
                  },
            printButtonText: variant === 'kinto' ? undefined : 'Imprimir',
            onPrintClick:
              variant === 'kinto' ? undefined : () => alert('imprimir'),
            attachFile: signDemo === 'download',
            fileAttachArea:
              variant === 'kinto'
                ? undefined
                : {
                    attachFileButtonText: 'Anexar ficheiro',
                    allowedTypesText:
                      'Tipos de ficheiros permitidos: jpg, jpeg, png, doc, pdf, gif, zip, rar, txt, xls, docx, xlsx.',
                    allowedTypes: '.pdf,image/*,.doc,.zip,.txt,.rar,.docx,.xlsx',
                    fileMaxSize: 'O tamanho máximo dos ficheiros é 2 MB',
                    filesProgressBarProps: {
                      progress: 50,
                      title: 'nomedoficheiro.pdf',
                      deleteIcon: true,
                      label: '2.3MB/8.4MB',
                      deleteTooltipText: 'Apagar',
                      onDeleteClick: () => alert('cancelar'),
                    },
                  },
          },
          directDebitAuthorizationArea: {
            seeLinkText: variant === 'kinto' ? 'visualizar' : undefined,
            onSeeLinkClick: variant === 'kinto' ? () => alert('view') : undefined,
            date: variant === 'kinto' ? '12.Mar.2022' : undefined,
            actions:
              variant === 'kinto'
                ? [
                    {
                      label: 'Solicitar novo',
                      onClick: () => alert('action click'),
                    },
  
                    {
                      label: 'Partilhar',
                      onClick: () => alert('action click'),
                    },
  
                    {
                      label: 'Visualizar',
                      onClick: () => alert('action click'),
                    },
  
                    {
                      label: 'Imprimir',
                      onClick: () => alert('action click'),
                    },
  
                    {
                      label: 'Eliminar',
                      onClick: () => alert('action click'),
                    },
                  ]
                : undefined,
            title: 'Autorização de debito directo',
            sentDate: 'Enviado a 12.03.2022',
            status: { color: 'success', label: 'Assinado' },
            signDropDownProps:
              variant === 'kinto'
                ? undefined
                : {
                    options: [
                      {
                        label: 'Digitalmente',
                        value: 'digital',
                      },
                      {
                        label: 'Download do documento',
                        value: 'download',
                      },
                    ],
                    placeholder: 'Assinar',
                    defaultValue: 'digital',
                  },
            printButtonText: variant === 'kinto' ? undefined : 'Imprimir',
            onPrintClick:
              variant === 'kinto' ? undefined : () => alert('imprimir'),
            attachFile: false,
            fileAttachArea:
              variant === 'kinto'
                ? undefined
                : {
                    attachFileButtonText: 'Anexar ficheiro',
                    allowedTypesText:
                      'Tipos de ficheiros permitidos: jpg, jpeg, png, doc, pdf, gif, zip, rar, txt, xls, docx, xlsx.',
                    allowedTypes: '.pdf,image/*,.doc,.zip,.txt,.rar,.docx,.xlsx',
                    fileMaxSize: 'O tamanho máximo dos ficheiros é 2 MB',
                    filesProgressBarProps: {
                      progress: 50,
                      title: 'nomedoficheiro.pdf',
                      deleteIcon: true,
                      label: '2.3MB/8.4MB',
                      deleteTooltipText: 'Apagar',
                      onDeleteClick: () => alert('cancelar'),
                    },
                  },
  
            emailConfirmationModalProps:
              variant === 'kinto'
                ? undefined
                : {
                    cancelText: 'Cancelar',
                    confirmText: 'Validar',
                    title: 'Confirmar email de envio do Contrato',
                    text: 'Por favor valide o email de envio do contrato',
                    emailTextFieldProps: {
                      defaultValue: 'ri.miranda@egeo.pt',
                      label: 'Email para envio',
                    },
                    open: false,
                    handleClose: () => alert('fechar'),
                    onCancel: () => alert('fechar'),
                    onConfirm: () => alert('fechar e validar'),
                  },
          },
          fulfillmentAgreementArea: {
            seeLinkText: variant === 'kinto' ? 'visualizar' : undefined,
            onSeeLinkClick: variant === 'kinto' ? () => alert('view') : undefined,
            date: variant === 'kinto' ? '12.Mar.2022' : undefined,
            actions:
              variant === 'kinto'
                ? [
                    {
                      label: 'Solicitar novo',
                      onClick: () => alert('action click'),
                    },
  
                    {
                      label: 'Partilhar',
                      onClick: () => alert('action click'),
                    },
  
                    {
                      label: 'Visualizar',
                      onClick: () => alert('action click'),
                    },
  
                    {
                      label: 'Imprimir',
                      onClick: () => alert('action click'),
                    },
  
                    {
                      label: 'Eliminar',
                      onClick: () => alert('action click'),
                    },
                  ]
                : undefined,
            title: 'Pacto de preenchimento',
            sentDate: 'Enviado a 12.03.2022',
            status: { color: 'success', label: 'Assinado' },
            signDropDownProps:
              variant === 'kinto'
                ? undefined
                : {
                    options: [
                      {
                        label: 'Digitalmente',
                        value: 'digital',
                      },
                      {
                        label: 'Download do documento',
                        value: 'download',
                      },
                    ],
                    placeholder: 'Assinar',
                  },
            printButtonText: variant === 'kinto' ? undefined : 'Imprimir',
            onPrintClick:
              variant === 'kinto' ? undefined : () => alert('imprimir'),
            attachFile: true,
            fileAttachArea:
              variant === 'kinto'
                ? undefined
                : {
                    attachFileButtonText: 'Anexar ficheiro',
                    allowedTypesText:
                      'Tipos de ficheiros permitidos: jpg, jpeg, png, doc, pdf, gif, zip, rar, txt, xls, docx, xlsx.',
                    allowedTypes: '.pdf,image/*,.doc,.zip,.txt,.rar,.docx,.xlsx',
                    fileMaxSize: 'O tamanho máximo dos ficheiros é 2 MB',
                    filesProgressBarProps: {
                      progress: 100,
                      title: 'nomedoficheiro.pdf',
                      deleteIcon: true,
                      label: '2.3MB/8.4MB',
                      deleteTooltipText: 'Apagar',
                      onDeleteClick: () => alert('cancelar'),
                    },
                  },
            emailConfirmationModalProps:
              variant === 'kinto'
                ? undefined
                : {
                    cancelText: 'Cancelar',
                    confirmText: 'Validar',
                    title: 'Confirmar email de envio do Contrato',
                    text: 'Por favor valide o email de envio do contrato',
                    emailTextFieldProps: {
                      defaultValue: 'ri.miranda@egeo.pt',
                      label: 'Email para envio',
                    },
                    open: false,
                    handleClose: () => alert('fechar'),
                    onCancel: () => alert('fechar'),
                    onConfirm: () => alert('fechar e validar'),
                  },
          },
        }}
        orderDetailProps={{
          title: '6 Veículos em encomenda',
          subtitle:
            '3 com nota de encomenda gerada, 3 a aguardar geração da nota de encomenda',
          expandAllText: 'expandir todos',
          collapseAllText: 'diminuir todos',
          vehicles: [
            {
              name: 'Renault 208 Diesel 208 1.5 Bluehdi Business Active',
              characteristics: '(1461CC | 90CV | 5 lugares | 5 portas | Azul)',
              status: {
                label: 'Estado',
                tagLabel: '1/2 Nota de encomenda validada',
                tagColor: 'info',
              },
              amount: { label: 'Qtd.', value: '2' },
              expandText: 'Abrir detalhes',
              collapseText: 'Fechar detalhes',
              actions: [
                variant === 'kinto'
                  ? undefined
                  : {
                      label: 'Corrigir valores',
                    },
                {
                  label: 'Enviar ao cliente',
                  onClick: () => alert('action click'),
                },
                { label: 'Comentários', onClick: () => alert('action click') },
                { label: 'Ver histórico', onClick: () => alert('action click') },
                { label: 'Contactar', onClick: () => alert('action click') },
              ],
              rows: [
                {
                  warningToolTipText: [
                    'Valor actualizado: 19.276,65€',
                    'Valor anterior: 19.300,65€',
                  ],
                  id: 'CT 10089803',
                  deliveryDateTextFieldProps: {
                    label: 'Definir data prevista de entrega',
                    value: '00.00.00',
                  },
                  validateBusinessPriceTTextFieldWithWarningProps: {
                    warningIcon: true,
                    label: 'Validar preço de negócio',
                    value: '19.276€',
                  },
                  vehicleColorTextFieldProps: {
                    label: 'Cor da viatura',
                    value: 'Azul',
                  },
                },
                {
                  warningToolTipText: [
                    'Valor actualizado: 19.276,65€',
                    'Valor anterior: 19.300,65€',
                  ],
                  id: 'CT 100898034',
                  deliveryDateTextFieldProps: {
                    value: '00.00.00',
                  },
                  validateBusinessPriceTTextFieldWithWarningProps: {
                    warningIcon: true,
                    value: '19.276€',
                  },
                  vehicleColorTextFieldProps: {
                    value: 'Azul',
                  },
                },
              ],
              generateButtonText: 'Gerar nota de encomenda',
              onPrintClick: () => alert('imprimir'),
              generatedStatus: firstRowGeneratedStatus,
              printText: 'Imprimir nota de encomenda',
              attachProformaFileText:
                variant === 'kinto' ? undefined : 'Anexar proforma',
              onAttachProformaFileChange:
                variant === 'kinto' ? undefined : () => alert('file'),
              seeAllOptionalText: 'Ver todos os opcionais',
              generateConfirmationModalProps: {
                cancelText: 'Cancelar',
                confirmText: 'Validar',
                onConfirm: handleValidateOrder,
                text: 'Confirma que está de acordo com todos os dados da encomenda?',
                title: 'Confirmar geração da nota de encomenda',
              },
              validateValueConfirmationModalProps: {
                title: 'Corrigir valor',
                text: 'Por favor anexe fatura com o valor correcto',
                cancelText: 'Cancelar',
                confirmText: 'Enviar',
                attachFileButtonText: 'Anexar ficheiro',
                allowedTypes: '.pdf,image/*,.doc,.zip,.txt,.rar,.docx,.xlsx',
                allowedTypesText:
                  'Tipos de ficheiros permitidos: jpg, jpeg, png, doc, pdf, gif, zip, rar, txt, xls, docx, xlsx. ',
                fileMaxSize: 'O tamanho máximo dos ficheiros é 2 MB',
                onConfirm: () => alert('enviar'),
                warningTextFieldProps: [
                  { label: 'Preço negócio inicial', defaultValue: '19.276€' },
                  { label: 'Preço negócio novo', defaultValue: '19.276€' },
                ],
                onAttachFileChange: handleConfirmationModalFileChange,
                filesProgressBarProps: firstRowConfirmationModalFile,
              },
            },
            {
              name: 'Renault 208 Diesel 208 1.5 Bluehdi Business Active',
              characteristics: '(1461CC | 90CV | 5 lugares | 5 portas | Azul)',
              status: {
                label: 'Estado',
                tagLabel: '1/2 Nota de encomenda validada',
                tagColor: 'info',
              },
              amount: { label: 'Qtd.', value: '2' },
              expandText: 'Abrir detalhes',
              collapseText: 'Fechar detalhes',
              actions: [
                variant === 'kinto'
                  ? undefined
                  : {
                      label: 'Corrigir valores',
                    },
                {
                  label: 'Enviar ao cliente',
                  onClick: () => alert('action click'),
                },
                { label: 'Comentários', onClick: () => alert('action click') },
                { label: 'Ver histórico', onClick: () => alert('action click') },
                { label: 'Contactar', onClick: () => alert('action click') },
              ],
              rows: [
                {
                  warningToolTipText: [
                    'Valor actualizado: 19.276,65€',
                    'Valor anterior: 19.300,65€',
                  ],
                  id: 'CT 10089803',
                  deliveryDateTextFieldProps: {
                    label: 'Definir data prevista de entrega',
                    value: '00.00.00',
                  },
                  validateBusinessPriceTTextFieldWithWarningProps: {
                    label: 'Validar preço de negócio',
                    value: '19.276€',
                  },
                  vehicleColorTextFieldProps: {
                    label: 'Cor da viatura',
                    value: 'Azul',
                  },
                },
                {
                  warningToolTipText: [
                    'Valor actualizado: 19.276,65€',
                    'Valor anterior: 19.300,65€',
                  ],
                  id: 'CT 100898034',
                  deliveryDateTextFieldProps: {
                    value: '00.00.00',
                  },
                  validateBusinessPriceTTextFieldWithWarningProps: {
                    value: '19.276€',
                  },
                  vehicleColorTextFieldProps: {
                    value: 'Azul',
                  },
                },
              ],
              generateButtonText: 'Gerar nota de encomenda',
              onPrintClick: () => alert('imprimir'),
              generatedStatus: {
                tagLabel: 'Nota de encomenda validada',
                tagColor: 'success',
              },
              printText: 'Imprimir nota de encomenda',
              attachProformaFileText:
                variant === 'kinto' ? undefined : 'Anexar proforma',
              fileProgressBarProps: {
                title: 'proforma.pdf',
                progress: 60,
                deleteIcon: true,
                label: '2.3MB/8.4MB',
              },
              onAttachProformaFileChange:
                variant === 'kinto' ? undefined : () => alert('file'),
              seeAllOptionalText: 'Ver todos os opcionais',
              generateConfirmationModalProps: {
                cancelText: 'Cancelar',
                confirmText: 'Validar',
                onConfirm: () => alert('Validar'),
                text: 'Confirma que está de acordo com todos os dados da encomenda?',
                title: 'Confirmar geração da nota de encomenda',
              },
  
              validateValueConfirmationModalProps: {
                title: 'Corrigir valor',
                text: 'Por favor anexe fatura com o valor correcto',
                cancelText: 'Cancelar',
                confirmText: 'Enviar',
                attachFileButtonText: 'Anexar ficheiro',
                allowedTypes: '.pdf,image/*,.doc,.zip,.txt,.rar,.docx,.xlsx',
                allowedTypesText:
                  'Tipos de ficheiros permitidos: jpg, jpeg, png, doc, pdf, gif, zip, rar, txt, xls, docx, xlsx. ',
                fileMaxSize: 'O tamanho máximo dos ficheiros é 2 MB',
                onConfirm: () => alert('enviar'),
                warningTextFieldProps: [],
              },
            },
            {
              name: 'Renault 208 Diesel 208 1.5 Bluehdi Business Active',
              characteristics: '(1461CC | 90CV | 5 lugares | 5 portas | Azul)',
              status: {
                label: 'Estado',
                tagLabel: '1/2 Nota de encomenda validada',
                tagColor: 'info',
              },
              amount: { label: 'Qtd.', value: '2' },
              expandText: 'Abrir detalhes',
              collapseText: 'Fechar detalhes',
              actions: [
                variant === 'kinto'
                  ? undefined
                  : {
                      label: 'Corrigir valores',
                    },
                {
                  label: 'Enviar ao cliente',
                  onClick: () => alert('action click'),
                },
                { label: 'Comentários', onClick: () => alert('action click') },
                { label: 'Ver histórico', onClick: () => alert('action click') },
                { label: 'Contactar', onClick: () => alert('action click') },
              ],
              rows: [
                {
                  warningToolTipText: [
                    'Valor actualizado: 19.276,65€',
                    'Valor anterior: 19.300,65€',
                  ],
                  id: 'CT 10089803',
                  deliveryDateTextFieldProps: {
                    label: 'Definir data prevista de entrega',
                    value: '00.00.00',
                  },
                  validateBusinessPriceTTextFieldWithWarningProps: {
                    label: 'Validar preço de negócio',
                    value: '19.276€',
                  },
                  vehicleColorTextFieldProps: {
                    label: 'Cor da viatura',
                    value: 'Azul',
                  },
                },
                {
                  warningToolTipText: [
                    'Valor actualizado: 19.276,65€',
                    'Valor anterior: 19.300,65€',
                  ],
                  id: 'CT 100898034',
                  deliveryDateTextFieldProps: {
                    value: '00.00.00',
                  },
                  validateBusinessPriceTTextFieldWithWarningProps: {
                    warningIcon: true,
                    value: '19.276€',
                  },
                  vehicleColorTextFieldProps: {
                    value: 'Azul',
                  },
                },
              ],
              generateButtonText: 'Gerar nota de encomenda',
              onPrintClick: () => alert('imprimir'),
              generatedStatus: {
                tagLabel: 'Nota de encomenda validada',
                tagColor: 'success',
              },
              printText: 'Imprimir nota de encomenda',
              attachProformaFileText:
                variant === 'kinto' ? undefined : 'Anexar proforma',
              fileProgressBarProps: {
                title: 'proforma.pdf',
                progress: 60,
                deleteIcon: true,
                label: '2.3MB/8.4MB',
              },
              onAttachProformaFileChange:
                variant === 'kinto' ? undefined : () => alert('file'),
              seeAllOptionalText: 'Ver todos os opcionais',
              generateConfirmationModalProps: {
                cancelText: 'Cancelar',
                confirmText: 'Validar',
                onConfirm: () => alert('Validar'),
                text: 'Confirma que está de acordo com todos os dados da encomenda?',
                title: 'Confirmar geração da nota de encomenda',
              },
              validateValueConfirmationModalProps: {
                title: 'Corrigir valor',
                text: 'Por favor anexe fatura com o valor correcto',
                cancelText: 'Cancelar',
                confirmText: 'Enviar',
                attachFileButtonText: 'Anexar ficheiro',
                allowedTypes: '.pdf,image/*,.doc,.zip,.txt,.rar,.docx,.xlsx',
                allowedTypesText:
                  'Tipos de ficheiros permitidos: jpg, jpeg, png, doc, pdf, gif, zip, rar, txt, xls, docx, xlsx. ',
                fileMaxSize: 'O tamanho máximo dos ficheiros é 2 MB',
                onConfirm: () => alert('enviar'),
                warningTextFieldProps: [
                  { label: 'Preço negócio', defaultValue: '19.276€' },
                ],
                filesProgressBarProps: [
                  {
                    title: 'proforma.pdf',
                    progress: 60,
                    deleteIcon: true,
                    label: '2.3MB/8.4MB',
                  },
                ],
              },
            },
          ],
        }}
        deliveryProps={{
          title: '6 Veículos em encomenda',
          subtitle:
            '3 com nota de encomenda gerada, 3 com matrícula a aguardar entrega',
          expandAllText: 'expandir todos',
          collapseAllText: 'diminuir todos',
          vehicleModels: [
            {
              name: 'Renault 208 Diesel 208 1.5 Bluehdi Business Active',
              characteristics: '(1461CC | 90CV | 5 lugares | 5 portas | Azul)',
              status: {
                label: 'Estado',
                tagLabel: '1/2 Nota de encomenda validada',
                tagColor: 'info',
              },
              amount: { label: 'Qtd.', value: '2' },
              expandText: 'Abrir detalhes',
              collapseText: 'Fechar detalhes',
              vehicles: [
                {
                  receiptCertificateModalProps: {
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: 'Concluir',
                    title: 'Auto de recepção',
                    confirmButtonProps: { onClick: () => alert('concluir') },
  
                    deliveryDataArea: {
                      title: 'Dados de entrega',
                      toTextFieldProps: {
                        label: 'Para',
                        placeholder: 'Inserir',
                        defaultValue: 'Moutinho Porto - Comércio Automóveis, Sa',
                      },
                      dateTextFieldProps: {
                        label: 'Data',
                        placeholder: 'Inserir',
                        defaultValue: '00.00.00',
                      },
                      fromTextFieldProps: {
                        label: 'At. De',
                        placeholder: 'Inserir',
                        defaultValue: 'Martim Reis',
                      },
                      emailTextFieldProps: {
                        label: 'Email',
                        placeholder: 'Inserir',
                        defaultValue: 'martim.reis@gmail.com',
                      },
                      contractNumberTextFieldProps: {
                        label: 'Nª do Contrato',
                        placeholder: 'Inserir',
                        defaultValue: 'CT 10067024',
                      },
                      CA_NumberTextFieldProps: {
                        label: 'Nª do CA',
                        placeholder: 'Inserir',
                        defaultValue: 'CA10787772',
                      },
                      registrationPlateTextFieldProps: {
                        label: 'Matricula',
                        placeholder: 'Inserir',
                        defaultValue: '00-AA-00',
                      },
                    },
                    vehicleDataArea: {
                      title: 'Dados da Viatura',
                      subtitle:
                        'Serve o presente documento para autorizar a entrega da viatura com os dados abaixo',
                      authorizedPersonTextFieldProps: {
                        label: 'Pessoa Autorizada',
                        placeholder: 'Inserir',
                        defaultValue: 'António Silva',
                      },
                      brandModelTextFieldProps: {
                        label: 'Marca/Modelo',
                        placeholder: 'Inserir',
                        defaultValue: 'Peugeot 208 -208 1.2 Puretech Active Pack',
                      },
                      chassisNumberTextFieldProps: {
                        label: 'Nº DE CHASSIS',
                        placeholder: 'Inserir',
                        defaultValue: 'Peugeot 208 -208 1.2 Puretech Active Pack',
                      },
                      innerColorTextFieldProps: {
                        label: 'Cor interior',
                        placeholder: 'Inserir',
                        defaultValue: 'Standard',
                      },
                      deliveryLocationTextFieldProps: {
                        label: 'Local de entrega',
                        placeholder: 'Inserir',
                        defaultValue: 'Concessionária Martins, Rua José Almeida',
                      },
                      outerColorTextFieldProps: {
                        label: 'Cor Exterior',
                        placeholder: 'Inserir',
                        defaultValue: 'Branco Banquise',
                      },
                    },
                    concessionaireData: {
                      title: 'Dados a preencher para concessionária',
                      frontTires: {
                        title: 'Pneus Dianteiros',
                        fullMeasureTextFieldProps: {
                          label: 'Medida Completa',
                          placeholder: 'Inserir',
                          defaultValue: '000/00 R 00 - 0000',
                        },
                        brandModelTextFieldProps: {
                          label: 'Marca e Modelo',
                          placeholder: 'Inserir',
                        },
                        runFlatTireCheckboxProps: {
                          label: 'Pneu RunFlat',
                          defaultChecked: true,
                        },
                      },
                      rearTires: {
                        title: 'Pneus Traseiros',
                        fullMeasureTextFieldProps: {
                          label: 'Medida Completa',
                          placeholder: 'Inserir',
                          defaultValue: '000/00 R 00 - 0000',
                        },
                        brandModelTextFieldProps: {
                          label: 'Marca e Modelo',
                          placeholder: 'Inserir',
                        },
                        runFlatTireCheckboxProps: {
                          label: 'Pneu RunFlat',
                          defaultChecked: true,
                        },
                      },
                      substituteTire: {
                        title: 'Pneu Suplente',
                        arrayCheckboxProps: [
                          {
                            label: 'Igual aos restantes',
                            defaultChecked: true,
                          },
                          {
                            label: 'Dimensões reduzidas',
                            defaultChecked: true,
                          },
                          {
                            label: 'Com Kit anti-furo',
                            defaultChecked: true,
                          },
                        ],
                      },
                      vehicleDeliveryDateTextFieldProps: {
                        label: 'Data de entrega viatura',
                        placeholder: 'Inserir',
                        defaultValue: '02.03.2022',
                      },
                      kmTextFieldProps: {
                        label: 'Km registados',
                        placeholder: 'Inserir',
                        defaultValue: '0',
                      },
                      keysWithCommandNumberTextFieldProps: {
                        label: 'Nº Chave c/comando',
                        placeholder: 'Inserir',
                        defaultValue: '0',
                      },
                      keysWithoutCommandNumberTextFieldProps: {
                        label: 'Nº Chave s/comando',
                        placeholder: 'Inserir',
                        defaultValue: '0',
                      },
                      selectVehicleIncludedItemsText:
                        'Selecionar materail/equipamento que veio incluido na viatura',
                      includedItemsCheckboxProps: [
                        {
                          label: 'Manual de instruções',
                          defaultChecked: true,
                        },
                        {
                          label: 'Macaco',
                          defaultChecked: true,
                        },
                        {
                          label: 'Comprovativo de Seguro',
                          defaultChecked: true,
                        },
                        {
                          label: 'Triângulo de Sinalização',
                          defaultChecked: true,
                        },
                        {
                          label: 'Original Carta Verde',
                          defaultChecked: true,
                        },
                        {
                          label: 'Manual /garantias',
                          defaultChecked: true,
                        },
                        {
                          label: 'Chaves de Rodas',
                          defaultChecked: true,
                        },
                        {
                          label: 'Declaração Aduaneira',
                          defaultChecked: true,
                        },
                        {
                          label: 'Colete Retrorefletor',
                          defaultChecked: true,
                        },
                        {
                          label: 'Manual do Condutor',
                          defaultChecked: true,
                        },
                      ],
                    },
                    commentsText: 'Comentários',
                    comment: '',
                    commentPlaceholder: 'Incluir aqui os comentários',
                    // onCommentChange:
                    signatureArea: {
                      title: 'Assinaturas',
                      concessionaire: {
                        label: 'Concessionário',
                        signDropDownProps: {
                          options: [
                            {
                              label: 'Digitalmente',
                              value: 'digital',
                            },
                            {
                              label: 'Download do documento',
                              value: 'download',
                            },
                          ],
                          placeholder: 'Assinar',
                        },
                      },
                      authorizedPerson: {
                        label: 'Pessoa autorizada',
                        signedDate: '12.Mar.2022',
                        signed: true,
                        signedLabel: 'Assinado Digitalmente',
                        signDropDownProps: {
                          options: [
                            {
                              label: 'Digitalmente',
                              value: 'digital',
                            },
                            {
                              label: 'Download do documento',
                              value: 'download',
                            },
                          ],
                          placeholder: 'Assinar',
                          defaultValue: 'digital',
                        },
                      },
                    },
                  },
                  validateConfirmationModalProps: {
                    cancelText: 'Cancelar',
                    confirmText: 'Validar',
                    title:
                      'Ao validar os dados de matricula e entrega não poderá voltar a fazer alterações',
                    text: 'Tem a certeza que quer validar e avançar para preenchimento do Auto de recepção?',
                    onConfirm: () => alert('do something'),
                  },
                  actions: [],
                  id: 'Ct 10089803',
                  firstStep: {
                    title: '1 passo',
                    attachInvoiceText: 'Submeter fatura',
                    onAttachFileChange: () => alert('new file'),
                    registrationPlateDropDownProps: {
                      defaultValue: 'op1',
                      options: [
                        {
                          label: '23 FG 22',
                          value: 'op1',
                        },
                        {
                          label: '23 FG 22',
                          value: 'op2',
                        },
                      ],
                      placeholder: 'Selecionar',
                      label: 'Matricula',
                    },
                    receivingResponsibleTextFieldProps: {
                      defaultValue: 'José Silva',
                      placeholder: 'Inserir',
                      label: 'Responsável recepção da viatura',
                    },
                    responsibleContactTextFieldProps: {
                      defaultValue: '960 000 000',
                      placeholder: 'Inserir',
                      label: 'Contacto',
                    },
                  },
                  secondStep: {
                    title: '2 passo',
                    deliveryDateConfirmationTextFieldProps: {
                      defaultValue: '15.04.22',
                      placeholder: 'Inserir',
                      label: 'Confirmar data de entrega',
                    },
                    waitingValidationText:
                      variant === 'kinto' ? 'Aguarda validação' : undefined,
                    validateButtonText:
                      variant === 'kinto' ? undefined : 'Validar',
                    validatedText: 'Validado',
                    // validated: false
                  },
                  thirdStep: {
                    title: '3 passo',
                    subTitle: 'Preencher Auto de recepção',
                    status: {
                      tagColor: 'warning',
                      tagLabel: 'Aguarda assinatura',
                    },
                    viewReceiptLinkText:
                      variant === 'kinto'
                        ? 'Visualizar Auto de recepção'
                        : undefined,
                    receptionFillingButtonText:
                      variant === 'kinto'
                        ? undefined
                        : 'Preencher auto de recepção',
                    printReceiptLinkText: 'Imprimir Auto de recepção',
                    onPrintReceiptLinkClick: () => alert('Imprimir'),
                    onViewReceiptLinkClick: () => alert('ver'),
                  },
                  documentAreaTitle: 'Documentos da viatura para download',
                  documents: [],
                },
                {
                  receiptCertificateModalProps: {
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: 'Concluir',
                    title: 'Auto de recepção',
                    confirmButtonProps: { onClick: () => alert('concluir') },
  
                    deliveryDataArea: {
                      title: 'Dados de entrega',
                      toTextFieldProps: {
                        label: 'Para',
                        placeholder: 'Inserir',
                        defaultValue: 'Moutinho Porto - Comércio Automóveis, Sa',
                      },
                      dateTextFieldProps: {
                        label: 'Data',
                        placeholder: 'Inserir',
                        defaultValue: '00.00.00',
                      },
                      fromTextFieldProps: {
                        label: 'At. De',
                        placeholder: 'Inserir',
                        defaultValue: 'Martim Reis',
                      },
                      emailTextFieldProps: {
                        label: 'Email',
                        placeholder: 'Inserir',
                        defaultValue: 'martim.reis@gmail.com',
                      },
                      contractNumberTextFieldProps: {
                        label: 'Nª do Contrato',
                        placeholder: 'Inserir',
                        defaultValue: 'CT 10067024',
                      },
                      CA_NumberTextFieldProps: {
                        label: 'Nª do CA',
                        placeholder: 'Inserir',
                        defaultValue: 'CA10787772',
                      },
                      registrationPlateTextFieldProps: {
                        label: 'Matricula',
                        placeholder: 'Inserir',
                        defaultValue: '00-AA-00',
                      },
                    },
                    vehicleDataArea: {
                      title: 'Dados da Viatura',
                      subtitle:
                        'Serve o presente documento para autorizar a entrega da viatura com os dados abaixo',
                      authorizedPersonTextFieldProps: {
                        label: 'Pessoa Autorizada',
                        placeholder: 'Inserir',
                        defaultValue: 'António Silva',
                      },
                      brandModelTextFieldProps: {
                        label: 'Marca/Modelo',
                        placeholder: 'Inserir',
                        defaultValue: 'Peugeot 208 -208 1.2 Puretech Active Pack',
                      },
                      chassisNumberTextFieldProps: {
                        label: 'Nº DE CHASSIS',
                        placeholder: 'Inserir',
                        defaultValue: 'Peugeot 208 -208 1.2 Puretech Active Pack',
                      },
                      innerColorTextFieldProps: {
                        label: 'Cor interior',
                        placeholder: 'Inserir',
                        defaultValue: 'Standard',
                      },
                      deliveryLocationTextFieldProps: {
                        label: 'Local de entrega',
                        placeholder: 'Inserir',
                        defaultValue: 'Concessionária Martins, Rua José Almeida',
                      },
                      outerColorTextFieldProps: {
                        label: 'Cor Exterior',
                        placeholder: 'Inserir',
                        defaultValue: 'Branco Banquise',
                      },
                    },
                    concessionaireData: {
                      title: 'Dados a preencher para concessionária',
                      frontTires: {
                        title: 'Pneus Dianteiros',
                        fullMeasureTextFieldProps: {
                          label: 'Medida Completa',
                          placeholder: 'Inserir',
                          defaultValue: '000/00 R 00 - 0000',
                        },
                        brandModelTextFieldProps: {
                          label: 'Marca e Modelo',
                          placeholder: 'Inserir',
                        },
                        runFlatTireCheckboxProps: {
                          label: 'Pneu RunFlat',
                          defaultChecked: true,
                        },
                      },
                      rearTires: {
                        title: 'Pneus Traseiros',
                        fullMeasureTextFieldProps: {
                          label: 'Medida Completa',
                          placeholder: 'Inserir',
                          defaultValue: '000/00 R 00 - 0000',
                        },
                        brandModelTextFieldProps: {
                          label: 'Marca e Modelo',
                          placeholder: 'Inserir',
                        },
                        runFlatTireCheckboxProps: {
                          label: 'Pneu RunFlat',
                          defaultChecked: true,
                        },
                      },
                      substituteTire: {
                        title: 'Pneu Suplente',
                        arrayCheckboxProps: [
                          {
                            label: 'Igual aos restantes',
                            defaultChecked: true,
                          },
                          {
                            label: 'Dimensões reduzidas',
                            defaultChecked: true,
                          },
                          {
                            label: 'Com Kit anti-furo',
                            defaultChecked: true,
                          },
                        ],
                      },
                      vehicleDeliveryDateTextFieldProps: {
                        label: 'Data de entrega viatura',
                        placeholder: 'Inserir',
                        defaultValue: '02.03.2022',
                      },
                      kmTextFieldProps: {
                        label: 'Km registados',
                        placeholder: 'Inserir',
                        defaultValue: '0',
                      },
                      keysWithCommandNumberTextFieldProps: {
                        label: 'Nº Chave c/comando',
                        placeholder: 'Inserir',
                        defaultValue: '0',
                      },
                      keysWithoutCommandNumberTextFieldProps: {
                        label: 'Nº Chave s/comando',
                        placeholder: 'Inserir',
                        defaultValue: '0',
                      },
                      selectVehicleIncludedItemsText:
                        'Selecionar materail/equipamento que veio incluido na viatura',
                      includedItemsCheckboxProps: [
                        {
                          label: 'Manual de instruções',
                          defaultChecked: true,
                        },
                        {
                          label: 'Manual /garantias',
                          defaultChecked: true,
                        },
                        {
                          label: 'Macaco',
                          defaultChecked: true,
                        },
                        {
                          label: 'Chaves de Rodas',
                          defaultChecked: true,
                        },
                        {
                          label: 'Comprovativo de Seguro',
                          defaultChecked: true,
                        },
                        {
                          label: 'Declaração Aduaneira',
                          defaultChecked: true,
                        },
                        {
                          label: 'Triângulo de Sinalização',
                          defaultChecked: true,
                        },
                        {
                          label: 'Colete Retrorefletor',
                          defaultChecked: true,
                        },
                        {
                          label: 'Original Carta Verde',
                          defaultChecked: true,
                        },
                        {
                          label: 'Manual do Condutor',
                          defaultChecked: true,
                        },
                      ],
                    },
                    commentsText: 'Comentários',
                    comment: '',
                    commentPlaceholder: 'Incluir aqui os comentários',
                    // onCommentChange:
                    signatureArea: {
                      title: 'Assinaturas',
                      concessionaire: {
                        label: 'Concessionário',
                        signedDate: '12.Mar.2022',
                        signed: false,
                        signedLabel: 'Assinado Digitalmente',
                        signDropDownProps: {
                          options: [
                            {
                              label: 'Digitalmente',
                              value: 'digital',
                            },
                            {
                              label: 'Download do documento',
                              value: 'download',
                            },
                          ],
                          placeholder: 'Assinar',
                        },
                      },
                      authorizedPerson: {
                        label: 'Pessoa autorizada',
                        signedDate: '12.Mar.2022',
                        signed: true,
                        signedLabel: 'Assinado Digitalmente',
  
                        signDropDownProps: {
                          options: [
                            {
                              label: 'Digitalmente',
                              value: 'digital',
                            },
                            {
                              label: 'Download do documento',
                              value: 'download',
                            },
                          ],
                          placeholder: 'Assinar',
                          defaultValue: 'digital',
                        },
                      },
                    },
                  },
                  validateConfirmationModalProps: {
                    cancelText: 'Cancelar',
                    confirmText: 'Validar',
                    title:
                      'Ao validar os dados de matricula e entrega não poderá voltar a fazer alterações',
                    text: 'Tem a certeza que quer validar e avançar para preenchimento do Auto de recepção?',
                    onConfirm: () => alert('do something'),
                  },
                  actions: [],
                  id: 'CP C010089804 ',
                  firstStep: {
                    title: '1 passo',
                    attachInvoiceText: 'Submeter fatura',
                    onAttachFileChange: () => alert('new file'),
                    registrationPlateDropDownProps: {
                      defaultValue: 'op1',
                      options: [
                        {
                          label: '23 FG 22',
                          value: 'op1',
                        },
                        {
                          label: '23 FG 22',
                          value: 'op2',
                        },
                      ],
                      placeholder: 'Selecionar',
                      label: 'Matricula',
                    },
                    receivingResponsibleTextFieldProps: {
                      defaultValue: 'José Silva',
                      placeholder: 'Inserir',
                      label: 'Responsável recepção da viatura',
                    },
                    responsibleContactTextFieldProps: {
                      defaultValue: '960 000 000',
                      placeholder: 'Inserir',
                      label: 'Contacto',
                    },
                    fileProgressBarProps: {
                      progress: 23,
                      title: 'nomeDoFicheiro.pdf',
                      deleteIcon: true,
                      label: '2MB/4MB',
                    },
                  },
                  secondStep: {
                    title: '2 passo',
                    deliveryDateConfirmationTextFieldProps: {
                      defaultValue: '15.04.22',
                      placeholder: 'Inserir',
                      label: 'Confirmar data de entrega',
                    },
                    waitingValidationText:
                      variant === 'kinto' ? 'Aguarda validação' : undefined,
                    validateButtonText:
                      variant === 'kinto' ? undefined : 'Validar',
                    validatedText: 'Validado',
                    validated: true,
                  },
                  thirdStep: {
                    title: '3 passo',
                    subTitle: 'Preencher Auto de recepção',
                    status: {
                      tagColor: 'success',
                      tagLabel: 'Assinado',
                    },
                    viewReceiptLinkText:
                      variant === 'kinto'
                        ? 'Visualizar Auto de recepção'
                        : undefined,
                    receptionFillingButtonText:
                      variant === 'kinto'
                        ? undefined
                        : 'Preencher auto de recepção',
                    printReceiptLinkText: 'Imprimir Auto de recepção',
                    onPrintReceiptLinkClick: () => alert('Imprimir'),
                    onViewReceiptLinkClick: () => alert('ver'),
                  },
                  documentAreaTitle: 'Documentos da viatura para download',
                  documents: [
                    {
                      downloadText: 'Download',
                      date: variant === 'kinto' ? '12.Mar.2022' : undefined,
                      name: 'CISA',
                      statusIcon: SuccessIcon,
                      onDownloadClick: () => alert('download file'),
                    },
                    {
                      downloadText: 'Download',
                      date: variant === 'kinto' ? '12.Mar.2022' : undefined,
                      name: 'Declaração Amigável',
                      onDownloadClick: () => alert('download file'),
                    },
                  ],
                },
              ],
            },
  
            {
              name: 'Renault 208 Diesel 208 1.5 Bluehdi Business Active',
              characteristics: '(1461CC | 90CV | 5 lugares | 5 portas | Azul)',
              status: {
                label: 'Estado',
                tagLabel: '1/2 Nota de encomenda validada',
                tagColor: 'info',
              },
              amount: { label: 'Qtd.', value: '2' },
              expandText: 'Abrir detalhes',
              collapseText: 'Fechar detalhes',
              vehicles: [
                {
                  receiptCertificateModalProps: {
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: 'Concluir',
                    title: 'Auto de recepção',
                    confirmButtonProps: { onClick: () => alert('concluir') },
  
                    deliveryDataArea: {
                      title: 'Dados de entrega',
                      toTextFieldProps: {
                        label: 'Para',
                        placeholder: 'Inserir',
                        defaultValue: 'Moutinho Porto - Comércio Automóveis, Sa',
                      },
                      dateTextFieldProps: {
                        label: 'Data',
                        placeholder: 'Inserir',
                        defaultValue: '00.00.00',
                      },
                      fromTextFieldProps: {
                        label: 'At. De',
                        placeholder: 'Inserir',
                        defaultValue: 'Martim Reis',
                      },
                      emailTextFieldProps: {
                        label: 'Email',
                        placeholder: 'Inserir',
                        defaultValue: 'martim.reis@gmail.com',
                      },
                      contractNumberTextFieldProps: {
                        label: 'Nª do Contrato',
                        placeholder: 'Inserir',
                        defaultValue: 'CT 10067024',
                      },
                      CA_NumberTextFieldProps: {
                        label: 'Nª do CA',
                        placeholder: 'Inserir',
                        defaultValue: 'CA10787772',
                      },
                      registrationPlateTextFieldProps: {
                        label: 'Matricula',
                        placeholder: 'Inserir',
                        defaultValue: '00-AA-00',
                      },
                    },
                    vehicleDataArea: {
                      title: 'Dados da Viatura',
                      subtitle:
                        'Serve o presente documento para autorizar a entrega da viatura com os dados abaixo',
                      authorizedPersonTextFieldProps: {
                        label: 'Pessoa Autorizada',
                        placeholder: 'Inserir',
                        defaultValue: 'António Silva',
                      },
                      brandModelTextFieldProps: {
                        label: 'Marca/Modelo',
                        placeholder: 'Inserir',
                        defaultValue: 'Peugeot 208 -208 1.2 Puretech Active Pack',
                      },
                      chassisNumberTextFieldProps: {
                        label: 'Nº DE CHASSIS',
                        placeholder: 'Inserir',
                        defaultValue: 'Peugeot 208 -208 1.2 Puretech Active Pack',
                      },
                      innerColorTextFieldProps: {
                        label: 'Cor interior',
                        placeholder: 'Inserir',
                        defaultValue: 'Standard',
                      },
                      deliveryLocationTextFieldProps: {
                        label: 'Local de entrega',
                        placeholder: 'Inserir',
                        defaultValue: 'Concessionária Martins, Rua José Almeida',
                      },
                      outerColorTextFieldProps: {
                        label: 'Cor Exterior',
                        placeholder: 'Inserir',
                        defaultValue: 'Branco Banquise',
                      },
                    },
                    concessionaireData: {
                      title: 'Dados a preencher para concessionária',
                      frontTires: {
                        title: 'Pneus Dianteiros',
                        fullMeasureTextFieldProps: {
                          label: 'Medida Completa',
                          placeholder: 'Inserir',
                          defaultValue: '000/00 R 00 - 0000',
                        },
                        brandModelTextFieldProps: {
                          label: 'Marca e Modelo',
                          placeholder: 'Inserir',
                        },
                        runFlatTireCheckboxProps: {
                          label: 'Pneu RunFlat',
                          defaultChecked: true,
                        },
                      },
                      rearTires: {
                        title: 'Pneus Traseiros',
                        fullMeasureTextFieldProps: {
                          label: 'Medida Completa',
                          placeholder: 'Inserir',
                          defaultValue: '000/00 R 00 - 0000',
                        },
                        brandModelTextFieldProps: {
                          label: 'Marca e Modelo',
                          placeholder: 'Inserir',
                        },
                        runFlatTireCheckboxProps: {
                          label: 'Pneu RunFlat',
                          defaultChecked: true,
                        },
                      },
                      substituteTire: {
                        title: 'Pneu Suplente',
                        arrayCheckboxProps: [
                          {
                            label: 'Igual aos restantes',
                            defaultChecked: true,
                          },
                          {
                            label: 'Dimensões reduzidas',
                            defaultChecked: true,
                          },
                          {
                            label: 'Com Kit anti-furo',
                            defaultChecked: true,
                          },
                        ],
                      },
                      vehicleDeliveryDateTextFieldProps: {
                        label: 'Data de entrega viatura',
                        placeholder: 'Inserir',
                        defaultValue: '02.03.2022',
                      },
                      kmTextFieldProps: {
                        label: 'Km registados',
                        placeholder: 'Inserir',
                        defaultValue: '0',
                      },
                      keysWithCommandNumberTextFieldProps: {
                        label: 'Nº Chave c/comando',
                        placeholder: 'Inserir',
                        defaultValue: '0',
                      },
                      keysWithoutCommandNumberTextFieldProps: {
                        label: 'Nº Chave s/comando',
                        placeholder: 'Inserir',
                        defaultValue: '0',
                      },
                      selectVehicleIncludedItemsText:
                        'Selecionar materail/equipamento que veio incluido na viatura',
                      includedItemsCheckboxProps: [
                        {
                          label: 'Manual de instruções',
                          defaultChecked: true,
                        },
                        {
                          label: 'Macaco',
                          defaultChecked: true,
                        },
                        {
                          label: 'Comprovativo de Seguro',
                          defaultChecked: true,
                        },
                        {
                          label: 'Triângulo de Sinalização',
                          defaultChecked: true,
                        },
                        {
                          label: 'Original Carta Verde',
                          defaultChecked: true,
                        },
                        {
                          label: 'Manual /garantias',
                          defaultChecked: true,
                        },
                        {
                          label: 'Chaves de Rodas',
                          defaultChecked: true,
                        },
                        {
                          label: 'Declaração Aduaneira',
                          defaultChecked: true,
                        },
                        {
                          label: 'Colete Retrorefletor',
                          defaultChecked: true,
                        },
                        {
                          label: 'Manual do Condutor',
                          defaultChecked: true,
                        },
                      ],
                    },
                    commentsText: 'Comentários',
                    comment: '',
                    commentPlaceholder: 'Incluir aqui os comentários',
                    // onCommentChange:
                    signatureArea: {
                      title: 'Assinaturas',
                      concessionaire: {
                        label: 'Concessionário',
                        signDropDownProps: {
                          options: [
                            {
                              label: 'Digitalmente',
                              value: 'digital',
                            },
                            {
                              label: 'Download do documento',
                              value: 'download',
                            },
                          ],
                          placeholder: 'Assinar',
                        },
                      },
                      authorizedPerson: {
                        label: 'Pessoa autorizada',
                        signedDate: '12.Mar.2022',
                        signed: true,
                        signedLabel: 'Assinado Digitalmente',
  
                        signDropDownProps: {
                          options: [
                            {
                              label: 'Digitalmente',
                              value: 'digital',
                            },
                            {
                              label: 'Download do documento',
                              value: 'download',
                            },
                          ],
                          placeholder: 'Assinar',
                          defaultValue: 'digital',
                        },
                      },
                    },
                  },
                  validateConfirmationModalProps: {
                    cancelText: 'Cancelar',
                    confirmText: 'Validar',
                    title:
                      'Ao validar os dados de matricula e entrega não poderá voltar a fazer alterações',
                    text: 'Tem a certeza que quer validar e avançar para preenchimento do Auto de recepção?',
                    onConfirm: () => alert('do something'),
                  },
                  actions: [],
                  id: 'Ct 10089803',
                  firstStep: {
                    title: '1 passo',
                    attachInvoiceText: 'Submeter fatura',
                    onAttachFileChange: () => alert('new file'),
                    registrationPlateDropDownProps: {
                      defaultValue: 'op1',
                      options: [
                        {
                          label: '23 FG 22',
                          value: 'op1',
                        },
                        {
                          label: '23 FG 22',
                          value: 'op2',
                        },
                      ],
                      placeholder: 'Selecionar',
                      label: 'Matricula',
                    },
                    receivingResponsibleTextFieldProps: {
                      defaultValue: 'José Silva',
                      placeholder: 'Inserir',
                      label: 'Responsável recepção da viatura',
                    },
                    responsibleContactTextFieldProps: {
                      defaultValue: '960 000 000',
                      placeholder: 'Inserir',
                      label: 'Contacto',
                    },
                  },
                  secondStep: {
                    title: '2 passo',
                    deliveryDateConfirmationTextFieldProps: {
                      defaultValue: '15.04.22',
                      placeholder: 'Inserir',
                      label: 'Confirmar data de entrega',
                    },
                    waitingValidationText:
                      variant === 'kinto' ? 'Aguarda validação' : undefined,
                    validateButtonText:
                      variant === 'kinto' ? undefined : 'Validar',
                    validatedText: 'Validado',
                    // validated: false
                  },
                  thirdStep: {
                    title: '3 passo',
                    subTitle: 'Preencher Auto de recepção',
                    status: {
                      tagColor: 'warning',
                      tagLabel: 'Aguarda assinatura',
                    },
                    viewReceiptLinkText:
                      variant === 'kinto'
                        ? 'Visualizar Auto de recepção'
                        : undefined,
                    receptionFillingButtonText:
                      variant === 'kinto'
                        ? undefined
                        : 'Preencher auto de recepção',
                    printReceiptLinkText: 'Imprimir Auto de recepção',
                    onPrintReceiptLinkClick: () => alert('Imprimir'),
                    onViewReceiptLinkClick: () => alert('ver'),
                  },
                  documentAreaTitle: 'Documentos da viatura para download',
                  documents: [],
                },
              ],
            },
          ],
        }}
      />
    );
  };
  
  export default React.memo(OrderDetailsPageDemo);
  