import { ButtonProps, CheckboxProps, PumpDieselIcon, TagProps, TextFieldProps } from "@hi-interactive-admin/simulador-kinto";
import { ProposalDetailsProps } from '@hi-interactive-admin/simulador-kinto/pages/proposal/edit/details/ProposalDetails';
import { EditProposalTableProps } from '@hi-interactive-admin/simulador-kinto/pages/proposal/edit/EditProposalTable';
import { useState, useCallback, useEffect, useMemo, ChangeEvent, Dispatch, SetStateAction } from 'react';
import { IAccessory } from "../../../model/Accessory";
// import { IExtraStandard } from "../../../model/ExtraStandard";
import { IVehicle } from '../../../model/Vehicle';
import { VehicleService } from '../../../services/VehicleService';
//import settings from "../../../appSettings.json";
import { Proposal } from "../../../model/Proposal";

import { Simulation as ISimulation, newSimulation } from "../../../model/Simulation";
import { SettingsService } from "../../../services/SettingsService";
import { AppSettings, EmptyAppSettings } from "../../../model/Settings";
import { useNavigate } from "react-router-dom";
import usePermissionsHook from "../../../customCallbacks/permissions/permissionsHook";
import { convertNumber, validaStringFormatDecimal } from "../../../Utilities/Utils";
import { Option } from "../../../model/interfaces";
import useComplementaryProductsHook from "../../../customCallbacks/proposal/complementaryProductsHook";
import { ContractSettingsDetailsProps } from "@hi-interactive-admin/simulador-kinto/pages/proposal/step-4/ContractSettingsDetails";
import { ComplementaryProductsSectionProps } from "@hi-interactive-admin/simulador-kinto/pages/proposal/edit/details/ComplementaryProductsSection";

interface Vehicle {
  id?: string;
  icons: string[];
  model: string;
  brand: string;
  version: string;
  segment: string;
  lot: string;
}
interface VehicleHeader {
  icons: string[];
  model: string;
  characteristics: string;
}
interface Simulation {
  id: string;
  qtd: string;
  term: string;
  km: string;
  proformaPrice: string;
  businessValueWithTax: string;
  vrReal: string;
  vrIncrease: string;
  vrTable: string;
  vrEurotaxWithTax: string;
  rappel: string;
  margin: string;
  status: {
      label: string;
      color: 'error' | 'success' | 'warning' | 'info';
      value: string;
      tooltipText: string[];
      options: {
          label: string;
          value: string;
          color: TagProps['color'];
      }[];
      onChange?: (newValue: {
          label: string;
          value: string;
          color: TagProps['color'];
      }, vehicleIndex: number, simIndex: number) => void;
  };
  income: {
      withTax: string;
      withoutTax: string;
  };
  rowActions: EditProposalTableProps['rows'][0]['actions'];
  details: ProposalDetailsProps;
  header: VehicleHeader;
  vehicleSummary: string;
  vehicle: Vehicle;
  vehicleIndex: number;
  simIndex: number;
  termkmHeaderText: string;
}
interface EditProposalTableData {
  vehicle: Vehicle;
  simulations: Omit<Simulation, 'vehicleIndex' | 'simIndex' | 'header' | 'vehicle'>[];
  header: VehicleHeader;
  proposedVehicleText: string;
  editVehicleLinkText: string;
  editVehicleLinkOnClick?: (vehicle: Vehicle) => void;
  index: number;
  addSimulationLinkText: string;
  addSimulationLinkOnClick?: (vehicle: Vehicle) => void;
}
interface simulationIndex {
  vehicleIndex: number;
  simulationIndex: number;
}
const vehicleService = new VehicleService();

const useVehiclesCustomHook = ( proposal:Proposal, filteredVehicles:IVehicle[], setContractSelectedRows: Dispatch<SetStateAction<{vehicleIndex: number, simulationIndex: number }[]>> ) => {

  const [settings, setSettings] = useState<AppSettings>(new EmptyAppSettings());


  const {  
    // currentUser, 
    // setCurrentUser, 
    propertyHidden, 
    propertyDisabled, 
    // allowedValues 
  } = usePermissionsHook();

  // não há necessidade de importar o navbarHook porque esta a ser utilizado na secção principal da página editProposal
  const navigate = useNavigate();
  const handleCardClick = useCallback((path: string) => {
    navigate(`/${path}`);
  },[navigate]);

  const [changedProposal, setChangedProposal] = useState<boolean>(false);
  const [maxKmChanged, setMaxKmChanged] = useState<boolean>(false);

  const [vehicleDataTable, setVehicleDataTable] = useState<IVehicle[]>([]);
  
  const [vehicles, setVehicles] = useState<Omit<EditProposalTableData, 'index'>[]>([]);

  const {editProposalComplementaryProductsByVehicleMemo} = useComplementaryProductsHook(vehicleDataTable, setVehicleDataTable, setChangedProposal, '');
  // const {complementaryProductsByVehicleMemo, } = useComplementaryProductsHook(vehicleDataTable, setVehicleDataTable, setChangedProposal, '');

  const [vehicle, setVehicle] = useState<IVehicle>();
  // tipo de empresa
  const [clientTypology, setClientTypology] = useState<any[]>();

  const [deadlineOptions, setDeadlineOptions] = useState<Option[]>([]);
  const [annualKmOptions, setAnnualKmOptions] = useState<Option[]>([]);
  const [paymentTerms, setPaymentTerms] = useState<any[]>();

  const [commercialConditionsOptions, setCommercialConditionsOptions] = useState<any[]>([]);
  const [insurancePackageOptions , setInsurancePackageOptions ] = useState<any[]>([]);
  const [insurancePackageLineOptions, setInsurancePackageLineOptions] = useState<any[]>([]);
  const [vsOptions, setVsOptions] = useState<any[]>([]);
  const [vsTypeOptions, setVsTypeOptions] = useState<any[]>([]);
  const [simulationInsuranceOptions, setSimulationInsuranceOptions] = useState<simulationIndex>({ vehicleIndex:-1, simulationIndex:-1});

  const [vehicleGroupEditModalValues, setVehicleGroupEditModalValues] =
  useState<{
    // step 5
    incomeWithoutTax: number; // renda target
    incomeWithTax: number;
    collateral: number; // caução
    margin: number; // % margem
    increaseVr: number; // % aumento vr
    initialEntry: number; // entrada inicial
    expenses: number; // despesas de contrato
    marginCheck: boolean;
    commercialEffortCheck: boolean; //disabled -  same as margin check
    vrCheck: boolean;

    // step 2
    discountWithTax: number;
    discountWithoutTax: number;
    discountWithTaxPercentage: number;
    campaignWithTax: number;
    campaignWithTaxPercentage: number;
    campaignWithoutTax: number;
    rappelWithTax: number;
    rappelWithTaxPercentage: number;
    rappelWithoutTax: number;
    extraRappel: number;
    lotId: string;
    extraAccessories: {
      extraAcessoryName: string;
      extraAcessoryPrice: number;
      extraAcessoryInstaller: string;
    }[];
  }>({
    incomeWithoutTax: 0,
    incomeWithTax: 0,
    collateral: 0,
    margin: 0,
    increaseVr:0,
    initialEntry: 0,
    expenses: 0,
    marginCheck: false,
    commercialEffortCheck: false,
    vrCheck: false,
    discountWithTax: 0,
    discountWithoutTax: 0,
    discountWithTaxPercentage: 0,
    campaignWithTax: 0,
    campaignWithTaxPercentage: 0,
    campaignWithoutTax: 0,
    rappelWithTax: 0,
    rappelWithTaxPercentage: 0,
    rappelWithoutTax: 0,
    extraRappel: 0,
    lotId: "",
    extraAccessories: [],
  }); 


  useEffect(() => {
    SettingsService.getInstance()
      .then(x => setSettings(x.settings));
  }, []);

  useEffect(() => {
    setPaymentTerms(settings.Data.Vehicles_TypeOfRental);
    setDeadlineOptions(settings.Data.DeadlineValues.map(x => ({ label: `${x.value} meses`, value: String(x.value) })));
    setAnnualKmOptions(settings.Data.AnnualKmValues.map(x => ({ label: `${x.value} km/ano`, value: String(x.value) })));
    setClientTypology(settings.Data.Vehicles_ClientTypology);
  }, [settings]);

  useEffect(() => {  
    if (simulationInsuranceOptions.vehicleIndex > -1 && simulationInsuranceOptions.simulationIndex > -1) {
      const comercitalConditions = vehicleDataTable[simulationInsuranceOptions.vehicleIndex].commercialConditions;
      let simulation = vehicleDataTable[simulationInsuranceOptions.vehicleIndex].simulations[simulationInsuranceOptions.simulationIndex];

      vehicleService.getInsurancePackage(comercitalConditions).then(response => {
        if (response && response.length > 0) {
          simulation.insurancePackageOptions = response.map((item: any) => {
            return { value: item.value, label: item.label }
          });
        }
        simulation.secureOptions = true;

        setSimulationInsuranceOptions({vehicleIndex:-1, simulationIndex:-1});
        setVehicleDataTable([...vehicleDataTable]); 
      });
    }
  }, [setVehicleDataTable, simulationInsuranceOptions, vehicleDataTable]);

  function updateSimulationValues(vehicle: IVehicle, simulation: ISimulation,simulationIndex: number, fieldName:string): IVehicle {
    if(fieldName === "consumersPrice"){
       simulation.priceexclVAT = convertNumber((
        convertNumber(simulation.consumersPrice) / (1 + vehicle.vat / 100)).toFixed(2)
      );
    } else {
      simulation.consumersPrice = convertNumber((
        convertNumber(simulation.priceexclVAT) * (1 + vehicle.vat / 100)
      ).toFixed(2));
    }

    simulation.discountWithTax = convertNumber((
      convertNumber(simulation.discountWithoutTax) * (1 + vehicle.vat / 100)
    ).toFixed(2));

    simulation.discountWithTaxTotal = 
      // regra para envio de valor
      convertNumber((
        convertNumber(simulation.discountWithoutTax) +
        ((convertNumber(simulation.priceexclVAT) + convertNumber(simulation.totalExtraWithoutTax)) * (Number(simulation.discountWithTaxPercentage) / 100))
      ).toFixed(2));

      // regra para envio de percentagem
      // convertNumber(
      //   ((convertNumber(simulation.discountWithoutTax) / ( Number(simulation.priceexclVAT) + simulation.totalExtraWithoutTax ) )*100
      // ).toFixed(2));

    simulation.campaignWithTax = convertNumber((
      Number(simulation.campaignWithoutTax) * (1 + vehicle.vat / 100)
    ).toFixed(2));

    simulation.campaignWithTaxTotal =convertNumber((
      convertNumber(simulation.campaignWithoutTax) +
      convertNumber(simulation.priceexclVAT) * (Number(simulation.campaignWithTaxPercentage) / 100)
    ).toFixed(2));

    simulation.rappelWithTax =convertNumber((
      Number(simulation.rappelWithoutTax) * (1 + vehicle.vat / 100)
    ).toFixed(2));
    let rappelTotal =convertNumber((
      convertNumber(simulation.rappelWithoutTax) +
      convertNumber(simulation.priceexclVAT) * (Number(simulation.rappelWithTaxPercentage) / 100)
    ).toFixed(2));
    //atualiza rappel
    simulation.rappelWithTaxTotal = rappelTotal;

    simulation.totalExtraWithoutTax =convertNumber((
      Number(simulation.totalExtraWithTax) / (1 + vehicle.vat / 100)
    ).toFixed(2));
    simulation.totalAccessoriesWithout =convertNumber((
      Number(simulation.totalAccessoriesWith) / (1 + vehicle.vat / 100)
    ).toFixed(2));
   
    simulation.proformaInvoicePriceWithoutTax = convertNumber((
      // convertNumber(simulation.pvp) +
      // convertNumber(simulation.totalAccessoriesWithout) +
      // convertNumber(simulation.totalExtraWithoutTax) -
      // convertNumber(simulation.discountWithTaxTotal) - 
      // convertNumber(simulation.campaignWithTaxTotal)

      convertNumber(simulation.priceexclVAT)
      + convertNumber(vehicle.luxury_tax)
      + convertNumber(vehicle.transport_x0026_immatriculation_cost)
      + convertNumber(vehicle.recycling_charge)

      + convertNumber(simulation.totalAccessoriesWithout)
      + convertNumber(simulation.totalExtraWithoutTax)

      // - convertNumber(simulation.discountWithoutTax) //envio percentagem
      - convertNumber(simulation.discountWithTaxTotal) //envio valor
      - convertNumber(simulation.campaignWithTaxTotal)
    ).toFixed(2));

    simulation.proformaInvoicePriceWithTax =convertNumber((
      (
        convertNumber(simulation.priceexclVAT)
        + convertNumber(vehicle.luxury_tax)
        + convertNumber(vehicle.transport_x0026_immatriculation_cost)
        + convertNumber(vehicle.recycling_charge)

        + convertNumber(simulation.totalAccessoriesWithout)
        + convertNumber(simulation.totalExtraWithoutTax)
        
        // - convertNumber(simulation.discountWithoutTax) //envio percentagem
        - convertNumber(simulation.discountWithTaxTotal) //envio valor
        - convertNumber(simulation.campaignWithTaxTotal)
      ) * (1 + vehicle.vat / 100)
    ).toFixed(2));

    vehicle.pvp = convertNumber((
      convertNumber(vehicle.luxury_tax) +
      convertNumber(vehicle.transport_x0026_immatriculation_cost) +
      convertNumber(vehicle.recycling_charge) +

      convertNumber(simulation.priceexclVAT) +
      convertNumber(simulation.totalAccessoriesWithout) +
      convertNumber(simulation.totalExtraWithoutTax)
    ).toFixed(2));

    simulation.businessValueWithoutTax = convertNumber((
      convertNumber(simulation.priceexclVAT) +

      convertNumber(simulation.totalAccessoriesWithout) +
      convertNumber(simulation.totalExtraWithoutTax) +

      convertNumber(vehicle.luxury_tax) +
      convertNumber(vehicle.transport_x0026_immatriculation_cost) +
      convertNumber(vehicle.recycling_charge) -

      convertNumber(simulation.rappelWithTaxTotal) -
      convertNumber(simulation.extraRappel) -

      // convertNumber(simulation.discountWithoutTax) -  //envio percentagem
      convertNumber(simulation.discountWithTaxTotal) -  //envio valor
      convertNumber(simulation.campaignWithTaxTotal)
    ).toFixed(2));

    simulation.businessValueWithTax =convertNumber((
      (
        convertNumber(simulation.priceexclVAT) +

        convertNumber(simulation.totalAccessoriesWithout) +
        convertNumber(simulation.totalExtraWithoutTax) +

        convertNumber(vehicle.luxury_tax) +
        convertNumber(vehicle.transport_x0026_immatriculation_cost) +
        convertNumber(vehicle.recycling_charge) -

        convertNumber(simulation.rappelWithTaxTotal) -
        convertNumber(simulation.extraRappel) -

        // convertNumber(simulation.discountWithoutTax) - //envio percentagem
        convertNumber(simulation.discountWithTaxTotal) - //envio valor
        convertNumber(simulation.campaignWithTaxTotal)
      ) * (1 + vehicle.vat / 100)
    ).toFixed(2));

    simulation.totalExtraWithTax = convertNumber(simulation.totalExtraWithTax.toFixed(2));
   

    vehicle.simulations[simulationIndex] = {...simulation};
   
    return vehicle;
  }

  function updateValuesVehicle(vehicle: IVehicle,fieldName:string): IVehicle {
    if(fieldName === "consumersPrice"){
       vehicle.priceexclVAT = convertNumber((
        convertNumber(vehicle.consumersPrice) / (1 + vehicle.vat / 100)).toFixed(2)
      );
    } else {
      vehicle.consumersPrice = convertNumber((
        convertNumber(vehicle.priceexclVAT) * (1 + vehicle.vat / 100)
      ).toFixed(2));
    }

    vehicle.discountWithTax = convertNumber((
      convertNumber(vehicle.discountWithoutTax) * (1 + vehicle.vat / 100)
    ).toFixed(2));

    vehicle.discountWithTaxTotal = 
      // regra para envio de valor
      convertNumber((
        convertNumber(vehicle.discountWithoutTax) +
        ((convertNumber(vehicle.priceexclVAT) + convertNumber(vehicle.totalExtraWithoutTax)) * (Number(vehicle.discountWithTaxPercentage) / 100))
      ).toFixed(2));

      // regra para envio de percentagem
      // convertNumber(
      //   ((convertNumber(vehicle.discountWithoutTax) / ( Number(vehicle.priceexclVAT) + vehicle.totalExtraWithoutTax ) )*100
      // ).toFixed(2));

    vehicle.campaignWithTax = convertNumber((
        Number(vehicle.campaignWithoutTax) * (1 + vehicle.vat / 100)
      ).toFixed(2));

    vehicle.campaignWithTaxTotal =convertNumber((
        convertNumber(vehicle.campaignWithoutTax) +
        convertNumber(vehicle.priceexclVAT) * (Number(vehicle.campaignWithTaxPercentage) / 100)
      ).toFixed(2));

    vehicle.rappelWithTax =convertNumber((
        Number(vehicle.rappelWithoutTax) * (1 + vehicle.vat / 100)
      ).toFixed(2)
    );
    let rappelTotal =convertNumber((
        convertNumber(vehicle.rappelWithoutTax) +
        convertNumber(vehicle.priceexclVAT) * (Number(vehicle.rappelWithTaxPercentage) / 100)
      ).toFixed(2)
    );
    //atualiza rappel
    vehicle.rappelWithTaxTotal = rappelTotal;

    vehicle.totalExtraWithoutTax =convertNumber((
        Number(vehicle.totalExtraWithTax) / (1 + vehicle.vat / 100)
      ).toFixed(2)
    );
    vehicle.totalAccessoriesWithout =convertNumber((
        Number(vehicle.totalAccessoriesWith) / (1 + vehicle.vat / 100)
      ).toFixed(2)
    );
   
    vehicle.proformaInvoicePriceWithoutTax = convertNumber((
      // convertNumber(vehicle.pvp) +
      // convertNumber(vehicle.totalAccessoriesWithout) +
      // convertNumber(vehicle.totalExtraWithoutTax) -
      // convertNumber(vehicle.discountWithTaxTotal) - 
      // convertNumber(vehicle.campaignWithTaxTotal)

      convertNumber(vehicle.priceexclVAT)
      + convertNumber(vehicle.luxury_tax)
      + convertNumber(vehicle.transport_x0026_immatriculation_cost)
      + convertNumber(vehicle.recycling_charge)

      + convertNumber(vehicle.totalAccessoriesWithout)
      + convertNumber(vehicle.totalExtraWithoutTax)

      - convertNumber(vehicle.discountWithTaxTotal) //envio valor
      // - convertNumber(vehicle.discountWithoutTax) //envio percentagem
      - convertNumber(vehicle.campaignWithTaxTotal)
    ).toFixed(2));

    vehicle.proformaInvoicePriceWithTax =convertNumber((
      (
        convertNumber(vehicle.priceexclVAT)
        + convertNumber(vehicle.luxury_tax)
        + convertNumber(vehicle.transport_x0026_immatriculation_cost)
        + convertNumber(vehicle.recycling_charge)

        + convertNumber(vehicle.totalAccessoriesWithout)
        + convertNumber(vehicle.totalExtraWithoutTax)
        
        - convertNumber(vehicle.discountWithTaxTotal) //envio valor
        // - convertNumber(vehicle.discountWithoutTax) //envio percentagem
        - convertNumber(vehicle.campaignWithTaxTotal)
      ) * (1 + vehicle.vat / 100)
    ).toFixed(2));

    vehicle.pvp = convertNumber((
      convertNumber(vehicle.priceexclVAT) +
      convertNumber(vehicle.totalAccessoriesWithout) +
      convertNumber(vehicle.totalExtraWithoutTax) +
      convertNumber(vehicle.luxury_tax) +
      convertNumber(vehicle.transport_x0026_immatriculation_cost) +
      convertNumber(vehicle.recycling_charge)
    ).toFixed(2));

    vehicle.businessValueWithoutTax = convertNumber((
      convertNumber(vehicle.priceexclVAT) +

      convertNumber(vehicle.totalAccessoriesWithout) +
      convertNumber(vehicle.totalExtraWithoutTax) +

      convertNumber(vehicle.luxury_tax) +
      convertNumber(vehicle.transport_x0026_immatriculation_cost) +
      convertNumber(vehicle.recycling_charge) -

      convertNumber(vehicle.rappelWithTaxTotal) -
      convertNumber(vehicle.extraRappel) -

      convertNumber(vehicle.discountWithTaxTotal) -  //envio valor
      // convertNumber(vehicle.discountWithoutTax) -  //envio percentagem
      convertNumber(vehicle.campaignWithTaxTotal)
    ).toFixed(2));

    vehicle.businessValueWithTax =convertNumber((
      (
        convertNumber(vehicle.priceexclVAT) +

        convertNumber(vehicle.totalAccessoriesWithout) +
        convertNumber(vehicle.totalExtraWithoutTax) +

        convertNumber(vehicle.luxury_tax) +
        convertNumber(vehicle.transport_x0026_immatriculation_cost) +
        convertNumber(vehicle.recycling_charge) -

        convertNumber(vehicle.rappelWithTaxTotal) -
        convertNumber(vehicle.extraRappel) -

        convertNumber(vehicle.discountWithTaxTotal) - //envio valor
        // convertNumber(vehicle.discountWithoutTax) - //envio percentagem
        convertNumber(vehicle.campaignWithTaxTotal)
      ) * (1 + vehicle.vat / 100)
    ).toFixed(2));

    vehicle.totalExtraWithTax = convertNumber(vehicle.totalExtraWithTax.toFixed(2));
    vehicle.simulations?.map((simula: ISimulation, index: number) => {
      // isto não faz sentido neste caso uma vez que estamos a lidar diretamente com os valores da simulação
      // e não com os valores genéricos da viatura
      
      // simula.discountWithTax = vehicle.discountWithTax;
      // simula.discountWithTaxTotal = vehicle.discountWithTaxTotal;
      // simula.campaignWithTax = vehicle.campaignWithTax;
      // simula.campaignWithTaxTotal = vehicle.campaignWithTaxTotal;
      // simula.rappelWithoutTax = rappelTotal;
      // simula.extraRappel = vehicle.extraRappel;
      // simula.proformaInvoicePriceWithoutTax = vehicle.proformaInvoicePriceWithoutTax;
      // simula.proformaInvoicePriceWithTax = vehicle.proformaInvoicePriceWithTax;
      // simula.businessValueWithoutTax = vehicle.businessValueWithoutTax;
      // simula.businessValueWithTax = vehicle.businessValueWithTax;
      // simula.totalAccessoriesWith = vehicle.totalAccessoriesWith;
      // simula.totalAccessoriesWithout = vehicle.totalAccessoriesWithout;
      simula.totalExtraWithoutTax = vehicle.totalExtraWithoutTax;
      simula.totalExtraWithTax = vehicle.totalExtraWithTax;

      return simula
    })
    
   
    return vehicle;
  }
  //#region CALLBACKCS DAS ACÇÕES DAS SIMLUAÇÕES
  const handleDuplicate = useCallback((vIndex:number, sIndex:number) => () => {
    setVehicleDataTable(rows => {
      let updatedRows = [...rows];
      let vehicleToDuplicate = updatedRows.find((row, index) => index === vIndex);
      if (vehicleToDuplicate){
        let simulationToDuplicate = vehicleToDuplicate.simulations.find((sim, index) => index === sIndex);
        if (simulationToDuplicate){
          simulationToDuplicate.id = 0;
          vehicleToDuplicate.simulations.push(simulationToDuplicate);
          updatedRows[vIndex] = vehicleToDuplicate;
        }
      }
      return updatedRows;
    })

  },[]);

  const handleEliminate = useCallback((vIndex:number, sIndex:number) => () => {
    setVehicleDataTable(rows => {
      let updatedRows = [...rows];
      let vehicleToRemoveSimulation = updatedRows.find((row, index) => index === vIndex);
      if(vehicleToRemoveSimulation){
        let simulationToRemove = vehicleToRemoveSimulation.simulations.find((sim, index) => index === sIndex);
        if(simulationToRemove){
          vehicleService.deleteSimulation(simulationToRemove.id)
          vehicleToRemoveSimulation.simulations.splice(sIndex, 1);
          updatedRows[vIndex] = vehicleToRemoveSimulation;
        }
      }
      return updatedRows;
    });
  },[]);

  //TODO VALIDAR COMO CHAMAR A MODAL DE APROVAÇÃO ( MODAL DE PEDIDO DE VALIDAÇÃO)
  const handleApprove = useCallback(() => {},[]);
  //#endregion


  // CALLBACK PARA ADICIONAR UMA NOVA SIMULAÇÃO A UMA VIATURA
  const handleAddSimulation = useCallback((vIndex:number) => () => {
    setVehicleDataTable(rows => {
      let updatedRows = [...rows];
      let rowToAddSimulation = updatedRows.find((row, index) => index === vIndex);
      if(rowToAddSimulation) {
        // cria simulação
        let sim = {...newSimulation,
          vehicleId: rowToAddSimulation.id,
          //valores associados à viatura
          priceexclVAT: rowToAddSimulation.priceexclVAT,
          consumersPrice: rowToAddSimulation.consumersPrice,
          totalExtraWithoutTax: rowToAddSimulation.totalExtraWithoutTax,
          totalExtraWithTax: rowToAddSimulation.totalExtraWithTax,
          totalAccessoriesWithout: rowToAddSimulation.totalAccessoriesWithout,
          totalAccessoriesWith: rowToAddSimulation.totalAccessoriesWith,
          discountWithoutTax: rowToAddSimulation.discountWithoutTax,
          discountWithTax: rowToAddSimulation.discountWithTax,
          discountWithTaxPercentage: rowToAddSimulation.discountWithTaxPercentage,
          discountWithTaxTotal: rowToAddSimulation.discountWithTaxTotal,
          proformaInvoicePriceWithoutTax: rowToAddSimulation.proformaInvoicePriceWithoutTax,
          proformaInvoicePriceWithTax: rowToAddSimulation.proformaInvoicePriceWithTax,
          campaignWithoutTax: rowToAddSimulation.campaignWithoutTax,
          campaignWithTax: rowToAddSimulation.campaignWithTax,
          campaignWithTaxPercentage: rowToAddSimulation.campaignWithTaxPercentage,
          campaignWithTaxTotal: rowToAddSimulation.campaignWithTaxTotal,
          rappelWithoutTax: rowToAddSimulation.rappelWithoutTax,
          rappelWithTax: rowToAddSimulation.rappelWithTax,
          rappelWithTaxPercentage: rowToAddSimulation.rappelWithTaxPercentage,
          rappelWithTaxTotal: rowToAddSimulation.rappelWithTaxTotal,
          extraRappel: rowToAddSimulation.extraRappel,
          businessValueWithoutTax: rowToAddSimulation.businessValueWithoutTax,
          businessValueWithTax: rowToAddSimulation.businessValueWithTax,

          quantity: rowToAddSimulation.quantity,
        }
        // carrega as opções de seleção para o contrato de quadro associado à viatura (AOV, ALD etc...)
        vehicleService.getRentalTypes().then(data => {
          if(data.succeeded){
            const rentalSettings = data.value.find((element: any) => {
              return element.value === rowToAddSimulation!.typeofRental
            })
            sim.maintenance = rentalSettings.maintenance;
            sim.tires = rentalSettings.tyres;
            sim.vehicleReplacementOptions = rentalSettings.vs;
            sim.secureOptions = rentalSettings.insurance;
          }
        })
        // adiciona a simulação às simulações existentes do veículo
        rowToAddSimulation.simulations.push(sim);
        // atualiza o veículo na lista de veículos
        updatedRows[vIndex] = rowToAddSimulation;

        // FALTA ATUALIZAR AS DROPDOWNS DOS SEGUROS E TIPOS DE VEÍCULOS DE SUBSTITUIÇÃO PARA PODEREM SER EFETUADAS AS SELECÇÕES
        // SE TAL FOR O CASO
      }
      return updatedRows;
    });
  },[]);



  //TODO VALIDAR ISTO - À PARTIDA SERÁ PARA DESAPARECER
  const handleAccessories = useCallback((vehicle:IVehicle) => () => {
    vehicleService.getVehicleAccessories(vehicle.id).then(data => {
      if(data.succeeded){
        setVehicle( {...vehicle,accessories: data.value })
      }
    })
  },[])



  
  
  //#region  // CALLBACKS DE VISUALIZAÇÃO DE LABELS APLICADAS A DADOS

  //#region SECÇÃO - vehicleValuesArea CONCLUIDO
  // não tem regras para validação visual
  //#endregion

  //#region SECÇÃO - vehicleExtrasArea CONCLUIDO
  // não tem regras para validação visual
  //#endregion

  //#region SECÇÃO - proposalConditionsArea AGUARDA MAPEAMENTOS DE CAMPOS EM FALTA NO FLUXO DE CRIACÇÃO DE PROPOSTA
  // PROPRIEDADE - Tyres
  const handleCommercialConditionLabel = useCallback((commercialCondition: string) => {
    if(commercialConditionsOptions && commercialConditionsOptions.length > 0) {
      const result = commercialConditionsOptions.find(x => x.value === commercialCondition);
      if (result) {
        return result.label;
      } else {
        return commercialCondition;
      }
    }
    return commercialCondition;

    // let commercialConditionLabel: string = ''
    // if(commercialCondition && commercialCondition !== ''){
    //   vehicleService.getCommercialConditionsByNo(commercialCondition).then(data => {
    //     if(data && data.succeeded) {
    //       commercialConditionLabel = data.value[0].label;
    //     }
    //     return commercialConditionLabel;
    //   });
    // }
    // return commercialConditionLabel;
  },[commercialConditionsOptions])
  // PROPRIEDADE - Commercial
  const handleTyresCallback = useCallback((tires: boolean, quantity:string) => {
    let description = ''
    if(tires){
      if(quantity !== '0' && quantity !== 'Ilimitados'){
        description += `Incluido. ${quantity} pneus`
      } else {
        description += 'Incluido. Sem Limite de Pneus '
      }
    } else {
      description += 'Não incluido.'
    }
    return description;
  },[])
  //#endregion

  //#region SECÇÃO - insuranceArea CONCLUIDO
  // PROPRIEDADE - management
  const handleInsurancePackageSummaryLabel = useCallback((vIndex: number, sIndex: number) => {
    let summary = '';
    const targetVehicle = vehicleDataTable.find((row,index) => index === vIndex);
    if(targetVehicle) {
      const targetSimulation = targetVehicle.simulations.find((sim,index) => index === sIndex);
      if (targetSimulation && insurancePackageLineOptions && insurancePackageLineOptions.length > 0){
        const occupiersLabel = insurancePackageLineOptions.find((option) => {
          return option.insurance === targetSimulation.occupants
        });
        const roadAssistanceLabel= insurancePackageLineOptions.find((option) => {
          return option.insurance === targetSimulation.travelAssistance
        });
        const liabilitiesLabel= insurancePackageLineOptions.find((option) => {
          return option.insurance === targetSimulation.responsibility
        });
        const isolatedGlassBreakageLabel = insurancePackageLineOptions.find((option) => {
          return option.insurance === targetSimulation.glassBreakage
        });

        if(occupiersLabel) {
          summary += `Ocupantes ${occupiersLabel.description}.`
        }
        if(roadAssistanceLabel) {
          if(summary !== '') {
            summary = summary.replace(/.$/,', '); 
          } 
          summary += `AV ${roadAssistanceLabel.description}.`
        }
        if(liabilitiesLabel) {
          if(summary !== '') {
            summary = summary.replace(/.$/,', '); 
          } 
          summary += `RC ${liabilitiesLabel.description}.`
        }
        if(isolatedGlassBreakageLabel) {
          if(summary !== '') {
            summary = summary.replace(/.$/,', '); 
          } 
          summary += `QIV ${isolatedGlassBreakageLabel.description}.`
        }        
      }
    }
    return summary;
  },[vehicleDataTable, insurancePackageLineOptions]);

  // PROPRIEDADE - vehicleDamages
  const handleOwnDamageSummaryLabel = useCallback((vIndex: number, sIndex: number) => {
    let summary = 'Não inclui danos próprios.';
    const targetVehicle = vehicleDataTable.find((row,index) => index === vIndex);
    if(targetVehicle) {
      const targetSimulation = targetVehicle.simulations.find((sim,index) => index === sIndex);
      if (targetSimulation && insurancePackageLineOptions){
        const ownDamageLabel = insurancePackageLineOptions.find((option) => {
          return option.insurance === targetSimulation.ownDamage
        });

        if(ownDamageLabel) {
          summary = `Inclui ${ownDamageLabel.description}.`
        }
      }
    }
    return summary;
  },[vehicleDataTable, insurancePackageLineOptions]);

  // PROPRIEDADE - iuc ==> não precisa ser mapeado uma vez que é possível efetuar o mapeamento direto no valor da propriedade
  // PROPRIEDADE - maintenance ==> OCULTADO UMA VEZ QUE NÃO TEMOS FUNCIONALIDADE PARA ESTES DADOS

  //#endregion

  //#region SECÇÃO - replacementVehicleArea CONLCUIDO AGUARDA DECISÃO SK-734
  const handleVsTypeOptionLabel = useCallback((type:string, simulationType:string) => {
    let result:string = "";
    let option: Option = vsTypeOptions.find((option: any) => {
      return (option.value === simulationType && option.type === type)
    })
    if(option){
      return option.label
    }
    return result;
  },[vsTypeOptions]);
  //#endregion

  //#region SECÇÃO - complementaryProducts BLOCO NÃO IMPLEMENTADO
  //#endregion

  //#region SECÇÃO - residualValue AGUARDA VALIDAÇÃO DA KINTO / AGUARDA ALTERAÇÕES HI-INTERACTIVE
  // não tem regras para validação visual
  //#endregion

  //#region SECÇÃO - effortArea CONCLUIDO
  // não tem regras para validação visual
  //#endregion

  //#region SECÇÃO - partialRentArea CONCLUIDO
  // só tem modo de visualização
  //#endregion

  //#region SECÇÃO - rentArea CONCLUIDO
  // não tem regras para validação visual
  //#endregion

  //#region SECÇÃO - paymentTermsArea CONCLUIDO
  // não tem regras para validação visual
  //#endregion

  //#endregion





  //#region // CALLBACKS DE EDIÇÃO DE VALORES DE UMA SIMULAÇÃO/VIATURA
  //#region SECÇÃO - vehicleValuesArea FALTA APLICAR REGRAS DE CALCULO

  // PROPRIEDADES: =>   atualiza as propriedades das simulações associadas a uma viatura
  // - valuesWithoutTaxColumn
  // - valuesWithTaxColumn
  // - rentalColumn
  // - businessValueWithoutTaxTextFieldProps 
  // - businessValueWithTaxTextFieldProps 
  const handleSimulationOnChange = useCallback((vIndex:number, sIndex:number) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setVehicleDataTable((rows) => {
      let updatedRows = [...rows];
      let targetVehicle = updatedRows.find((row, index) => index === vIndex);
      const propertyName = e.target.name;

      if (targetVehicle) {

        let targetSimulation = targetVehicle.simulations.find((sim, index) => index === sIndex);

        if (targetSimulation) {
          let stringValue = e.target.value;
          let value: number | undefined = undefined;

          let lastChar = stringValue.charAt(stringValue.length - 1);
          if (lastChar !== '.' && lastChar !== ',') {
            let decimalValue = stringValue.split('.')[1];
            if(Number(decimalValue) !== 0) {
              value = Number(stringValue);
            } 
          }

          if (value && !isNaN(value)){
            targetSimulation = {...targetSimulation, [e.target.name]: value}
          } else {
            targetSimulation = {...targetSimulation, [e.target.name]: e.target.value}
          }

          if(propertyName === "increaseVR") {
            targetSimulation = { ...targetSimulation, realVR: convertNumber(((Number(e.target.value)/100) * targetSimulation.baseVRTable + targetSimulation.baseVRTable).toFixed(2)) };
            targetSimulation = { ...targetSimulation, incomeWithoutTax: 
              Math.round( 
                (
                  (targetSimulation.baseIncomeWithoutTax - (targetSimulation.realVR - targetSimulation.baseVRTable)/targetSimulation.deadline) 
                  + Number.EPSILON 
                ) * 100
              )/100
            };
            targetSimulation = {...targetSimulation, incomeWithTax : 
              Math.round(
                (
                  (targetSimulation.incomeWithoutTax * (100 + targetVehicle.vat)/100 )
                    + Number.EPSILON
                ) * 100
              ) /100
            }
          }


          if (propertyName === "quantity") {
            targetSimulation.quantity = convertNumber(e.target.value);
          } else {
            let propertyName = e.target.name;
            let propertyValue = targetSimulation[propertyName as keyof typeof targetSimulation];

            targetSimulation = {...targetSimulation, [propertyName]: propertyValue}
          }

          if (propertyName === "businessValueWithoutTax") {
            let valueP = validaStringFormatDecimal(e.target.value, targetSimulation.businessValueWithoutTax);
            let busissValue = convertNumber((
              convertNumber(targetSimulation.priceexclVAT) +

              convertNumber(targetSimulation.totalAccessoriesWithout) +
              convertNumber(targetSimulation.totalExtraWithoutTax) +

              convertNumber(targetVehicle.luxury_tax) +
              convertNumber(targetVehicle.transport_x0026_immatriculation_cost) +
              convertNumber(targetVehicle.recycling_charge) -

              convertNumber(targetSimulation.rappelWithTaxTotal) -
              convertNumber(targetSimulation.extraRappel) -

              convertNumber(targetSimulation.campaignWithTaxTotal)
            ).toFixed(2));

            let discount = convertNumber((convertNumber(busissValue)-convertNumber(valueP)).toFixed(2));
            if (discount >= 0){
              targetSimulation.discountWithoutTax =discount;
            } else {
              targetSimulation.discountWithoutTax = 0;
            }
            updateSimulationValues(targetVehicle, targetSimulation, sIndex, "");

          } 

          if (propertyName === "consumersPrice") {
            targetSimulation.priceexclVAT = convertNumber(
              (convertNumber(targetSimulation[propertyName]) / (1 + targetVehicle.vat / 100)).toFixed(2)
            );
            updateSimulationValues(targetVehicle, targetSimulation, sIndex, "consumersPrice");
          } else {
            updateSimulationValues(targetVehicle, targetSimulation, sIndex, "");
          }

          // a simulação já foi atualizada com os novos valores dentro da função updateSimulationValues
          // logo não é necessário atualizar a simulação na lista de simulações
          // com os valores da simulação targetSimulação, ou corremos o risco de reverter 
          // os dados atualizados
          // targetVehicle.simulations[sIndex] = targetSimulation;
          updatedRows[vIndex] = targetVehicle;
        }
      }
      return updatedRows;
    });
  },[]);

  // PROPRIEDADE - expensesColumn =>  atualiza as propriedades das viaturas
  const handleVehicleOnChange = useCallback((vehicle:IVehicle, vIndex:number) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let value = Number(e.target.value)
    if (isNaN(value)){
      vehicle = {...vehicle, [e.target.name]:e.target.value}
    } else {
      vehicle = {...vehicle, [e.target.name]:Number(e.target.value)}
    }

    setVehicleDataTable((rows) =>
      rows.map((cur, index) => {
        if(index === vIndex) {
          cur = vehicle;
        }
        return cur;
      })
    );
  },[])
  //#endregion

  //#region SECÇÃO - vehicleExtrasArea FALTA VALIDAR

  //TODO VALIDAR ISTO NO FIM
  // transofrma os acessorios da viatura  para a estrutura da tabela
  const handleAccessoriesDTO = useCallback((vehicleAccessories:IAccessory[]) => {
    let accessoriesDisplayList: {
      nameTextFieldProps: TextFieldProps;
      priceTextFieldProps: TextFieldProps;
      installerTextFieldProps: TextFieldProps;
      onDeleteIcon: (index: number) => void;
      lazyload?: boolean;
    }[] = []

    if (vehicleAccessories.length > 0) {
      vehicleAccessories.forEach((accessory,index:number) => {
        accessoriesDisplayList.push({
          nameTextFieldProps: { value: accessory.description },
          priceTextFieldProps: { value: accessory.price },
          installerTextFieldProps: { value: accessory.responsable },
          onDeleteIcon: (index: number) => vehicleAccessories.slice(index,1),
          lazyload: true
        });
      });
    }
    return accessoriesDisplayList;
  },[]);


  const handleExtraStandardTextFieldChange = useCallback((simulationIndex: number) => (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    let splits = e.target.name.split("-");
    // const name = splits[0];
    const nameVat= splits[1];
    const rowIndex =convertNumber(splits[2]);
    const indexExtr =convertNumber(splits[3]);

    setVehicleDataTable((rows) =>
      rows.map((row, index) => {
        if (index !== rowIndex) {
          return row;
        }
        let vehicle = row;
        let acce = vehicle.extraStandard.filter(x=>x.visible===true)[indexExtr];
      
      let priceVat=convertNumber(validaStringFormatDecimal(e.target.value,acce['price_Excl_VAT']));
      if(isNaN(priceVat)){
          acce={...acce,[nameVat]: validaStringFormatDecimal(e.target.value,acce['price_Excl_VAT'])};
          acce={...acce,[nameVat]: validaStringFormatDecimal(e.target.value,acce['price'])};
          vehicle.extraStandard.filter(x=>x.visible===true)[indexExtr].price_Excl_VAT =acce.price_Excl_VAT;
      } else {
        acce={...acce,[nameVat]: validaStringFormatDecimal(e.target.value,acce['price_Excl_VAT'])}

        // acce={...acce,[name]:Number(validaStringFormatDecimal(
        //   (Number(priceVat) * (1+row.vat/100)).toString(),
        //   acce['price']))}
        acce.price = Math.round(( Number(priceVat) * (1+row.vat/100)) * 100) / 100;// ( Number(priceVat) * (1+row.vat/100)).toFixed(2)

        vehicle.extraStandard.filter(x=>x.visible===true)[indexExtr].price_Excl_VAT =acce.price_Excl_VAT;
        vehicle.extraStandard.filter(x=>x.visible===true)[indexExtr].price =acce.price;
        vehicle.extraStandard.filter(x=>x.visible===true)[indexExtr].valueTextFieldProps.value = acce.price_Excl_VAT;

        let totalextraStandard = 0;
        let totalextraStandardVat = 0;

        if (vehicle.extraStandard.length > 0) {
          vehicle.extraStandard.forEach((item) => {
            if (item.checkedExtra === true){
              totalextraStandard +=convertNumber(item.price_Excl_VAT);
            }
          });

          if (totalextraStandard !== 0)
            totalextraStandardVat =convertNumber(
              (totalextraStandard * (1 + vehicle.vat / 100)).toFixed(2)
            );
          } else {
            totalextraStandard = vehicle.totalExtraWithoutTax;
            totalextraStandardVat = vehicle.totalExtraWithTax;
          }
          vehicle.totalExtraWithoutTax = Number(totalextraStandard.toFixed(2));
          vehicle.totalExtraWithTax = Number(totalextraStandardVat.toFixed(2));
          updateValuesVehicle(vehicle,"");

          updateSimulationValues(vehicle, vehicle.simulations[simulationIndex], simulationIndex, "")

        }
        return { ...row, vehicle };
      })
    );
  },[]);

  const handleExtraStandardCheckboxChange = useCallback((vehicleIndex: number, simulationIndex: number) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setVehicleDataTable(rows => {
      let targetVehicle = rows.find((row, vIndex) => vIndex === vehicleIndex);
      if (targetVehicle) {
        let targetExtra = targetVehicle.extraStandard.find((extra) => extra.optionTypeId === event.target.name);
        let targetExtraIndex = targetVehicle.extraStandard.findIndex((ext) => ext.optionTypeId === event.target.name);

        let totalextraStandard = 0;
        let totalextraStandardVat = 0;

        if (targetExtra) {
          targetExtra.checkedExtra = checked;
          // targetExtra.checkboxProps.checked = checked;
          targetVehicle.extraStandard[targetExtraIndex] = targetExtra

          targetVehicle.extraStandard.forEach((item) => { 
            if (item.checkedExtra === true) {
              totalextraStandard +=convertNumber(item.price_Excl_VAT.toString());
            }
          });

          if (totalextraStandard !== 0) {
            totalextraStandardVat = convertNumber( (totalextraStandard * (1 + targetVehicle.vat / 100)).toFixed(2) );
          }
        } else {
          totalextraStandard = targetVehicle.totalExtraWithoutTax;
          totalextraStandardVat = targetVehicle.totalExtraWithTax;
        }
        targetVehicle.totalExtraWithoutTax = Number(totalextraStandard.toFixed(2));
        targetVehicle.totalExtraWithTax = Number(totalextraStandardVat.toFixed(2));
        updateValuesVehicle(targetVehicle,"");

        updateSimulationValues(targetVehicle, targetVehicle.simulations[simulationIndex], simulationIndex, "")

        rows[vehicleIndex] = targetVehicle;

      }


      return [...rows];
    });
  },[]);

  // transforma os extras standard da viatura para a estrutura da tabela
  const handleExtraStandardDTO = useCallback((vehicleIndex: number, simulationIndex: number) => {
    let extrasDisplayList: {
      group: string;
      groupLabel: string;
      code: string;
      label: string;
      valueTextFieldProps: Omit<TextFieldProps, 'label'>;
      checkboxProps: CheckboxProps;
      lazyload?: boolean;
      visible: boolean;
      requiredCodes: string[], // => obrigatórios
      incompatibilityCodes: string[], // incompatibilidade de extras
      includes: { label: string; code: string; }[], // => packs de extras
    }[] = [];

    let targetVehicle =  vehicleDataTable.find((row, index) => index === vehicleIndex);
    if(targetVehicle) {
      let disable = targetVehicle.licensePlate ? true :false;
      let extras = targetVehicle.extraStandard;
      if (extras.length > 0) {
        extras.forEach((extra, indexExtr: number) => {
          extrasDisplayList.push({
            group: extra.main_Group_Allocation ? extra.main_Group_Allocation : "",
            groupLabel: extra.descGroup ? extra.descGroup :"",
            code: extra.optionTypeId,
            label: extra.description,
            valueTextFieldProps: {
              value: extra.price_Excl_VAT,
              name: "price-price_Excl_VAT-" + vehicleIndex + "-" + indexExtr, //name: `price-${index}-${indexExtr}`,
              onChange: handleExtraStandardTextFieldChange(simulationIndex),
              disabled: disable
              // disabled: propertyDisabled('Extras','Detalhes Viatura') || disable,
              // hidden: propertyHidden('Extras','Detalhes Viatura') || disable,

            },
            checkboxProps: {
              checked: extra.checkedExtra,
              name: extra.optionTypeId,
              disabled: disable,
              onChange: handleExtraStandardCheckboxChange(vehicleIndex, simulationIndex),
              // disabled: propertyDisabled('Extras','Detalhes Viatura') || disable,
              // hidden: propertyHidden('Extras','Detalhes Viatura') || disable,
            },
            lazyload:true,
            visible: extra.visible,

            requiredCodes: [], // => obrigatórios
            incompatibilityCodes: [], // incompatibilidade de extras
            includes: [], // => packs de extras
          });
          // Inclusion Type
          switch (extra.inclusion_Type) {
            case 'Obligatory':
              extrasDisplayList[extrasDisplayList.length-1].requiredCodes = extra.compatibleExtrasList
              break;
            case 'Inclusion':
              extrasDisplayList[extrasDisplayList.length-1].requiredCodes = extra.compatibleExtrasList
              break;
            case 'Exclusion':
              // temp fix
              let incompatibleExtrasTmp:string[] = []
              extra.incompatibleExtrasList.forEach(element => {
                element.forEach(value => {
                  incompatibleExtrasTmp.push(value)
                });
              });
              extrasDisplayList[extrasDisplayList.length-1].incompatibilityCodes = incompatibleExtrasTmp
              // end temp fix
              // aux[aux.length-1].incompatibilityCodes = item.incompatibleExtrasList
              break;
          
            default:
              break;
          }
          // Package
          if(extra.package === 'Yes') {
            let packageExtras:{
              label:string,
              code:string
            }[] = [];

            //não consigo resolver isto. retorna sempre uma promise
            // let data = await vehiclesService.getIncompatibilityExtraPackageDescription(vehicleDataTable[index].versionId,item.compatibleExtrasList)//.then(data => {
            // if(data.succeeded) {
            //   data.value.forEach((element:{label:string, code:string}) => {
            //     packageExtras.push(element)
            //   });
            //   aux[aux.length-1].includes = packageExtras;
            //   aux[aux.length-1].requiredCodes = []
            // }
            // // });

            extra.compatibleExtrasList.forEach(value => {

              packageExtras.push({
                label:value,
                code:value
              })
            })
            extrasDisplayList[extrasDisplayList.length-1].includes = packageExtras;
            extrasDisplayList[extrasDisplayList.length-1].requiredCodes = []

          } else {
            extrasDisplayList[extrasDisplayList.length-1].includes = []
          }
        })
      }
    }

    return extrasDisplayList.filter(x => x.visible);
  },[ vehicleDataTable, handleExtraStandardTextFieldChange, handleExtraStandardCheckboxChange ]);

  //adiciona um acessório à viatura
  const handleAddAccessoryButtonPropsClick: ButtonProps["onClick"] = useCallback((e: any) => {
    let splits = e.currentTarget.name.split("-");
    const rowIndex = convertNumber(splits[0]);
    const id = convertNumber(splits[1]);
    setVehicleDataTable((rows) =>
      rows.map((row, index) => {
        if (index !== rowIndex) {
          return row;
        }
        let vehicle = row;
        vehicle.accessories.unshift({
          id: 0,
          optionTypeId: "",
          description: "",
          vehicleId: id,
          price: 0,
          price_Excl_VAT:0,
          responsable: "",
        });
        return { ...row, vehicle };
      })
    );
  }, []);

  // input de pesquisa de extra
  const handleExtraSearchPropsChange = useCallback((rowIndex: Number) => (e: any, option: any) => {
    setVehicleDataTable((rows) =>
      rows.map((row, index) => {
        if (index !== rowIndex) {
          return row;
        }

        if (option === null) {
          row.extraStandard.forEach((element) => {
            element.visible = true;
          });
        }
        row.extraOptionSelect = option;

        return {...row};
      })
    );
  }, []);

  // botão de pesquisa
  const handleExtraSearchPropsClick: ButtonProps["onClick"] = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    let rowIndex = convertNumber(e.currentTarget.name);
    setVehicleDataTable((rows) =>
      rows.map((row, index) => {
        if (index !== rowIndex) {
          return row;
        }
        let searchResults = row.extraOptionsSearch.find((b) => b.label === row.extraOptionSelect?.label); // [0 temporary solution]

        if (searchResults !== null) {
          row.extraStandard.forEach((element) => {
            if (element.optionTypeId === searchResults?.value) {
              element.visible = true;
            } else {
              element.visible = false;
            }
          });
        }
        
        return row;
      })
    );
  }, []);
  //#endregion

  //#region SECÇÃO - proposalConditionsArea AGUARDA MAPEAMENTOS DE CAMPOS EM FALTA NO FLUXO DE CRIACÇÃO DE PROPOSTA
  // PROPRIEDADE -maxKmDropDownProps // AGUARDA VALIDAÇÃO DA KINTO

  // PROPRIEDADE - rentalDurationDropDownProps
  const handleDeadlineChangeCallback = useCallback((vIndex:number, sIndex:number) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setVehicleDataTable((rows) => {
      let updatedRows = [...rows];
      let targetVehicle = updatedRows[vIndex];
      if(targetVehicle) {
        let targetSimulation = targetVehicle.simulations[sIndex];
        if(targetSimulation) {
          // targetVehicle.simulations[sIndex] = {...targetSimulation, deadline: Number(e.target.value)}

          // TODO REFLETIR ATUALIZAÇÕES DOS CAMPOS DA SIMULAÇÃO QUANDO FOREM MAPEADOS OS CAMPOS NOVOS
          let selectedMileagePerYear = targetSimulation.miles / (targetSimulation.deadline / 12);
          let totalMileage = (Number(e.target.value)/12) * selectedMileagePerYear;
          targetVehicle.simulations[sIndex] = {...targetSimulation, 
            deadline: Number(e.target.value),
            miles: totalMileage
          }
          //TODO END TODO


          updatedRows[vIndex] = targetVehicle;
          setMaxKmChanged(true);
        }
      }
      return updatedRows;
    });
  },[])
  // PROPRIEDADE - kmDropDownProps
  const handleMileageChangeCallback = useCallback((vIndex:number, sIndex:number) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setVehicleDataTable((rows) => {
      let updatedRows = [...rows];
      let targetVehicle = updatedRows[vIndex];
      if(targetVehicle) {
        let targetSimulation = targetVehicle.simulations[sIndex];
        if(targetSimulation) {
          // TODO REFLETIR ATUALIZAÇÕES DOS CAMPOS DA SIMULAÇÃO QUANDO FOREM MAPEADOS OS CAMPOS NOVOS

          targetVehicle.simulations[sIndex] = {...targetSimulation, 
            miles: Number(e.target.value) * (targetSimulation.deadline / 12 ) 
          }
          //TODO END TODO

          updatedRows[vIndex] = targetVehicle;
          setMaxKmChanged(true);
        }
      }
      return updatedRows;
    });
  },[])
  // PROPRIEDADE - tyresDropDownProps
  const handleTyresChangeCallback = useCallback((vIndex:number, sIndex:number) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let tiresValue = e.target.value === 'yes' ? true : false;
    setVehicleDataTable((rows) => {
      let updatedRows = [...rows];
      let targetVehicle = updatedRows[vIndex];
      if(targetVehicle) {
        let targetSimulation = targetVehicle.simulations[sIndex];
        if(targetSimulation) {
          targetVehicle.simulations[sIndex] = {...targetSimulation, tires: tiresValue }
          if(!tiresValue){
            targetVehicle.simulations[sIndex] = {...targetSimulation, 
              tires: tiresValue,
              tiresQuantity: '0' 
            }
          }
          updatedRows[vIndex] = targetVehicle;
        }
      }
      return updatedRows;
    });
  },[])
  // PROPRIEDADE - amountTextFieldProps
  const handleTyresAmountChangeCallback = useCallback((vIndex:number, sIndex:number) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setVehicleDataTable((rows) => {
      let updatedRows = [...rows];
      let targetVehicle = updatedRows[vIndex];
      if(targetVehicle) {
        let targetSimulation = targetVehicle.simulations[sIndex];
        if(targetSimulation) {
          targetVehicle.simulations[sIndex] = {...targetSimulation, tiresQuantity: Number(e.target.value).toString()} // elimina 0s à esquerda
          updatedRows[vIndex] = targetVehicle;
        }
      }
      return updatedRows;
    });
  },[])
  //#endregion
  
  //#region SECÇÃO - insuranceArea AGUARDA VALIDAÇÃO DA KINTO E ALTERAÇÕES HI-INTERACTIVE
  // PROPRIEDADE - radioGroupProps
  const handleInsuranceSelectedRadioChange = useCallback((vehicleIndex:number, simulationIndex:number) => (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
    if (value === 'yes') {
      setVehicleDataTable((rows) => {
        let updatedRows = [...rows];
        updatedRows.map(async(vehicle, index) => {
          if(index === vehicleIndex) {
            let targetVehicle = {...vehicle};
            targetVehicle.simulations.map(async (sim, sIndex) => {
              if (sIndex === simulationIndex) {
                setSimulationInsuranceOptions({vehicleIndex: vehicleIndex, simulationIndex: simulationIndex});
                sim.secureOptions = true;
              }
              return sim;
            });
            return targetVehicle;
          }
          return vehicle;
        })
        return updatedRows;
      })
    } else {
      setVehicleDataTable((rows) => {
        let updatedRows = [...rows];
        updatedRows.map((row, index) => {
          if(index === vehicleIndex) {
            let vehicle = row;
            vehicle.simulations.map((sim, sIndex) => {
              if (sIndex === simulationIndex) {
                sim.secureOptions = false;
                sim.ownDamage = '';
                sim.occupants = '';
                sim.travelAssistance = '';
                sim.responsibility = '';
                sim.glassBreakage = '';
                sim.insurance = '';
                sim.insurancePackageOptions = [];
              }
              return sim;
            });
            return vehicle;
          }
          return row;
        })
        return updatedRows;
      })
    }
  },[]);

  const handleFuelOrViaVerdeSelectedRadioChange = useCallback((vehicleIndex:number, simulationIndex:number) => (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
    if (value === 'yes') {
      setVehicleDataTable((rows) => {
        let updatedRows = [...rows];
        updatedRows.map(async(vehicle, index) => {
          if(index === vehicleIndex) {
            let targetVehicle = {...vehicle};
            targetVehicle.simulations.map(async (sim, sIndex) => {
              if (sIndex === simulationIndex) {
                if(e.target.name === 'fuelCard'){
                  sim.fuelCard = true;
                }
                if(e.target.name === 'viaVerde'){
                  sim.viaVerde = true;
                }
              }
              return sim;
            });
            return targetVehicle;
          }
          return vehicle;
        })
        return updatedRows;
      })
    } else {
      setVehicleDataTable((rows) => {
        let updatedRows = [...rows];
        updatedRows.map((row, index) => {
          if(index === vehicleIndex) {
            let vehicle = row;
            vehicle.simulations.map((sim, sIndex) => {
              if (sIndex === simulationIndex) {
                if(e.target.name === 'fuelCard'){
                  sim.fuelCard = false;
                  sim.fuelDescriptiom = '';       
                }
                if(e.target.name === 'viaVerde'){
                  sim.viaVerde = false;
                  sim.entityViaVerde = '';
                }
              }
              return sim;
            });
            return vehicle;
          }
          return row;
        })
        return updatedRows;
      })
    }
  },[]);

  // PROPRIEDADES:
  // - damageDropDownProps
  // - occupiersDropDownProps
  // - travelAssistanceDropDownProps
  // - liabilityDropDownProps
  // - isolatedGlassBreakDropDownProps
  const handleInsuranceOptionsValues = useCallback((insurance:string, use:string, type:string) => {
    let options: {
      value: string;
      label: string;
    }[] = [];
    if(insurance !== "" && use !== "" && type !== "") {

      const targetInsurancePackageInsuranceOptions = insurancePackageLineOptions.filter((option: any) => option.insurance_Package === insurance);
      const targetFilteredOptions = targetInsurancePackageInsuranceOptions.filter((option: any) => option.use === use && option.type === type);
      options = targetFilteredOptions.map((option: any) => {
        return { value: option.insurance, label: option.description }
      });
    }
    return options;
  },[insurancePackageLineOptions]);

  const handleOptionChangeCallback = useCallback((vehicleIndex:number, simulationIndex:number) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setVehicleDataTable((rows) => {
      let updatedRows = [...rows];
      let targetVehicle = updatedRows.find((row, index) => index === vehicleIndex);

      if(targetVehicle) {
        let targetSimulation = targetVehicle.simulations.find((sim, index) => index === simulationIndex);
       
        if(targetSimulation) {
          targetVehicle.simulations[simulationIndex] = {...targetSimulation, [e.target.name]: e.target.value}
          updatedRows[vehicleIndex] = targetVehicle;
        }
      }
      return updatedRows;
    });
  },[]);

  //#endregion
  
  //#region SECÇÃO - replacementVehicleArea CONCLUIDO AGUARDA DECISÃO SK-734
  // PROPRIEDADE - radioGroupProps
  const handleReplacementVehicleSelectedRadioChange = useCallback((vehicleIndex:number, simulationIndex:number) => (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
    if( value === 'yes') {
      setVehicleDataTable((rows) => {
        let updatedRows = [...rows];
        let targetVehicle = updatedRows.find((row, index) => index === vehicleIndex)

        if(targetVehicle){ 
          let targetSimulation = targetVehicle.simulations.find((sim, index) => index === simulationIndex)
        
          if (targetSimulation) {
            // exemplo de mapeamento correcto de estrutura completa das opções
            // const overhaulTypeOptions: Option[] = vsTypeOptions.filter((option:any) => option.type === 'Revisão').map((x: Option) => ({ label: x.label, value: x.value }));
            // exemplo de mapeamento de estrutura completa sem reduzir o objecto ( casting )
            const overhaulTypeOptions: Option[] = vsTypeOptions.filter((option:any) => option.type === 'Revisão')
            const assistanceTypeOptions: Option[] = vsTypeOptions.filter((option:any) => option.type === 'Avaria')
            const accidentTypeOptions: Option[] = vsTypeOptions.filter((option:any) => option.type === 'Sinistro')
            const theftTypeOptions: Option[] = vsTypeOptions.filter((option:any) => option.type === 'Roubo')

            targetVehicle.simulations[simulationIndex] = {...targetSimulation, 
              vehicleReplacementOptions: true,
              vehicleTypeOptions: vsOptions, //TODO no futuro este campo poderá ter de sofrer alterações aos mapeamentos. É preciso validar que alterações
              vsOverhaulVehicleOptions: vsOptions,
              vsOverhaulVehicleTypeOptions: overhaulTypeOptions,
              vsAssistanceVehicleOptions: vsOptions,
              vsAssistanceVehicleTypeOptions: assistanceTypeOptions,
              vsAccidentVehicleOptions: vsOptions,
              vsAccidentVehicleTypeOptions: accidentTypeOptions,
              vsTheftVehicleOptions: vsOptions,
              vsTheftVehicleTypeOptions: theftTypeOptions,
            }
          }
        
          updatedRows[vehicleIndex] = {...targetVehicle};
        }
        return updatedRows;
      });
    } else {
      setVehicleDataTable((rows) => {
        let updatedRows = [...rows];
        let targetVehicle = updatedRows.find((row, index) => index === vehicleIndex)

        if(targetVehicle){ 
          let targetSimulation = targetVehicle.simulations.find((sim, index) => index === simulationIndex)
        
          if (targetSimulation) {
            targetVehicle.simulations[simulationIndex] = {...targetSimulation, 
              vehicleReplacementOptions: false,
              vehicleTypeOptions: [],
              vsOverhaulVehicleOptions: [],
              vsOverhaulVehicleTypeOptions: [],
              vsAssistanceVehicleOptions: [],
              vsAssistanceVehicleTypeOptions: [],
              vsAccidentVehicleOptions: [],
              vsAccidentVehicleTypeOptions: [],
              vsTheftVehicleOptions: [],
              vsTheftVehicleTypeOptions: [],
            }
          }

          updatedRows[vehicleIndex] = {...targetVehicle};
        }
        return updatedRows;
      });
    }
  },[vsOptions, vsTypeOptions]);

  // PROPRIEDADE - vehicleTypeDropDownProps
  const handleVehicleTypeChangeCallback = useCallback((vehicleIndex:number, simulationIndex:number) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setVehicleDataTable((rows) => {
      let updatedRows = [...rows];
      let targetVehicle = updatedRows.find((row, index) => index === vehicleIndex)
      
      if(targetVehicle){ 
        let targetSimulation = targetVehicle.simulations.find((sim, index) => index === simulationIndex)
      
        if (targetSimulation) {
          targetVehicle.simulations[simulationIndex] = {...targetSimulation, 
            vehicleType: e.target.value,
            vsAccidentVehicle: e.target.value,
            vsAssistanceVehicle: e.target.value,
            vsOverhaulVehicle: e.target.value,
            vsTheftVehicle: e.target.value,
          }
          updatedRows[vehicleIndex] = {...targetVehicle};
        }
      
      }
      return updatedRows;

    });
  },[]);

  // PROPRIEDADES:
  // overhaul - vehicleTypeOptionDropDownProps
  // malfunction - vehicleTypeOptionDropDownProps
  // accident - vehicleTypeOptionDropDownProps
  // theft - vehicleTypeOptionDropDownProps
  const handleVsTypeOptionChangeCallback = useCallback((vehicleIndex:number, simulationIndex:number) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setVehicleDataTable((rows) => {
      let updatedRows = [...rows];
      let targetVehicle = updatedRows.find((row, index) => index === vehicleIndex)
      
      if(targetVehicle){ 
        let targetSimulation = targetVehicle.simulations.find((sim, index) => index === simulationIndex)
      
        if (targetSimulation) {
          targetVehicle.simulations[simulationIndex] = {...targetSimulation, [e.target.name]: e.target.value }
          updatedRows[vehicleIndex] = {...targetVehicle};
        }
      
      }
      return updatedRows;

    });

  },[]);

  // PROPRIEDADES:
  // overhaul - vehicleDropDownProps
  // malfunction - vehicleDropDownProps
  // accident - vehicleDropDownProps
  // theft - vehicleDropDownProps
  const handleVsOptionChangeCallback = useCallback((vehicleIndex:number, simulationIndex:number) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setVehicleDataTable((rows) => {
      let updatedRows = [...rows];
      let targetVehicle = updatedRows.find((row, index) => index === vehicleIndex)
      
      if(targetVehicle){ 
        let targetSimulation = targetVehicle.simulations.find((sim, index) => index === simulationIndex)
      
        if (targetSimulation) {
          targetVehicle.simulations[simulationIndex] = {...targetSimulation, [e.target.name]: e.target.value }
          updatedRows[vehicleIndex] = {...targetVehicle};
        }
      
      }
      return updatedRows;

    });
  },[]);
  //#endregion

  //#region SECÇÃO - complementaryProducts BLOCO NÃO IMPLEMENTADO
  //#endregion

  //#region SECÇÃO - residualValue AGUARDA VALIDAÇÃO DA KINTO / AGUARDA ALTERAÇÕES HI-INTERACTIVE
  // utiliza o metodo do mapeamento de valores da secção vehicleValuesArea para as propriedades da simulação
  //#endregion
  
  //#region SECÇÃO - effortArea CONCLUIDO
  // utiliza o metodo do mapeamento de valores da secção vehicleValuesArea para as propriedades da simulação
  //#endregion
  
  //#region SECÇÃO - partialRentArea CONCLUIDO
  // só tem modo de visualização
  //#endregion
  
  //#region SECÇÃO - rentArea CONCLUIDO
  // só tem modo de visualização
  //#endregion
  
  //#region SECÇÃO - paymentTermsArea CONCLUIDO
  // utiliza o metodo do mapeamento de valores da secção vehicleValuesArea para as propriedades da simulação
  //#endregion
  
  //#endregion

  const handleComplementaryProductsCallback = useCallback((vehicleIndex:number, simulationIndex:number) => {
    let cp: ComplementaryProductsSectionProps = {
      notIncludedText: 'Não Incluído',    
      complementaryProducts: {
        title: "Produtos completares/extras de contrato",
        //PESQUISA
        complementaryProductSearchResultProps: {
          placeholder:
            "Pesquisar por  descrição ou número do extra",
          options: [
            {
              label: "Opção1",
              value: "op1",
            },
            {
              label: "Opção2",
              value: "op2",
            },
          ],
        },
        searchButtonText: "Pesquisar",
        searchButtonProps: {
          // onClick: () => alert("button click"),
        },
        // LISTA DE PRODUTOS COMPLEMENTARES
        addedProductsNumberText: "0 produtos adicionados",
        editTableHeadCells:  [
          { label: 'Incluir' },
          { label: 'Nº' },
          { label: 'Descrição' },
          { label: 'Código Registo' },
          { label: 'Custos adicionais ' },
          { label: 'Seguro' },
        ],
        product: [],
      }
    };

    // if (complementaryProductsByVehicleMemo && complementaryProductsByVehicleMemo.length > 0) {
    //   // metodo para obter produtos complementares por simulação separado em duas declarações
    //   let vehicleHasSimulationWithComplementaryProducts = complementaryProductsByVehicleMemo.find((v, vIndex) => vIndex === vehicleIndex);

    //   let complementaryProducts: ContractSettingsDetailsProps['complementaryProducts'] | undefined = 
    //     vehicleHasSimulationWithComplementaryProducts?.simulations.find((s,sIndex) => sIndex === simulationIndex)?.complementaryProducts;

    //   if(complementaryProducts){
    //     cp.complementaryProducts.addedProductsNumberText = complementaryProducts.totalProductsText;
    //     cp.complementaryProducts.complementaryProductSearchResultProps = complementaryProducts.productSearchResultProps;
    //     //cp.editTableHeadCells = ,
    //     //complementaryProducts.tableProps.headCells;
    //     cp.complementaryProducts.product = complementaryProducts.tableProps.rows.map((item, index) => {
    //       return {
    //         includeCheckboxProps: {
    //           checked: item.cells[0].checked,
    //           //name: item.cells[0],
    //           // onChange: complementaryProducts.tableProps.onChange,
    //         },
    //         number: { 
    //           label: item.cells[1],
    //           value: item.cells[1]
    //         },
    //         description:{
    //           label: item.cells[2],
    //           value: item.cells[2]
    //         },
    //         additionalCosts: {
    //           costTextFieldProps: {
    //             value: item.cells[4][0].value,
    //             disabled: item.cells[4][0].disabled
    //           },
    //           periodicityDropDownProps: {
    //             options: item.cells[4][1].options,
    //             value: item.cells[4][1].value,
    //             disabled: item.cells[4][1].disabled
    //           },
    //         },
    //         regCodeDropDownProps: item.cells[3],
    //         inseranceDropDownProps: item.cells[5],
    //         typeDropDownProps: {
    //           options: [],
    //           value: '',
    //           disabled: true,
    //           hidden: true,
    //         },// DropDownProps;
    //         basePrice: item.details.basePrice,
    //         residualValueTextFieldProps: item.details.residualValueTextFieldProps,
    //         supplierDropDownProps: item.details.supplierDropDownProps,
    //         includeText: 'XXXXX'  
    //       }
    //     });
    //   }
    // }

    if (editProposalComplementaryProductsByVehicleMemo && editProposalComplementaryProductsByVehicleMemo.length > 0) {
      // metodo para obter produtos complementares por simulação separado em duas declarações
      let vehicleHasSimulationWithComplementaryProducts = editProposalComplementaryProductsByVehicleMemo.find((v, vIndex) => vIndex === vehicleIndex);

      let complementaryProducts: ComplementaryProductsSectionProps['complementaryProducts'] | undefined = 
        vehicleHasSimulationWithComplementaryProducts?.simulations.find((s,sIndex) => sIndex === simulationIndex)?.complementaryProducts;

        if(complementaryProducts ){
          cp.complementaryProducts.addedProductsNumberText = complementaryProducts.addedProductsNumberText;
          cp.complementaryProducts.complementaryProductSearchResultProps = complementaryProducts.complementaryProductSearchResultProps;
          cp.complementaryProducts.searchButtonProps = complementaryProducts.searchButtonProps;
          //cp.editTableHeadCells = ,
          //complementaryProducts.tableProps.headCells;
          cp.complementaryProducts.product = complementaryProducts.product?.map((item, index) => {
            return {
              includeCheckboxProps: item.includeCheckboxProps,
              number: item.number,
              additionalInfo1: item.additionalInfo1,
              additionalInfo2: item.additionalInfo2,
              description:item.description,
              typeDropDownProps: item.typeDropDownProps,
              additionalCosts: item.additionalCosts,
              regCodeDropDownProps: item.regCodeDropDownProps,
              inseranceDropDownProps: item.inseranceDropDownProps,
              basePrice: item.basePrice,
              residualValueTextFieldProps: item.residualValueTextFieldProps,
              supplierDropDownProps: item.supplierDropDownProps,
              includeText: item.includeText
            }
          });
        }
    }
    return cp.complementaryProducts;
  }, [
    // complementaryProductsByVehicleMemo, 
    editProposalComplementaryProductsByVehicleMemo
  ]);
  
  // CALLBACK DE MAPEAMENTO DOS DETALHES DA SIMULAÇÃO ==> IMPLICA MODO DE VISUALIZAÇÃO E MODO DE EDIÇÃO 
  // cria a estrutura de dados para a tabela de viaturas a ser mapeada para o useMemo vehiclesMemo
  const handleVehicleCallback = useCallback(() => {
  // substituir este parametro de entrada pelo estado vehicleDataTable e ver como isto afeta o código

    let vehicles : IVehicle[]=vehicleDataTable;
    if(filteredVehicles && filteredVehicles.length > 0) {
      vehicles = filteredVehicles;
    }
    let proposalTableData:Omit<EditProposalTableData, 'index'>[] = []
    vehicles.forEach((vehicle, vIndex:number) => {
      let simulations = vehicle.simulations;
      proposalTableData.push({
        proposedVehicleText: 'Veículo proposto',
        header: {
          icons: [PumpDieselIcon],
          model: `${vehicle.modelDescription} ${vehicle.versionDescription}`,
          characteristics: `(${vehicle.engineCc}CC | ${vehicle.powerHP}CV | ${vehicle.quantity_of_Seats} lugares | ${vehicle.numberofDoors} portas ) `
        },
        simulations: simulations.map((simulation: ISimulation,sIndex:number) =>  {

          return {
            vehicleSummary: `${vehicle.modelDescription} ${vehicle.versionDescription}`,
            id: simulation.no,
            qtd: simulation.quantity.toString(),
            term: simulation.deadline.toString(),
            km: simulation.miles.toString(),
            termkmHeaderText: `${simulation.deadline}/${simulation.miles}`,
            proformaPrice: `${simulation.proformaInvoicePriceWithoutTax}€`,
            businessValueWithTax:  `${simulation.businessValueWithoutTax}€`,
            vrReal: `${simulation.realVR}%`,
            vrIncrease: `${simulation.increaseVR}%`,
            vrTable: `${simulation.vrTable}€`,
            vrEurotaxWithTax: `${simulation.vrEurotax}€`,
            rappel: simulation.extraRappel.toString(),
            margin: `${simulation.margin}%`,
            income: {
              withoutTax: `${simulation.incomeWithoutTax} € `,
              withTax: `${simulation.incomeWithTax} € `
            },

            //TODO este campo deve reflectir os campos referentes ao estado da proposta do BC
            // no caso de a proposta estar pendente de validação ou aprovação
            // deverá existir um estado correspondente para efetuar essa validação ou aprovação
            status: {
              color: 'warning',
              label: 'Validar',
              value: 'validate',
              tooltipText: [
                'Informação para aprovar:',
                'Valor Residual',
                'Prazo de pagamento',
              ],
              options: [
                {
                  value: 'created',
                  color: 'info',
                  label: 'Criado',
                },

                {
                  value: 'validate',
                  color: 'warning',
                  label: 'Validar',
                },
              ],
              onChange: (option, vIndex, sIndex) =>alert(`Atribuir:\n{
                      value: '${option.value}',
                      color: '${option.color}',
                      label: '${option.label}',\n}\nao estado da simulação com índice ${sIndex} do veículo ${vIndex}`),
            },

            details: {
              // DETALHES DA PROPOSTA - FALTA APLICAR REGRAS DE CALCULO
              // FALTA aplicar permissões
              title: 'Detalhes da proposta',
              vehicleValuesArea: {
                title: 'Valores da viatura',
                valuesWithoutTaxColumn: {
                  onDiscountFileClick: () => alert('discount file'),
                  onRappelFileClick: () => alert('rappel file'),
                  title: 'Valores sem IVA',
                  basePriceTextFieldProps: {
                    label: 'Preço base',
                    value: simulation.priceexclVAT,
                    name: "priceexclVAT",
                    hasEndAdornment: true,
                    endAdornment:'€',
                    onChange: handleSimulationOnChange(vIndex, sIndex),
                  },
                  totalExtraTextFieldProps: {
                    label: 'Total extras',
                    value: simulation.totalExtraWithoutTax,
                    name: "totalExtraWithoutTax",
                    hasEndAdornment: true,
                    endAdornment:'€',
                    onChange: handleSimulationOnChange(vIndex, sIndex),
                  },
                  totalAccessoriesTextFieldProps: {
                    label: 'Total acessórios',
                    value: simulation.totalAccessoriesWithout,
                    name: "totalAccessoriesWithout",
                    hasEndAdornment: true,
                    endAdornment:'€',
                    onChange: handleSimulationOnChange(vIndex, sIndex),
                  },
                  discountTextFieldProps: {
                    label: 'Desconto',
                    value: simulation.discountWithoutTax,
                    name: "discountWithoutTax",
                    hasEndAdornment: true,
                    endAdornment:'€',
                    onChange: handleSimulationOnChange(vIndex, sIndex),
                  },
                  proformaInvoicePriceTextFieldProps: {
                    label: 'Preço fatura proforma',
                    value: simulation.proformaInvoicePriceWithoutTax,
                    name: "proformaInvoicePriceWithoutTax",
                    hasEndAdornment: true,
                    endAdornment:'€',
                    onChange: handleSimulationOnChange(vIndex, sIndex),
                  },
                  campaignTextFieldProps: {
                    label: 'Campanha',
                    value: simulation.campaignWithoutTax,
                    name: "campaignWithoutTax",
                    hasEndAdornment: true,
                    endAdornment:'€',
                    onChange: handleSimulationOnChange(vIndex, sIndex),

                  },
                  rappelTextFieldProps: {
                    label: 'Rappel',
                    value: simulation.rappelWithoutTax,
                    name: "rappelWithoutTax",
                    hasEndAdornment: true,
                    endAdornment:'€',
                    onChange: handleSimulationOnChange(vIndex, sIndex),
                  },
                  rappelExtraTextFieldProps: {
                    label: 'Rappel extra',
                    value: simulation.extraRappel,
                    name: "extraRappel",
                    hasEndAdornment: true,
                    endAdornment:'€',
                    onChange: handleSimulationOnChange(vIndex, sIndex),
                  },
                },
                valuesWithTaxColumn: {
                  title: 'Valores com IVA',
                  basePriceTextFieldProps: {
                    value: simulation.consumersPrice,
                    name: "consumersPrice",
                    hasEndAdornment: true,
                    endAdornment:'€',
                    onChange: handleSimulationOnChange(vIndex, sIndex),
                  },
                  totalExtraTextFieldProps: {
                    value: simulation.totalExtraWithTax,
                    name: "totalExtraWithTax",
                    hasEndAdornment: true,
                    endAdornment:'€',
                    onChange: handleSimulationOnChange(vIndex, sIndex),
                  },
                  totalAccessoriesTextFieldProps: {
                    value: simulation.totalAccessoriesWith,
                    name: "totalAccessoriesWith",
                    hasEndAdornment: true,
                    endAdornment:'€',
                    onChange: handleSimulationOnChange(vIndex, sIndex),
                  },
                  discountTextFieldProps: {
                    value: simulation.discountWithTax,
                    name: "discountWithTax",
                    hasEndAdornment: true,
                    endAdornment:'€',
                    onChange: handleSimulationOnChange(vIndex, sIndex),
                  },
                  discountExtraTextFieldProps: {
                    value: simulation.discountWithTaxPercentage ,
                    name: "discountWithTaxPercentage",
                    hasEndAdornment: true,
                    endAdornment:'%',
                    onChange: handleSimulationOnChange(vIndex, sIndex),
                  },
                  discountTotalTextFieldProps: {
                    value: simulation.discountWithTaxTotal ,
                    name: "discountWithTaxTotal",
                    hasEndAdornment: true,
                    endAdornment:'€',
                    onChange: handleSimulationOnChange(vIndex, sIndex),
                  },
                  proformaInvoicePriceTextFieldProps: {
                    value: simulation.proformaInvoicePriceWithTax,
                    name: "proformaInvoicePriceWithTax",
                    hasEndAdornment: true,
                    endAdornment:'€',
                    onChange: handleSimulationOnChange(vIndex, sIndex),
                  },
                  campaignTextFieldProps: {
                    value: simulation.campaignWithTax,
                    name: "campaignWithTax",
                    hasEndAdornment: true,
                    endAdornment:'€',
                    onChange: handleSimulationOnChange(vIndex, sIndex),
                  },
                  campaignExtraTextFieldProps: {
                    value: simulation.campaignWithTaxPercentage ,
                    name: "campaignWithTaxPercentage",
                    hasEndAdornment: true,
                    endAdornment:'%',
                    onChange: handleSimulationOnChange(vIndex, sIndex),
                  },
                  campaignTotalTextFieldProps: {
                    value: simulation.campaignWithTaxTotal ,
                    name: "campaignWithTaxTotal",
                    hasEndAdornment: true,
                    endAdornment:'€',
                    onChange: handleSimulationOnChange(vIndex, sIndex),
                  },
                  rappelTextFieldProps: {
                    value: simulation.rappelWithTax,
                    name: "rappelWithTax",
                    hasEndAdornment: true,
                    endAdornment:'€',
                    onChange: handleSimulationOnChange(vIndex, sIndex),
                  },
                  rappelExtraTextFieldProps: {
                    value: simulation.rappelWithTaxPercentage,
                    name: "rappelWithTaxPercentage",
                    hasEndAdornment: true,
                    endAdornment:'%',
                    onChange: handleSimulationOnChange(vIndex, sIndex),
                  },
                  rappelTotalTextFieldProps: {
                    value: simulation.rappelWithTaxTotal ,
                    name: "rappelWithTaxTotal",
                    hasEndAdornment: true,
                    endAdornment:'€',
                    onChange: handleSimulationOnChange(vIndex, sIndex),
                  },
                },
                expensesColumn: {
                  title: 'Despesas',
                  ecoValueTextFieldProps: {
                    label: 'Ecovalor',
                    value: vehicle.recycling_charge ,
                    endAdornment:'€',
                    //TODO FALTA MAPEAR
                    name: "recycling_charge",
                    onChange: handleVehicleOnChange(vehicle, vIndex),
                  },
                  ISV_WithoutTaxTextFieldProps: {
                    label: 'ISV sem IVA',
                    value: vehicle.luxury_tax,
                    endAdornment:'€',
                    //TODO FALTA MAPEAR
                    name: "luxury_tax",
                    onChange: handleVehicleOnChange(vehicle, vIndex),
                  },
                  PVP: {
                    label: 'PVP sem e com IVA',
                    value: `${vehicle.pvp}€ | ${(vehicle.pvp * (100 + vehicle.vat)/100).toFixed(2)} €`,
                    editPVPTextFieldProps: {
                      label:'PVP sem Iva',
                      value: vehicle.pvp,
                      endAdornment:'€',
                      //TODO FALTA MAPEAR
                      name: "pvp",
                      onChange: handleVehicleOnChange(vehicle, vIndex),
                    },
                  },

                  legalizationAndTransportCostsTextFieldProps: {
                    label: 'Despesas de legalização e transporte',
                    value: vehicle.transport_x0026_immatriculation_cost,
                    endAdornment:'€',
                    //TODO FALTA MAPEAR
                    name: "transport_x0026_immatriculation_cost",
                    onChange: handleVehicleOnChange(vehicle, vIndex),
                  },
                },
                rentalColumn: {
                  title: 'Dados do aluguer',
                  rentalType: {
                    label: 'Tipo de aluguer',
                    value: vehicle.typeofRental,
                  },
                  businessConditions: {
                    label: 'Condiçoes comerciais',
                    value: vehicle.commercialConditions,
                  },
                  amountTextFieldProps: {
                    label: 'Qtd',
                    value: simulation.quantity,
                    name: "quantity",
                    onChange: handleSimulationOnChange(vIndex, sIndex),
                  },
                },
                businessValueWithoutTaxTextFieldProps: {
                  label: 'Valor de negócio sem IVA',
                  value: simulation.businessValueWithoutTax,
                  hasEndAdornment: true,
                  endAdornment:'€',
                  name: "businessValueWithoutTax",
                  onChange: handleSimulationOnChange(vIndex, sIndex),
                },
                businessValueWithTaxTextFieldProps: {
                  label: 'Valor de negócio com IVA',
                  value: simulation.businessValueWithTax,
                  hasEndAdornment: true,
                  endAdornment:'€',
                  name: "businessValueWithTax",
                  onChange: handleSimulationOnChange(vIndex, sIndex),
                },
              },

              // EXTRAS DA VIATURA FALTA VALIDAR
              // FALTA aplicar permissões
              vehicleExtrasArea: {
                title: 'Extras da viatura',

                // Modo de visualização
                extraStandardsSubtitle: 'Extras',
                extraAccessoriesSubtitle: 'Acessórios manuais',

                // Modo de edição
                expandExtraAccessoriesText: 'Mostrar todos os acessórios',
                collapseExtraAccessoriesText: 'Ocultar acessórios',
                expandExtraStandardsText: 'Mostrar todos os extras',
                collapseExtraStandardsText: 'Ocultar extras',
                noSelectedExtraStandardsText: [
                  'Não existem extras selecionados',
                  'Selecione os extras da lista apresentada em Todos os extras',
                ],

                allExtrasButtonText: 'Todos os extras',
                allExtrasButtonProps: {},
                selectedExtrasButtonText: 'Extras selecionados',
                selectedExtrasButtonProps: {},

                addAccessoriesSubtitle: 'Adicionar acessórios',
                addAccessoriesButtonText: 'Adicionar Acessório',
                addAccessoriesButtonProps: {
                  name: vIndex+"-"+vehicle?.id,
                  onClick: handleAddAccessoryButtonPropsClick,
                  disabled: propertyDisabled('Acessórios'),
                  hidden: propertyHidden('Acessórios'),
                },
                selectExtrasStandardsSubtitle: 'Escolher extras standard',
                extraStandardSearchResultProps: {
                  options: vehicle.extraOptionsSearch,
                  placeholder: 'Pesquisar',
                  value: vehicle.extraOptionSelect,
                  onChange: handleExtraSearchPropsChange(vIndex),
                },
                searchButtonText: 'Pesquisar',
                searchButtonProps: {
                  name: vIndex.toString(),
                  onClick: handleExtraSearchPropsClick
                },
                extraStandard: handleExtraStandardDTO(vIndex, sIndex), //TODO aplicar o metodo comentado quando o campo for corrigido
                extraAccessories: handleAccessoriesDTO(vehicle.accessories), //TODO aplicar o metodo comentado quando o campo for corrigido
              },

              // CONDIÇÕES DA PROPOSTA - AGUARDA MAPEAMENTOS DE CAMPOS EM FALTA NO FLUXO DE CRIACÇÃO DE PROPOSTA
              // FALTA aplicar permissões
              proposalConditionsArea: {
                title: 'Condições da proposta',
                // MODO DE VISUALIZAÇÃO
                tyres: { 
                  label: 'Pneus', 
                  value: handleTyresCallback(simulation.tires, simulation.tiresQuantity),
                },
                //PROPRIEDADES COMUNS A AMBOS OS MODOS ( NÃO NECESSITAM DE DIFERENCIAÇÃO ENTRE OS MODOS DE VISUALIZAÇÃO/EDIÇÃO)
                commercial: {
                  label: 'Condições comerciais',
                  value: handleCommercialConditionLabel(vehicle.commercialConditions),
                },
                finalFee: {
                  label: 'Taxa final',
                  value: `${simulation.finalTax} %`
                },

                // MODO DE EDIÇÃO

                // AGUARDA VALIDAÇÃO DA KINTO E POSSÍVEL ALTERAÇÃO DA HI-INTERACTIVE
                maxKmDropDownProps: {
                  label: 'KM máximos',
                  options: [
                    {
                      label: `${simulation.maximum_Kilometers}`,
                      value: `${simulation.maximum_Kilometers}`,
                    },
                  ],
                  disabled: true,
                  value: `${simulation.maximum_Kilometers}`,
                  // onChange: () => {}
                  warning: maxKmChanged
                },

                rentalDurationDropDownProps: {
                  label: 'Duração do aluguer',
                  options: deadlineOptions,
                  value: simulation.deadline.toString(),
                  onChange: handleDeadlineChangeCallback(vIndex, sIndex)
                },

                // NÃO TEM PROPRIEDADE PARA NO MODO DE VISUALIZAÇÃO MOSTRAR O VALOR TOTAL.
                kmDropDownProps: {
                  label: 'KM contratados',
                  options: annualKmOptions,
                  value: (simulation.miles / (simulation.deadline / 12)).toString(),
                  onChange: handleMileageChangeCallback(vIndex, sIndex)
                },
                tyresDropDownProps: {
                  label: 'Pneus',
                  options: [
                    {
                      label: 'Não',
                      value: 'no',
                    },
                    {
                      label: 'Sim',
                      value: 'yes',
                    },
                  ],
                  value: simulation.tires ? 'yes' : 'no',
                  onChange: handleTyresChangeCallback(vIndex, sIndex)
                },
                amountTextFieldProps: { 
                  label: 'Qtd.', 
                  value: simulation.tiresQuantity,
                  disabled: !simulation.tires,
                  options: settings.Data.Vehicles_TyresNumber,
                  onChange: handleTyresAmountChangeCallback(vIndex, sIndex)
                },
               
              },

              // SEGURO - AGUARDA VALIDAÇÃO DA KINTO E POSSÍVEL ALTERAÇÃO DA HI-INTERACTIVE
              // FALTA aplicar permissões
              insuranceArea: {
                title: 'Seguro',

                // MODO DE VISUALIZAÇÃO
                management: {
                  label: 'Gestão de seguro',
                  value: handleInsurancePackageSummaryLabel(vIndex,sIndex)
                },
                vehicleDamages: {
                  label: 'Danos no veículo em sinistro',
                  value: handleOwnDamageSummaryLabel(vIndex,sIndex)
                },
                iuc: {
                  label: 'IUC',
                  value: `Incluido. Valor inicial de ${simulation.iuc} €`
                },
                maintenance: {
                  label: 'Manutenção',
                  value: 'Incluído. Programada + Preventiva + Correctiva + IPO',
                  hidden: true,
                  disabled: true
                },

                // MODO DE EDIÇÃO - AGUARDA VALIDAÇÃO DA KINTO E POSSÍVEL ALTERAÇÃO DA HI-INTERACTIVE
                collapsableText: 'Fechar condições pré-definidas',
                expandableText: 'Abrir condições pré-definidas',
                radioGroupProps: {
                  value: simulation.secureOptions ? 'yes' : 'no',
                  onChange: handleInsuranceSelectedRadioChange(vIndex, sIndex),
                },
                yesCheckboxProps: {
                  label: 'Sim',
                  value: 'yes',
                  checked: simulation.secureOptions // não é necessário mapear a propriedade mas para efeitos de consistencia de código é feito
                },
                noCheckboxProps: {
                  label: 'Não',
                  value: 'no',
                  checked: !simulation.secureOptions // não é necessário mapear a propriedade mas para efeitos de consistencia de código é feito
                },
                // Own Damage
                damageDropDownProps: {
                  label: 'Danos próprios',
                  placeholder: 'Selecionar',
                  options: handleInsuranceOptionsValues(simulation?.insurance, vehicle.use, "Own Damage"),
                  value: simulation.ownDamage,
                  name: 'ownDamage',
                  onChange: handleOptionChangeCallback(vIndex, sIndex),
                  disabled: !simulation.secureOptions,
                },
                //Other Passenger
                occupiersDropDownProps: {
                  label: 'Ocupantes',
                  placeholder: 'Selecionar',
                  options: handleInsuranceOptionsValues(simulation?.insurance, vehicle.use, "Other Passenger"),
                  value: simulation.occupants,
                  name: 'occupants',
                  onChange:  handleOptionChangeCallback(vIndex, sIndex),
                  disabled: !simulation.secureOptions,
                },
                //Legal defense
                travelAssistanceDropDownProps: {
                  label: 'Assistência em viagem',
                  placeholder: 'Selecionar',
                  options: handleInsuranceOptionsValues(simulation?.insurance, vehicle.use, "Legal Defense"),
                  value: simulation.travelAssistance,
                  name: 'travelAssistance',
                  onChange:  handleOptionChangeCallback(vIndex, sIndex),
                  disabled: !simulation.secureOptions,
                },
                //Liabilities
                liabilityDropDownProps: {
                  label: 'Responsabilidade civil ',
                  placeholder: 'Selecionar',
                  options: handleInsuranceOptionsValues(simulation?.insurance, vehicle.use, "Liability"),
                  value: simulation.responsibility,
                  name: 'responsibility',
                  onChange:  handleOptionChangeCallback(vIndex, sIndex),
                  disabled: !simulation.secureOptions,
                },
                //Glass Breakage
                isolatedGlassBreakDropDownProps: {
                  label: 'Quebra isolada de vidros',
                  placeholder: 'Selecionar',
                  options: handleInsuranceOptionsValues(simulation?.insurance, vehicle.use, "Fire "),
                  value: simulation.glassBreakage,
                  name: 'glassBreakage',
                  onChange:  handleOptionChangeCallback(vIndex, sIndex),
                  disabled: !simulation.secureOptions,
                },
                maintenanceDropDownProps: {
                  label: 'Manutenção',
                  placeholder: 'Selecionar',
                  options: [
                    {
                      label: 'M10 00001 - 10.000€ MIP',
                      value: 'op1',
                    },
                  ],
                  hidden: true,
                  disabled: true,
                },
                //IUC - AGUARDA VALIDAÇÃO HI-INTERACTIVE
                // este campo não é nem deve ser uma drop down
                iucDropDownProps: {
                  label: 'IUC',
                  placeholder: 'Selecionar',
                  value: simulation.iuc.toString(),
                  hasEndAdornment: true,
                  endAdornment:'€',
                },
                // VALIDAR ESTAS 3 DROPDOWNS 
                insurancePackageDropDownPros: {
                    label: 'Pacote de Seguros',
                    placeholder: 'Selecionar',
                    options: simulation.insurancePackageOptions,
                    value: simulation.insurance,
                    name: 'insurance',
                    onChange: handleOptionChangeCallback(vIndex, sIndex),
                    disabled: !simulation.secureOptions,
                  },
                fuelCard: {
                    title: 'Cartão de combústivel',
                    disabled: !simulation.secureOptions,
                    yesCheckboxProps: { label: 'Sim', value: 'yes', checked: simulation.fuelCard },
                    noCheckboxProps: { label: 'Não', value: 'no', checked: !simulation.fuelCard }, 
                    radioGroupProps: {
                      value: simulation.fuelCard ? 'yes' : 'no',
                      onChange: handleFuelOrViaVerdeSelectedRadioChange(vIndex, sIndex),
                      name: 'fuelCard',
                    }, 
                    dropdownProps: {
                        options: simulation.fuelOptions,
                        value: simulation.fuelDescriptiom,
                        onChange: handleOptionChangeCallback(vIndex, sIndex),
                        name: 'fuelDescriptiom',
                        disabled: !simulation.fuelCard
                    },
                },
                viaVerde: {
                    title: 'Via Verde',
                    disabled: !simulation.secureOptions,
                    yesCheckboxProps: { label: 'Sim', value: 'yes', checked: simulation.viaVerde },
                    noCheckboxProps: { label: 'Não', value: 'no', checked: !simulation.viaVerde }, 
                    radioGroupProps: {
                      value: simulation.viaVerde ? 'yes' : 'no',
                      name: 'viaVerde',
                      onChange: handleFuelOrViaVerdeSelectedRadioChange(vIndex, sIndex),
                    }, 
                    dropdownProps: {
                      options: simulation.viaVerdeOptions,
                      onChange: handleOptionChangeCallback(vIndex, sIndex),
                      name: 'entityViaVerde',
                      value: simulation.entityViaVerde,
                      disabled: !simulation.viaVerde
                    },
                },
                // FALTAM DROPDOWNS PARA VIA VERDE E CARTÃO COMBUSTIVEL
              },

              // VIATURA DE SUBSTITUIÇÃO - CONCLUIDO AGUARDA DECISÃO SK-734
              // FALTA aplicar permissões
              replacementVehicleArea: {
                title: 'Viatura de substituição',

                // MODO DE VISUALIZAÇÃO
                accidentVS: {
                  label: 'VS em sinistro',
                  // value: 'Incluído VIP. Até 30 dias. (Citadino)',
                  value: handleVsTypeOptionLabel("Sinistro", simulation.vsAccidentVehicleType),
                },
                reviewVS: {
                  label: 'VS em revisões',
                  value: handleVsTypeOptionLabel("Revisão", simulation.vsOverhaulVehicleType),
                },
                stealthVS: {
                  label: 'VS em furto',
                  value: handleVsTypeOptionLabel("Roubo", simulation.vsTheftVehicleType),
                },
                malfunctionsVS: {
                  label: 'VS em avarias',
                  value: handleVsTypeOptionLabel("Avaria", simulation.vsAssistanceVehicleType),
                },

                //MODO DE EDIÇÃO
                collapsableText: 'Fechar condições pré-definidas',
                expandableText: 'Abrir condições pré-definidas',
                radioGroupProps: {
                  value: simulation.vehicleReplacementOptions ? 'yes' : 'no',
                  onChange: handleReplacementVehicleSelectedRadioChange(vIndex, sIndex),
                },
                yesCheckboxProps: { 
                  label: 'Sim', 
                  value: 'yes', 
                  checked: simulation.vehicleReplacementOptions // não é necessário mapear a propriedade mas para efeitos de consistencia de código é feito
                },
                noCheckboxProps: { 
                  label: 'Não', 
                  value: 'no', 
                  checked: !simulation.vehicleReplacementOptions  // não é necessário mapear a propriedade mas para efeitos de consistencia de código é feito
                },
                // este campo deixou de fazer parte da modal
                descriptionDropDownProps: {
                  label: 'Descrição',
                  options: [
                    {
                      label: 'R02N - Até 2 dias',
                      value: 'op1',
                    },
                  ],
                  defaultValue: 'op1',
                  hidden: true,
                  disabled: true
                },
                vehicleTypeDropDownProps: {
                  label: 'Tipo  de viatura',
                  options: simulation.vehicleTypeOptions,
                  // defaultValue: simulation.vehicleType,
                  value: simulation.vehicleType,
                  onChange: handleVehicleTypeChangeCallback(vIndex, sIndex),
                  disabled: !simulation.vehicleReplacementOptions 
                },
                overhaul: {
                  title: 'VS Revisão',
                  vehicleDropDownProps: {
                    label: 'Escolha viatura',
                    options: simulation.vsOverhaulVehicleOptions,
                    value: simulation.vsOverhaulVehicle,
                    name: 'vsOverhaulVehicle',
                    onChange: handleVsOptionChangeCallback(vIndex, sIndex),
                    disabled: !simulation.vehicleReplacementOptions 
                  },
                  vehicleTypeDropDownProps: {
                    label: 'Tipo viatura',
                    options: simulation.vsOverhaulVehicleTypeOptions,
                    value: simulation.vsOverhaulVehicleType,
                    name: 'vsOverhaulVehicleType',
                    onChange: handleVsTypeOptionChangeCallback(vIndex, sIndex),
                    disabled: !simulation.vehicleReplacementOptions 
                  },
                },
                malfunction: {
                  title: 'VS Avaria',
                  vehicleDropDownProps: {
                    label: 'Escolha viatura',
                    options: simulation.vsAssistanceVehicleOptions,
                    value: simulation.vsAssistanceVehicle,
                    name: 'vsAssistanceVehicle',
                    onChange: handleVsOptionChangeCallback(vIndex, sIndex),
                    disabled: !simulation.vehicleReplacementOptions 
                  },
                  vehicleTypeDropDownProps: {
                    label: 'Tipo viatura',
                    options: simulation.vsAssistanceVehicleTypeOptions,
                    value: simulation.vsAssistanceVehicleType,
                    name: 'vsAssistanceVehicleType',
                    onChange: handleVsTypeOptionChangeCallback(vIndex, sIndex),
                    disabled: !simulation.vehicleReplacementOptions 
                  },
                },
                accident: {
                  title: 'VS Sinistro',
                  vehicleDropDownProps: {
                    label: 'Escolha viatura',
                    options: simulation.vsAccidentVehicleOptions,
                    value: simulation.vsAccidentVehicle,
                    name: 'vsAccidentVehicle',
                    onChange: handleVsOptionChangeCallback(vIndex, sIndex),
                    disabled: !simulation.vehicleReplacementOptions 
                  },
                  vehicleTypeDropDownProps: {
                    label: 'Tipo viatura',
                    options: simulation.vsAccidentVehicleTypeOptions,
                    value: simulation.vsAccidentVehicleType,
                    name: 'vsAccidentVehicleType',
                    onChange: handleVsTypeOptionChangeCallback(vIndex, sIndex),
                    disabled: !simulation.vehicleReplacementOptions 
                  },
                },
                theft: {
                  title: 'VS Roubo',
                  vehicleDropDownProps: {
                    label: 'Escolha viatura',
                    options: simulation.vsTheftVehicleOptions,
                    value: simulation.vsTheftVehicle,
                    name: 'vsTheftVehicle',
                    onChange: handleVsOptionChangeCallback(vIndex, sIndex),
                    disabled: !simulation.vehicleReplacementOptions 
                  },
                  vehicleTypeDropDownProps: {
                    label: 'Tipo viatura',
                    options: simulation.vsTheftVehicleTypeOptions,
                    value: simulation.vsTheftVehicleType,
                    name: 'vsTheftVehicleType',
                    onChange: handleVsTypeOptionChangeCallback(vIndex, sIndex),
                    disabled: !simulation.vehicleReplacementOptions 
                  },
                },
              },

              // PRODUTOS COMPLEMENTARES - Bloco não implementado
              // FALTA aplicar permissões
              notIncludedText: 'Não Incluído',
              complementaryProducts: handleComplementaryProductsCallback(vIndex, sIndex),

              // VALOR RESIDUAL - AGUARDA VALIDAÇÕES DA KINTO / AGUARDA CORREÇÕES DA HI-INTERACTIVE
              // FALTA aplicar permissões
              residualValue: {
                thresholdText: 'Carece validação',
                title: 'Valor residual',

                businessWithTaxTextFieldProps: {
                  warning: true,
                  label: 'VR negócio com IVA',
                  value: simulation.realVR,
                  name: 'realVR',
                  hasEndAdornment: true,
                  endAdornment:'€',
                  onChange: handleSimulationOnChange(vIndex, sIndex),
                },
                eurotaxWithTaxTextFieldProps: {
                  label: 'VR Eurotax Original Com IVA',
                  value: simulation.vrEurotax,
                  name: 'vrEurotax',
                  hasEndAdornment: true,
                  endAdornment:'€',
                  onChange: handleSimulationOnChange(vIndex, sIndex),
                  disabled: true
                },
                tableWithTaxTextFieldProps: {
                  label: 'VR Tabela C/IVA',
                  value: simulation.vrTable,
                  name: 'vrTable',
                  hasEndAdornment: true,
                  endAdornment:'€',
                  onChange: handleSimulationOnChange(vIndex, sIndex),
                  disabled: true
                },
                percentualTextFieldProps: {
                  label: 'VR %',
                  value: simulation.increaseVR,
                  name: 'increaseVR',
                  hasEndAdornment: true,
                  endAdornment:'%',
                  onChange: handleSimulationOnChange(vIndex, sIndex),
                },

                // OCULTADOS/DESABILITADOS A PEDIDO DO CLIENTE
                addedDCpercentualVRTextFieldProps: {
                  // label: 'VR com Acréscimo DC VR%',
                  // value: '36.78%' +' REMOVER',
                  hidden: true,
                  disabled: true
                },
                addedDCpercentualTextFieldProps: {
                  // label: 'VR com Acréscimo DC %',
                  // value: '11.561.40%'+' REMOVER',
                  hidden: true,
                  disabled: true
                },
                realTextFieldProps: {
                  // label: 'VR Negócio C/IVA',
                  // value: '41.55%',
                  hidden: true,
                  disabled: true
                },
                // AGUARDAM CORREÇÕES DA HI-INTERACTIVE PARA DEIXAREM DE SER VISIVEIS
                withoutTaxTextFieldProps: {
                  // label: 'VR sem e com IVA',
                  // value: '463,46€'+' REMOVER',
                  hidden: true,
                  disabled: true
                },

                withTaxTextFieldProps: {
                  // value: '463,46€'+' REMOVER'
                  hidden: true,
                  disabled: true
                },
              },

              // ESFORÇO - CONCLUIDO
              // FALTA aplicar permissões
              effortArea: {
                title: 'Esforço',
                thresholdText: 'Carece validação',
                commercialTextFieldProps: {
                  label: 'Esforço Comercial',
                  name: 'margin',
                  value: `${simulation.margin}`,
                  warning: true,
                  hasEndAdornment: true,
                  endAdornment:'%',
                  onChange: handleSimulationOnChange(vIndex, sIndex),
                },
              },
              // PARCIAIS DE RENDA - CONCLUIDO
              // FALTA aplicar permissões
              partialRentArea: {
                title: 'Parciais renda',
                ammortization: {
                  label: 'Amortização',
                  value: `${simulation.amortization} €`,
                  hasEndAdornment: true,
                  endAdornment:'€',
                  name: "amortization",
                  // hidden: propertyHidden(''),
                  // disabled: propertyDisabled('')
                },
                tyres: {
                  label: 'Pneus',
                  value: `${simulation.tyresCost} €` ,
                  hasEndAdornment: true,
                  endAdornment:'€',
                  name: "tyresCost",
                  // hidden: propertyHidden(''),
                  // disabled: propertyDisabled('')
                },
                vs: {
                  label: 'VS',
                  value: `${simulation.vsCost} €` ,
                  hasEndAdornment: true,
                  endAdornment:'€',
                  name: "vsCost",
                  // hidden: propertyHidden(''),
                  // disabled: propertyDisabled('')
                },
                interests: {
                  label: 'Juros', 
                  value: `${simulation.interests} €`,
                  hasEndAdornment: true,
                  endAdornment:'€',
                  name: "interests",
                  // hidden: propertyHidden(''),
                  // disabled: propertyDisabled('')
                },
                iuc: {
                  label: 'IUC', 
                  value: `${simulation.iucCost} €` ,
                  hasEndAdornment: true,
                  endAdornment:'€',
                  name: "iucCost",
                  // hidden: propertyHidden(''),
                  // disabled: propertyDisabled('')
                },
                fee: {
                  label: 'FEE', 
                  value: `${simulation.fee} €` ,
                  hasEndAdornment: true,
                  endAdornment:'€',
                  name: "fee",
                  // hidden: propertyHidden(''),
                  // disabled: propertyDisabled('')
                },
                maintenance: {
                  label: 'Manut.', 
                  value: `${simulation.maintenanceCost} €` ,
                  hasEndAdornment: true,
                  endAdornment:'€',
                  name: "maintenanceCost",
                  // hidden: propertyHidden(''),
                  // disabled: propertyDisabled('')
                },
                insurance: {
                  label: 'Seguro', 
                  value: `${simulation.insuranceCost} €` ,
                  hasEndAdornment: true,
                  endAdornment:'€',
                  name: "insuranceCost",
                  // hidden: propertyHidden(''),
                  // disabled: propertyDisabled('')
                },
              },
              // RENDA - CONCLUIDO
              // FALTA aplicar permissões
              rentArea: {
                title: 'Renda',
                incomeWithAddedDCValues: {
                  label: [
                    'Renda com alteração de VR C/ acréscimo DC ',
                    'Renda sem e com IVA',
                  ],
                  // para facilitar o cálculo e não haver discrepancias entre os valores
                  // o valor ao iva aplicado é inferido com base nos valores alterados
                  value: `${simulation.baseIncomeWithoutTax}€ | ${simulation.baseIncomeWithoutTax * simulation.incomeWithTax / simulation.incomeWithoutTax}€` //'463,46€ | 391,90€',
                },
                incomeWithoutAddedDCValues: {
                  label: 'Renda sem e com IVA',
                  value: `${simulation.incomeWithoutTax}€ | ${simulation.incomeWithTax}€` //'463,46€ | 391,90€',
                },
              },
              // CONDIÇÕES DE PAGAMENTO - CONCLUIDO
              // FALTA aplicar permissões
              paymentTermsArea: {
                title: 'Condições de pagamento',
                initialEntryTextFieldProps: {
                  label: 'Entrada inicial/Primeira renda',
                  value: `${simulation.initialEntry}`, //'N/A',
                  name: 'initialEntry',
                  hasEndAdornment: true,
                  endAdornment:'€',
                  onChange: handleSimulationOnChange(vIndex, sIndex),
                },
                periodicity: {
                  label: 'Periodicidade',
                  value: 'Mensal'
                },
                paymentStart: {
                  label: 'Início de pagamentos',
                  value: paymentTerms ? paymentTerms[1].result?.find((x: any) => x.value === vehicle.typeofIncome).label : ''
                },
                paymentsNumber: {
                  label: 'Número de pagamentos',
                  value: simulation.deadline.toString(),
                },
                totalWithoutTax: {
                  label: 'Total sem IVA',
                  value: `${simulation.incomeWithoutTax} €`,
                },
                totalWithTax: {
                  label: 'Total com IVA',
                  value: `${simulation.incomeWithTax} €`,
                },
              },

              // validar se conseguimos ter controlo sobre esta variável e para que serve com a hi-interactive
              // para podermos controlar que extras/acessórios aparecem quando a proposta é apresentada
              // em modo de consulta e em modo de edição
              // editMode: true,
            },
            rowActions: [
              { label: 'Ver detalhes', },
              { label: 'Editar', },
              {
                label: 'Aprovar',
                onClick: handleApprove,
              },
              {
                label: 'Duplicar',
                // onClick: handleDuplicate(simulation),
                onClick: handleDuplicate(vIndex,sIndex),
              },
              {
                label: 'Eliminar',
                onClick: handleEliminate(vIndex,sIndex),
              },
            ],
          }
        }),
        // o que é que esta estrutura reflete?
        vehicle: {
          icons: [PumpDieselIcon],
          brand: vehicle.makeId,
          model: vehicle.modelDescription,
          version: vehicle.versionDescription,
          segment: vehicle.fuelType,
          lot: `lote: ${vehicle.lotId}`,
          id: vehicle.id.toString(),
        },
        // neste botão de editar não é visivel o ícone da mão para dar indicação ao utilizador de que é um elemento clicável
        editVehicleLinkText: 'Editar',
        editVehicleLinkOnClick: (vehicle) => handleCardClick(`proposal/edit/${proposal.id}`),
          // alert(`Editar o veículo : ${vehicle.model} ${vehicle.version}`),
        addSimulationLinkText: 'Nova simulação',
        addSimulationLinkOnClick: handleAddSimulation(vIndex),
      });
    });
    setVehicles((rows) => {
      rows = proposalTableData;
      return rows;
    });
    return proposalTableData;

  //};
  }, [
    settings.Data.Vehicles_TyresNumber,
    vehicleDataTable,
    // acções
    handleAddSimulation,
    handleApprove,
    handleCardClick,
    handleDuplicate,
    handleEliminate,
    handleFuelOrViaVerdeSelectedRadioChange,

    filteredVehicles,
    proposal,
    
    handleAccessoriesDTO, 

    handleExtraStandardDTO, 
    handleExtraSearchPropsChange, handleExtraSearchPropsClick,

    propertyDisabled, propertyHidden, handleAddAccessoryButtonPropsClick,
    // visualização
    handleVsTypeOptionLabel,
    handleCommercialConditionLabel, handleTyresCallback,
    handleInsurancePackageSummaryLabel, handleOwnDamageSummaryLabel,
    // edição
    handleSimulationOnChange,
    handleVehicleOnChange,
    paymentTerms, 
    deadlineOptions, annualKmOptions, 
    handleDeadlineChangeCallback, handleMileageChangeCallback,
    handleTyresAmountChangeCallback, handleTyresChangeCallback,
    handleVehicleTypeChangeCallback, 
    handleVsOptionChangeCallback, handleVsTypeOptionChangeCallback, 
    handleInsuranceOptionsValues, handleOptionChangeCallback,
    handleInsuranceSelectedRadioChange,
    handleReplacementVehicleSelectedRadioChange,

    maxKmChanged, 
    handleComplementaryProductsCallback
  ]);

  const handleGroupEditModalChange = useCallback((e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    let value: any;
    let discount: Number = 0;
    let campaign: Number = 0;
    let rappel: Number = 0;
    let targetIncomeWithTax: Number = 0;
    let targetIncomeWithoutTax: Number = 0;
    setVehicleGroupEditModalValues((current) => {
      if (
        e.target.name === "marginCheck" ||
        e.target.name === "commercialEffortCheck" ||
        e.target.name === "vrCheck"
      ) {
        value = (e.target as any).checked;
      } else if (e.target.name === "lotId") {
        value = e.target.value;
      } else {
        value = convertNumber(e.target.value);
        if (e.target.name === "discountWithoutTax"){
          discount = convertNumber((value * (1 + 23 / 100)).toFixed(2));
        } else if (e.target.name === "campaignWithoutTax"){
          campaign = convertNumber((value * (1 + 23 / 100)).toFixed(2));
        } else if (e.target.name === "rappelWithoutTax"){
          rappel = convertNumber((value * (1 + 23 / 100)).toFixed(2));
        } else if (e.target.name === "incomeWithoutTax" /*&& step  === 4*/ ) {
          targetIncomeWithoutTax = convertNumber((value).toFixed(2));
          targetIncomeWithTax = convertNumber((value * (1 + 23 / 100)).toFixed(2));
        } else if (e.target.name === "incomeWithTax" /*&& step  === 4*/ ) {
          targetIncomeWithTax = convertNumber((value).toFixed(2));
          targetIncomeWithoutTax = convertNumber((value / (1.23 )).toFixed(2));         
        }
      }
      // let updatedValues = {...current}

      return {
        ...current,
        [e.target.name]: value,
        discountWithTax:convertNumber(discount ? discount : current.discountWithTax),
        campaignWithTax:convertNumber(campaign ? campaign : current.campaignWithTax),
        rappelWithTax:convertNumber(rappel ? rappel : current.rappelWithTax),
        incomeWithTax:convertNumber(targetIncomeWithTax ? targetIncomeWithTax : current.incomeWithTax),
        incomeWithoutTax:convertNumber(targetIncomeWithoutTax ? targetIncomeWithoutTax : current.incomeWithoutTax),
      };
    });
  }, []);

  const handleCloseGroupEditModal: ButtonProps["onClick"] = useCallback(() => {
    const vehicleGroupEdit = vehicleGroupEditModalValues;
    setContractSelectedRows((rowsSelect) =>
      rowsSelect.map((rowSelect) => {
          setVehicleDataTable((rows) =>
            rows.map((rowVehicle, index) => {
              if (index !== rowSelect.vehicleIndex) {
                return rowVehicle;
              }

              let simulation = rowVehicle.simulations[rowSelect.simulationIndex];

              if (vehicleGroupEdit.marginCheck && vehicleGroupEdit.incomeWithoutTax > 0) {
                if (vehicleGroupEdit.margin > 0 && vehicleGroupEdit.incomeWithoutTax === 0 ) {
                  //doNothing();
                  // qual a regra de calculo a aplicar??
                }
              }

              if(vehicleGroupEdit.vrCheck && vehicleGroupEdit.incomeWithoutTax > 0) {
                  // VR Final = VR Atual + (Renda atual- Renda Target)* Nº de Prestações
                  simulation.vrTable = // VR Final
                    //VR Atual              + (Renda atual                - Renda target                      ) * Nº prestações
                    simulation.baseVRTable + (simulation.incomeWithoutTax - vehicleGroupEdit.incomeWithoutTax ) * simulation.deadline; 

                  // % Aumento VR
                  simulation.increaseVR = Number((
                    (simulation.vrTable - simulation.baseVRTable) * 100 / simulation.baseVRTable
                  ).toFixed(2))
              }

              rowVehicle.lotId = vehicleGroupEdit.lotId;

              if ( vehicleGroupEdit.incomeWithoutTax > 0 && (vehicleGroupEdit.vrCheck || vehicleGroupEdit.marginCheck)) {
                simulation.incomeWithTax = vehicleGroupEdit.incomeWithTax;
                simulation.incomeWithoutTax = vehicleGroupEdit.incomeWithoutTax;
              }

              simulation.initialEntry = vehicleGroupEdit.initialEntry;
              simulation.collateral = vehicleGroupEdit.collateral;

              if (vehicleGroupEdit.increaseVr > 0) {
                simulation.increaseVR = vehicleGroupEdit.increaseVr;
                simulation.vrTable = Number((simulation.baseVRTable * ((100 + vehicleGroupEdit.increaseVr) / 100)).toFixed(2))  //VR Atual
              }
              if(vehicleGroupEdit.margin > 0) {
                simulation.margin = vehicleGroupEdit.margin;
              }
              if(vehicleGroupEdit.expenses !== simulation.contractExpenses) {
                simulation.contractExpenses = vehicleGroupEdit.expenses;
              }
              
              updateValuesVehicle(rowVehicle,"");

              return rowVehicle;
            })
          );
        return rowSelect;
      }
    ));
    setVehicleGroupEditModalValues({
      incomeWithoutTax: 0,
      incomeWithTax: 0,
      collateral: 0,
      margin: 0,
      increaseVr:0,
      initialEntry: 0,
      expenses: 0,
      marginCheck: false,
      commercialEffortCheck: false,
      vrCheck: false,
      discountWithoutTax: 0,
      discountWithTax: 0,
      discountWithTaxPercentage: 0,
      campaignWithoutTax: 0,
      campaignWithTax: 0,
      campaignWithTaxPercentage: 0,
      rappelWithoutTax: 0,
      rappelWithTax: 0,
      rappelWithTaxPercentage: 0,
      extraRappel: 0,
      lotId: "",
      extraAccessories: [],
    }); 
  },[vehicleGroupEditModalValues, setContractSelectedRows]);

  const vehiclesMemo = useMemo(() => handleVehicleCallback(), [handleVehicleCallback]);
  // const vehiclesMemo = useMemo(() => handleVehicleCallback(vehicleDataTable), [vehicleDataTable, handleVehicleCallback]);

  useEffect(() => {

    // carrega condições comerciais
    vehicleService.getCommercialConditions().then(data => {
      if(data && data.succeeded){
        setCommercialConditionsOptions(data.value);
      }
      return;
    });
    // carrega todos os pacotes de seguros
    vehicleService.getAllInsurancePackage().then(data => {
      if(data && data.succeeded){
        setInsurancePackageOptions(data.value);
      }
      return;
    })
    // carrega todos os seguros
    vehicleService.getInsurancePackageLine().then(data => {
      if(data && data.length > 0){
        setInsurancePackageLineOptions(data);
      }
      return;
    })
    // carrega todos os tipos de viaturas de substituição
    vehicleService.getReplacTransType().then(data => {
      if(data && data.length > 0){
        let options: {
          label: string,
          value: string,
          type: string
        }[] = []
        data.map((option:any) => {
          return options.push({ label: option.description, value: option.replacementVehicle, type: option.concept });
        });
        setVsTypeOptions(options)
      }
    });
    // carreaga todas a viaturas de substituição
    vehicleService.getVehiclePriceGroup().then(data => {
      if(data && data.length > 0){
        let options: Option[] = [];
        data.map((option:any) => {
          return options.push({ label: option.label, value: option.value });
        });
        setVsOptions(options);
      }
    })

  },[])

  return {vehicleDataTable, setVehicleDataTable, setAnnualKmOptions, vehicles, handleVehicleCallback, vehiclesMemo, setSimulationInsuranceOptions, vehicleGroupEditModalValues, setVehicleGroupEditModalValues, handleGroupEditModalChange, handleCloseGroupEditModal };
};

export default useVehiclesCustomHook;