import { CommentsService } from "../services/CommentsService";
import { UserModel } from "./User";

export interface ProposalComment {
  id: number;
  proposalId: number;
  avatarSrc: string;
  name: string;
  created: Date;
  createdBy: UserModel;
  attachments: File[];
  read: boolean;
  text: string;
  message: string;
}

export function convertToComment(comment: ProposalComment): {
  avatarSrc: string;
  name: string;
  text: string;
  date: string;
  onSaveButtonClick?: () => void;
  showEditButton? : boolean;
  files?: [
    {
       file: File ; 
       onClick?: (() => void) | undefined; 
    }
  ]
}
{
  const commentsService = new CommentsService();
  let items: any = [];
  if (comment.attachments.length > 0)
  {
    items = (comment.attachments as unknown as []).map(attachment =>
    {
      var file = new File([],  attachment['fileName']);
      return {
        file: file,
        onClick: (() => {
          commentsService.getAttachment(attachment['guid']).then(res => {
            // blob upload
            // var blob = new Blob([res], {
            //   // type: attachment['contentType'],
            //   type: 'image/png'
            // });
            // file = new File([blob],  attachment['fileName']);

            if (res.length > 0)
              {
              var downloadLink = document.createElement("a");
              const linkSource = `data:${attachment['contentType']};base64,${res}`;
              downloadLink.href = linkSource; 
              //URL.createObjectURL(file);
              downloadLink.download = attachment['fileName'];      
              downloadLink.click();
              //window.open(downloadLink.download, "_blank")
            }
          });
          
        })
      };
    });
  
  }
  // if (comment)
  // {
  // // if user is the same as authenticated
  // // do stuff

  // }
  return {
    avatarSrc: comment.createdBy?.avatarSrc,
    name: comment.createdBy?.friendlyName,
    text: comment.message,
    date: toDateDtring(comment?.created),
    onSaveButtonClick: () => {
      commentsService.updateComment(comment.proposalId, comment.id, comment.message, comment.attachments);
    },
    showEditButton: true,
    files: items
  };
}

function toDateDtring(tmpDate:Date): string {
  let dateString = "";
  if (tmpDate !== null && tmpDate !== undefined) {
    const date = new Date(tmpDate);
    //const month = date.getMonth() + 1;
    const hours = date.getHours();
    const day = date.getDate();

    // lógica alternativa caso seja necessário
    // let formattedDay = '0';
    // if(day < 10) {
    //   formattedDay += day.toString()
    // }
    // else{ 
    //   formattedDay = day.toString()
    // }
    // let formattedMonth = '';
    // if(date.getMonth() < 10) {
    //   formattedMonth = (1 + date.getMonth()).toString() 
    // }
    // else {
    //   formattedMonth = date.getMonth().toString()
    // }
    // let formattedHour = ''
    // if (hours < 10) {
    //   formattedHour = '0' + hours.toString()
    // }
    // else {
    //   formattedHour = hours.toString()
    // }
    // let formattedMinutes = '0'
    // if (date.getMinutes() < 10) {
    //   formattedMinutes += date.getMinutes().toString()
    // }
    // else{
    //   formattedMinutes = date.getMinutes().toString()
    // }

    // dateString = `${formattedDay}-${formattedMonth}-${date.getFullYear().toString()} ${formattedHour}:${formattedMinutes}`

    dateString =
      (day > 9 ? day.toString() : "0" + day.toString()) +
      "-" +
      (date.getMonth() > 9 ? date.getMonth().toString() : (1 + date.getMonth()).toString()) +
      "-" +
      date.getFullYear() +
      " " +
      (hours > 9 ? hours.toString() : "0" + hours.toString()) +
      ":" +
      (date.getMinutes() > 9 ? date.getMinutes().toString() : "0" + date.getMinutes().toString());
  }
  return dateString;
}
