import {
AccountIcon,
  Alert,
  AlertProps,
BackofficeUsersPage,
BackofficeUsersPageProps,
DropDownProps,
} from '@hi-interactive-admin/simulador-kinto';

import { useCallback, useEffect, useMemo, useState,ChangeEvent, memo } from 'react';
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';
import { UserModel } from '../../model/User';
import { CustomerService } from '../../services/CustomerServices';
import { UserServices } from '../../services/UserServices';
import { IUser } from './users';
import { Option } from '../../model/interfaces';
import { KintoRootProps } from '../../model/KintoComponentProps';

// import exemplo from  '../../assets/';
// import exemplo from '../assets/teste upload utilizadores.csv';

import Papa from 'papaparse';
import { validateEmail } from '../../Utilities/Utils';
import { ProfileServices } from '../../services/ProfileServices';
import usePermissionsHook from '../../customCallbacks/permissions/permissionsHook';
import useNavbarHook from '../../customCallbacks/navbarHook';
import { AppSettings, EmptyAppSettings } from '../../model/Settings';
import { VehicleService } from '../../services/VehicleService';
import { useAsyncRequest } from '../../services/AsyncRequestContext';

const userService = new UserServices();
const customerService = new CustomerService();
const profileService = new ProfileServices();
const vehicleService = new VehicleService();

const BackofficeUsers: React.FC<KintoRootProps>  = ({ onLogout }) => {
  const { 
    navigate,
    handleCardClick,
    handleLogout,
    topBarPropsMemo,
    dashboardMenuButtonPropsMemo, 
    proposalMenuButtonPropsMemo, 
    clientsMenuButtonPropsMemo, 
    contractsMenuButtonPropsMemo,
    performanceMenuButtonPropsMemo, 
    backofficeMenuButtonPropsMemo
  } = useNavbarHook(onLogout);

  const doNothing = () => {
    return;
  };
  // utilizador logado
  const {currentUser, setCurrentUser, propertyDisabled, propertyHidden} = usePermissionsHook();
  const [settings, setSettings] = useState<AppSettings>(new EmptyAppSettings());

  const [users, setUsers] = useState<IUser[]>([]);
  // variavel para atualização do utilizador em modo de edição 
  const [user, setUser] = useState<UserModel>({
    userId: 0,
    friendlyName:"",
    username: "",
    avatarSrc: "",
    refreshToken: "",
    typeofRental: "",
    typeofIncome: "",
    commercialConditions: "",
    webPortalContactId:"",
    //GESTÃO DE UTILIZADORES
    email:"",
    isAdministrator: false,
    profileId: undefined,
    profile: undefined,
    concessions:[],
    created: new Date(Date.now()),
    createdByUserId:0,
    active:true
  });
  //lista de opções de perfis
  const [profilesOptions, setProfilesOptions] = useState<Option[]>([]);
  // opções de tipos de aluguer
  const [rentalTypeOptions, setRentalTypeOptions] = useState<Option[]>([]);
  // opções de condições comerciais
  const [commercialConditionsOptions, setCommercialConditionsOptions] = useState<Option[]>([]);
  // opções de tipos de renda
  const [paymentTermsOptions, setPaymentTermsOptions] = useState<Option[]>(settings.Data.Vehicles_TypeOfRental[1].result);

  const { asyncResponse, setAsyncResponse } = useAsyncRequest();
  // constantes de mensagems de aviso 
  //inicio
  const [open, setOpen] = useState<boolean>(false);
  const [alertProps, setAlertProps] = useState<Omit<AlertProps, "open" | "onClose">>({
    variant: "info",
    text: "",
    title: "",
  });
  const openAlert = (alertProps: Omit<AlertProps, "open" | "onClose">) => {
    setAlertProps(alertProps);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  //fim

  // mensagens de erro para inputs de preenchimento obrigatório
  const [invalidInputList, setInvalidInputList] = useState<{
    inputPropName: string,
    errorText: string,
    hasError: boolean
  }[]>([]);

  // CALLBACKS DE EDIÇÃO DE UTILIZADORES
  const handleEdit  = useCallback( (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, rowIndex: number) => {

    setUsers(rows => {
      let updatedUsers = [...rows];

      updatedUsers = updatedUsers.map((row, index) => { 
        if(index === rowIndex) {
          row = {...row, [e.target.name]: e.target.value};

          userService.getUserById(Number(row.id), null).then(data => {
            if(data && data.succeeded) {
              // pre carrega campos da linha a serem tratados
              let updateUser:UserModel = data.value;
              updateUser.username = row.username;
              updateUser.profileId = row.profile;
              updateUser.email = row.email;
              updateUser.concessions = row.concessions;
              updateUser.active = row.state === 'active' ? true : false;

              // atualiza campos com valores alterados
              updateUser = {...updateUser, [e.target.name]: e.target.value};
              if(e.target.name === 'state'){
                updateUser.active = e.target.value === 'active' ? true : false;
              }
              if(e.target.name === 'profile' && row.profile && row.profile.toString() !== e.target.value){
                updateUser.profile = undefined;
              }
              setUser(updateUser);
            }
          })
        }
        return row;
      });

      return updatedUsers;
    
    })
  },[]);
  
  const handleArrowClick = useCallback( (itemId: string) => {
    navigate(`/users/${itemId}`);
  }, [navigate]);

  // CALLBACKS DE UPLOAD DE FICHEIROS
  const [usersUploadfile, setUsersUploadFile] = useState<File[] | undefined>();

  const handleUploadNewFile = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const newFileList = e.target.files;
    if (!newFileList) {
      // não foi possivel carregar o ficheiro
      return;
    }

    const filesArray: File[] = Array.from(newFileList);
    if(filesArray.some(file => file.type !== 'text/csv')) {
      openAlert({
        variant: "error",
        text: "O ficheiro carregado não é um ficheiro CSV. Por favor carregue um ficheiro CSV.",
        title: "Erro",
      });
      return;
    }   
    setUsersUploadFile(filesArray);
  }, []);

  const fileRejected = useCallback((fileRejections: FileRejection[], event: DropEvent) => {
    let acceptedFiles = fileRejections.filter(({errors}) => errors.length === 0).map(({file}) => file);
    let rejectedFIles = fileRejections.filter(({errors}) => errors.length > 0);

    if(rejectedFIles.length > 0){
      let names = rejectedFIles.map(({file, errors}) => { return file.name})
      let errors = rejectedFIles.map(({errors}) => { return errors.map(({message}) => { return message}).join(', ')})
      let message = `Os ficheiros ${names.join(', ')} não foram carregados.<br>Erros: ${errors.join(', ')}`;
      openAlert({
        variant: "error",
        text: message,
        // text: `O ficheiro carregado não é um ficheiro CSV. Por favor carregue um ficheiro CSV.`,
        title: "Erro",
      });
    }
    if(acceptedFiles.length > 0){
      setUsersUploadFile(files => {
        if(files){
          return [...files, ...acceptedFiles];
        } else {
          return acceptedFiles;
        }
      });
    }

  }, []);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setUsersUploadFile(acceptedFiles);
  }, []);

  const { getInputProps, getRootProps } = useDropzone({
    onDrop,
    accept: { 'text/csv': ['.csv'] },
    onDropRejected: fileRejected,
    // maxFiles: 1,
  });

  const onDelete = useCallback(() => {
    setUsersUploadFile(undefined);
  }, []);

  // CALLBACKS DE PESQUISA E EDIÇÃO DE VALORES
  const [concessionOptions, setConcessionOptions] = useState<{value:string,label:string}[]>([])
  const [dropDownValues, setDropDownValues] = useState<{ rowIndex: number; values: string[] }[]>(users.map((_, index) => ({ rowIndex: index, values: [] })));

  const dropdownMultipleResult = useCallback((options: DropDownProps, rowIndex: number) => {
    let result = '';

    dropDownValues.length > 0 &&
    dropDownValues[rowIndex] &&
    dropDownValues[rowIndex].values.forEach((element, index) => {
      if (index === 0) {
        result += `${options.options?.find((item) => item.value === element)?.label}`;
      } else {
        result += `, ${options.options?.find((item) => item.value === element)?.label}`;
      }
    });

    return result;
  }, [dropDownValues]);

  const handleEditLineDropcownChange  = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, rowIndex: number) => {
    const { target: { value }, } = e;
    setDropDownValues((old) => {
      return old.map((item) => {
        if (item.rowIndex === rowIndex) {
          //mapeia atualização de concessões e carrega utilizador para atualização fora do handleEdit (estrutura do componente)
          const edituser = users.find((_, index) => index === rowIndex);
          if(edituser) {
            userService.getUserById( Number(edituser.id),null ).then(data => {
              if(data.succeeded) {
                let updateUser:UserModel = data.value;
                updateUser.username = edituser.username;
                updateUser.profileId = edituser.profile;
                updateUser.email = edituser.email;
                updateUser.concessions = [value];
                updateUser.active = edituser.state === 'active' ? true : false;
                updateUser = {...updateUser, [e.target.name]: e.target.value};
                if(e.target.name === 'state'){
                  updateUser.active = e.target.value === 'active' ? true : false;
                }
                setUser(updateUser);
              }
            });
          }

          return {
            ...item,
            values: typeof value === 'string' ? value.split(',') : value,
          };
        } else {
          return { ...item };
        }
      });
    });
  },[users]);

  const [disable, setDisable] = useState<boolean>(true);
  const [selectedOption, setSelectedOption] = useState<Option>({label:"", value:""});
  const [userOptions, setUserOptions] = useState<Option[]>([{label:"", value:""}]);
  const [statusOption, setStatusOption] = useState<string>('all');

  const handleUserSearchResultChange = useCallback((e: React.SyntheticEvent<Element, Event>,value:any,reason:any) => {
    if (userOptions.length > 0) {
      let option = userOptions.find(x => { return x.value === (e.target as any).value });
      if (option)
        setSelectedOption(option)
    }
  }, [userOptions]);
 
  const handleUserSearchResultInputChange = useCallback((e: React.SyntheticEvent<Element, Event>,value:any,reason:any) => {
    let usersList:IUser[] = [];
    if (e) {
      if ((e.target as any).value.length> 3 ) {
        userService.getUserByNameOrNumber((e.target as any).value).then(data => {
          if (data.length > 0) {
            let options:Option[] = []
            data.forEach((userData:any) => {
              usersList.push({
                id: userData['userId'], // 'user1',
                imageSrc: AccountIcon,
                username: userData['username'],
                email: userData['email'],
                state:  userData['active'] ? 'active' : 'inactive',
                profile: userData['profileId'],
                concessions: userData['concession'],
                creator: 'Pedro Coutinho',  
              })
              options.push({label:userData['username'],value:userData['userId']})
            });
            setUserOptions(options);

            // setUsers(usersList);
          }   
        });
      }
    }
  }, []);
 
  const handleSaveUserClick  = useCallback((e: any) => {
    let updateUser = user;
    if (updateUser && updateUser.userId !== 0) {
      userService.add(updateUser).then(data => {
        if(data && data.succeeded) {
          openAlert({
            variant: "success",
            text: "O utilizador foi editado com sucesso.",
            title: "Successo",
          });
          setUser({
            userId: 0,
            friendlyName:"",
            username: "",
            avatarSrc: "",
            refreshToken: "",
            typeofRental:"",
            typeofIncome:"",
            commercialConditions:"",
            webPortalContactId:"",
            //GESTÃO DE UTILIZADORES
            email:"",
            isAdministrator: false,
            profileId: undefined,
            profile: undefined,
            concessions:[],
            created: new Date(Date.now()),
            createdByUserId:0,
            active:false
          });
        } else {
          openAlert({
            variant: "error",
            text: "Ocorreu um erro na edição do utilizador. Por favor tente novamente, ou contacte um administrador.",
            title: "Erro",
          });
        }

      });
    }
  },[user]);
 
  const handleTableSearchUserClick = useCallback((e: any)  => {
    setDisable(false);
    let usersList:IUser[] = []
    let status:boolean | null | undefined;
    if (statusOption === 'at') {  
      status = true;
    } else if (statusOption === 'in') {  
      status = false;
    } else {
      status = null;
    }
    userService.getUserById(Number(selectedOption.value),status).then(userData => {
      if(userData.succeeded){
        usersList.push({
          id: userData.value['userId'], // 'user1',
          imageSrc: AccountIcon,
          username: userData.value['username'],
          email: userData.value['email'],
          state: userData.value['active'] ? 'active' : 'inactive',
          profile: undefined,
          concessions: userData.value['concession'],
          creator: 'Pedro Coutinho',  
        })
        setUsers(usersList);
      } else {
        openAlert({
          variant: "info",
          text: "O resultado da pesquisa não foi encontrado. Por favor confirme os dados da pesquisa.",
          title: "Atenção",
        });
      }
    });
  }, [selectedOption,statusOption]);
  
  const handleConcessionDropDownChange = useCallback(( e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setStatusOption(e.target.value)
  }, []); 
  
  // CALLBACKS DE CRIAÇÃO DE NOVOS UTILIZADORES VIA MODAL
  // callbacks de alteração de nome
  const handleUserModalNameChange = useCallback(( e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let newUser = user;
    setUser({...newUser, username:e.target.value})
    setInvalidInputList((old) => {
      return old.map((item) => {
        if (item.inputPropName === 'userNameTextFieldProps') {
          return {
            ...item,
            errorText: e.target.value === '' ? 'O nome do utilizador é obrigatório. Por favor preencha o campo.' : '',
            hasError: e.target.value === '',
          };
        }
        return { ...item };
      });
    });
  }, [user]);
  const handleUserModalNameFocus = useCallback(() => {
    setInvalidInputList((old) => {
      // se a lista de erros já contém um objecto para o campo de nome de utilizador e o campo está preenchido
      if(old.some( x => x.inputPropName === 'userNameTextFieldProps') && user.username !== ''){
        return old.map((item) => {
          if (item.inputPropName === 'userNameTextFieldProps') {
            return {
              ...item,
              errorText: '',
              hasError: false,
            };
          }
          return { ...item };
        });
      } else if(old.some( x => x.inputPropName === 'userNameTextFieldProps') && user.username === ''){
        return old.map((item) => {
          if (item.inputPropName === 'userNameTextFieldProps') {
            return {
              ...item,
              errorText: 'O nome do utilizador é obrigatório. Por favor preencha o campo.',
              hasError: true,
            };
          }
          return { ...item };
        });
      } else {
        return [...old, {
          inputPropName: "userNameTextFieldProps",
          errorText: "O nome do utilizador é obrigatório. Por favor preencha o campo.",
          hasError: true
        }];
      }
    });
  }, [user]);

  // callbacks de alteração de email
  const handleUserModalEmailChange = useCallback(( e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let newUser = user;
    setUser({...newUser, email:e.target.value})
    setInvalidInputList((old) => {
      return old.map((item) => {
        if (item.inputPropName === 'emailTextFieldProps') {
          return {
            ...item,
            errorText: e.target.value === '' ? // se o input não for preenchido
              // a mensagem de erro apresentada é
              'O email do utilizador é obrigatório. Por favor preencha o campo.' :  // senão
              !validateEmail(e.target.value) ? // se o input não for um email válido
                // a mensagem de erro apresentada é
                'O email do utilizador não é válido. Por favor preencha com um email valido.' : // senão
                '', // não apresenta mensagem de erro
            hasError: e.target.value === '' || !validateEmail(e.target.value),
          };
        }
        return { ...item };
      });
    });
  }, [user]); 
  const handleUserModalEmailFocus = useCallback(() => {
    setInvalidInputList((old) => {
      // se a lista de erros já contém um objecto para o campo de email de utilizador e o campo está preenchido
      if(old.some( x => x.inputPropName === 'emailTextFieldProps') && user.email !== '' && validateEmail(user.email)){
        return old.map((item) => {
          if (item.inputPropName === 'userNameTextFieldProps') {
            return {
              ...item,
              errorText: '',
              hasError: false,
            };
          }
          return { ...item };
        });
      } else if(old.some( x => x.inputPropName === 'emailTextFieldProps') && user.email === '' ) {
        return old.map((item) => {
          if (item.inputPropName === 'emailTextFieldProps') {
            return {
              ...item,
              errorText: 'O email do utilizador é obrigatório. Por favor preencha o campo.',
              hasError: true,
            };
          }
          return { ...item };
        });
      } else if(old.some( x => x.inputPropName === 'emailTextFieldProps') && !validateEmail(user.email)) {
        return old.map((item) => {
          if (item.inputPropName === 'emailTextFieldProps') {
            return {
              ...item,
              errorText: 'O email do utilizador não é válido. Por favor preencha com um email valido.',
              hasError: true,
            };
          }
          return { ...item };
        });
      } else {
        return [...old, {
          inputPropName: "emailTextFieldProps",
          errorText: "O email do utilizador é obrigatório. Por favor preencha o campo.",
          hasError: true
        }];
      }
    });
  }, [user]);
  
  // callback the alteração de estado
  const handleUserModalStatusChange = useCallback(( e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let newUser = user;
    let activeUser = false;
    if(e.target.value === 'true'){
      activeUser = true;
    } 
    setUser({...newUser, active: activeUser})
  }, [user]); 
  
  // callback the alteração de concessão
  const handleUserModalConcessionChange = useCallback((event: any) => {
    let newUser = user;
    if (event.target.value){
      setUser({...newUser, concessions: event.target.value})
    } 
  }, [user]);

  // callback the alteração de perfil
  const handleUserModalProfileChange = useCallback(( e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setUser(x => {
      let updateUser = {...x};
      updateUser.profileId = Number(e.target.value);
      return updateUser;
      // return {...x, profileId: Number(e.target.value)}
    })
    setInvalidInputList((old) => {
      return old.map((item) => {
        if (item.inputPropName === 'profileDropDownProps') {
          return {
            ...item,
            errorText: e.target.value === '' ? 'O perfil do utilizador é obrigatório. Por favor selecione um perfil.' : '',
            hasError: e.target.value === '',
          };
        }
        return { ...item };
      });
    });
  }, []);
  const handleUserModalProfileFocus = useCallback(() => {
    setInvalidInputList((old) => {
      // se a lista de erros já contém um objecto para o campo de nome de utilizador e o campo está preenchido
      if(old.some( x => x.inputPropName === 'profileDropDownProps') && user.profileId ){
        return old.map((item) => {
          if (item.inputPropName === 'profileDropDownProps') {
            return {
              ...item,
              errorText: '',
              hasError: false,
            };
          }
          return { ...item };
        });
      } else if(old.some( x => x.inputPropName === 'profileDropDownProps') && !user.profileId){
        return old.map((item) => {
          if (item.inputPropName === 'profileDropDownProps') {
            return {
              ...item,
              errorText: 'O perfil do utilizador é obrigatório. Por favor selecione um perfil.',
              hasError: true,
            };
          }
          return { ...item };
        });
      } else {
        return [...old, {
          inputPropName: "profileDropDownProps",
          errorText: "O perfil do utilizador é obrigatório. Por favor selecione um perfil.",
          hasError: true
        }];
      }
    });
  }, [user]);

  const handleUserModalDropdownChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setUser(x => { 
      return {...x, [e.target.name]: e.target.value}
    });
    if(e.target.name === 'typeofRental'){
      vehicleService.getCommercialConditions(e.target.value).then(data => {
        if(data.succeeded) {
          let options: Option[] = [];
          data.value.forEach((element: any) => {
            options.push({
              label:element.label,
              value:element.value
            });
          });
          setCommercialConditionsOptions(options);
        }
      });
    }
  },[]);

  const handleUserModalCommercialConditionFocus = useCallback(() => {
    setInvalidInputList((old) => {
      if(old.some( x => x.inputPropName === 'commercialConditionsDropDownProps') ){
        if( user.typeofRental && user.typeofRental !== ''){
          return old.map((item) => {
            if (item.inputPropName === 'commercialConditionsDropDownProps') {
              return {
                ...item,
                errorText: '',
                hasError: false,
              };
            }
            return { ...item };
          });
        }
      } else {
        if(user.typeofRental === ''){
          return [...old, {
            inputPropName: "commercialConditionsDropDownProps",
            errorText: 'Deve selecionar o tipo de aluguer antes de poder selecionar as condições comerciais.',
            hasError: true
          }];
        }
      }
      return [...old];
    });
  },[user.typeofRental]);


  //atualizar utilizador 
  const handleConfirmUserModalClick = useCallback((e: any)  => {
    if(user){
      const emailIsValid = validateEmail(user.email);
      const usernameIsValid = user.username !== '' ? true : false;
      const profileIsValid = user.profileId !== undefined ? true : false;
      if( emailIsValid && usernameIsValid && profileIsValid) {
        userService.add(user).then(userData => {
          if(!userData.succeeded) {
            openAlert({
              variant: "error",
              text: `Já existe um utilizador registado com o email: ${user.email}. Por favor utilize outro email`,
              title: "Erro",
            });
          } else {
            setUsers([...users,{
              id: userData.value['userId'], // 'user1',
              imageSrc: AccountIcon,
              username: userData.value['username'],
              email: userData.value['email'],
              state: userData.value['active'] ? 'active' : 'inactive',
              profile: userData.value['profileId'],
              concessions: userData.value['concessions'],
              creator: 'Pedro Coutinho',  
            }])
            setUser({
              userId: 0,
              friendlyName:"",
              username: "",
              avatarSrc: "",
              refreshToken: "",
              typeofRental:"",
              typeofIncome:"",
              commercialConditions:"",
              webPortalContactId:"",
              //GESTÃO DE UTILIZADORES
              email:"",
              isAdministrator: false,
              profileId: undefined,
              profile: undefined,
              concessions:[],
              created: new Date(Date.now()),
              createdByUserId:0,
              active:true
            })
            // validar a inserção de um novo utilizador e a inserção 
            // setDropDownValues( users.map((u, index) => ({ rowIndex: index, values: u?.concessions ? u?.concessions : [] })));
          }
        })
      }
      else if(!emailIsValid && usernameIsValid && profileIsValid){
        openAlert({
          variant: "error",
          text: `O campo email:${user.email} não é válido. Por favor preencha com um email valido e tente novamente!`,
          title: "Erro",
        });
      }
      else if(emailIsValid && !usernameIsValid && profileIsValid){
        openAlert({
          variant: "error",
          text: `O campo nome do utilizador é obrigatório. Por favor preencha o campo e tente novamente!`,
          title: "Erro",
        });
      } else if(emailIsValid && usernameIsValid && !profileIsValid){
        openAlert({
          variant: "error",
          text: `O campo perfil do utilizador é obrigatório. Por favor selecione um perfil e tente novamente!`,
          title: "Erro",
        });
      } else {
        openAlert({
          variant: "error",
          text: `Os campos email, nome do utilizador e perfil do utilizador são obrigatórios. Por favor preencha os campos e tente novamente!`,
          title: "Erro",
        });
      }
    }

  }, [user,users]);

  // CALLBACKS DE IMPORTAÇÃO DE UTILIZADORES VIA FICHEIRO
  const handleUsersImport = useCallback(() => {
    let usersList: UserModel[] = []

    setUsersUploadFile(uploadedFiles => {
      if(uploadedFiles && uploadedFiles.length) {
        uploadedFiles.forEach((uploadedFile) => {
          const reader = new FileReader();

          reader.onload = (e) => {
            const fileData = e.target?.result?.toString();
    
            Papa.parse(fileData || uploadedFile, {
              escapeChar: '\\',
              dynamicTyping:true,
              header: true,
              complete: (results:any) => {
                // const totalAmountOfLines = results.data.length;
                // Utilizador,Email,Estado,Perfil,Concessao
               
             
                // results.meta.fields.map((x:string,index:number) => {
                //   switch (x) {
                //     case "Utilizador":
                //       results.meta.fields[index] = "username"
                //       break;
                //     case "Email":
                //       results.meta.fields[index] = "email"
                //       break;
                //     case "Estado":
                //       results.meta.fields[index] = "active"
                //       break;
                //     case "Perfil":
                //       results.meta.fields[index] = "profile"
                //       break;
                //     case "Concessao":
                //       results.meta.fields[index] = "concession"
                //       break;
                                
                //     default:
                //       break;
                //   }
                //   return x;
                // })
    

                const headerFields = results.meta.fields
                const data = results.data;
                data.map((row:any) => {
                  let rowLength = Object.keys(row).includes('__parsed_extra') ? Object.keys(row).length -1 :Object.keys(row).length;
                  if (headerFields && headerFields.length > rowLength){
                    return row;
                  }
    
                  const expectedTypes: Record<keyof UserModel, string> = {
                    userId: 'number',
                    friendlyName: 'string',
                    username: 'string',
                    avatarSrc: 'string',
                    refreshToken: 'string',
                    typeofRental:'string',
                    typeofIncome:'string',
                    commercialConditions:'string',
                    webPortalContactId:'string',
                    email:'string',
                    isAdministrator: 'boolean',
                    profileId: 'number | undefined',
                    profile: "Profile | undefined",
                    concessions:'string[]',
                    created:'Date',
                    createdByUserId:'number',
                    active:'boolean',
                  };

    
    
                  Object.keys(row).map(key => {
                    // row[key].replace('\\"','')
                    const type = typeof row[key]
                    const expectedType = expectedTypes[key as keyof UserModel];
                    if (type !== expectedType && row[key]) {
                      if (expectedType === 'string'|| expectedType === 'object'){
                        row[key] = row[key].toString()
                      }
                      if (expectedType === 'boolean'){
                        row[key]= Boolean(row[key]);
                      }
                      if(expectedType === 'number') {
                        row[key] = Number(row[key])
                      }
                      if(expectedType === 'string[]') {
                        row[key] = row[key].toString().split(' ');
                      }
                      if(expectedType === 'Profile | undefined') {
                        row.profileId = profilesOptions.find(x => x.label === row[key])?.value;
                        row[key] = undefined;
                      }
                    }
                    if(key === 'typeofIncome' && row[key] ){
                      row[key] = row[key].toString();
                    }
    
                    return key;
                  })
                  row.userId = 0;
                  row.created = new Date(Date.now());
                  row.createdByUserId = currentUser.userId;
                  row.refreshToken ="";
                  row.webPortalContactId = "";
                  row.avatarSrc = "";
                  row.isAdministrator = false;

                  let user:UserModel = row as UserModel;
                  usersList.push(user)
                  
                  return row
                });
                if(usersList.length > 0){
                  userService.csvImportUsers(usersList).then(data => {
                    if (data && data.succeeded) {
                      data.value.map((uploadedUser: any) => {
                        setUsers((rows) => [
                          ...rows,{
                            id: uploadedUser['userId'], // 'user1',
                            imageSrc: AccountIcon,
                            username: uploadedUser['username'],
                            email: uploadedUser['email'],
                            state: uploadedUser['active'] ? 'active' : 'inactive',
                            profile: uploadedUser['profileId'],
                            concessions: uploadedUser['concessions'],
                            creator: 'Pedro Coutinho',
                          }
                        ])
                        return uploadedUser;
                      })
                      if (results.errors){
                        let errorLine:string =''
                        results.errors.map((error:any )=> {
                          let row = results.data[error.row]
                          // results.data[error.row].friendlyName === null && Object.keys(row).length === 1 ==> linha vazia
                          if (row.friendlyName !== null && Object.keys(row).length > 1) {
                            const line = Number(error.row) +1;
                            errorLine += `<li>linha ${line} - ${error.message}</li>`
                          }
                          return error
                        })
                        if(errorLine !== '')
                        openAlert({
                          variant: "error",
                          text: `<ul>Erros nas linhas:\n${errorLine}</ul>`,
                          title: "Erro",
                        });
                        else {
                        openAlert({
                          variant: "success",
                          text: "Os utilizadores foram importados com sucesso.",
                          title: "Successo",
                          autoHideDuration: 5000
                        });
                      }
                      }
                      else {
                        openAlert({
                          variant: "success",
                          text: "Os utilizadores foram importados com sucesso.",
                          title: "Successo",
                          autoHideDuration: 5000
                        });
                      }
                    
                    } else {
    
                      if(data.message.length>0) {
                        if(data.errors.length>0){
                          let errors = "";
                          data.errors.map(error => {
                            errors += `<li>${error}</li>`
                            return error
                          })
                          openAlert({
                            variant: "error",
                            text: errors, 
                            title: "Erro",
                          });
                          return;
    
                        } else {
                          let existingUsers = "";
                          data.message.split(";", data.message.split(";").length -1 ).forEach(row => {
                            existingUsers+=`<li>${row}</li>`
                          })
                          if(existingUsers !== ""){
                            openAlert({
                              variant: "info",
                              text: `O ficheiro importado foi importado com sucesso, mas contém utilizadores duplicados. Utilizadores duplicados: ${existingUsers}`,
                              title: "Aviso",
                              autoHideDuration: 5000
                            });
                            return;

                          }
                        }
                      }
    
                      // validar se este código vai ser preciso
                      if(data.value && data.value.length > 0){
                        let checkedUsers: number[] =[]
                        let duplicatedUsers: number[] =[]
                        data.value.map((row:any) => {
                          if (!checkedUsers.includes(row.userId)){
                            checkedUsers.push(row.userId)
                          } else { 
                            duplicatedUsers.push(row.userId) 
                          }
                          return row;
                        })
                        if(duplicatedUsers.length > 0) {
                          
                          let duplicateUserEmailList:string = '';
                          duplicatedUsers.forEach(id => {
                            let duplicateUserEmail:string = data.value.find((x:any) => x.userId === id ).email
                            if(duplicateUserEmail && duplicateUserEmail !== ''){
                              duplicateUserEmailList += `<li>${duplicateUserEmail}</li>`;
                            }
                          })
      
                          openAlert({
                            variant: "info",
                            text: `O ficheiro importado foi importado com sucesso, mas contém utilizadores duplicados. Utilizadores duplicados: ${duplicateUserEmailList}`,
                            title: "Aviso",
                            autoHideDuration: 5000
                          });
                        } else {
                          openAlert({
                            variant: "error",
                            text: "Ocorreu um erro na importação. Por favor contacte um administrador.",
                            title: "Erro",
                          });
                        }
                      } else {
                        openAlert({
                          variant: "error",
                          text: "Ocorreu um erro na importação. Por favor contacte um administrador.",
                          title: "Erro",
                        });
                      }
                    }
                  })
                }
              },
            });
          };
          reader.readAsText(uploadedFile);
    
        });

      }
      return uploadedFiles;
    })

  },[currentUser,profilesOptions]);
  
  const handleRemoveFile = useCallback(() => {
    setUsersUploadFile(undefined);
  },[])

  const newUserModalMemo = useMemo(() => {
    return {
      title: 'Criar novo utilizador',
      userNameTextFieldProps: { 
        label: 'Username do Utilizador',
        name:'username',
        value: user.username,
        // onInput: handleUserModalNameChange,
        onChange: handleUserModalNameChange,
        onFocus: handleUserModalNameFocus,
        required: true,
        helperText: invalidInputList.find(x => x.inputPropName === 'userNameTextFieldProps')?.errorText ?? '',
        error: invalidInputList.find(x => x.inputPropName === 'userNameTextFieldProps')?.hasError ?? false,
      },
      stateDropDownProps: {
        label: 'Estado',
        options: [
          { label: 'Ativo', value: 'true' },
          { label: 'Inativo', value: 'false' },
        ],
        value: user.active.toString() ?? 'true',
        placeholder: 'Selecionar estado',
        onChange:handleUserModalStatusChange,
      },
      emailTextFieldProps: {
        label: 'Email',
        value: user.email,
        onChange: handleUserModalEmailChange,
        onFocus: handleUserModalEmailFocus,
        required: true,
        helperText: invalidInputList.find(x => x.inputPropName === 'emailTextFieldProps')?.errorText ?? '',
        error: invalidInputList.find(x => x.inputPropName === 'emailTextFieldProps')?.hasError ?? false,
      },
      profileDropDownProps: {
        label: 'Perfil',
        options: profilesOptions,
        value: user.profile,
        placeholder: 'Selecionar perfil',
        onChange: handleUserModalProfileChange,
        onFocus: handleUserModalProfileFocus,
        required: true,
        helperText: invalidInputList.find(x => x.inputPropName === 'profileDropDownProps')?.errorText ?? '',
        error: invalidInputList.find(x => x.inputPropName === 'profileDropDownProps')?.hasError ?? false,
      },
      concessionDropDownProps: {
        label: 'Concessão',
        placeholder: 'Pesquisar',
        options: concessionOptions,
        value: user.concessions ? user.concessions :[''], // todas as opções
        name:'concessions',
        // opções filtradas de acordo com as permissões a que o utilizador a ser editado tem acesso
        //value: concessionOptions && user && user.concessions.length > 0 ? concessionOptions.filter(x => user.concessions.some(value => value === x.value)) : [],
        // value: concessionOptions && user && user.concessions.length > 0 ? concessionOptions.find(x => x.value === user.concessions[0]) : '',
        // value: concessionOptions && user && user.concessions.length > 0 ? user.concessions : [''],
        multiple:true,        
        onChange: handleUserModalConcessionChange,
        disabled: false,
        hidden: false
      },

      // isto não faz sentido pelo que deve ser ocultado
      addConcessionButtonText: '',//'Adicionar concessão',
      addConcessionButtonProps: {
        hidden: true,
        disabled: true,
        // onChange 
        // onClick: handleAddConcession,
      },
      // addConcessionProps: newConcession,

      // uma vez que estas 3 dropdowns não são de preenchimento obrigatório, não é necessário validar as mensagens de erro
      // no entanto não deve ser permitido seleccionar condições comerciais sem seleccionar o tipo de aluguer
      // e nesse caso especifico deverá ser apresentada uma mensagem de erro
      rentalTypeDropDownProps: {
        label: 'Tipo de aluguer',
        options: rentalTypeOptions,
        value: user.typeofRental ?? '',
        name: 'typeofRental',
        placeholder: 'Selecionar tipo de aluguer',
        onChange: handleUserModalDropdownChange,
        // onFocus: handleUserModalProfileFocus,
      },
      commercialConditionsDropDownProps: {
        label: 'Condições comerciais',
        options: commercialConditionsOptions,
        value: user.commercialConditions ?? '',
        name: 'commercialConditions',
        placeholder: 'Selecionar condições comerciais',
        onChange: handleUserModalDropdownChange,
        onFocus: handleUserModalCommercialConditionFocus,
        onClick: handleUserModalCommercialConditionFocus,
        helperText: invalidInputList.find(x => x.inputPropName === 'commercialConditionsDropDownProps')?.errorText ?? '',
        error: invalidInputList.find(x => x.inputPropName === 'commercialConditionsDropDownProps')?.hasError ?? false,
        disabled: (user.typeofRental && user.typeofRental === ('')) || !user.typeofRental,
      },
      incomeTypeDropDownProps: {
        label: 'Tipo de rendimento',
        options: paymentTermsOptions,
        value: user.typeofIncome ?? '',
        name: 'typeofIncome',
        placeholder: 'Selecionar tipo de rendimento',
        onChange: handleUserModalDropdownChange,
        // onFocus: handleUserModalProfileFocus,
      },
      cancelButtonText: 'Cancelar',
      handleClose: () => {},
      confirmButtonText: 'Criar utilizador',
        
      confirmButtonProps: {
        onClick: handleConfirmUserModalClick,
      },
    }
  },[
    concessionOptions, profilesOptions,
    handleUserModalNameChange, handleUserModalNameFocus,
    handleUserModalEmailChange, handleUserModalEmailFocus,
    handleUserModalProfileChange, handleUserModalProfileFocus,
    handleUserModalConcessionChange, 
    handleUserModalStatusChange, 
    handleConfirmUserModalClick, 
    handleUserModalDropdownChange, handleUserModalCommercialConditionFocus,
    paymentTermsOptions,
    rentalTypeOptions,
    commercialConditionsOptions,
    user, 
    invalidInputList, 
  ])

  const loadPage = useCallback(() => {
    //CARREGAR UTILIZADORES
    let usersList:IUser[] = [];
    userService.get().then(data => {
      data.forEach((userData:any) => {
        usersList.push({
          id: userData['userId'], // 'user1',
          imageSrc: AccountIcon,
          username: userData['username'],
          email: userData['email'],
          state: userData['active'] ? 'active' : 'inactive',
          profile: userData['profileId'],
          concessions: userData['concessions'],
          creator: 'Pedro Coutinho',  
        })
      });
      setUsers(usersList);
      //CAREGAR CONCESSÕES DO UTILIZADOR
      setDropDownValues( usersList.map((u, index) => ({ rowIndex: index, values: u?.concessions ? u?.concessions : [] })));
    });
    //CARREGAR CONCESSÕES
    customerService.getCenterDealer().then((data)=>{
      let options:{value:string,label:string}[] = [];
      // let options:Option[] = [];
      if(data) {
        data.forEach((element:any) => {
          options.push({
            label: `${element.id} - ${element.description}`,
            value: element.id,
          })
        })
        setConcessionOptions(options);
      }
    });
    //CARREGAR PERFIS
    profileService.get().then(data => {
      if(data.succeeded) {
        data.value.forEach((element: any) => {
          setProfilesOptions(rows => {
            let updatedProfileOptions =  [...rows];
            if(!updatedProfileOptions.some(x => x.value === element.id)) {
              updatedProfileOptions.push({label:element.name,value:element.id});
            }
            return updatedProfileOptions;
          })
        });
      }
    });
  }, [])

  const handleEraseLinkClick  = useCallback((e: any) => {
    loadPage();
    setSelectedOption({label:"",value:""});
    setDisable(true);
    setStatusOption('all');
  },[
    loadPage
  ]);


  useEffect(() => {
    loadPage();
    // tipos de alguuer
    vehicleService.getRentalTypes().then(data => {
      if(data.succeeded) {
        let options:{value:string,label:string}[] = [];
        data.value.forEach((element: any) => {
          options.push({
            label:element.label,
            value:element.value
          });
        });
        setRentalTypeOptions(options);
      }
    });
    
  }, [ loadPage ]);
  
  useEffect(() => {
    if (asyncResponse) {
      // Display the openAlert message with the response data.
      if(asyncResponse.errors !== "") {
        openAlert({
          variant: "error",
          text: asyncResponse.errors,
          title: "Atenção",
          // autoHideDuration: 3000,
        })
      } else {

        openAlert({
          variant: "success",
          text: "O utilizador foi removido com sucesso.",
          title: "Atenção",
          autoHideDuration: 5000,
        })
      }
      // Reset the asyncResponse in the context to avoid repeated alerts.
      setAsyncResponse(null);
    }
  },[asyncResponse, setAsyncResponse]);

  const userTablePropsMemo = useMemo(() => {
    return { 
      dropdownMultipleResult: dropdownMultipleResult,
      dropdownValues: dropDownValues,
      handleDropDownByChange: handleEditLineDropcownChange,
      // hideActionButton: propertyHidden('Utilizadores', 'Backoffice'),
      // disableActionButton: propertyDisabled('Utilizadores', 'Backoffice'),
      onActionButtonClick: (_:any, index:number) => propertyDisabled('Utilizadores', 'Backoffice') ?  doNothing() : handleArrowClick(`${users[index].id}`)  ,
      // botão de editar o utilizador na linha x
      editButtonProps: {
        disabled: propertyDisabled('Utilizadores', 'Backoffice'),
        hidden: propertyHidden('Utilizadores', 'Backoffice')
      } ,
      editButtonText: 'Editar',
      saveButtonText: 'Guardar',
      saveButtonProps:{
        onClick: handleSaveUserClick, 
      },  
      onChange: handleEdit,
      rows: users.map((user) => {
        return {
          cells: [
            {
              imgSrc: user.imageSrc,
              username: { value: user.username, name: 'username' },
            },
            { 
              value: user.email, 
              name: 'email' 
            },
            {
              value: user.state,
              name: 'state',
              options: [
                { value: 'active', label: 'Ativo' },
                { value: 'inactive', label: 'Inativo' },
              ],
            },
            {
              // value: user.profile ? profilesOptions.find(x => x.value.toString() === user.profile?.toString()) : {label:"",value:"" } || {label:"",value:"" } ,
              name: 'profile',
              options: profilesOptions,
              profilesOptions,
              value: user.profile ?? "",
              placeholder: 'Selecionar perfil',
              disabled: propertyDisabled('Utilizadores', 'Backoffice'),
              hidden: propertyHidden('Utilizadores', 'Backoffice')
            },
            {
              name: 'concessions',
              multiple: true,
              // concessões filtradas de acordo com as permissões do utilizador que está a editar outros utilizadores 
              // se é administrador, pode ver todas as concessões
              options: currentUser.isAdministrator ? 
                concessionOptions : 
                // senão, se tiver concessões atribuidas
                concessionOptions.every(x => currentUser.concessions.some(c => c === x.value)) ?
                  // só pode ver as concessões que tem acesso
                  concessionOptions.filter(x => currentUser.concessions.includes(x.value)) :
                  // senão tiver concessões atribuidas, mostra todas as concessões
                  concessionOptions, 
              value: [dropDownValues],
              placeholder: 'Pesquisar',
              // pode editar
              disabled: propertyDisabled('Utilizadores', 'Backoffice'),
              // pode ver
              hidden: propertyHidden('Utilizadores', 'Backoffice')
            },
          ],
        };
      }),
      headCells: [
        {
          id: 'user',
          label: 'Username do Utilizador',
        },
        {
          id: 'email',
          label: 'Email',
        },
        {
          id: 'state',
          label: 'Estado ',
        },
        {
          id: 'profile',
          label: 'Perfil',
        },
        {
          id: 'concession',
          label: 'Concessão ',
        },
      ],
    } as BackofficeUsersPageProps['userTableProps']
  },[
    dropdownMultipleResult, 
    dropDownValues, 
    handleEditLineDropcownChange, 
    handleEdit, 
    handleSaveUserClick, 
    profilesOptions, 
    users, 
    concessionOptions,
    handleArrowClick, 
    currentUser.isAdministrator, 
    currentUser.concessions,
    propertyDisabled,
    propertyHidden
  ])


  return (
  <>
    <BackofficeUsersPage
    topBarProps={topBarPropsMemo}
    dashboardMenuButtonProps={dashboardMenuButtonPropsMemo}
    proposalMenuButtonProps={proposalMenuButtonPropsMemo}
    backofficeMenuButtonProps={backofficeMenuButtonPropsMemo}
    clientsMenuButtonProps={clientsMenuButtonPropsMemo}
    contractsMenuButtonProps={contractsMenuButtonPropsMemo}
    performanceMenuButtonProps={performanceMenuButtonPropsMemo}

    breadCrumbsLinks={[
    {
      label: 'Backoffice',
      onClick: handleCardClick('backoffice'),
    },
    { label: 'Gestão de Utilizadores',
      onClick: handleCardClick('users'), 
    },
    
    ]}
    pageTitle="Gestão de Utilizadores"
    importUserButtonText="Importar utilizadores"
    importUserButtonProps={{
      disabled: propertyDisabled('Utilizadores', 'Backoffice'),
      hidden: propertyHidden('Utilizadores', 'Backoffice')
    }}
    addNewUserButtonText="Criar novo utilizador"
    addNewUserButtonProps={{
      onMouseUp: () => { 
        handleUserModalNameFocus();
        handleUserModalEmailFocus();
        handleUserModalProfileFocus();
      },
      disabled: propertyDisabled('Utilizadores', 'Backoffice'),
      hidden: propertyHidden('Utilizadores', 'Backoffice'),
      // EVENTO PARA CHAMAR SERVIÇOS AO CARREGAR A MODAL
      // onClickCapture: () => {
      //   alert('on click capture');
      //   console.log(concessionOptions);
      // },
    }}
    userSearchResultProps={{
      label: 'Pesquisar por Nome ou numero de cliente',
      placeholder: 'Pesquisar',
      options: userOptions,
      value: selectedOption,
      onChange:handleUserSearchResultChange,
      onInputChange:handleUserSearchResultInputChange,
    }}
    filterIconProps={{}}
    eraseLinkProps={{ 
      disabled: disable, 
      onClick: handleEraseLinkClick,
    }}
    eraseLinkText="LIMPAR"
    userTableProps={userTablePropsMemo}
    concessionDropDownProps={{//filtros
      label: 'Estado',
      defaultValue: 'all',
      value: statusOption,
      options: [
        { value: 'all', label: 'Todos' },
        { value: 'at', label: 'Ativo' },
        { value: 'in', label: 'Inativo' },
      ],
      onChange:handleConcessionDropDownChange,
    }}
    resultNumber={`${users.length < 10 ? '0' : ''}${users.length}`}
    resultText="resultados encontrados"
    groupEditButtonText="Editar em grupo"
    groupEditButtonProps={{
      disabled: propertyDisabled('Utilizadores', 'Backoffice'),
      hidden: propertyHidden('Utilizadores', 'Backoffice')
    }}
    searchUserButtonText="Pesquisar"
    searchUserButtonProps={{
      onClick:handleTableSearchUserClick, 
    }}
    newUserModalProps={newUserModalMemo}
    importUserModalProps={{
      title: 'Importar Utilizadores',
      subTitle: 'Faça download do ficheiro <b>EXEMPLO CSV</b> para conhecer o formato.',
      linkText: 'EXEMPLO CSV',
      url: './assets/importadorUtilizadores.csv',
      confirmButtonText: 'IMPORTAR',
      importButtonText: 'upload ficheiro csv',
      confirmButtonProps: {
        onClick: handleUsersImport,
        disabled: !usersUploadfile,
      },
      fileTextError: 'O ficheiro não tem a estrutura correcta',
      progressBarProps: 
      // não suporta multiplos ficheiros
      // usersUploadfile?.map(file => {
      //   return {
      //     progress: 100,
      //     title: file.name,
      //     deleteIcon: true,
      //     deleteTooltipText: 'Cancelar',
      //     onDeleteClick: handleRemoveFile,
      //     size: 'small',
      //     label: file.size ? (file.size/1024/1024).toString() + ' MB' : '2.3MB/8.4MB',
      //   }
      // })[0] || {
      //   progress: 100,
      //   title: '',
      //   deleteIcon: true,
      //   deleteTooltipText: 'Cancelar',
      //   onDeleteClick: handleRemoveFile,
      //   size: 'small',
      //   label: '2.3MB/8.4MB',
      // },
      {
        progress: 100,
        title: usersUploadfile ? usersUploadfile[0].name : '',
        deleteIcon: true,
        deleteTooltipText: 'Cancelar',
        onDeleteClick: handleRemoveFile,//() => alert('remover/cancelar este ficheiro'),
        size: 'small',
        label: usersUploadfile && usersUploadfile[0] && usersUploadfile[0].size  ? (usersUploadfile[0].size/1024/1024).toString() + ' MB' : '2.3MB/8.4MB',
      },
      handleClose: () => {},
      getInputProps: getInputProps,
      getRootProps: getRootProps,
      onDelete: onDelete,
      updateNewFile: handleUploadNewFile,
    }}
    />
    <Alert open={open} onClose={handleClose} {...alertProps} />
  </>
  );
};

export default memo(BackofficeUsers);