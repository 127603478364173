import { ElectricCarIcon, PumpDieselIcon, PumpPetrolIcon } from "@hi-interactive-admin/simulador-kinto";
import { PreConfiguredVehicleState } from "../model/Enums";

export default class VehicleUtilities {
    static getFuelTypeIconFor(fuelType: string)  {
        let fuelIcon = [PumpPetrolIcon];
        try{
            switch (fuelType?.toLowerCase()) {
                case "diesel":
                    fuelIcon = [PumpDieselIcon];
                    break;
                case "electro":
                    fuelIcon = [ElectricCarIcon];
                    break;
                case "benzine":
                    fuelIcon = [PumpPetrolIcon];
                    break;
                case "gasóleo":
                    fuelIcon = [PumpDieselIcon];
                    break;
                case "outro":
                    fuelIcon = [ElectricCarIcon];
                    break;
                case "gasolina":
                    fuelIcon = [PumpPetrolIcon];
                    break;
            }
        } 
        catch (ex) {
            console.log("Erro ao obter icone de combustível:");
            console.log(ex);
        }
        return fuelIcon;
    }
    
    static getVehicleTypeStringFor(preConfiguredVehicleState: PreConfiguredVehicleState): string {
        const enumValue = VehicleUtilities.getEnumValue(PreConfiguredVehicleState, preConfiguredVehicleState?.toString());
        switch (enumValue) {
            case PreConfiguredVehicleState.New:
                return "Novo";
            case PreConfiguredVehicleState.Used:
                return "Usado";
            default:
                return "";
        }
    }

    private static getEnumValue = (enumObject: any, value: string) => {
        try{
            return enumObject[value as keyof typeof enumObject];
        }
        catch(ex){
            return null;
        }
      };

}