import { ClientDetailsPage, EditIcon } from '@hi-interactive-admin/simulador-kinto';
import { useParams } from 'react-router-dom';
import { client1, client2 } from './clientDetails';
import useNavbarHook from '../../customCallbacks/navbarHook';
import { KintoRootProps } from '../../model/KintoComponentProps';

type ClientDetailsRouteParams = {
  clientId: string;
  startWithEditMode?: string;
};
const ClientDetailsPageDemo: React.FC<KintoRootProps>  = ({ onLogout })  => {
  const params = useParams<ClientDetailsRouteParams>();
  const { 
    navigate,
    handleCardClick,
    handleLogout,
    topBarPropsMemo,
    dashboardMenuButtonPropsMemo, 
    proposalMenuButtonPropsMemo, 
    clientsMenuButtonPropsMemo, 
    contractsMenuButtonPropsMemo,
    performanceMenuButtonPropsMemo, 
    backofficeMenuButtonPropsMemo
  } = useNavbarHook(onLogout);
  
  return (
    <ClientDetailsPage
      startInEditMode={params.startWithEditMode === 'edit'}
      topBarProps={topBarPropsMemo}
      dashboardMenuButtonProps={dashboardMenuButtonPropsMemo}
      proposalMenuButtonProps={proposalMenuButtonPropsMemo}
      clientsMenuButtonProps={clientsMenuButtonPropsMemo}
      contractsMenuButtonProps={contractsMenuButtonPropsMemo}
      performanceMenuButtonProps={performanceMenuButtonPropsMemo}
      backofficeMenuButtonProps={backofficeMenuButtonPropsMemo}
      breadCrumbsLinks={[
        {
          label: 'Propostas',
        },
        {
          label: 'Proposta EGEO - Nº C010089803',
        },
        { label: 'EGEO Pressão, S.A.' },
      ]}
      saveText="Guardar alterações"
      interlocutorDataArea={{
        title: 'Dados do Interlocutor',
        nameTextFieldProps: { value: 'Andreia Gonçalves', label: 'Nome' },
        lastModification: {
          value: '17 jun. 2020',
          label: 'Última modificação',
        },
        postTextFieldProps: { label: 'Cargo', value: 'Administradora' },
        sellerCodeDropDownProps: {
          label: 'Código vendedor',
          value: 'op1',
          options: [{ label: 'VAFS', value: 'op1' }],
        },
        socialNameTextFieldProps: {
          label: 'Denominação social',
          value: 'EGEO Circular, S.A. e EGEO Pressão, S.A.',
        },
        taxNumberTextFieldProps: {
          label: 'NIF',
          value: '514986298',
          helperText: 'test',
        },
        clientTypologyDropDownProps: {
          label: 'Tipologia de cliente',
          value: 'op1',
          options: [
            {
              label: 'Grandes Empresas (Frota > 30 Veículos)',
              value: 'op1',
            },
          ],
        },
        companyNumberDropDownProps: {
          label: 'Número da Empresa',
          value: 'op1',
          options: [
            {
              label: 'CN149467',
              value: 'op1',
            },
          ],
        },
        addressTextFieldProps: {
          label: 'Morada',
          value:
            'Rua 25 de Abril, Nº 1, Quinta da Francelha de Baixo 2685-368 Prior Velho',
        },
        countryInputProps: {
          label: '  País',
          value: 'PT',
        },
        postalCodeTextFieldProps: {
          label: 'Código Postal',
          value: '2685',
        },
        postalCodeSecondPartTextFieldProps: {
          value: 368,
        },
        locationTextFieldProps: {
          value: 'Prior Velho',
          disabled: true,
        },
        onSaveClick: () => alert('guardar'),
      }}
      client={params.clientId === 'client1' ? client1 : client2}
      addressText="Moradas"
      contractedText="Contratado"
      detailsText="Detalhes"
      documentText="Documentos"
      editIcon={EditIcon}
      editText="Editar"
      onEditClick={() => alert('editar dados cliente')}
      generalInfoText="Informação geral"
      onProposalText="Em proposta"
      profitabilityText="Rentabilidade"
      proposalStatisticsText="Estatísticas de propostas"
      tabs={[
        'Dados do Cliente',
        'Dados do Interlocutor',
        'Moradas e documentos',
      ]}
      otherInfoText="Outros detalhes"
      printOutText={'Imprimir'}
      toViewDocumentText={'Visualizar'}
      documentActions={[
        {
          onClick: (id: string) =>
            alert(`solicitou alteração do documento ${id}`),
          label: 'Solicitar novo',
        },
        {
          onClick: (id: string) => alert(`partilha do documento ${id}`),
          label: 'Partilhar',
        },
        {
          onClick: (id: string) => alert(`eliminar documento ${id}`),
          label: 'Eliminar',
        },
      ]}
      seeAction={(id: string) => alert(`visualizar documento ${id}`)}
      printAction={(id: string) => alert(`imprimir documento ${id}`)}
    />
  );
};

export default ClientDetailsPageDemo;
