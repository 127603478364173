import { AccountIcon, DashboardPage, DocumentPDFIcon } from '@hi-interactive-admin/simulador-kinto';
import React from 'react';
import { proposals } from '../proposal/proposals';
import useNavbarHook from '../../customCallbacks/navbarHook';
import { KintoRootProps } from '../../model/KintoComponentProps';

const DashboardDemo: React.FC<KintoRootProps>  = ({ onLogout }) => {
  const { 
    navigate,
    handleCardClick,
    handleLogout,
    topBarPropsMemo,
    dashboardMenuButtonPropsMemo, 
    proposalMenuButtonPropsMemo, 
    clientsMenuButtonPropsMemo, 
    contractsMenuButtonPropsMemo,
    performanceMenuButtonPropsMemo, 
    backofficeMenuButtonPropsMemo
  } = useNavbarHook(onLogout);
  
  return (
    <DashboardPage
      topBarProps={topBarPropsMemo}
      dashboardMenuButtonProps={dashboardMenuButtonPropsMemo}
      proposalMenuButtonProps={proposalMenuButtonPropsMemo}
      clientsMenuButtonProps={clientsMenuButtonPropsMemo}
      contractsMenuButtonProps={contractsMenuButtonPropsMemo}
      performanceMenuButtonProps={performanceMenuButtonPropsMemo}
      backofficeMenuButtonProps={backofficeMenuButtonPropsMemo}

      pageTitle="Dashboard"
      pageSubTitle="Área Comercial"
      yearDropDownProps={{
        label: 'Ano',
        value: 'op1',
        options: [
          {
            label: '2021',
            value: 'op1',
          },
        ],
      }}
      monthDropDownProps={{
        label: 'Mês',
        value: 'op1',
        options: [
          {
            label: 'Janeiro',
            value: 'op1',
          },
        ],
      }}
      pendingApprovalProposalCardProps={{
        title: '234',
        subtitle: 'Propostas carecem de aprovação',
        onClick: () => alert('Propostas carecem de aprovação'),
      }}
      approvedProposalCardProps={{
        title: '105',
        subtitle: 'Propostas aprovadas',
        onClick: () => alert('Propostas aprovadas'),
      }}
      negotiationProposalCardProps={{
        title: '57',
        subtitle: 'Propostas em negociação',
        onClick: () => alert('Propostas em negociação'),
      }}
      endingContractsCardProps={{
        title: '578',
        subtitle: 'Contratos a terminar',
        onClick: () => alert('Contratos a terminar'),
      }}
      orderStatisticsProps={{
        legend: 'Encomendas',
        monthOrders: 2839,
        seeDetailsText: 'VER DETALHES',
        title: 'Estatítica de encomendas',
        graphicTitle: 'Encomendas este mês',
        statisticsData: [
          { name: 'Jan', value: 0 },
          { name: 'Fev', value: 10 },
          { name: 'Mar', value: 15 },
          { name: 'Abr', value: 36 },
          { name: 'Mai', value: 32 },
          { name: 'Jun', value: 42 },
        ],
      }}
      recentProposalProps={{
        title: 'Propostas recentes',
        seeAllText: 'VER TODOS',
        proposals: [
          {
            company: 'EGEO Pressão, S.A.',
            creationDateTime: 'Ontem, às 10:32 h',
            proposalId: 'Proposta Nº C010089803',
            proposalState: { tagColor: 'warning', tagLabel: 'Pendente' },
            proposalStateLabel: 'Em negociação',
            numberOfVehicles: '2 veículos',
            rentability: 'Rentabilidade',
            rentabiliyValue: '30%',
          },
          {
            company: 'EGEO Pressão, S.A.',
            creationDateTime: 'Ontem, às 10:32 h',
            proposalId: 'Proposta Nº C010089803',
            proposalState: { tagColor: 'success', tagLabel: 'Ganha' },
            proposalStateLabel: 'Em negociação',
            numberOfVehicles: '2 veículos',
            rentability: 'Rentabilidade',
            rentabiliyValue: '30%',
          },
          {
            company: 'EGEO Pressão, S.A.',
            creationDateTime: 'Ontem, às 10:32 h',
            proposalId: 'Proposta Nº C010089803',
            proposalState: { tagColor: 'error', tagLabel: 'Perdida' },
            proposalStateLabel: 'Em negociação',
            numberOfVehicles: '2 veículos',
            rentability: 'Rentabilidade',
            rentabiliyValue: '30%',
          },
        ],
      }}
      allProposalsProps={{
        searchButtonText: 'Pesquisar',
        clearText: 'Limpar',
        eraseLinkProps: { disabled: true },
        clientSearchResultProps: {
          label: 'Cliente',
          placeholder: 'Pesquisar pelo nome...',
          options: [
            { label: 'opção1', value: 'op1' },
            { label: 'opção2', value: 'op2' },
          ],
        },
        proposalNumberSearchResultProps: {
          label: 'Número da proposta',
          placeholder: 'Pesquisar nº da proposta...',
          options: [
            { label: 'opção1', value: 'op1' },
            { label: 'opção2', value: 'op2' },
          ],
        },
        searchButtonProps: { onClick: () => alert('Pesquisar') },
        statesDropDownProps: {
          label: 'Estados',
          value: 'opt1',
          options: [
            {
              label: 'Todos',
              value: 'opt1',
            },
          ],
        },
        subTitle: 'Filtrar por',
        title: 'Todas as propostas',
        tableProps: {
          rows: proposals.map((cur) => {
            return {
              cell: [
                cur.client,
                cur.number,
                cur.state,
                cur.created,
                cur.channel,
                cur.negociation,
                cur.rentability,
              ],
            };
          }),
          headCells: [
            {
              id: 'client',
              label: 'Cliente',
            },
            {
              id: 'number',
              label: 'Proposta Nº ',
            },
            {
              id: 'state',
              label: 'Estado',
            },
            {
              id: 'created',
              label: 'Criada a',
            },
            {
              id: 'channel',
              label: 'Canal',
            },
            {
              id: 'negociation',
              label: 'Em negociação',
            },
            {
              id: 'rentability',
              label: 'Rentabilidade',
            },
          ],
        },
      }}
      annualOrderStateProps={{
        title: 'Status de encomendas anual',
        legend: [
          {
            title: 'Aprovada',
            color: '#00708D',
          },
          {
            title: 'Pendente',
            color: '#389FB5',
          },
          {
            title: 'Substítuida',
            color: '#BAEFED',
          },
          {
            title: 'Recusada',
            color: '#E3E9EB',
          },
        ],
        data: [
          {
            name: '1 Semana',
            aproved: 67,
            pending: 32,
            refused: 12,
            replaced: 10,
          },
          {
            name: '2 Semana',
            aproved: 67,
            pending: 32,
            refused: 12,
            replaced: 10,
          },

          {
            name: '3 Semana',
            aproved: 67,
            pending: 32,
            refused: 12,
            replaced: 10,
          },
          {
            name: '4 Semana',
            aproved: 67,
            pending: 32,
            refused: 12,
            replaced: 20,
          },
        ],
      }}
    />
  );
};

export default React.memo(DashboardDemo);
