import {
    GreenArrowUpIcon,
    ProposalsApprovationPageProps,
  } from '@hi-interactive-admin/simulador-kinto';
  
  export const step1DetailsExample: ProposalsApprovationPageProps['proposalSelectionProps']['tableData'][0]['simulations'][0]['details'] =
    {
      impact: {
        title: 'Impacto das alterações na sua renda',
        prevValue: {
          label: 'Valor anterior',
          value: '381,93€  |  481,93€',
        },
        curValue: {
          label: 'Valor atual',
          value: '391,93€  |  491,93€',
        },
        arrowIcon: GreenArrowUpIcon,
      },
      proposedVehicleArea: {
        title: 'Veículo proposto',
        amount: {
          label: 'Quantidade',
          value: '2',
        },
        vehicleSummary: {
          label: 'Marca | Modelo | Versão',
          value: 'Peugeot 208 Diesel - 208 1.5 Bluehdi Business Active',
        },
        fuelType: {
          label: 'Combustível',
          value: 'Gasolina',
        },
        cylinderCapacity: {
          label: 'Cilindrada',
          value: '1499 CC',
        },
        potency: {
          label: 'Potência',
          value: '107 CV',
        },
        consumption: {
          label: 'Consumo (UHB | EXTA | COMB)',
          value: '4,30 | 3,20| 4,00',
        },
        registrationPlateDate: {
          label: 'Data da matricula',
          value: '-',
        },
        currentKms: {
          label: 'Km atuais',
          value: '0',
        },
        co2: {
          label: 'CO2',
          value: '140 Gr',
        },
        doors: {
          label: 'Portas',
          value: '5',
        },
        seats: {
          label: 'Lugares',
          value: '5',
        },
        pvpWithoutTax: {
          label: 'PVP sem IVA',
          value: '22.579,59€',
        },
      },
      servicesArea: {
        title: 'Serviços (incluídos / excluídos)',
        insuranceManagement: {
          label: 'Gestão de seguro',
          value: 'RC (50.000,00€), Ocupantes (MIP 10.000€ e DT 1.000€)',
        },
        accidentDamageEditableFieldProps: {
          label: 'Danos do veículo em sinistro',
          defaultValue: 'op1',
          options: [
            {
              label: 'Incluído. Franquia de 902,75€+IVA em vigor',
              value: 'op1',
            },
            {
              label: 'Opção 2',
              value: 'op2',
            },
          ],
        },
        maintenance: {
          label: 'Manutenção',
          value: 'Incluído. Programada',
        },
        tyresEditableFieldProps: {
          label: 'Pneus',
          defaultValue: 'op1',
          options: [
            {
              label: 'Incluído. Até 4 pneus.',
              value: 'op1',
            },
            {
              label: 'Opção 2',
              value: 'op2',
            },
          ],
        },
        iuc: {
          label: 'IUC',
          value: 'Incluído. Valor inicial de 32,52€',
        },
        viaVerde: {
          label: 'Gestão de Via Verde',
          value: 'Não incluído',
        },
        accidentVSEditableFieldProps: {
          label: 'VS em sinistro',
          defaultValue: 'op1',
          options: [
            {
              label: 'Incluído. Até 30 dias (Citadino)',
              value: 'op1',
            },
            {
              label: 'Opção 2',
              value: 'op2',
            },
          ],
        },
        stealthVS: {
          label: 'VS em furto',
          value: 'Incluído. Até 60 dias (Citadino)',
        },
        reviewVS: {
          label: 'VS em revisão',
          value: 'Incluído. Até 2 dias (Citadino)',
        },
        malfunctionsVS: {
          label: 'VS em avaria',
          value: 'Incluído. Até 15 dias (Citadino)',
        },
        fuelManagement: {
          label: 'Gestão de combustível',
          value: 'Não incluído',
        },
        othersEditableFieldProps: {
          label: 'Outros serviços',
          defaultValue: 'op1',
          options: [
            {
              label: '-',
              value: 'op1',
            },
            {
              label: 'Opção 2',
              value: 'op2',
            },
          ],
        },
      },
      characteristicsArea: {
        title: 'Características ',
        rentDurationEditableFieldProps: {
          label: 'Duração do aluguer',
          defaultValue: 'op1',
          options: [
            {
              label: '36 Meses',
              value: 'op1',
            },
            {
              label: 'Opção 2',
              value: 'op2',
            },
          ],
        },
        contractedKmsEditableFieldProps: {
          label: 'Km contratados',
          defaultValue: 'op1',
          options: [
            {
              label: '100.000 Km',
              value: 'op1',
            },
            {
              label: 'Opção 2',
              value: 'op2',
            },
          ],
        },
        maxKms: {
          label: 'Km máximos',
          value: '130.000 Km',
        },
        additionalKmsWithoutTax: {
          label: 'Km adicional sem IVA',
          value: '0,041€',
        },
        kmNotTraveledWithoutTax: {
          label: 'Km não percorridos sem IVA',
          value: '0,00€',
        },
        initialEntryWithoutTax: {
          label: 'Entrada inicial sem IVA',
          value: '2008',
        },
        securityDeposit: {
          label: 'Deposito caução',
          value: '2008',
        },
        modelYear: {
          label: 'Ano do modelo',
          value: '2008',
        },
      },
      paymentsArea: {
        title: 'Pagamentos',
        startingPayments: {
          label: 'Início dos pagamentos',
          value: 'Antecipado',
        },
        paymentsNumber: {
          label: 'Nº de pagamentos',
          value: '36',
        },
        frequency: {
          label: 'Periodicidade',
          value: 'Mensal',
        },
        totalWithoutTax: {
          label: 'Total sem IVA',
          value: '335,52€',
        },
        totalWithTax: {
          label: 'Total com IVA',
          value: '427,11€',
        },
      },
    };