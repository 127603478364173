//import axios from "axios";
import axiosInstance from "../Utilities/axiosInstance";
//import settings from "../appSettings.json";
import { UserModel } from "../model/User";
import { SettingsService } from "./SettingsService";
import { APIResponse } from "../model/APIResponse";

//import { SettingsService } from "./SettingsService";

export class UserServices {
  //private readonly settingsService = SettingsService.getInstance();
  // constructor(){
  //   //const settingsService = await SettingsService.getInstance();
  //   this.EntityURL = this.settingsService.settings.APIBaseURL + this.settingsService.settings.Users_RelativeURL;
  // }

  private EntityURL: string = '';// = settings.APIBaseURL + settings.Users_RelativeURL;
  
  loadSettings =  async () => {
    if(this.EntityURL.length === 0){
      const settingsService = await SettingsService.getInstance();
      this.EntityURL = settingsService.settings.APIBaseURL + settingsService.settings.Users_RelativeURL;
    }
  };

  logInUser =  async (user: string, password: string): Promise<APIResponse> => {
    await this.loadSettings();
    try {
      //const { data } = await axiosInstance.get(this.EntityURL + user + "/" + password);
      const { data } = await axiosInstance.post(this.EntityURL + 'login',{userName: user, password: password});
      return data;
    } catch (error: any) {
      console.log(error.response);
      return {
        succeeded: false,
        hasErrors:true,
        message:
          error.message !== ""
            ? error.message
            : "Ocorreu um erro ao criar um utilizador.",
        value: {},
        errors: [],
      };
    }
  };

  getUserById = async (id:number, active:boolean | null | undefined): Promise<APIResponse> => {
    await this.loadSettings();
    try {
      let url = this.EntityURL + 'user/' + id;
      if(active !== (null || undefined))
      {
        url += `/${active}`;
      }
      const { data } = await axiosInstance.get(url);
      return data;
    } catch (error: any) {
      console.log(error.response);
      return {
        succeeded: false,
        hasErrors:true,
        message:
          error.message !== ""
            ? error.message
            : "Ocorreu um erro ao criar um utilizador.",
        value: {},
        errors: [],
      };
    }
  };

  add = async (user:UserModel): Promise<APIResponse> => {
    await this.loadSettings();
    try {
      const { data } = await axiosInstance.post(this.EntityURL,user);

      return data;
    } catch (error: any) {
      console.log(error.response);
      return {
        succeeded: false,
        hasErrors:true,
        message:
          error.message !== ""
            ? error.message
            : "Ocorreu um erro ao criar um utilizador.",
        value: {},
        errors: [],
      };
    }
  };

  csvImportUsers = async (usersList: UserModel[]): Promise<APIResponse> => {
    await this.loadSettings();
    try {
      let url = `${this.EntityURL}csvImport/`
      const { data } = await axiosInstance.post(url,usersList);

      return data;
    } catch (error: any) {
      console.log(error.response);
      return {
        succeeded: false,
        hasErrors:true,
        message:
          error.message !== ""
            ? error.message
            : "Ocorreu um erro ao importar os utilizadores.",
        value: {},
        errors: [],
      };
    }
  }

  get = async () => {
    await this.loadSettings();
    try {
      const { data } = await axiosInstance.get(this.EntityURL);
      return data.value;
    } catch (error) {
      console.log(error);
    }
  };

  getUserByNameOrNumber = async (searchParam:string) => {
    await this.loadSettings();
    try {
      const { data } = await axiosInstance.get(this.EntityURL + 'SearchUser/' + searchParam);
      return data.value;
    } catch (error) {
      console.log(error);
    }
  };

  delete = async (userId:number): Promise<APIResponse> => {
    await this.loadSettings();
    try {
      const { data } = await axiosInstance.delete(this.EntityURL + 'delete/' + userId);
      return data;
    } catch (error: any) {
      console.log(error);
      return {
        succeeded: false,
        hasErrors:true,
        message:
          error.message !== ""
            ? error.message
            : "Ocorreu um erro ao importar os utilizadores.",
        value: {},
        errors: [],
      };
    }
  }

  changePwd = async (user:UserModel,newPassword:string) => {
    await this.loadSettings();
    try {
      const url = this.EntityURL + 'change-password';
      const { data } = await axiosInstance.post(url, {
        newPassword: newPassword, 
        destinationUserId: user.userId ? user.userId.toString() : "-1"
      });
      return data;
    } catch (error) {
      console.log(error);
      let errorMessage = "Ocorreu um erro ao alterar a password. Por favor tente novamente.";
      if ((error as any).response && (error as any).response.status === 401) {
        errorMessage = "O periodo de tempo para alterar a password expirou. Por favor inicie um novo pedido de alteração/recuperação de password.";
      }
      return {
        succeeded: false,
        hasErrors:true,
        message: errorMessage,
        value: {},
        errors: [],
      };
    }
  };

  validatePwd = async (username:string,pwd:string) => {
    await this.loadSettings();
    try {
      let usernameDto:{
        username: string,
        password:string
      } = {
        username:username,
        password:pwd
      }
      const { data } = await axiosInstance.post(this.EntityURL + 'validate/',usernameDto);

      return data.value;
    } catch (error) {
      console.log(error);
    }
  };

  uploadImage = async (id:number,formData:FormData) => {
    await this.loadSettings();
    try {
      const { data } = await axiosInstance.post(this.EntityURL + 'uploadImage/'+id,formData);

      return data;
    } catch (error) {
      console.log(error);
    }
  };

  removeImage = async (id:number) => {
    await this.loadSettings();
    try {
      const { data } = await axiosInstance.post(this.EntityURL + 'removeImage/'+id,"");

      return data;
    } catch (error) {
      console.log(error);
    }
  };

  // getAuth = async () => {
  //   try {
  //     const { data } = await axiosInstance.get(this.EntityURL + 'auth/');
  //     return data;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  //  validateToken = async (token:string): Promise<APIResponse> => {
  //   await this.loadSettings();
  //   try {
  //     let url = this.EntityURL + 'validate-token/'
  //     const { data } = await axiosInstance.post(url, undefined, {
  //       headers: {
  //         "Authorization": `Bearer:${token}`,
  //         "Content-Type": "application/json",
  //       },
        
  //     });
  //     return data;
  //   } catch (error: any) {
  //     console.log(error.response);
  //     return {
  //       succeeded: false,
  //       hasErrors:true,
  //       message:
  //         error.message !== ""
  //           ? error.message
  //           : "Ocorreu um erro ao criar um utilizador.",
  //       value: {},
  //       errors: [],
  //     };
  //   }
  // }

  refreshLogin = async (refreshToken:string): Promise<APIResponse> => {
    await this.loadSettings();
    try {
      let url = this.EntityURL + 'refresh-token/'
      const { data } = await axiosInstance.post(url, {refreshToken: refreshToken}, {
        headers: {
          "Content-Type": "application/json",
        },
        
      });
      return data;
    } catch (error: any) {
      console.log(error.response);
      return {
        succeeded: false,
        hasErrors:true,
        message:
          error.message !== ""
            ? error.message
            : "Ocorreu um erro ao atualizar o token.",
        value: {},
        errors: [],
      };
    }
  }

  sendEmailForgotPassword = async (email:string): Promise<APIResponse> => {
    await this.loadSettings();
    try {
     
      const { data } = await axiosInstance.post(this.EntityURL + 'SendEmailForgotPassword', {email: email});

      return data
      ;
    }  catch (error: any) {
      console.log(error.response);
      return {
        succeeded: false,
        hasErrors:true,
        message:
          error.message !== ""
            ? error.message
            : "Ocorreu um erro ao enviar o email.",
        value: {},
        errors: [],
      };
    }
  };
 
   sendSendEmailWelcome = async (email:string) => {
    await this.loadSettings();
    try {
     
      const { data } = await axiosInstance.post(this.EntityURL + 'SendEmailWelcome', {email: email});

      return data.value;
    } catch (error) {
      console.log(error);
    }
  };
}

