

import SecureLS from "secure-ls";
const ls = new SecureLS({encodingType: 'aes'});
// export class Auth1 {
//   private static _rememberMe = ls.get("rememberMe");
//   static get rememberMe() {
//     if (this._rememberMe) {          
//       return this._rememberMe;
//     }
//     return false;
//   }
//   static getRememberMe() {
//     let value = ls.get("rememberMe");
//     if (value) {
//       return value;
//     } else {
//       return false;
//     }
//   }
//   static setRememberMe(value: string) {
//     ls.set("rememberMe", value);
//   }
//   static setToken = (userToken: string) => {
//     if (Auth1.getRememberMe()) {
//       ls.set("token", userToken);
//     } else {
//       ls.set("token", userToken);
//     }
//   };
//   static getToken() {
//     let tokenString: any = "";
//     if (Auth1.getRememberMe()) {
//       tokenString = ls.get("token");
//     } else {
//       tokenString = ls.get("token");
//     }
//     return tokenString || "";
//   }
//   static clearToken() {
//     ls.set("token", "");
//     ls.remove("rememberMe");
//     return "";
//   }
// }


export class Auth {
  
  private static _rememberMe = localStorage.getItem("rememberMe");

  static get rememberMe() {
    if (this._rememberMe){
      //valor true ou false
      return JSON.parse(this._rememberMe)
    }
    //null ou undefined
    return false;
  }

  static getRememberMe() {
    let value = localStorage.getItem("rememberMe")
    if( value ) {
      return JSON.parse(value)
    }
    else {
      return false;
    }
  }
  static setRememberMe(value:string) {
    localStorage.setItem("rememberMe",value);
  }

  static setToken = (userToken: string) => {
    if (Auth.getRememberMe()) {
      localStorage.setItem("token", userToken);
    }
    else {
      sessionStorage.setItem("token", userToken);
    }
  };

  static getToken() {
    let tokenString:any = '';
    if (Auth.getRememberMe()) {
      tokenString = localStorage.getItem("token");
    }
    else {
      tokenString = sessionStorage.getItem("token");
    }
    //const tokenString = localStorage.getItem("token");
    return tokenString || "";
  }

  static clearToken() {
    // if (Auth.rememberMe) {
      localStorage.setItem("token","");
      //localStorage.removeItem("rememberMe")
    // }
    // else {
      sessionStorage.setItem("token", "");
      this.clearRefreshToken();

      // debug log (temp)
      const currentTime = new Date();
      currentTime.setHours(currentTime.getHours() + 1);
      localStorage.setItem("lastTokenClear", currentTime.toISOString());
    // }
    return "";
  }

  static setRefreshToken = (refreshToken: string) => {
    if (Auth.getRememberMe()) {
      ls.set("refreshToken", refreshToken);
    } else {
      ls.set("refreshToken", refreshToken);
    }
  };
  static getRefreshToken() {
    let refreshToken: string = "";
      try {
        refreshToken = ls.get("refreshToken");
      } catch (error) {
        
      }

    return refreshToken;
  }
  static clearRefreshToken() {
    ls.set("refreshToken", "");
    return "";
  }

  static getUserId() {
    let userId: any = '';
    if(Auth.getRememberMe()){
      userId = localStorage.getItem("userId");
    } else {
      userId = sessionStorage.getItem("userId");
    }
    return userId || "";
  }
  
  static setUserId(userId: string) {
    if(Auth.getRememberMe()){
      localStorage.setItem("userId", userId);
    } else {
      sessionStorage.setItem("userId", userId);
    }
  }
}
