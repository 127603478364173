import {  useCallback, useMemo, useState } from "react";

const useDistanceCustomHook = () => {
  //INICIO Km por ano
  const [newMileageValue, setNewMileageValue] = useState<string>('')
  const handleToggleSelectMileageCallback = useCallback((option:string) => () => {
    setMileage((rows:any[]) => 
      rows.map(row => {
        if ( row.value === option) {
          row.checked = !row.checked
        }
        return row;
      })
    )
  },[]);

  const [mileage, setMileage] = useState<any[]>([
    {
      label: '10000 km/ano',
      value: '10000',
      checked: false,
      onClick: handleToggleSelectMileageCallback("10000"),
    },
    {
      label: '20000 km/ano',
      value: '20000',
      checked: false,
      onClick: handleToggleSelectMileageCallback('20000')
    },
    {
      label: '30000 km/ano',
      value: '30000',
      checked: false,
      onClick: handleToggleSelectMileageCallback("30000"),
    },
    {
      label: '40000 km/ano',
      value: '40000',
      checked: false,
      onClick: handleToggleSelectMileageCallback("40000"),
    },
    {
      label: '50000 km/ano',
      value: '50000',
      checked: false,
      onClick: handleToggleSelectMileageCallback("50000"),
    },
    {
      label: '60000 km/ano',
      value: '60000',
      checked: false,
      onClick: handleToggleSelectMileageCallback("60000"),
    },
  ]);
 
  const handleMileagePerYear = useCallback((options:any[]) => {
    let mileageOptions: any[any] = options;
    if(mileageOptions.length !== 0 ) {
      setMileage((rows:any[]) => {
        rows = mileageOptions;
        return rows;
      })
      return mileageOptions;
    }
    setMileage((rows:any[]) => {
      if(rows.length === 0) {
        rows.push(mileageOptions)
      }
      return rows;
    });
    return mileage;
  },[mileage])

  const mileageMemo = useMemo(() => handleMileagePerYear(mileage),
  [handleMileagePerYear, mileage])

  return { mileage, mileageMemo, setMileage, handleToggleSelectMileageCallback, setNewMileageValue, newMileageValue };
};

export default useDistanceCustomHook;